import styled from 'styled-components';

import { BorderRadius } from '../../consts/sizes';

import { PaperBlockProps } from './PaperBlock';

export const PaperWrapper = styled.div<PaperBlockProps>`
  background: #fff;
  border-radius: ${BorderRadius.default};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  transition: box-shadow ease-in-out 0.4s;

  ${({ hoverable }) =>
    hoverable &&
    `
    cursor: pointer;
    
    &:hover {
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    }
  `}
`;
