export enum ProfileRoutes {
  Dashboard = '/dashboard/profile/',
  DashboardMobile = '/dashboard/profile/mobile-menu',
  EditPersonalData = '/dashboard/profile/edit',
  EditCompetitionsData = '/dashboard/profile/edit-expertises',
  EditInterestsData = '/dashboard/profile/edit-interests',
  Organizations = '/dashboard/profile/organizations/',
  Publications = '/dashboard/profile/publications',
  Fields = '/dashboard/profile/fields',
  AllFields = '/dashboard/profile/fields/allFields',
  OrgFields = '/dashboard/profile/fields/organizationFields',
  Widgets = '/dashboard/profile/widgets',
  EditWeatherWidgets = '/dashboard/profile/widgets/weather',
  FullEditWeatherWidgets = '/dashboard/profile/widgets/weather/full',
  FullEditQuoteWidgets = '/dashboard/profile/widgets/quotes/full',
  EditQuotesWidgets = '/dashboard/profile/widgets/quotes',
  ChangeLog = '/dashboard/profile/changelog',
  ExternalAgroidProfile = '/agroid',
  ExternalAgroidProfileDev = 'http://localhost:3100/agroid',
}
