import { FC, useState, useEffect, useRef } from 'react';
import { YMaps, Map, Placemark, GeolocationControl } from 'react-yandex-maps';
import { Input, Button } from '@uralhim-innovative/farmik-ui';

import postImage from '../../../modules/shared/assets/images/post_page.png';

import {
  Wrapper,
  ActionsColumn,
  ButtonsWrapper,
  EmptyWrapper,
  SuggestItem,
  SuggestItemSubTitle,
  SuggestItemTitle,
  SuggestItemsWrapper,
} from './style';

type Props = {
  hideForm: () => void;
  handleChangeCoords: (coords: number[], address: string) => void;
  defaultCoords: number[];
  defaultAddress: string;
  finishForm: () => void;
};

type SuggestItemType = {
  value: string;
  displayName: string;
};

export const MapWeather: FC<Props> = ({
  finishForm,
  hideForm,
  handleChangeCoords,
  defaultCoords,
  defaultAddress,
}) => {
  const ymaps: any = useRef(null);
  let map: any;

  const defaultViewCoords = defaultCoords.length
    ? [Number(defaultCoords[0].toFixed(4)), Number(defaultCoords[1].toFixed(4))]
    : [];

  const [coordState, setCoordState] = useState<number[]>(defaultCoords.length ? defaultCoords : []);
  const [coordView, setCoordView] = useState<number[]>(defaultViewCoords);
  const [suggestItems, setSuggestItems] = useState<SuggestItemType[]>([]);
  const [address, setAddress] = useState(defaultAddress);

  useEffect(() => {
    if (coordState.length) {
      handleChangeCoords(coordState, address);
    }
  }, [coordState, address]);

  useEffect(() => {
    if (ymaps.current) {
      setTimeout(() => {
        ymaps.current.suggest(address).then((items: SuggestItemType[]) => {
          setSuggestItems(items);
        });
      }, 1000);
    }
  }, [address]);

  const onYmapsLoad = (ymapsData: any) => {
    ymaps.current = ymapsData;

    if (!defaultViewCoords.length) {
      ymaps.current.geolocation
        .get({
          // Выставляем опцию для определения положения по ip
          provider: 'yandex',
          // Карта автоматически отцентрируется по положению пользователя.
          mapStateAutoApply: true,
        })
        .then((result: any) => {
          map.geoObjects.add(result.geoObjects);
        });
    }
  };

  const handleInstance = (instance: any) => {
    if (!map) {
      map = instance;
      // instance.events.add('click', handleOnMap);
    }
  };

  const handleOnMap = (event: any) => {
    if (event) {
      const coords = event.get('coords');

      if (ymaps.current && ymaps.current.geocode) {
        ymaps.current.geocode(coords).then((res: any) => {
          const firstGeoObject = res.geoObjects.get(0);

          setAddress(firstGeoObject.getAddressLine());
        });
      }

      setCoordState(coords);
      setCoordView([coords[0].toFixed(4), coords[1].toFixed(4)]);
    }
  };

  const handleChangeSearch = (value: string) => {
    setAddress(value);
  };

  const handleCLickOnSuggestItem = (item: SuggestItemType) => () => {
    setAddress(item.value);

    if (ymaps) {
      ymaps.current.geocode(item.value).then((res: any) => {
        const firstGeoObject = res.geoObjects.get(0);

        const coords = firstGeoObject.geometry._coordinates;

        setCoordState(coords);
        setCoordView([coords[0].toFixed(4), coords[1].toFixed(4)]);
      });
    }
  };

  const renderSuggestItem = (item: SuggestItemType, index: number) => {
    // eslint-disable-next-line prefer-destructuring
    const title = item.displayName.split(',')[0];
    return (
      <SuggestItem
        data-test-id={`map-suggest-item-${index}`}
        key={item.displayName}
        onClick={handleCLickOnSuggestItem(item)}
      >
        <SuggestItemTitle>{title}</SuggestItemTitle>
        <SuggestItemSubTitle>{item.displayName}</SuggestItemSubTitle>
      </SuggestItem>
    );
  };

  const disabledFinishButton = !coordState.length || !coordState[0] || !coordState[1];

  return (
    <Wrapper>
      <ActionsColumn>
        <div>
          <Input
            dataTestId={'map-suggest-input'}
            startIcon="search"
            placeholder="Поиск"
            onChange={handleChangeSearch}
            value={address}
          />
          {suggestItems.length ? (
            <SuggestItemsWrapper>
              {suggestItems.map((item, index) => renderSuggestItem(item, index))}
            </SuggestItemsWrapper>
          ) : (
            ''
          )}
        </div>
        {!suggestItems.length && (
          <EmptyWrapper>
            <img src={postImage} alt="" />
            <br />
            <span>
              Найдите поле через поиск
              <br /> или точку на карте
            </span>
          </EmptyWrapper>
        )}
        <ButtonsWrapper>
          <Button type="button" color="default" onClick={hideForm} dataTestId={'map-cancel'}>
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={finishForm}
            disabled={disabledFinishButton}
            dataTestId={'map-finish'}
          >
            Применить
          </Button>
        </ButtonsWrapper>
      </ActionsColumn>
      <div>
        <YMaps
          query={{
            ns: 'use-load-option',
            load: 'package.full',
            apikey: 'fc3a7de7-4fd6-4699-a3cc-c1b3863ed0b0',
          }}
        >
          <div>
            <Map
              width={856}
              height={600}
              defaultState={{
                center: coordView,
                zoom: 11,
                modules: ['geoObject.addon.hint', 'SuggestView'],
              }}
              state={{
                center: coordView,
                zoom: 11,
                modules: ['geoObject.addon.hint', 'SuggestView'],
              }}
              onLoad={onYmapsLoad}
              // @ts-ignore
              instanceRef={handleInstance}
              onClick={handleOnMap}
            >
              {coordState.length ? (
                <Placemark
                  // @ts-ignore
                  geometry={coordState}
                  modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                  properties={{
                    hintContent: 'Поле',
                    balloonContent: 'Поле',
                  }}
                />
              ) : undefined}
              <GeolocationControl options={{ float: 'left' }} />
            </Map>
          </div>
        </YMaps>
      </div>
    </Wrapper>
  );
};
