import React, { FC, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { AuthorizationRoutes } from '../../../authorization/routes';
import { Breakpoints } from '../../../shared/consts/sizes';
import { useStore } from '../../../shared/utils/IoC';
import { SpinnerLoader, SpinnerLoaderRelativeWrapper } from '../../../shared/styles/dotsLoader';

import { DashboardRoutes } from './dashboard.routes';
import { ProfileRouter } from './pages/profile/ProfileRouter';
import { OrganizationRouter } from './pages/organization/OrganizationRouter';
import { DashboardController } from './controllers/dashboard.controller';

export const DashboardRouter: FC = observer(() => {
  const authPath =
    process.env?.NODE_ENV === 'development'
      ? `${window._env_.AUTH_URL}${AuthorizationRoutes.signInDev}`
      : `${window._env_.AUTH_URL}${AuthorizationRoutes.signIn}`;

  const { checkIsUserLogined, checkIsNoNeedAdditionalInfo } = useStore(DashboardController);

  const [userDefined, setUserDefined] = useState(false);
  const [showedLoader, setShowedLoader] = useState(true);

  const handleSmothHideLoader = () => {
    setUserDefined(true);

    setTimeout(() => {
      setShowedLoader(false);
    }, 3000);
  };

  useEffect(() => {
    (async () => {
      try {
        await checkIsUserLogined(authPath);
        await checkIsNoNeedAdditionalInfo(authPath);
      } catch (e) {
        console.log(e);
      } finally {
        handleSmothHideLoader();
      }
    })();
  }, []);

  return (
    <>
      {userDefined && (
        <Wrapper>
          <Switch>
            <Route
              path={`${DashboardRoutes.organization}/:organizationId`}
              component={OrganizationRouter}
            />
            <Route path={DashboardRoutes.profile} component={ProfileRouter} />
            <Redirect to={DashboardRoutes.profile} />
          </Switch>
        </Wrapper>
      )}
      {showedLoader && (
        <SpinnerLoaderRelativeWrapper>
          <SpinnerLoader data-test-id="dots-loader">
            <span />
          </SpinnerLoader>
        </SpinnerLoaderRelativeWrapper>
      )}
    </>
  );
});

const Wrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: ${Breakpoints.small}) {
    padding-left: 0;
    padding-right: 0;
  }
`;
