import { FC } from 'react';

import AvatarMock from '../../../../assets/avatar-mock.png';

import { Wrapper, RightColumn, Content, Time, LeftColumn, Title, Avatar } from './style';

type FeedNotificationProps = {
  id: string;
  avatar: string;
  name: string;
  content: string;
  time: string;
};

export const FeedNotification: FC<FeedNotificationProps> = ({
  children,
  id,
  content,
  name,
  avatar,
  time,
}) => {
  return (
    <Wrapper>
      <LeftColumn>
        <Avatar src={avatar || AvatarMock} />
      </LeftColumn>
      <RightColumn>
        <Title>{name || 'Организация'}</Title>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
        <Time>{time}</Time>
      </RightColumn>
    </Wrapper>
  );
};
