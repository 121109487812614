import { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { NotificationMenu } from '../../components/NotificationMenu/NotificationMenu';
import { MainRoutes } from '../../../main/routes';
import { NotificationRoutes } from '../../NotificationRoutes';
import { NotificationFeed } from '../../components/NotificationFeed/NotificationFeed';
import { useStore } from '../../../shared/utils/IoC';
import { NotificationStore } from '../../stores/NotificationStore';
import { Settings } from '../../components/Settings/Settings';
import { ProfileMenuMobilePageHeaderWrapper } from '../../../main/modules/dashboard/pages/profile/components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../shared/components/MobilePageHeader/MobilePageHeader';
import { MobileTabs } from '../../../shared/components/MobileTabs/MobileTabs';
import { RootStoresContext } from '../../../App';

import {
  PageWrapper,
  ContentColumn,
  MenuColumn,
  SettingsColumn,
  RightColumn,
  MobileMenuWrapper,
} from './style';

const links: Array<{ alias: string; href: string }> = [
  {
    alias: 'Все уведомления',
    href: MainRoutes.notifications,
  },
  {
    alias: 'Комментарии',
    href: NotificationRoutes.comments,
  },
  {
    alias: 'Заявки',
    href: NotificationRoutes.applications,
  },
];

const NotificationsAll: FC = observer(() => {
  const store = useStore(NotificationStore);
  const currentStore = store.allNotificationsStore;
  useEffect(() => {
    currentStore.clearStore();
    store.bootstrapAllStore();
  }, []);
  return (
    <NotificationFeed
      title={'Уведомления'}
      isPaginational={currentStore.isAdditionalLoadAllow}
      fetchAdditional={currentStore.fetchAdditional}
      notificationList={currentStore.notifications}
      isAdditionalLoading={currentStore.isAdditionalLoading}
    />
  );
});

const NotificationsComments: FC = observer(() => {
  const store = useStore(NotificationStore);
  const currentStore = store.commentsNotificationStore;

  useEffect(() => {
    currentStore.clearStore();
    store.bootstrapCommentsStore();
  }, []);
  return (
    <NotificationFeed
      title={'Комментарии'}
      isPaginational={currentStore.isAdditionalLoadAllow}
      fetchAdditional={currentStore.fetchAdditional}
      notificationList={currentStore.notifications}
      isAdditionalLoading={currentStore.isAdditionalLoading}
    />
  );
});

const NotificationsApplications: FC = observer(() => {
  const store = useStore(NotificationStore);
  const currentStore = store.applicationsNotificationStore;
  useEffect(() => {
    currentStore.clearStore();
    store.bootstrapApplicationsStore();
  }, []);
  return (
    <NotificationFeed
      title={'Заявки'}
      isPaginational={currentStore.isAdditionalLoadAllow}
      fetchAdditional={currentStore.fetchAdditional}
      notificationList={currentStore.notifications}
      isAdditionalLoading={currentStore.isAdditionalLoading}
    />
  );
});

export const NofiticationsPage: FC = observer(() => {
  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent } = headerComponentStore;
  const history = useHistory();
  const [isSettingChosed, setIsSettingChosed] = useState(false);

  const changeSettings = useEffect(() => {
    // if (true) {
    setHeaderContent(
      <ProfileMenuMobilePageHeaderWrapper>
        <MobilePageHeader
          title={'Уведомления'}
          onBack={() => {
            history.push('');
          }}
          onClickSettings={() => history.push(NotificationRoutes.settings)}
        />
        <MobileTabs
          items={links.map(i => {
            return { label: i.alias, path: i.href };
          })}
        />
      </ProfileMenuMobilePageHeaderWrapper>,
      {
        height: '90px',
      }
    );
    // }

    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  return (
    <PageWrapper>
      <MenuColumn>
        <NotificationMenu links={links} />
      </MenuColumn>
      <ContentColumn>
        <Switch>
          <Route path={NotificationRoutes.all} exact={true} component={NotificationsAll} />
          <Route
            path={NotificationRoutes.comments}
            exact={true}
            component={NotificationsComments}
          />
          <Route
            path={NotificationRoutes.applications}
            exact={true}
            component={NotificationsApplications}
          />
          <Route path={NotificationRoutes.settings} exact={true} component={Settings} />
        </Switch>
      </ContentColumn>
      <RightColumn>
        <MobileMenuWrapper>
          <NotificationMenu links={links} />
        </MobileMenuWrapper>
        <SettingsColumn>
          <Settings />
        </SettingsColumn>
      </RightColumn>
    </PageWrapper>
  );
});
