import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { TypeService } from '../../../../shared/types/service';
import { Axios2 } from '../../../../shared/utils/axios2';

@provide.singleton()
export class ServiceStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  service?: TypeService;
  serviceErrorMessage = '';

  getServiceById = (serviceId: string) => {
    this.serviceErrorMessage = '';

    this.axios2.api
      .getService({ serviceId }, { omit: ['serviceId'] })
      .then(response => {
        runInAction(() => {
          this.service = response;
        });
      })
      .catch(error => {
        console.error(error);

        this.service = undefined;
        this.serviceErrorMessage = 'Сервис не найден';
      });
  };
}
