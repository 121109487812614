import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { OrganizationType } from '../types/OrganizationType';
import { OrganizationCard } from '../types/OrganizationCard';
import { ApplicationType } from '../types/ApplicationType';
import { Axios2 } from '../../../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationListingStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  isListingLoading = true;
  idToOrganizationType: Map<number, OrganizationType> = new Map();
  searchQuery = '';
  selectedTags: Array<string> = [];

  setTags = (v: Array<string>) => {
    this.selectedTags = v;
    this.fetchMyOrganizationCards();
  };

  setSearchQuery = (v: string) => {
    this.searchQuery = v;
    this.fetchMyOrganizationCards();
  };

  idToMyOrganization: Map<string, OrganizationCard> = new Map();
  idToMyApplication: Map<string, ApplicationType> = new Map();

  get myOrganizations() {
    return Array.from(this.idToMyOrganization.values());
  }

  fetchOrganizationsListing = () =>
    this.axios2.api
      .getOrganizationsMine(
        { nameOrInn: this.searchQuery, roles: this.selectedTags },
        { omit: ['roles', 'nameOrInn'] }
      )
      .then(response => {
        runInAction(() => {
          response.content.forEach(item => {
            this.idToMyOrganization.set(item.organizationId, {
              avatar: (item.logo && item.logo?.downloadUrl) || '',
              organizationId: item.organizationId,
              title: item.name,
              roleName: item.employeeRole,
              type: 'participate',
            });
          });
        });
      });

  fetchApplications = () =>
    this.axios2.api
      .getOrganizationApplicationsMine({
        nameOrInn: this.searchQuery,
        status: 'SENT',
      })
      .then(response => {
        runInAction(() => {
          response.content.forEach((item: ApplicationType) => {
            if (item.type !== 'INVITATION') {
              return;
            }

            this.idToMyApplication.set(item.applicationId, item);

            this.idToMyOrganization.set(item.applicationId, {
              avatar: '',
              organizationId: item.applicationId,
              title: item.organization ? item.organization.name : '',
              roleName: '',
              type: item.type === 'INVITATION' ? 'invitation' : 'application',
              applicationId: item.applicationId,
            });
          });
        });
      });

  fetchMyOrganizationCards = () => {
    this.isListingLoading = true;
    this.idToMyOrganization.clear();
    Promise.all([this.fetchApplications(), this.fetchOrganizationsListing()]).then(() => {
      this.isListingLoading = false;
    });
  };
}
