import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 24px;
  background: ${Colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Letters = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 16px;
  color: ${Colors.grayBorder};
  margin: 0;

  @media (max-width: ${Breakpoints.small}) {
    font-size: 11px;
  }
`;

export const Avatar = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 24px;

  @media (max-width: ${Breakpoints.small}) {
    width: 24px;
    height: 24px;
  }
`;
