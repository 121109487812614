import { action, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';

@provide.singleton()
export class WidgetWeatherStore {
  @lazyInject(Axios2)
  axios: Axios2;

  widgetIdToWeather: Map<string, Array<TypeApiResponse<'getCommonWeatherByPoint'>>> = new Map();
  slotToId: Map<string, string> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  fetchWeatherByPoints = (points: any[], key: string, id: string) => {
    this.slotToId.set(key, id);
    this.axios.api
      .getWeatherByPoints({ points })
      .then(response =>
        this.onFetchWeatherByPointsSuccess(
          response.map((item, index) => {
            return { ...item, linkId: points[index].linkId };
          }),
          key
        )
      )
      .catch(this.onFetchWeatherByPointsError);
  };

  @action.bound
  onFetchWeatherByPointsSuccess = (response: any, key: string) => {
    this.widgetIdToWeather.set(key, response);
  };

  onFetchWeatherByPointsError = () => {
    console.log('onFetchWeatherByPointsError');
  };
  fetchWeatherByPoint = (
    lat: number,
    lon: number,
    widgetSlot: string,
    linkId: string,
    subId: string
  ) => {
    this.slotToId.set(widgetSlot.toString(), linkId);
    this.axios.api
      .getCommonWeatherByPoint({
        lat,
        lon,
      })
      .then(response => this.onFetchWeatherByPointSuccess(response, widgetSlot, subId))
      .catch(this.onFetchWeatherByPointError);
  };

  protected onFetchWeatherByPointSuccess = (
    response: TypeApiResponse<'getCommonWeatherByPoint'>,
    widgetSlot: string,
    linkId: string
  ) => {
    console.log('onFetchWeatherByPointSuccess', response, linkId);

    this.widgetIdToWeather.set(widgetSlot, [{ ...response, linkId }]);
  };

  protected onFetchWeatherByPointError = () => {
    console.log('onFetchWeatherByPointError');
  };

  @action
  clear = () => {
    this.widgetIdToWeather.clear();
    this.slotToId.clear();
  };
}
