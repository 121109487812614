import { FC } from 'react';
import { Button } from '@uralhim-innovative/farmik-ui';

import { PaperBlock } from '../../../../../shared/components/PaperBlock';

import {
  Wrapper,
  SubServiceImage,
  SubServiceTitle,
  SubServiceDescription,
  ContentText,
} from './style';

type Props = {
  imgUrl: string;
  title: string;
  description: string;
  serviceUrl: string;
};

export const SubServiceCard: FC<Props> = ({ imgUrl, title, description, serviceUrl }) => (
  <PaperBlock hoverable>
    <Wrapper>
      <SubServiceImage src={imgUrl} />
      <SubServiceTitle>{title}</SubServiceTitle>
      <SubServiceDescription>{description}</SubServiceDescription>
      <a href={serviceUrl} target="_blank">
        <Button type="button" color="default">
          Подробнее
        </Button>
      </a>
    </Wrapper>
  </PaperBlock>
);
