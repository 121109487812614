import styled from 'styled-components';

import { Colors } from '../../consts/color';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 100vh;
  background: ${Colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  max-width: 340px;
  max-height: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
`;

export const BackButton = styled.div`
  left: 0;
  top: 20px;
  position: absolute;
  display: flex;
  font-size: 14px;
  color: ${Colors.darkGray};
  align-items: center;

  div {
    margin-right: 5px;
  }
`;

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 20px;
  margin-top: 20%;
`;

export const Description = styled.p`
  max-width: 300px;
  text-align: center;
`;

export const ButtonCustom = styled.div<{ $bgColor?: Colors }>`
  text-align: center;
  padding: 20px 50px;
  background: ${({ $bgColor }) => $bgColor || Colors.green};
  border-radius: 16px;
  color: ${Colors.white};
  width: 100%;
`;

export const SubDescription = styled.p`
  max-width: 300px;
  text-align: center;
  color: ${Colors.darkGray};
  font-weight: lighter;
`;

export const ButtonWrapper = styled.a<{ $margin?: string }>`
  width: 300px;
  margin: ${({ $margin }) => $margin || 0};
`;

export const SupportText = styled.p`
  max-width: 300px;
  text-align: center;
  color: ${Colors.darkGray};
  font-weight: lighter;
  font-size: 14px;
  margin-top: auto;
`;
