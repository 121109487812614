import styled from 'styled-components';

import { Colors } from '../../consts/color';

export const MobileTabsWrapper = styled.div`
  overflow: auto;
  margin-left: -15px;
  margin-right: -15px;
`;

export const MobileTabsInner = styled.div`
  display: flex;
  padding: 0 15px;
`;

export const MobileTabsItem = styled.div<{ active: boolean; hiddenItem?: boolean }>`
  font-size: 12px;
  color: ${({ active }) => (active ? Colors.green : Colors.darkGray)};
  margin-right: 20px;
  padding-bottom: 12px;
  padding-right: 8px;
  padding-left: 8px;
  position: relative;
  white-space: nowrap;
  display: ${({ hiddenItem }) => (hiddenItem ? 'none' : 'block')};

  ${({ active }) =>
    active &&
    `
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      border-radius: 20px;
      background: ${Colors.green};
    }
  `}
`;
