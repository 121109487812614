import { FC } from 'react';

import AvatarMockSVG from '../../assets/avatar-mock.svg';
import Logo1 from '../../assets/company/logo-1.png';
import Logo2 from '../../assets/company/logo-2.png';
import Logo3 from '../../assets/company/logo-3.png';
import Logo4 from '../../assets/company/logo-4.png';
import Logo5 from '../../assets/company/logo-5.png';
import { EmployeeRole } from '../../types/employeeRole';
import { ApplicationInvitationState } from '../../types/OrganizationCard';

import {
  Avatar,
  AvatarWrapper,
  CardStyledProps,
  OrganizationName,
  OrganizationNameWrapper,
  OrganizationRole,
  Wrapper,
} from './style';

type Props = {
  organizationId: string;
  avatar?: string;
  title: string;
  roleName: string;
  onClick: (id: string) => void;
  state?: ApplicationInvitationState;
} & CardStyledProps;

const AVATAR_ARRAY = [AvatarMockSVG, Logo1, Logo2, Logo3, Logo4, Logo5];

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const OrganizationCard: FC<Props> = ({
  // avatar,
  onClick,
  type,
  title,
  roleName,
  organizationId,
  state,
  avatar,
}) => {
  const getRoleName = (v: EmployeeRole) => {
    const variants: Record<EmployeeRole, string> = {
      EMPLOYEE: 'Сотрудник',
      MANAGER: 'Менеджер',
      OWNER: 'Владелец',
    };
    return variants[v];
  };

  const renderState = (v: ApplicationInvitationState) => {
    const variants: Record<ApplicationInvitationState, string> = {
      ACCEPTED: 'Принята',
      CANCELLED: 'Отменена',
      DECLINED: 'Отклонена',
      SENT: 'Новая заявка',
    };
    return variants[v];
  };

  const renderRole = () => {
    const variants: Record<Props['type'], any> = {
      search: 'Присоединиться',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      invitation: renderState(state!),
      participate: getRoleName(roleName as EmployeeRole),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      application: renderState(state!),
    };

    const value = variants[type];
    return <OrganizationRole>{value}</OrganizationRole>;
  };

  const renderTitle = () => (title.length > 14 ? `${title.substr(0, 14)}...` : `${title}`);

  return (
    <Wrapper onClick={() => onClick(organizationId)} type={type} status={state}>
      {/* {avatar ? <Avatar src={avatar} /> : <Avatar src={AvatarMockSVG} />} */}
      <AvatarWrapper>
        <Avatar src={avatar || AVATAR_ARRAY[getRandomInt(AVATAR_ARRAY.length)]} />
      </AvatarWrapper>
      <OrganizationNameWrapper>
        <OrganizationName>{renderTitle()}</OrganizationName>
      </OrganizationNameWrapper>
      {renderRole()}
    </Wrapper>
  );
};
