import styled from 'styled-components';

import { Breakpoints } from '../../../../../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 964px;
  padding: 10px;
  padding-top: 0;

  @media (max-width: ${Breakpoints.medium}) {
    width: 100%;
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 15px;
  display: -webkit-box;
  /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
  display: -moz-box;
  /* OLD: Firefox (buggy) */
  display: -ms-flexbox;
  /* MID: IE 10 */
  display: -webkit-flex;
  /* NEW, Chrome 21–28, Safari 6.1+ */
  display: flex;
  -webkit-flex-flow: row wrap;
  -webkit-justify-content: space-between;

  @media (max-width: ${Breakpoints.medium}) {
    padding: 0;
  }
`;
