export enum AuthorizationRoutes {
  signInDev = '/sign_in?systemId=localhost3000',
  signIn = '/sign_in?systemId=farmlink',
  signUp = '/sign_up?systemId=farmlink',
  reset = '/reset/?systemId=farmlink',
  invite = '/invite?systemId=farmlink',
  externalAuth = '/external_auth?systemId=farmlink',
  logout = '/logout?systemId=farmlink',
}

export enum RedirectRoutes {
  signIn = '/sign_in',
  signUp = '/sign_up',
}
