import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  min-height: 332px;
  width: 100%;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 8px;

  @media (max-width: ${Breakpoints.medium}) {
    height: auto;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: block;
    height: auto;
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const SocialBar = styled.div`
  border-top: 0.5px solid ${Colors.grayBorder};
  display: flex;
  align-items: center;
  height: 56px;
`;

export const SocialBarControl = styled.div`
  display: flex;
  height: 16px;
  align-items: center;
  color: ${Colors.darkGray};
  fill: ${Colors.black};
  margin-right: 15px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  *:first-child {
    margin-right: 3px;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;

export const SocialBarView = styled.div`
  display: flex;
  height: 16px;
  align-items: center;
  color: ${Colors.darkGray};
  font-size: 12px;
  line-height: 12px;
  *:first-child {
    margin-right: 3px;
    fill: ${Colors.darkGray} !important;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  @media (max-width: ${Breakpoints.medium}) {
    height: auto;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: block;
    height: auto;
    margin-bottom: 36px;
  }
`;

type ContentAreaStyledProps = {
  isExtended: boolean;
};

export const ContentArea = styled.div<ContentAreaStyledProps>`
  ${({ isExtended }) => (isExtended ? `width: 100%;` : `width: 592px;`)};
  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoints.medium}) {
    height: auto;
  }

  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
    height: auto;
  }
`;

export const Date = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 12px 0 8px;
  cursor: pointer;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0 0 8px;
  color: ${Colors.black};
  cursor: pointer;
`;

export const Description = styled.p`
  margin: 0 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${Colors.darkGray};
  cursor: pointer;

  @media (max-width: ${Breakpoints.medium}) {
    max-height: 100%;
    overflow: visible;
  }
`;

export const ReadMore = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.green};
  margin: 0;
  cursor: pointer;
  display: flex;
`;

export const NewsImageWrapper = styled.div<{ url: string }>`
  width: 224px;
  height: 224px;
  border-radius: 16px;
  margin-right: 32px;
  ${({ url }) => `background: url(${url}) center;`};
  background-size: cover;
  cursor: pointer;
  flex: 1 0 224px;
  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const MobileNewsImageWrapper = styled.div<{ url: string }>`
  width: 100%;
  max-width: 244px;
  height: 224px;
  border-radius: 16px;
  margin-right: 32px;
  ${({ url }) => `background: url(${url}) center;`};
  background-size: cover;
  cursor: pointer;
  margin-top: 16px;
  display: none;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
    max-width: 100%;
    height: 142px;
  }
`;

export const NewsImage = styled.img`
  width: 224px;
  height: 224px;
  border-radius: 16px;
`;

export const HideOnSmall = styled.div`
  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;
