export type WidgetData = {
  name: string;
  id: string | null;
  widget_config?: string;
  type?: string;
};

export type WidgetTypes = 'WEATHER' | 'QUOTES';

export type WidgetProp = {
  id: string;
  type: WidgetTypes;
  name: string;
  widget_config: string;
};

export enum WidgetMapTypes {
  WEATHER = 'Погода',
  QUOTES = 'Котировки',
}
