import { action, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../modules/shared/utils/IoC';
import { Axios2 } from '../modules/shared/utils/axios2';
import { WidgetMapTypes, WidgetProp } from '../modules/shared/types/editWidget.dto';

const emptyWidgetData = {
  id: '',
  name: '',
  type: '',
  widget_config: '',
};

type WidgetsOnPanel = {
  '1'?: WidgetProp;
  '2'?: WidgetProp;
  '3'?: WidgetProp;
};

const defaultQuotesWidget = {
  id: 'c79fd5aa-30f0-47e7-9852-c79fd5aa43f0',
  type: 'QUOTES',
  name: 'defaultQuotes',
  widget_config: JSON.stringify({
    size: 'small',
    positions: [
      {
        basisId: '06606ef2-f432-4e9c-9073-f6643bcb9e21',
        providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
        cultureId: '2396c6f0-6d2a-11eb-8d42-dd19d7aaf478',
        portId: '77d2ca2b-c4c6-41b4-a37c-2877c90eee03',
        currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
        unitsId: '154edcc0-6d3b-11eb-8d42-dd19d7aaf478',
        isActive: true,
      },
    ],
  }),
};

@provide.singleton()
export class EditWidgetStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  search = '';

  widgetsList: Array<WidgetProp> = [];

  mainPageWidgets: WidgetsOnPanel = {};

  widgetsGeoData: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  @action
  initiate = async () => {
    await this.loadWidgetsPanel();
    await this.loadWidgetsList();
  };

  @action
  setSearch = async (search: string) => {
    this.search = search;
    await this.loadWidgetsList();
  };

  @action
  loadWidgetsPanel = async () => {
    const response = await this.axios2.api.getWidgetsPanel({});
    if (response.widgets) {
      this.mainPageWidgets = Object.keys(response.widgets).reduce((acc, key) => {
        // @ts-ignore
        if (response.widgets && response.widgets[key]) {
          // @ts-ignore
          const widget = response.widgets[key];
          return {
            ...acc,
            [key]: widget,
          };
        } else {
          return acc;
        }
      }, this.mainPageWidgets);
    }
  };

  @action
  loadWidgetsList = async () => {
    const response = await this.axios2.api.getWidgetsList(
      {
        name: this.search,
      },
      { omit: ['size', 'page'] }
    );

    // this.widgetsList = [defaultQuotesWidget as WidgetProp, ...response.content];
    this.widgetsList = response.content;

    await this.onSuccessLoadWidgetsList(response.content);
  };

  @action.bound
  onSuccessLoadWidgetsList = (widgetsList: WidgetProp[]) => {
    widgetsList.forEach(async widget => {
      const widgetConfig = JSON.parse(widget.widget_config);

      if (!widgetConfig?.positions) {
        return;
      }
      if (widget.type === 'WEATHER') {
        const positions = widgetConfig?.positions.map((item: any) => {
          return { lat: item.lat, lon: item.lon };
        });

        const response = await this.axios2.api.getWeatherByPoints({ points: positions });

        this.widgetsGeoData = {
          ...this.widgetsGeoData,
          [widget.id]: response,
        };
      } else if (widget.type === 'QUOTES') {
        const positions = widgetConfig?.positions.map((item: any) => {
          return {
            basisId: item.basisId,
            providerId: item.providerId,
            cultureId: item.cultureId,
            portId: item.portId,
            currencyId: item.currencyId,
            unitsId: item.unitsId,
            economicRegionId: item.economicRegionId,
            cultureClassId: item.cultureClassId,
          };
        });

        const response = await this.axios2.api.getQuotesByPositions(positions, {
          noValidate: true,
        });

        this.widgetsGeoData = {
          ...this.widgetsGeoData,
          [widget.id]: response,
        };
      }
    });
  };

  @action
  bindWidgetToPanel = async (widgetId: string, location: number, widgetConfig: string) => {
    await this.axios2.api.updateWidgetInfo(
      {
        id: widgetId,
        widget_config: widgetConfig,
      },
      {
        omit: ['id'],
      }
    );
    await this.axios2.api.bindWidgetsPanel({
      widgetId,
      location,
    });
  };

  @action
  setMainPageWidget = (key: string, widgetData: WidgetProp) => {
    const oldWidgetPositionKey = Object.keys(this.mainPageWidgets).find(
      // @ts-ignore
      item => this.mainPageWidgets[item].id === widgetData.id
    );

    if (oldWidgetPositionKey) {
      // @ts-ignore
      this.mainPageWidgets[oldWidgetPositionKey] = emptyWidgetData;
    }

    // @ts-ignore
    this.mainPageWidgets[key] = widgetData;

    if (widgetData?.id) {
      this.bindWidgetToPanel(widgetData.id, Number(key), widgetData.widget_config);
    }
  };

  @action
  removeWidgetFromMainPage = (key: string) => {
    this.axios2.api.unbindWidgetsPanel(
      {
        // @ts-ignore
        widgetId: this.mainPageWidgets[key].id,
      },
      { omit: ['widgetId'] }
    );

    // @ts-ignore
    this.mainPageWidgets[key] = emptyWidgetData;
  };

  @action
  removeWidget = async (widgetId: string) => {
    await this.axios2.api.removeWidget({ widgetId }, { omit: ['widgetId'] });
    await this.loadWidgetsList();
  };

  @action
  clear = () => {
    this.search = '';
    this.widgetsList = [];
    this.mainPageWidgets = {};
    this.widgetsGeoData = {};
  };
}
