import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  ids: Array<string>;
};

export const saveInterestsToProfile: TypeApiRoute & {
  request: TypeRequest;
  response: any;
} = {
  url: `/api/da-profile/users/myProfile/interests`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as any,
};
