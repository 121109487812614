import { computed, makeAutoObservable, observable } from 'mobx';
import * as _ from 'lodash';

import { provide } from '../../modules/shared/utils/IoC';
import { BreakpointsNumbers } from '../../modules/shared/consts/sizes';

import { RootStore } from './rootStore';

@provide.singleton()
export class UiStateStore {
  windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  @computed
  get deviceWidth() {
    return this.windowDimensions.width;
  }

  root: RootStore;

  constructor(root: RootStore) {
    makeAutoObservable(this, { windowDimensions: observable.struct });

    this.root = root;

    window.onresize = _.throttle(() => {
      this.windowDimensions = this.getWindowDimensions();
    }, 300);
  }

  getWindowDimensions = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  get isMobileDevice() {
    return this.windowDimensions.width <= BreakpointsNumbers.small;
  }

  get isWidthLessThanContent() {
    return this.windowDimensions.width < 1284;
  }
}
