import Morning from '../assets/profile-illustrations/illustration-morning.svg';
import Day from '../assets/profile-illustrations/illustration-day.svg';
import Evening from '../assets/profile-illustrations/illustration-evening.svg';
import Night from '../assets/profile-illustrations/illustration-night.svg';

export function TimeToProfileIllustration() {
  const hours = new Date().getHours();

  if (hours <= 10) {
    return Morning;
  }
  if (hours >= 11 && hours <= 17) {
    return Day;
  }
  if (hours >= 18 && hours <= 21) {
    return Evening;
  }
  if (hours >= 22) {
    return Night;
  }
}
