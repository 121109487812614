import { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { OrganizationSearchStore } from '../../stores/organization.search.store';
import { OrganizationCard } from '../OrganizationCard';
import { Modal } from '../../../../../../../../../shared/components/Modal';
import { Grid, WidgetWrapper } from '../OrganizationList/style';
import { RootStoresContext } from '../../../../../../../../../App';
import { UiStore } from '../../../../../../../../../shared/stores/ui.store';
import { BreakpointsNumbers } from '../../../../../../../../../shared/consts/sizes';
import { ProfileMenuMobilePageHeaderWrapper } from '../../../../components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../../../../../../../shared/components/MobilePageHeader/MobilePageHeader';
import { ProfileRoutes } from '../../../../routes';
import { MobileTabs } from '../../../../../../../../../shared/components/MobileTabs/MobileTabs';
import { mobileMenuItems } from '../OrganizationList/constants';

import { SendInviteToOrganizationForm } from './components/SendInviteToOrganizationForm';
import { CardMock } from './components/CardMock';
import { NothingFound } from './components/NothingFound';
import { SearchControlBar } from './components/SearchControlBar';

export const OrganizationSearchWidget: FC<RouteComponentProps> = observer(({ history }) => {
  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent } = headerComponentStore;
  const uiStore = useStore(UiStore);

  const [choseOrganization, setChoseOrganization] = useState('');
  const store = useStore(OrganizationSearchStore);
  const { fetchSearchedOrganizations, isLoading, cards } = store;

  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small) {
      setHeaderContent(
        <ProfileMenuMobilePageHeaderWrapper withTab={true}>
          <MobilePageHeader
            title={'Организации'}
            onBack={() => {
              history.push(ProfileRoutes.DashboardMobile);
            }}
          />
          <MobileTabs items={mobileMenuItems} />
        </ProfileMenuMobilePageHeaderWrapper>,
        {
          height: '90px',
        }
      );
    }

    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  useEffect(() => {
    fetchSearchedOrganizations();
  }, []);

  const renderCards = () => {
    return cards.map(item => (
      <OrganizationCard
        onClick={() => {
          setChoseOrganization(item.organizationId);
        }}
        type={item.type}
        roleName={item.roleName}
        title={item.title}
        organizationId={item.organizationId}
        avatar={item.avatar}
      />
    ));
  };

  const renderLoading = () => {
    const mockArray = [];
    for (let i = 0; i <= 18; i++) {
      mockArray.push(i);
    }
    return mockArray.map(i => <CardMock key={i} />);
  };

  return (
    <WidgetWrapper>
      <SearchControlBar />
      {cards.length ? (
        <Grid search={true}>{isLoading ? renderLoading() : renderCards()}</Grid>
      ) : (
        <NothingFound />
      )}

      <Modal
        shown={Boolean(choseOrganization)}
        hideModal={() => {
          setChoseOrganization('');
        }}
      >
        <SendInviteToOrganizationForm
          hideForm={() => {
            setChoseOrganization('');
          }}
          organizationId={choseOrganization}
        />
      </Modal>
    </WidgetWrapper>
  );
});
