import React, { FC } from 'react';

import {
  QuoteColumn,
  QuoteDate,
  QuotePrice,
  QuoteSubTitle,
  QuoteTitle,
  QuoteWrapper,
  CircleBackground,
  QuoteColumnRight,
  Arrow,
  Stonks,
} from '../QuotesEditWidget/style';
import { Quote } from '../../../../modules/shared/types/quotes.dto';
import ARROW_UP from '../../../assets/icons/arrow-top.svg';
import ARROW_DOWN from '../../../assets/icons/arrow-bottom.svg';
import { formatPrice } from '../../../../modules/shared/utils/formatPrice';

import { CultureIcon } from './style';

type QuoteEditWidgetProps = {
  quote: Quote;
  onClick: () => void;
};

export const QuoteEditWidget: FC<QuoteEditWidgetProps> = ({ quote, onClick }) => {
  return (
    <QuoteWrapper key={quote.id} onClick={onClick}>
      <CultureIcon src={quote.culture.icon && quote.culture.icon.downloadUrl} alt="" />
      <CircleBackground />
      <QuoteColumn>
        <QuoteTitle>{quote.culture && quote.culture.name}</QuoteTitle>
        <QuoteSubTitle>{quote?.cultureClass?.name || ''}</QuoteSubTitle>
        <QuoteDate>
          {quote.basis && quote.basis.name} {quote.economicRegion && quote.economicRegion.name}
        </QuoteDate>
      </QuoteColumn>
      <QuoteColumnRight>
        <QuotePrice>
          <div style={{ display: 'flex' }}>
            <span>{formatPrice(quote.price)} ₽</span>
          </div>
          <Stonks direction={(quote.tendency && quote.tendency?.direction) || 'NONE'}>
            {quote.tendency?.direction === 'ASC' && <Arrow src={ARROW_UP} />}
            {quote.tendency?.direction === 'DESC' && <Arrow isDown={true} src={ARROW_DOWN} />}
            {quote.tendency && quote.tendency?.diff} (
            {quote.tendency && quote.tendency?.diffPercent}%)
          </Stonks>
          <QuoteSubTitle>{`/тонн ${quote.vat ? 'с' : 'без'} НДС`}</QuoteSubTitle>
        </QuotePrice>
      </QuoteColumnRight>
    </QuoteWrapper>
  );
};
