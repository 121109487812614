import styled from 'styled-components';

export const MobilePageHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
`;

export const MobilePageHeaderBackIcon = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  & > div {
    width: 14px;
    height: 14px;
  }
`;

export const MobilePageHeaderTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SettingsIconBlock = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 16px;
`;
