import { TypeApiRoute } from '../models/TypeApiRoute';
// import { SendMailRequestType } from '../modules/main/modules/services/pages/calculatorService';

type TypeRequest = {
  finalActionType: 'SUPPLIER_SEARCH_REQUEST' | 'CONSULTATION_REQUEST';
  email: string;
  name: string;
  phone: string;
  message: string;
  district: string;
  region: string;
  soil: string;
  culture: string;
  area: string;
  yieldPlanned: string;
  nutrientsDosing: {
    n: number;
    p2o5: number;
    k2o: number;
    s: number;
    cao: number;
    mgo: number;
    zn: number;
    mo: number;
    cu: number;
    mn: number;
    co: number;
    b: number;
  };
  fertilizers: {
    name: string;
    totalMass: number;
  }[];
  warnings: string[];
};

type TypeResponse = {};

export const sendEmailForConsultation: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-calculator/sendMail`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
