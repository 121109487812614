import { TypeApiRoute } from '../models';

type TypeRequest = {};

type TypeResponse = {
  widgets: any;
};

type TypeResponse1 = {
  widgets: any;
};

export const getPanelWidgets: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse1;
} = {
  url: `/api/da-profile/widgets/panel`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse1,
};
