import { FC } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import { Colors } from '../../consts/color';

import {
  MobilePageHeaderWrapper,
  MobilePageHeaderTitle,
  MobilePageHeaderBackIcon,
  SettingsIconBlock,
} from './style';
import SettingsIcon from './assets/settings2.svg';

type Props = {
  title: string;
  onBack: () => void;
  onClickSettings?: () => void;
};

export const MobilePageHeader: FC<Props> = ({ title, onBack, onClickSettings }) => {
  return (
    <MobilePageHeaderWrapper>
      <MobilePageHeaderBackIcon onClick={onBack}>
        <Icon size="medium" icon="arrow_left" />
      </MobilePageHeaderBackIcon>
      <MobilePageHeaderTitle>{title}</MobilePageHeaderTitle>
      {onClickSettings && <SettingsIconBlock src={SettingsIcon} onClick={onClickSettings} />}
    </MobilePageHeaderWrapper>
  );
};
