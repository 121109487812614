import { FC } from 'react';
import { Button, Link } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import DocumentIcon from '../../assets/document.svg';
import { AuthorizationRoutes } from '../../../../../authorization/routes';

import { Wrapper, Icon, Title, Content, ButtonWrapper, BottomSection } from './style';

export const ModalNews: FC = () => {
  const history = useHistory();

  const redirectToPath = (path: string) => history.push(path);

  return (
    <Wrapper>
      <Icon src={DocumentIcon} />
      <Title>Пройдите регистрацию</Title>
      <Content>
        Зарегистрированные пользователи
        <br /> могут оставлять комментарии и лайки
      </Content>
      <ButtonWrapper>
        <Button
          type={'button'}
          color={'primary'}
          onClick={() => redirectToPath(AuthorizationRoutes.signUp)}
        >
          Зарегистрироваться
        </Button>
      </ButtonWrapper>
      <BottomSection>
        <p>У вас уже есть аккаунт?</p>
        <Link
          label={'Вход'}
          color={'primary'}
          onClick={() => redirectToPath(AuthorizationRoutes.signIn)}
          postfixIcon={'arrow_right'}
        />
      </BottomSection>
    </Wrapper>
  );
};
