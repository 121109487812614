import React, { FunctionComponent } from 'react';
import { useDrop, useDrag } from 'react-dnd';

import { Colors } from '../../../../../../../shared/consts/color';
import {
  WidgetProp,
  WidgetMapTypes,
  WidgetTypes,
} from '../../../../../../../shared/types/editWidget.dto';
import trash from '../../../../../../../shared/assets/images/trash.svg';

import { MainPageWidgetWrapper, MainPageWidgetTitle, MainPageWidgetSubTitle } from './style';

type Props = {
  widgetData?: WidgetProp;
  widgetType: 'king' | 'normal';
  onDrop: (data: WidgetProp) => void;
  onRemove: () => void;
  dataTestId?: string;
};

export const MainPageWidget: FunctionComponent<Props> = ({
  widgetData,
  widgetType,
  onDrop,
  onRemove,
  dataTestId,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'WIDGET',
    drop: data => {
      // @ts-ignore
      const { widget_config } = data.widgetData;
      const widgetConfig = widget_config ? JSON.parse(widget_config) : {};
      widgetConfig.size = widgetType === 'king' ? 'big' : 'small';

      console.log(widgetConfig);
      onDrop({
        // @ts-ignore
        ...data.widgetData,
        widget_config: JSON.stringify(widgetConfig),
      });
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [{ isDragging }, drag] = useDrag({
    item: { id: widgetData?.id || null, type: 'WIDGET', widgetData },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const isActive = canDrop && isOver;

  let backgroundColor = Colors.lightGray;

  if (isActive) {
    backgroundColor = Colors.darkGray;
  }

  const opacity = isDragging ? 0.4 : 1;

  return (
    <MainPageWidgetWrapper
      isKingSize={widgetType === 'king'}
      filled={Boolean(widgetData)}
      ref={drop}
      style={{ backgroundColor }}
      data-test-id={dataTestId}
    >
      {widgetData ? (
        <div className="mainPageInner" ref={drag} style={{ opacity }}>
          <MainPageWidgetTitle>
            {WidgetMapTypes[widgetData.type as WidgetTypes]}
          </MainPageWidgetTitle>
          <MainPageWidgetSubTitle>{widgetData.name}</MainPageWidgetSubTitle>
          <div
            className="mainPageWidgetSubIcon"
            onClick={onRemove}
            data-test-id={`${dataTestId}-trash`}
          >
            <img src={trash} alt="" />
          </div>
        </div>
      ) : (
        <div className="mainPageInner">
          <MainPageWidgetTitle>Пустая ячейка</MainPageWidgetTitle>
          <MainPageWidgetSubTitle>
            Перетащите сюда виджет, который хотите видеть на главной странице
          </MainPageWidgetSubTitle>
        </div>
      )}
    </MainPageWidgetWrapper>
  );
};
