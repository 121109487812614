import { Icon } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useState } from 'react';

import { TypeAnswer, TypeCommentRoot } from '../../../../../../shared/types/comments';
import { TypeApiRequest } from '../../../../../../shared/utils/axios2';
import LikeFilledSvg from '../../../assets/hearth-fill.svg';
import LikeEmptySvg from '../../../assets/hearth-default.svg';

import {
  CommentDate,
  CommentItem,
  CommentMeta,
  CommentText,
  CommentLikes,
  CommentAuthor,
  CommentAvatar,
  CommentDelete,
  CommentAnswers,
  CommentContent,
  CommentAnswerButton,
  CommentDeleteIconWrapper,
  NextCommentsButton,
  CommentAvatarImage,
} from './style';

type CommentItemProps = {
  comment: TypeAnswer | TypeCommentRoot;
  currentUserId?: string;
  handleSendLike: (params: TypeApiRequest<'updateCommentLike'>) => any;
  parentCommentId?: string;
  setIsExpandedParent?: (isExpanded: boolean) => any;
  setAnsweredAuthor: (authorName: string) => any;
  handleDeleteComment: (id: string, parentCommentId?: string) => any;
  setAnsweredCommentId: (commentId: string) => any;
  fetchNewsCommentAnswers: (commentId: string) => any;
  dataTestId?: string;
};

function replaceLinks(text: string) {
  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  return text.split(' ').map((part, index) =>
    URL_REGEX.test(part) ? (
      <span key={index}>
        <a target="_blank" href={part} rel="nofollow noopener">
          {part}
        </a>
        &nbsp;
      </span>
    ) : (
      `${part} `
    )
  );
}

function replaceNewLinesWithParagraphs(str: string) {
  return str.split(/\n/).map((stringRow, index) => <p key={index}>{replaceLinks(stringRow)}</p>);
}

export const CommentItemComponent = observer((props: CommentItemProps) => {
  const {
    comment,
    currentUserId,
    handleSendLike,
    parentCommentId,
    setAnsweredAuthor,
    handleDeleteComment,
    setIsExpandedParent,
    setAnsweredCommentId,
    fetchNewsCommentAnswers,
    dataTestId,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const authorName = [comment.createdBy.firstName, comment.createdBy.lastName]
    .filter(Boolean)
    .join(' ');

  // @ts-ignore
  const commentId = (comment as TypeCommentRoot)?.commentId || (comment as TypeAnswer)?.answerId;
  const commentOrParentId = (comment as TypeCommentRoot)?.commentId || parentCommentId;

  const onLikeClick = () => {
    handleSendLike({
      commentId,
      like: !comment.likedByMe,
    });
  };

  const onAnswerClick = () => {
    setAnsweredAuthor(authorName);
    setAnsweredCommentId(commentOrParentId as string);
  };

  const onDeleteClick = () => {
    handleDeleteComment(commentId, parentCommentId);

    if (setIsExpandedParent) setIsExpandedParent(true);
  };

  const answersCount = (comment as TypeCommentRoot)?.answers?.length;

  return (
    <CommentItem id={`Comment_${commentId}`} data-test-id={`${dataTestId}-wrapper`}>
      <CommentAvatar>
        {comment.createdBy.avatar ? (
          <CommentAvatarImage src={comment.createdBy.avatar.downloadUrl} />
        ) : (
          <Icon size="large" icon="user" />
        )}
      </CommentAvatar>
      <CommentContent>
        {currentUserId === comment.createdBy.publicId && (
          <CommentDelete onClick={onDeleteClick}>
            <span data-test-id={`${dataTestId}-delete`}>Удалить</span>
            <CommentDeleteIconWrapper>
              <Icon size={'small'} icon={'close'} />
            </CommentDeleteIconWrapper>
          </CommentDelete>
        )}
        <CommentAuthor data-test-id={`${dataTestId}-name`}>{authorName}</CommentAuthor>
        <CommentText data-test-id={`${dataTestId}-content`}>
          {replaceNewLinesWithParagraphs(comment.commentContent)}
        </CommentText>
        <CommentMeta>
          <CommentDate data-test-id={`${dataTestId}-date`}>
            {moment(comment.createdDate).format('DD.MM.yyyy HH:mm')}
          </CommentDate>
          {Boolean(currentUserId) && !parentCommentId && (
            <CommentAnswerButton onClick={onAnswerClick}>Ответить</CommentAnswerButton>
          )}

          <CommentLikes
            data-test-id={`${dataTestId}-like`}
            likedByMe={comment.likedByMe}
            onClick={onLikeClick}
          >
            <img src={comment.likedByMe ? LikeFilledSvg : LikeEmptySvg} alt={'like'} />
            {comment.likes}
          </CommentLikes>
        </CommentMeta>
        {Boolean(answersCount) && (
          <CommentAnswers>
            {(comment as TypeCommentRoot).answers.map(answer => (
              <CommentItemComponent
                key={answer.answerId}
                {...props}
                comment={answer}
                setIsExpandedParent={setIsExpanded}
                parentCommentId={(comment as TypeCommentRoot).commentId}
              />
            ))}
            {Boolean((comment as TypeCommentRoot).hasMoreAnswers) && !isExpanded && (
              <NextCommentsButton
                onClick={() => {
                  setIsExpanded(true);
                  fetchNewsCommentAnswers(parentCommentId || commentId);
                }}
              >
                +&nbsp;&nbsp;Показать следующие комментарии
              </NextCommentsButton>
            )}
          </CommentAnswers>
        )}
      </CommentContent>
    </CommentItem>
  );
});
