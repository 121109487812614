import im1 from '../assets/avatars/1.png';
import im2 from '../assets/avatars/2.png';
import im3 from '../assets/avatars/3.png';
import im4 from '../assets/avatars/4.png';
import im5 from '../assets/avatars/5.png';

export const getAvatar = () => {
  const avatars = [im1, im2, im3, im4, im5];
  return avatars[getRandomInt(avatars.length)];
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}
