import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';

export const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);
  z-index: 101;
  overflow: auto;
`;

export const ModalWrapper = styled.div`
  width: 748px;
  height: 660px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 102;
`;

export const ModalHeader = styled.div`
  height: 64px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  padding-top: 32px;
  padding-left: 32px;
`;

export const ModalTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.black};
`;

export const ModalContent = styled.div`
  height: 596px;
  display: flex;
`;

export const LeftBar = styled.div`
  border-right: 1px solid ${Colors.grayBorder};
  width: 344px;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 25px;
`;

export const Separator = styled.div`
  margin-top: 4px;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 0.5px solid ${Colors.grayBorder};
`;

export const LineWrapper = styled.div`
  margin-bottom: 16px;
`;

export const RightBar = styled.div`
  width: 404px;
  padding-right: 32px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalBottom = styled.div`
  width: 100%;
  height: 76px;
  background: rgba(255, 255, 255, 0.86);
  backdrop-filter: blur(6px);
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${Colors.grayBorder};
`;

export const BottomText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0 auto 0 0;
`;

export const ButtonWrapper = styled.div`
  width: 148px;
  margin-left: 44px;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;

export const ListColumn = styled.div`
  padding-left: 32px;
  width: 100%;
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  & > div {
    margin-bottom: 24px;
  }
  padding-bottom: 75px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.darkGray};
`;

export const SearchMock = styled.div`
  height: 520px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-top: 170px;
    width: 68px;
    margin-bottom: 40px;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${Colors.black};
    text-align: center;
    margin: 0 0 12px;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    color: ${Colors.black};
    margin: 0;
  }

  p {
  }
`;
