import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { OrganizationCard } from '../types/OrganizationCard';
import { Axios2 } from '../../../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationSearchStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  idToCard: Map<string, OrganizationCard> = new Map();

  searchQuery = '';

  get cards() {
    return Array.from(this.idToCard.values());
  }

  fetchTimer: any = null;

  setSearchQuery = (v: string) => {
    this.searchQuery = v;
    this.fetchSearchedOrganizations();
  };

  isLoading = true;

  fetchSearchedOrganizations = () => {
    this.isLoading = true;
    this.axios2.api
      .getOrganizations({
        nameOrInn: this.searchQuery,
      })
      .then(response => {
        runInAction(() => {
          this.isLoading = false;
          this.idToCard.clear();

          response.content.forEach(item => {
            this.idToCard.set(item.organizationId, {
              organizationId: item.organizationId,
              avatar: '',
              type: 'search',
              title: item.name,
              roleName: '',
            });
          });
        });
      })
      .catch(error => {
        this.isLoading = false;
        console.error(error);
      });
  };
}
