import { FC, useEffect, useContext } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { IconNames } from '@uralhim-innovative/farmik-ui/dist/Icon/Icon.enum';

import { ProfileRoutes } from '../../routes';
import { ApplicationRoutes } from '../../../../../../../routes';
import { PaperBlock } from '../../../../../../../shared/components/PaperBlock';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { Colors } from '../../../../../../../shared/consts/color';
import { MobilePageHeader } from '../../../../../../../shared/components/MobilePageHeader/MobilePageHeader';
import { MobileTabs } from '../../../../../../../shared/components/MobileTabs/MobileTabs';
import { RootStoresContext } from '../../../../../../../App';
import { SessionController } from '../../../../../../../authorization/controllers/session.controller';

import {
  LabelWrapper,
  ProfileLeftMenuItem,
  ProfileLeftMenuItemLogout,
  ProfileLeftMenuWrapper,
  SubMenuItem,
  SubMenuWrapper,
  ProfileBackButton,
  ProfileLeftMenuInner,
  ProfileMenuMobilePageHeaderWrapper,
} from './style';

export type MenuItem = {
  label: string;
  path: string;
  children?: MenuItem[];
  icon?: IconNames;
  hiddenItem?: boolean;
};

const defaultPathTree: Array<MenuItem> = [
  {
    label: 'Профиль',
    path: ProfileRoutes.Dashboard,
    icon: 'user',
  },
];

type ProfileLeftMenuPropsType = {
  pathTree?: Array<MenuItem>;
  history: any;
  location: any;
  backButtonText?: string;
  backButtonPath?: string;
  showLogout?: boolean;
};

export const ProfileLeftMenu: FC<ProfileLeftMenuPropsType> = observer(
  ({ location, pathTree = defaultPathTree, backButtonPath, backButtonText, showLogout = true }) => {
    const history = useHistory();
    const sessionController = useStore(SessionController);
    const { headerComponentStore } = useContext(RootStoresContext);
    const { setHeaderContent } = headerComponentStore;

    useEffect(() => {
      if (backButtonPath && backButtonText) {
        setHeaderContent(
          <ProfileMenuMobilePageHeaderWrapper withTabs={true}>
            <MobilePageHeader
              title={backButtonText}
              onBack={() => {
                history.push(backButtonPath);
              }}
            />
            <MobileTabs items={pathTree} />
          </ProfileMenuMobilePageHeaderWrapper>,
          {
            height: '90px',
          }
        );
      }

      // return () => {
      //   setHeaderContent(null, {
      //     height: '76px',
      //   });
      // };
    }, [backButtonPath, backButtonText, pathTree]);

    const onChangeRoute = (path: string) => {
      if (
        [ProfileRoutes.Dashboard as string, ProfileRoutes.EditPersonalData as string].includes(path)
      ) {
        location.replace(
          process.env?.NODE_ENV === 'development'
            ? ProfileRoutes.ExternalAgroidProfileDev
            : `${window._env_.AUTH_URL}${ProfileRoutes.ExternalAgroidProfile}`
        );
      } else {
        history.push(path);
      }
    };

    const renderItems = () =>
      pathTree.map((item, key) => (
        <ProfileLeftMenuItem
          isActive={
            item.children
              ? location.pathname.startsWith(item.path)
              : location.pathname === item.path
          }
          key={`${item.path}-${key}`}
          to={item.path}
        >
          <LabelWrapper onClick={() => onChangeRoute(item.path)}>
            {item.icon && <Icon size="small" icon={item.icon} />}
            <span>{item.label}</span>
          </LabelWrapper>

          {location.pathname.startsWith(item.path) && item.children && (
            <SubMenuWrapper>
              {item.children.map((child, childKey) => (
                <SubMenuItem
                  to={child.path}
                  isActive={child.path === location.pathname}
                  key={`${child.path}-${childKey}`}
                >
                  {child.label}
                </SubMenuItem>
              ))}
            </SubMenuWrapper>
          )}
        </ProfileLeftMenuItem>
      ));

    return (
      <ProfileLeftMenuWrapper>
        <PaperBlock>
          <ProfileLeftMenuInner>
            {backButtonText && backButtonPath && (
              <ProfileBackButton to={backButtonPath}>
                <LabelWrapper>
                  <Icon fill={Colors.darkGray} size="small" icon="arrow_left" />
                  <span>{backButtonText}</span>
                </LabelWrapper>
              </ProfileBackButton>
            )}
            {renderItems()}
            {showLogout && (
              <div>
                <ProfileLeftMenuItemLogout
                  onClick={() => sessionController.handleLogout()}
                  to={ApplicationRoutes.Main}
                  data-test-id="profile-menu-exit-button"
                >
                  <LabelWrapper>
                    <span>Выход</span>
                  </LabelWrapper>
                </ProfileLeftMenuItemLogout>
              </div>
            )}
          </ProfileLeftMenuInner>
        </PaperBlock>
      </ProfileLeftMenuWrapper>
    );
  }
);
