import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../modules/shared/consts/sizes';

export const ForecastWrapper = styled.div`
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-bottom: 32px;
  width: 100%;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ForecastHeaderWrapper = styled.div`
  padding: 0 32px;
  width: 100%;

  @media (max-width: ${Breakpoints.small}) {
    padding: 0 16px;
  }
`;

export const ForecastHeader = styled.div`
  height: 64px;
  width: 100%;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${Breakpoints.small}) {
    width: calc(100% - 32px);
  }
`;

export const ForecastTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.black};
  margin-top: 32px;
  margin-bottom: 16px;
  margin-right: auto;
`;

export const ForecastSelectWrapper = styled.div`
  width: 208px;
`;

export const ForecastContentWrapper = styled.div`
  width: 100%;
  height: 360px;
  position: relative;
  margin-top: 32px;
  overflow: hidden;
`;
export const ForecastContent = styled.div`
  display: flex;
  padding-left: 32px;
  padding-right: 32px;
  div:last-child {
    margin-right: 0;
  }
`;

export type ForecastControlStyledProps = {
  disabled?: boolean;
};

const ForecastControl = styled.div<ForecastControlStyledProps>`
  height: 100%;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: ${Colors.white};
  top: 0;
  filter: ${({ disabled }) =>
    disabled ? 'none' : 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.08))'};
  svg * {
    fill: ${({ disabled }) => (disabled ? Colors.darkGray : Colors.green)} !important;
  }
`;

export const ForecastLeftControl = styled(ForecastControl)`
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  left: 0;
  z-index: 10;
`;

export const ForecastRightControl = styled(ForecastControl)`
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  right: 0;
  z-index: 10;
`;

export const SunsetAndSunrise = styled.div`
  margin-right: 100px;
  display: flex;
  margin-top: 10px;
`;

export const SunItem = styled.div`
  margin-left: 28px;
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
  }
`;

export const ForecastDayPartCarouselWrapper = styled.div`
  max-width: calc(100vw - 289px);
  overflow: hidden;
  position: relative;

  @media (max-width: ${Breakpoints.small}) {
    max-width: 100%;
  }
`;

export const ForecastDayPartCarouselWrapperLine = styled.div<{ shift: number }>`
  width: 9999px;
  display: flex;

  transition: 300ms;
  ${({ shift }) => `transform: translate3d(${shift}px, 0, 0 );`};
`;
