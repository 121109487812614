import { action, computed, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';
import { WeatherConfig } from '../models/WeatherConfig';

@provide.singleton()
export class WidgetWeathersFullStore {
  @lazyInject(Axios2)
  protected axios: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  selectedGeoPoint = '';

  @action
  selectGeoPoint = (id: string) => {
    this.selectedGeoPoint = id;
  };

  geoPoints: Array<{ lat: number; lon: number; linkId: string; aliasName?: string }> = [];
  weathers: Array<any> = [];

  @computed
  get geoPointsSlider() {
    if (
      !this.weathers.length ||
      !this.geoPoints.length ||
      this.weathers.length !== this.geoPoints.length
    ) {
      return [];
    }
    return this.geoPoints.map((item, index) => {
      const widget = this.weathers[index];
      return {
        id: index,
        condition: widget.fact?.condition,
        geoPoint:
          widget?.aliasName ||
          (widget.geoObject.district && widget.geoObject.district.name) ||
          (widget.geoObject.locality && widget.geoObject.locality.name) ||
          (widget.geoObject.province && widget.geoObject.province.name) ||
          (widget.geoObject.country && widget.geoObject.country.name) ||
          'Неопределено',
        temperature: widget.fact?.temp,
        active: item.linkId === this.selectedGeoPoint,
        linkId: item.linkId,
      };
    });
  }

  @computed
  get aliasName() {
    return this.geoPoints.filter(i => i.linkId === this.selectedGeoPoint)[0]?.aliasName || '';
  }

  @computed
  get weatherCard() {
    return this.weathers.filter(i => i.linkId === this.selectedGeoPoint)[0];
  }

  @computed
  get coordinates() {
    if (!this.geoPoints.filter(i => i.linkId === this.selectedGeoPoint)[0]) {
      return [0, 0];
    }
    return [
      this.geoPoints.filter(i => i.linkId === this.selectedGeoPoint)[0].lat,
      this.geoPoints.filter(i => i.linkId === this.selectedGeoPoint)[0].lon,
    ];
  }

  @computed
  get plantProtection(): Array<{ type: 'common' | 'green' | 'yellow' | 'red'; time: string }> {
    if (!this.weatherCard) {
      return [];
    }
    return this.weatherCard.forecasts[0].hours.map((item: any) => {
      const hourTitle = item.hour < 10 ? `0${item.hour}:00` : `${item.hour}:00`;
      if (
        item.temp >= 12 &&
        item.temp <= 25 &&
        item.humidity >= 60 &&
        item.humidity <= 80 &&
        item.windSpeed >= 3 &&
        item.windSpeed <= 4
      ) {
        return {
          type: 'green',
          time: hourTitle,
        };
      }
      if (
        item.temp >= 12 &&
        item.temp <= 25 &&
        item.humidity >= 60 &&
        item.humidity <= 80 &&
        item.windSpeed > 4 &&
        item.windSpeed < 5
      ) {
        return {
          type: 'yellow',
          time: hourTitle,
        };
      }
      if (item.temp >= 25 && (item.humidity < 60 || item.humidity > 80) && item.windSpeed >= 5) {
        return {
          type: 'red',
          time: hourTitle,
        };
      }
      return {
        type: 'common',
        time: hourTitle,
      };
    });
  }
  id = '';

  @action
  fetchWidget = (id: string) => {
    this.id = id;
    this.axios.api
      .getWidgetInfo({ id })
      .then(this.onFetchWidgetSuccess)
      .catch(this.onFetchWidgetsError);
  };

  @action.bound
  protected onFetchWidgetSuccess = (response: TypeApiResponse<'getWidgetInfo'>) => {
    const config: WeatherConfig = JSON.parse(response.widget_config) as WeatherConfig;

    this.geoPoints = config.positions.map(item => {
      return {
        lat: item.lat,
        lon: item.lon,
        linkId: item.linkId,
        aliasName: item.aliasName || '',
      };
    });

    const ids = config.positions.map(i => i.linkId);

    if (!this.geoPoints) {
      return;
    }

    this.fetchWeatherByPoints(ids);
  };

  @action.bound
  protected onFetchWidgetsError = (response: TypeApiResponse<'getWidgetInfo'>) => {
    console.log('onFetchWidgetsError ', response);
  };

  @action
  fetchWeatherByPoints = (linkIds: Array<string>) => {
    this.axios.api
      .getWeatherByPoints({ points: this.geoPoints })
      .then(response => this.onFetchWeatherByPointsSuccess(response, linkIds))
      .catch(this.onFetchWeatherByPointsError);
  };

  @action.bound
  protected onFetchWeatherByPointsSuccess = (
    weathers: TypeApiResponse<'getWeatherByPoints'>,
    linkIds: Array<string>
  ) => {
    // this.weathers = weathers;
    this.weathers = weathers.map((item, index) => {
      return {
        ...item,
        linkId: linkIds[index],
        aliasName: this.geoPoints.filter(i => i.linkId === linkIds[index])[0]?.aliasName || '',
      };
    });
  };

  @action.bound
  protected onFetchWeatherByPointsError = () => {
    console.log('onFetchWeatherByPointsError');
  };
}
