import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../modules/shared/consts/sizes';

export const Wrapper = styled.div`
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 340px;
  height: 260px;
  padding: 24px;
  flex-direction: column;
  display: inline-flex;
  vertical-align: top;
  margin-left: 32px;

  @media (max-width: ${Breakpoints.medium}) {
    display: none;
  }
`;

export const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ImageMock = styled.div`
  background: ${Colors.gray};
  border-radius: 16px;
  width: 88px;
  height: 88px;
`;

export const TopBarRightLine = styled.div`
  width: 100%;
  height: 24px;
  background: ${Colors.gray};
  border-radius: 8px;
  margin-bottom: 8px;
`;

export const TopBarRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  ${TopBarRightLine}:last-child {
    margin-bottom: 0;
  }
`;

export const LastLine = styled.div`
  width: 100%;
  height: 24px;
  background: ${Colors.gray};
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;
