import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  id: string;
  confirm: boolean;
};

export const toggleExpertise: TypeApiRoute & {
  request: TypeRequest;
  response: any;
} = {
  url: req => `/api/userExpertise/confirm/${req.id}?confirm=${req.confirm}`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as any,
};
