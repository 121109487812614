import { FC, useRef, useState } from 'react';
import { Icon, Input } from '@uralhim-innovative/farmik-ui';
import * as _ from 'lodash';
import { observer } from 'mobx-react';

import { Card, CardProps } from '../Card';
import { Breadcrumbs, BreadcrumbsProps } from '../Breadcrumbs';
import { Loader } from '../../../../../shared/components/Loader';
import { EntityType } from '../../types/culture.response.model';
import { Colors } from '../../../../../shared/consts/color';

import {
  CardsWrapper,
  MobileGridHeader,
  MobileSearchInputWrapper,
  BreadcrumbWrapper,
  GridArea,
  GridContainer,
  LoaderWrapper,
  SearchTitle,
  SearchWrapper,
  WidgetWrapper,
} from './style';

type CulturesWizardProps = {
  cards: CardProps[];
  onSearch: (v: string) => void;
  isLoading: boolean;
  isPaginational: boolean;
  isPaginationLoading: boolean;
  isPage?: boolean;
  searchQuery: string;
  loadAdditionalCards: () => void;
  entity: EntityType | null;
} & BreadcrumbsProps;

export const CulturesWizard: FC<CulturesWizardProps> = observer(
  ({
    cards,
    breadcrumbs,
    onChangeNode,
    onReset,
    onSearch,
    isLoading,
    isPaginational,
    loadAdditionalCards,
    isPaginationLoading,
    entity,
    searchQuery,
    isPage,
  }) => {
    const cardsWrapperReference = useRef(null);
    const [mobileHeaderStep, setMobileHeaderStep] = useState<'first' | 'second'>('first');

    const handleScroll = () => {
      // @ts-ignore
      const { clientHeight, scrollHeight, scrollTop } = cardsWrapperReference.current;
      // @ts-ignore
      const difference = scrollHeight - clientHeight;

      // @ts-ignore
      if (isPaginational && scrollTop + 100 >= difference && !isPaginationLoading) {
        loadAdditionalCards();
      }
    };

    return (
      <WidgetWrapper>
        <SearchWrapper>
          <SearchTitle>
            Справочник культур <br />
            <span>В справочнике вы можете найти описание интересующей вас культуры</span>
          </SearchTitle>
          <Input
            error=""
            placeholder="Поиск по культурам"
            type="text"
            onChange={onSearch}
            startIcon="search"
            value={searchQuery}
          />
        </SearchWrapper>
        <GridContainer
          isPage={isPage}
          // isLevelSelected={Boolean(breadcrumbs.length)}
          isLevelSelected={Boolean(entity && entity.level > 0)}
        >
          {Boolean(entity && entity.level > 0) && (
            <BreadcrumbWrapper>
              <MobileGridHeader>
                {mobileHeaderStep === 'first' ? (
                  <>
                    <p>Справочник культур</p>
                    <Icon
                      onClick={() => setMobileHeaderStep('second')}
                      icon={'search'}
                      size={'large'}
                      fill={Colors.darkGray}
                    />
                  </>
                ) : (
                  <>
                    <MobileSearchInputWrapper>
                      <Input
                        onChange={onSearch}
                        startIcon="search"
                        value={searchQuery}
                        dataTestId={'culture-mobile'}
                        placeholder={'Поиск по культурам'}
                        type={'text'}
                      />
                    </MobileSearchInputWrapper>

                    <Icon
                      onClick={() => setMobileHeaderStep('first')}
                      icon={'close'}
                      size={'small'}
                      fill={Colors.darkGray}
                    />
                  </>
                )}
              </MobileGridHeader>
              <Breadcrumbs
                onReset={onReset}
                onChangeNode={onChangeNode}
                breadcrumbs={breadcrumbs}
                isLoading={isLoading}
                entity={entity}
                isPage={isPage}
              />
            </BreadcrumbWrapper>
          )}
          <GridArea
            isPage={isPage}
            ref={cardsWrapperReference}
            onScroll={_.throttle(handleScroll, 100)}
          >
            {isLoading ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <>
                {cards &&
                  cards.map(item => (
                    <Card
                      onClick={
                        item.isLastLevel
                          ? () => ''
                          : () => onChangeNode(item.code, item.id, item.name)
                      }
                      name={item.name}
                      descriptionName={item.descriptionName}
                      description={item.description}
                      id={item.id}
                      key={item.id}
                      avatar={item.avatar}
                      isLastLevel={false}
                      code={item.code}
                    />
                  ))}
              </>
            )}
            <MobileGridHeader>
              {mobileHeaderStep === 'first' ? (
                <>
                  <p>Справочник культур</p>
                  <Icon
                    onClick={() => setMobileHeaderStep('second')}
                    icon={'search'}
                    size={'large'}
                    fill={Colors.darkGray}
                  />
                </>
              ) : (
                <>
                  <MobileSearchInputWrapper>
                    <Input
                      onChange={onSearch}
                      startIcon="search"
                      value={searchQuery}
                      dataTestId={'culture-mobile'}
                      placeholder={'Поиск по культурам'}
                      type={'text'}
                    />
                  </MobileSearchInputWrapper>

                  <Icon
                    onClick={() => setMobileHeaderStep('first')}
                    icon={'close'}
                    size={'small'}
                    fill={Colors.darkGray}
                  />
                </>
              )}
            </MobileGridHeader>
          </GridArea>
        </GridContainer>
      </WidgetWrapper>
    );
  }
);
