import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Toggle } from '../../../../widgets/components/Toggle/Toggle';
import { useStore } from '../../../shared/utils/IoC';
import { NotificationStore } from '../../stores/NotificationStore';

import { Wrapper, Header, LineWrapper } from './style';

export const Settings: FC = observer(() => {
  const store = useStore(NotificationStore);

  const {
    fetchSettings,
    showAnswersSetting,
    showViaEmailSetting,
    showNotificationSetting,
    toggleAnswersSettings,
    toggleViaEmail,
    toggleNotificationSetting,
  } = store;

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Wrapper>
      <Header>
        <p>Настройки</p>
      </Header>
      <LineWrapper>
        <p>Показывать уведомления</p>
        <Toggle isActive={showNotificationSetting} onChange={toggleNotificationSetting} />
      </LineWrapper>
      <LineWrapper>
        <p>Показывать ответы на комментарии</p>
        <Toggle isActive={showAnswersSetting} onChange={toggleAnswersSettings} />
      </LineWrapper>
      <LineWrapper>
        <p>Получать уведомления по электронной почте</p>
        <Toggle isActive={showViaEmailSetting} onChange={toggleViaEmail} />
      </LineWrapper>
    </Wrapper>
  );
});
