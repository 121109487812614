import { TypeUser } from '../modules/shared/types/users';
import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';

type TypeRequest = {
  role?: string;
  status?: string;
  fullname?: string;
  organizationId: string;
};

type TypeResponse = {
  content: Array<TypeUser>;
} & TypeResponsePageable;

export const getOrganizationUsers: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/organizations/userList`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
