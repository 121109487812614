import styled from 'styled-components';

import { Colors } from '../../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../../modules/shared/consts/sizes';

export const Wrapper = styled.div`
  padding: 32px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  @media (max-width: ${Breakpoints.small}) {
    padding: 24px;
  }
`;

export const Header = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${Colors.grayBorder};
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
`;

export const Content = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-top: 32px;
  color: ${Colors.black};
`;
