import styled from 'styled-components';

import { Colors } from '../../../shared/consts/color';
import { Breakpoints } from '../../../shared/consts/sizes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  padding-top: 24px;
  padding-bottom: 16px;
  width: 100%;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    color: ${Colors.black};
  }
`;

export const FeedWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 750px;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
