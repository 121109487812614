import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PaperWrapper } from '../../../../../../../shared/components/PaperBlock/style';
import { Colors } from '../../../../../../../shared/consts/color';
import { BorderRadius, Breakpoints } from '../../../../../../../shared/consts/sizes';

export type ProfileLeftMenuItemStyledProps = {
  isActive?: boolean;
  hiddenItem?: boolean;
};

const activeState = `
  background: ${Colors.gray};
 
  * {
    color: ${Colors.green};
    fill: ${Colors.green};
  }
`;

export const ProfileLeftMenuItem = styled(Link)<ProfileLeftMenuItemStyledProps>`
  display: ${({ hiddenItem }) => (hiddenItem ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  text-decoration: none;
  color: ${Colors.black};

  &:visited {
    color: ${Colors.black};
  }

  &:first-child {
    border-radius: ${BorderRadius.default} ${BorderRadius.default} 0 0;
  }

  &:hover {
    color: ${Colors.green};
    background: ${Colors.lightGray};
  }

  &:last-child:hover {
    border-bottom-left-radius: ${BorderRadius.default};
    border-bottom-right-radius: ${BorderRadius.default};
  }

  & span {
    display: inline-block;
    margin-left: 15px;
  }

  ${({ isActive }) => isActive && activeState};
`;

export const ProfileLeftMenuItemLogout = styled(ProfileLeftMenuItem)`
  color: ${Colors.darkGray};

  &:first-child {
    border-radius: 0 0 ${BorderRadius.default} ${BorderRadius.default};
  }
`;

export const ProfileLeftMenuWrapper = styled.div`
  width: 196px;
  margin-right: 20px;
  position: sticky;
  top: 92px;
  align-self: flex-start;
  z-index: 2;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const ProfileLeftMenuInner = styled(PaperWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProfileBackButton = styled(ProfileLeftMenuItem)`
  color: ${Colors.darkGray};
  border-bottom: 1px solid ${Colors.grayBorder};
`;

export const LabelWrapper = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  align-items: center;

  & > span:first-child {
    margin-left: 0;
  }
`;

export const SubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
`;

export const SubMenuItem = styled(Link)<ProfileLeftMenuItemStyledProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0 !important;
  margin-bottom: 12px !important;
  ${({ isActive }) =>
    isActive &&
    `
    color: ${Colors.green}; 
    text-decoration: underline
  `};
`;

export const ProfileMenuMobilePageHeaderWrapper = styled.div<{ withTab?: boolean }>`
  width: 100%;
  ${({ withTabs }) => withTabs && `height: 80px;`}
`;
