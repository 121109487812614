import { TypeApiRoute } from '../models/TypeApiRoute';
import { WidgetProp } from '../modules/shared/types/editWidget.dto';

type TypeRequest = {};

type TypeResponse = {
  widgets?: {
    '1'?: WidgetProp;
    '2'?: WidgetProp;
    '3'?: WidgetProp;
  };
};

export const getWidgetsPanel: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/da-profile/widgets/panel`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
