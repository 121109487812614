import { TimesOfDayHoursMap, TimesOfDayNameMap } from '../consts/date';

export const getTimesOfDay = (): string => {
  const hours = new Date().getHours();

  if (TimesOfDayHoursMap.evening.includes(hours)) {
    return TimesOfDayNameMap.evening;
  }

  if (TimesOfDayHoursMap.morning.includes(hours)) {
    return TimesOfDayNameMap.morning;
  }

  if (TimesOfDayHoursMap.night.includes(hours)) {
    return TimesOfDayNameMap.night;
  }

  return TimesOfDayNameMap.dayTime;
};
