export const TimesOfDayHoursMap = {
  morning: [5, 6, 7, 8, 9, 10],
  dayTime: [11, 12, 13, 14, 15, 16],
  evening: [17, 18, 19, 20, 21, 22],
  night: [23, 0, 1, 2, 3, 4],
};

export const TimesOfDayNameMap = {
  morning: 'morning',
  dayTime: 'dayTime',
  evening: 'evening',
  night: 'night',
};
