export const WindDirectionEngToRus = (key: string): string => {
  const list = {
    nw: 'СЗ',
    n: 'С',
    ne: 'СВ',
    e: 'В',
    se: 'ЮВ',
    s: 'Ю',
    sw: 'ЮЗ',
    w: 'З',
    C: '-',
  };

  // @ts-ignore
  return list[key];
};
