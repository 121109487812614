import { FC } from 'react';

import { Wrapper, Chip } from './style';

type ChipsProps = {
  chips: Array<string>;
  dataTestId?: string;
};

export const Chips: FC<ChipsProps> = ({ chips, dataTestId }) => (
  <Wrapper data-test-id={dataTestId}>
    {chips &&
      chips.map((item, index) => (
        <Chip
          data-test-id={`${dataTestId}-tag-${index}`}
          key={`${item}-${index}-${new Date().getTime()}`}
        >
          {item}
        </Chip>
      ))}
  </Wrapper>
);
