import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { YMInitializer } from 'react-yandex-metrika';

import { App } from './modules/App';
import FontStyle from './modules/shared/styles/fonts';
import Normalize from './modules/shared/styles/normalize';

const historyInstance = createBrowserHistory();


render(
  <StrictMode>
    {process.env.ENABLE_METRICS === 'true' && (
      <YMInitializer
        accounts={[73460749]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          ecommerce: 'dataLayer',
        }}
      />
    )}

    <FontStyle />
    <Normalize />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

// Enable Webpack hot rel
if (module.hot) module.hot.accept(err => console.error(err));
