import styled from 'styled-components';

import illustration from '../../../../../../../shared/assets/images/illustration.png';
import { BorderRadius, Breakpoints } from '../../../../../../../shared/consts/sizes';

export const ToggleWidget = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: #ffffff;
  align-items: center;
  display: flex;
  img {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
`;

export const WelcomeWidgetWrapper = styled.div<{ collapsed: boolean; image: any }>`
  grid-area: welcome;
  position: relative;
  height: 304px;
  padding: 104px 64px;
  ${({ image }) => `background: url(${image});`}
  //background-size: 100%;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  background-position: center;
  color: #ffffff;
  border-radius: ${BorderRadius.default};
  transition: height ease-in-out 0.4s, padding ease-in-out 0.4s, font-size ease-in-out 0.4s,
    background-position ease-in-out 0.4s;
  cursor: pointer;
  background-size: cover;
  ${({ collapsed }) =>
    collapsed &&
    `
    padding: 18px 40px;
    height: 84px;
    font-size: 20px;
    background-position-y: -179px;
  `}

  & span {
    max-width: 300px;
    display: block;

    ${({ collapsed }) => collapsed && `max-width: 100%`}
  }
  @media (max-width: ${Breakpoints.medium}) {
    height: 130px;
    padding: 60px 51px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    ${ToggleWidget} {
      display: none;
    }
  }
  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const VersionWrapper = styled.div`
  position: absolute;
  color: #ffffff;
  bottom: 0;
  right: 20px;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
`;
