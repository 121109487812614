import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Colors } from '../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../shared/consts/sizes';

export const CommentsWrapper = styled.div`
  border-top: 0.5px solid ${Colors.grayBorder};
  padding: 10px 72px 79px 72px;
  position: relative;

  @media (max-width: ${Breakpoints.large}) {
    border-top: none;
  }

  @media (max-width: ${Breakpoints.medium}) {
    padding: 16px;
  }
`;

export const LeaveCommentGrid = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  width: 100%;
  //max-width: 1332px;
  max-width: 1316px;
  padding: 0 16px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    width: 340px;

    @media (max-width: ${Breakpoints.small}) {
      display: none;
    }
  }

  @media (max-width: ${Breakpoints.small}) {
    bottom: 53px;
    padding: 0;
  }
`;

export const LeaveCommentWrapper = styled.div`
  max-width: 960px;
  width: 100%;
  background: ${Colors.gray};
  padding: 0 24px 30px 24px;
  margin: 0px -24px;
  overflow-y: hidden;

  @media (max-width: 1218px) {
    padding-right: 27px;
  }

  @media (max-width: ${Breakpoints.small}) {
    position: relative;
    margin-left: 0;
    left: 0;
    width: 100%;
    padding: 0 6px 16px 0;
    background: #fff;
    border: 0;
  }
`;

export const LeaveCommentNoComments = styled.div`
  color: ${Colors.darkGray};
  padding-top: 20px;
  padding-bottom: 30px;
`;

export const LeaveCommentContent = styled.div`
  background: ${Colors.white};
  padding: 40px 20px 20px 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 1px;
    border-top: 0.5px solid ${Colors.grayBorder};
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
  }

  @media (max-width: ${Breakpoints.small}) {
    border-radius: 0;
    box-shadow: none;
    padding: 16px 0 0;
    margin-top: 0;

    &:after {
      top: 0;
    }
  }
`;

export const LeaveCommentAvatar = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;

  img {
    width: 40px;
  }

  @media (max-width: ${Breakpoints.small}) {
    margin-right: 6px;
  }
`;

export const LeaveCommentFieldWrapper = styled.div`
  flex-grow: 1;
`;

export const LeaveCommentButton = styled.div<{ disabled: boolean }>`
  background: ${({ disabled }) => (disabled ? Colors.darkGray : Colors.green)};
  color: ${Colors.white};
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-left: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
    vertical-align: top;
    height: 1em;

    path {
      fill: ${Colors.white};
    }
  }

  &:hover {
    background: ${({ disabled }) => (disabled ? Colors.darkGray : Colors.greenHover)};
  }
`;

export const LeaveCommentNotificationIconWrapper = styled.div`
  box-shadow: 0 0 10px 10px #f1f0ee;
  background: #f1f0ee;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;

  img {
    width: 15px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    display: none;
  }
`;

export const LeaveCommentNotificationText = styled.div`
  width: 250px;
  color: #fd7e09;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${Breakpoints.medium}) {
    width: 100%;
    text-align: center;
    padding: 16px 0;
  }
`;

export const LeaveCommentContentUnregistered = styled.div`
  background: ${Colors.white};
  padding: 33px 20px 13px 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  position: relative;

  &:after {
    content: '';
    display: block;
    border-top: 0.5px solid ${Colors.grayBorder};
    height: 1px;
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
  }

  @media (max-width: ${Breakpoints.medium}) {
    display: block;
    padding: 33px 0 80px 0;
    box-shadow: none;
  }
`;

export const LeaveCommentTextAreaWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid ${Colors.grayBorder};
  padding: 12px 16px 12px 16px;
  max-height: 165px;
`;

export const LeaveCommentTextArea = styled.textarea<{ isAnswer?: boolean }>`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-height: 165px;

  outline: none;
  resize: none;
  line-height: 16px;
  border: none;

  padding: 12px 0;
  margin-top: -12px;
  margin-bottom: -12px;

  &:hover,
  &:focus {
    border-color: ${Colors.darkGray};
  }
`;

export const LeaveCommentAuthorName = styled.div`
  color: ${Colors.green};
  flex: 0 0 auto;
  margin-right: 8px;
  line-height: 16px;
`;

export const ButtonAuth = styled.a`
  color: ${Colors.green};
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  span {
    margin-right: 5px;
  }

  svg {
    height: 12px;

    path {
      fill: ${Colors.green};
    }
  }

  &:hover {
    color: ${Colors.greenHover};

    svg path {
      fill: ${Colors.greenHover};
    }
  }

  @media (max-width: ${Breakpoints.medium}) {
    float: left;
    padding: 18px 58px;
  }
`;

export const ButtonRegister = styled.a`
  background: ${Colors.green};
  color: ${Colors.white};
  border-radius: 20px;
  margin-left: 30px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  padding: 18px 40px;
  text-decoration: none;

  &:hover {
    background: ${Colors.greenHover};
  }

  @media (max-width: ${Breakpoints.medium}) {
    float: right;
    margin-left: 0;
  }
`;

export const Gutter = styled.div`
  flex-grow: 1;
`;
