import React, { FC, useState } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import { Arrow } from '../../../../components/Quotes/QuotesEditWidget/style';
import ARROW_UP from '../../../../assets/icons/arrow-top.svg';
import ARROW_UP_WHITE from '../../../../assets/icons/arrow-top-white.svg';
import ARROW_DOWN from '../../../../assets/icons/arrow-bottom.svg';
import ARROW_DOWN_WHITE from '../../../../assets/icons/arrow-bottom-white.svg';
import { formatPrice } from '../../../../../modules/shared/utils/formatPrice';
import {
  MobileLine,
  MobileSliderWrapper,
} from '../../../../components/FullWeatherPage/Slider/style';

import {
  Wrapper,
  LeftControl,
  RightControl,
  Line,
  Column,
  QuoteIcon,
  QuoteSlide,
  ContentWrapper,
  QuoteName,
  QuotePosition,
  QuotePrice,
  Tendency,
  BackgroundCircle,
} from './style';

type SliderProps = {
  cards: Array<{
    linkId: number;
    isActive: boolean;
    id: number;
    image: string;
    name: string;
    position: string;
    price: string;
    tendency: {
      amount: string;
      percent: string;
      direction: 'DESC' | 'ASC' | 'NONE';
    };
  }>;
  onClick: (v: number) => void;
};

export const Slider: FC<SliderProps> = ({ cards, onClick }) => {
  // const [isLeftControlAvailable, setIsLeftControlAvailable] = useState(false);
  // const [isRightControlAvailable, setIsRightsControlAvailable] = useState(false);
  const [shift, setShift] = useState(0);

  const handleLeftClick = () => {
    if (shift === 0) {
      return;
    }
    setShift(shift + 220);
  };

  const handleRightClick = () => {
    if (Math.abs(shift) > cards.length * 118) {
      return;
    }
    setShift(shift - 220);
  };

  return (
    <>
      <Wrapper>
        <LeftControl onClick={handleLeftClick} isActive={shift !== 0}>
          <Icon icon={'arrow_left'} size={'small'} />
        </LeftControl>
        <ContentWrapper>
          <Line shift={shift}>
            {cards &&
              cards.map(card => (
                <QuoteSlide isActive={card.isActive} onClick={() => onClick(card.linkId)}>
                  <QuoteIcon src={card.image} />
                  {card.isActive && <BackgroundCircle />}
                  <Column>
                    <QuoteName>
                      {card.name.length < 10 ? card.name : `${card.name.substr(0, 10)}...`}
                    </QuoteName>
                    <QuotePosition>{card.position}</QuotePosition>
                  </Column>
                  <Column style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <QuotePrice>{formatPrice(card.price)} ₽</QuotePrice>
                    <Tendency>
                      {card.tendency.direction === 'ASC' && (
                        <Arrow src={card.isActive ? ARROW_UP_WHITE : ARROW_UP} />
                      )}
                      {card.tendency.direction === 'DESC' && (
                        <Arrow isDown={true} src={card.isActive ? ARROW_DOWN_WHITE : ARROW_DOWN} />
                      )}
                      {card.tendency.amount}({card.tendency.percent}%)
                    </Tendency>
                  </Column>
                </QuoteSlide>
              ))}
          </Line>
        </ContentWrapper>

        <RightControl onClick={handleRightClick} isActive={Math.abs(shift) < cards.length * 118}>
          <Icon icon={'arrow_right'} size={'small'} />
        </RightControl>
      </Wrapper>
      <MobileSliderWrapper>
        <MobileLine>
          {cards &&
            cards.map((card, index) => {
              return (
                <QuoteSlide isActive={card.isActive} onClick={() => onClick(card.linkId)}>
                  <QuoteIcon src={card.image} />
                  {card.isActive && <BackgroundCircle />}
                  <Column>
                    <QuoteName>
                      {card.name.length < 10 ? card.name : `${card.name.substr(0, 10)}...`}
                    </QuoteName>
                    <QuotePosition>{card.position}</QuotePosition>
                  </Column>
                  <Column style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <QuotePrice>{formatPrice(card.price)} ₽</QuotePrice>
                    <Tendency>
                      {card.tendency.direction === 'ASC' && (
                        <Arrow src={card.isActive ? ARROW_UP_WHITE : ARROW_UP} />
                      )}
                      {card.tendency.direction === 'DESC' && (
                        <Arrow isDown={true} src={card.isActive ? ARROW_DOWN_WHITE : ARROW_DOWN} />
                      )}
                      {card.tendency.amount}({card.tendency.percent}%)
                    </Tendency>
                  </Column>
                </QuoteSlide>
              );
            })}
        </MobileLine>
      </MobileSliderWrapper>
    </>
  );
};
