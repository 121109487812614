import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeNews } from '../modules/main/modules/news/types/type-news';

type TypeRequest = {
  status?: string;
  author?: string;
  tags?: Array<string>;
  context?: string;
  size?: number;
  page?: number;
  sort: string;
};

type TypeResponse = {
  content: Array<TypeNews>;
} & TypeResponsePageable;

export const getNewsList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-news/news/list?page=${request.page}&size=${request.size}&sort=${request.sort}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
