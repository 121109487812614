import { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import { observer } from 'mobx-react';
import { Button, Input } from '@uralhim-innovative/farmik-ui';

import {
  ButtonWrapper,
  FieldWrapper,
} from '../../../profile/modules/organizations/components/CreateOraganizationWizard/style';
import { OrgNameInput } from '../../../../../../../shared/components/OrgNameInput';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { CreateOrganizationFormStore } from '../../../profile/modules/organizations/stores/CreateOrganizationFormStore';
import { OrganizationStore } from '../../stores/organization.store';
import { OrganizationRoutes } from '../../organization.routes';
import { AvatarUploader } from '../../../profile/components/AvatarUploader/AvatarUploader';

import { ActionsWrapper, FormGrid, PageTitle, Wrapper } from './style';

export const OrganizationEdit: FunctionComponent<RouteComponentProps> = observer(({ history }) => {
  // @ts-ignore
  const { organizationId } = useParams();

  const store = useStore(CreateOrganizationFormStore);
  const {
    name,
    setName,
    INN,
    setINN,
    KPP,
    setKPP,
    OGRN,
    setOGRN,
    OKVED,
    setOKVED,
    OKPO,
    setOKPO,
    addressJur,
    setAddressJur,
    addressFact,
    setAddressFact,
    updateOrganization,
    orgTypes,
    getOrgTypes,
    errors,
    // resetFields,
    // orgTypeId,
    setFormError,
    uploadFile,
  } = store;

  const organizationStore = useStore(OrganizationStore);
  const { organization, getOrganizationInfo } = organizationStore;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [creatingSuccess, setCreatingSuccess] = useState(false);

  useEffect(() => {
    getOrganizationInfo(organizationId);
  }, []);

  useEffect(() => {
    if (organization && orgTypes.length) {
      setName(organization.name || '', organization.type ? organization.type.orgTypeId : 0);
      setINN(organization.INN || null);
      setKPP(organization.KPP || null);
      setOGRN(organization.OGRN || null);
      setOKVED(organization.OKVED || null);
      setOKPO(organization.OKPO || null);
      setAddressJur(organization.addressJur?.addressfull || '');
      setAddressFact(organization.addressFact?.addressfull || '');
    } else {
      getOrgTypes();
    }
  }, [organizationId, organization, orgTypes.length]);

  if (!organization) {
    return <>Загрузка</>;
  }

  const handleSubmit = () => {
    updateOrganization(organizationId, organization)
      .then(() => {
        getOrganizationInfo(organizationId);
        setCreatingSuccess(true);
        history.push(`${OrganizationRoutes.main}/${organizationId}`);
      })
      .catch(err => {
        if (err && err.data && err.data.title) {
          setFormError(err.data.title);
        }
      });
  };

  const handleBackAction = () => {
    history.push(`${OrganizationRoutes.main}/${organizationId}`);
  };

  const { canEdit } = organization;

  return (
    <Wrapper>
      <PageTitle>Редактирование</PageTitle>
      <FormGrid>
        <div>
          <FieldWrapper>
            <AvatarUploader
              title="организации"
              data-test-id={'profile-avatar-uploader'}
              icon={organization.logo && organization.logo.downloadUrl}
              onUpload={file => uploadFile(file, organizationId)}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper>
            <OrgNameInput
              orgTypes={orgTypes}
              defaultOrgTypeId={organization.type ? organization.type.orgTypeId : undefined}
              label="Название организации"
              onChange={setName}
              value={name}
              error={errors.name}
              disabled={!canEdit}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              label="ИНН"
              placeholder="0000000000"
              value={INN || ''}
              onChange={setINN}
              error={errors.INN}
              disabled={!canEdit}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              label="КПП"
              placeholder="0000000000"
              value={KPP || ''}
              onChange={setKPP}
              error={errors.KPP}
              disabled={!canEdit}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              label="ОГРН"
              placeholder="0000000000"
              value={OGRN || ''}
              onChange={setOGRN}
              error={errors.OGRN}
              disabled={!canEdit}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper>
            <Input
              label="ОКВЭД"
              placeholder="0000000000"
              value={OKVED || ''}
              onChange={setOKVED}
              error={errors.OKVED}
              disabled={!canEdit}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              label="ОКПО"
              placeholder="0000000000"
              value={OKPO || ''}
              onChange={setOKPO}
              error={errors.OKPO}
              disabled={!canEdit}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              label="Юридический адрес"
              placeholder="337483, Москва, ул.Строительная, д.5, к.3"
              value={addressJur}
              onChange={setAddressJur}
              error={errors.addressJur}
              disabled={!canEdit}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              label="Фактический адрес"
              placeholder="337483, Москва, ул.Строительная, д.5, к.3"
              value={addressFact}
              onChange={setAddressFact}
              error=""
              disabled={!canEdit}
            />
          </FieldWrapper>
        </div>
      </FormGrid>

      <ActionsWrapper>
        <ButtonWrapper>
          <Button type="button" color="default" onClick={handleBackAction} alignment="center">
            Отменить
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            disabled={!canEdit}
            type="button"
            color="primary"
            onClick={handleSubmit}
            alignment="center"
          >
            Сохранить
          </Button>
        </ButtonWrapper>
      </ActionsWrapper>
    </Wrapper>
  );
});
