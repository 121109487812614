import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';

export const LikeWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  color: ${Colors.darkGray};
  fill: ${Colors.black};
  margin-right: 15px;
  font-size: 12px;
  line-height: 12px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
`;
