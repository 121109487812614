import { FC, useRef } from 'react';

import { NotificationType } from '../../models/notification-type';

import { Wrapper, Header, FeedWrapper } from './style';
import { FeedNotification } from './components/FeedNotification/FeedNotification';

type NotificationFeedProps = {
  title: string;
  notificationList: Array<NotificationType>;
  fetchAdditional: () => void;
  isAdditionalLoading: boolean;
  isPaginational: boolean;
};

export const NotificationFeed: FC<NotificationFeedProps> = ({
  title,
  notificationList,
  isPaginational,
  fetchAdditional,
  isAdditionalLoading,
}) => {
  const listReference = useRef(null);

  const handleScroll = () => {
    // @ts-ignore
    const { clientHeight, scrollHeight, scrollTop } = listReference.current;
    // @ts-ignore
    const difference = scrollHeight - clientHeight;

    if (isPaginational && scrollTop + 100 >= difference && !isAdditionalLoading) {
      fetchAdditional();
    }
  };

  return (
    <Wrapper>
      <Header>
        <p>{title}</p>
      </Header>
      <FeedWrapper ref={listReference} onScroll={handleScroll}>
        {notificationList.length > 0 ? (
          notificationList.map(item => (
            <FeedNotification
              name={item.senderName}
              content={item.contentHtml}
              id={item.id}
              avatar={item.senderAvatar ? item.senderAvatar.downloadUrl : ''}
              time={''}
            />
          ))
        ) : (
          <p>Не найдено уведомлений</p>
        )}
      </FeedWrapper>
    </Wrapper>
  );
};
