import { FC } from 'react';

import { Circle, Wrapper } from './style';

type Props = {
  isActive: boolean;
  onChange: () => void;
};

export const Toggle: FC<Props> = ({ isActive, onChange }) => {
  return (
    <Wrapper isActive={isActive} onClick={onChange}>
      <Circle />
    </Wrapper>
  );
};
