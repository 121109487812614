import { action, computed, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../../../../../shared/utils/axios2';

export type ExpertiseType = {
  expertiseId: string;
  expertiseName: string;
  confirmations: number;
};

@provide.singleton()
export class EditExpertiseStore {
  @lazyInject(Axios2)
  axios: Axios2;
  constructor() {
    makeAutoObservable(this);
  }

  idToExpertise: Map<string, any> = new Map();

  @computed
  get expertises() {
    return Array.from(this.idToExpertise.values());
  }

  @action
  addToExpertises = async () => {
    const user = await this.axios.api.getProfile({});
    user.expertises.forEach(item => this.idToExpertise.set(item.expertiseId, item));
  };

  @action
  selectExpertise = (value: string, label: string) => {
    this.selectedExpertise = {
      expertiseName: label,
      confirmations: 0,
      expertiseId: value,
    };
    console.log(value, label);
  };

  selectedExpertise: ExpertiseType = {
    expertiseId: '',
    confirmations: 0,
    expertiseName: '',
  };

  @action
  addToList = () => {
    if (!this.selectedExpertise.expertiseId) {
      return;
    }
    this.idToExpertise.set(this.selectedExpertise.expertiseId, this.selectedExpertise);
    this.searchExpertise = '';
    this.selectedExpertise = {
      expertiseId: '',
      confirmations: 0,
      expertiseName: '',
    };
    this.fetchExpertiseList();
  };

  searchExpertise = '';

  expertiseOptions: Array<{ value: string; label: string; active?: boolean }> = [];

  @action
  setExpertise = (v: string) => {
    this.searchExpertise = v;
    this.selectedExpertise = {
      expertiseId: '',
      confirmations: 0,
      expertiseName: '',
    };
    this.fetchExpertiseList();
  };

  @action
  deleteExpertise = (id: string) => {
    this.idToExpertise.delete(id);
    this.fetchExpertiseList();
  };

  @action
  fetchExpertiseList = async () => {
    await this.axios.api
      .getExpertises({ page: 0, size: 10, search: this.searchExpertise })
      .then(this.onFetchExpertiseListSuccess)
      .catch(this.onFetchExpertiseListError);
  };

  @action.bound
  protected onFetchExpertiseListSuccess = (response: TypeApiResponse<'getExpertises'>) => {
    this.expertiseOptions = response.content.map(i => {
      return {
        value: i.expertiseId,
        label: i.expertiseName,
        active: Boolean(this.idToExpertise.get(i.expertiseId)),
      };
    });
  };

  @action.bound
  protected onFetchExpertiseListError = () => {
    console.warn('onFetchExpertiseListError');
  };

  @action
  saveExpertises = async () => {
    await this.axios.api.saveExpertisesToProfile({
      ids: this.expertises.length > 0 ? this.expertises.map(i => i.expertiseId) : [],
    });
  };

  @action
  reset = () => {
    this.idToExpertise.clear();
    this.expertiseOptions = [];
    this.selectedExpertise = {
      expertiseId: '',
      confirmations: 0,
      expertiseName: '',
    };
    this.searchExpertise = '';
  };
}
