import { TypeApiRoute } from '../models';

type TypeRequest = {};

export type WidgetType = {
  id: any;
  type: any;
  name: any;
  widget_config: any;
};

type TypeResponse = {
  widgets: any;
};

export const getDefaultWidgets: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/widgets/defaultPanel`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
