import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { ActionsWrapper, IconBUtton, WidgetWrapper } from '../organizationUsersList/style';
import { Table } from '../../../../../../../shared/components/Table';
import { Chips } from '../../../../../../../shared/components/Chips';
import { Colors } from '../../../../../../../shared/consts/color';
import { Modal } from '../../../../../../../shared/components/Modal';
import { TypeApplication } from '../../../../../../../shared/types/applications';

import { UserApplicationView } from './components/UserApplicationView';
import { OrganizationApplicationStore } from './stores/organization.application.store';
import { OrganizationUsersStore } from './stores/organization.users.store';

export const statusInvitationMap = {
  SENT: 'Новое',
  ACCEPTED: 'Принято',
  DECLINED: 'Отклонено',
  CANCELLED: 'Отменено',
};

const statusTypeInvitationMap = {
  SENT: 'success',
  ACCEPTED: 'success',
  DECLINED: 'error',
  CANCELLED: 'error',
};

export const OrganizationInvitesApplications: FC = observer(() => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { openEditUserForm } = useStore(OrganizationUsersStore);
  const {
    applications,
    getApplications,
    acceptApplication,
    declineApplication,
    cancelInvitation,
  } = useStore(OrganizationApplicationStore);
  const [editApplicationForm, openEditApplicationForm] = useState<TypeApplication | null>(null);

  useEffect(() => {
    getApplications(organizationId);
  }, []);

  const handleAccept = (applicationId: string) => {
    acceptApplication(applicationId).then(() => {
      openEditApplicationForm(null);
      getApplications(organizationId);
    });
  };

  const handleDecline = (applicationId: string) => {
    if (editApplicationForm?.type === 'INVITATION') {
      cancelInvitation(applicationId).then(() => {
        openEditApplicationForm(null);
        getApplications(organizationId);
      });
    } else {
      declineApplication(applicationId).then(() => {
        openEditApplicationForm(null);
        getApplications(organizationId);
      });
    }
  };

  return (
    <WidgetWrapper>
      <Table
        headers={['Статус', 'Имя', 'Тип', 'Дата подключения', '']}
        ids={applications.map(item => item.applicationId)}
        rows={applications.map(item => {
          const organizationJoinDate = item.creationDate.slice(0, 10);
          const [year, month, day] = organizationJoinDate.split('-');

          return [
            <Chips
              title={statusInvitationMap[item.status]}
              // @ts-ignore
              type={statusTypeInvitationMap[item.status] || 'default'}
            />,
            item.userName ? item.userName : '',
            item.type === 'INVITATION' ? 'Приглашение' : 'Заявка',
            `${day}.${month}.${year}`,
            <ActionsWrapper>
              <IconBUtton>
                <Icon
                  size="small"
                  icon="account_info"
                  fill={Colors.darkGray}
                  onClick={() => {
                    openEditApplicationForm(item);
                    // openEditUserForm(item.user?.userId || item.createdBy.userId);
                  }}
                />
              </IconBUtton>
            </ActionsWrapper>,
          ];
        })}
      />
      {editApplicationForm && (
        <Modal
          shown={Boolean(editApplicationForm)}
          hideModal={() => {
            openEditApplicationForm(null);
          }}
        >
          <UserApplicationView
            organizationId={organizationId}
            application={editApplicationForm}
            hideModal={() => {
              openEditApplicationForm(null);
            }}
            handleAccept={handleAccept}
            handleDecline={handleDecline}
          />
        </Modal>
      )}
    </WidgetWrapper>
  );
});
