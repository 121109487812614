import { action, makeAutoObservable } from 'mobx';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { IStompSocket } from '@stomp/stompjs/esm6/types';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { SessionStore } from '../../authorization/stores/session.store';
import { Axios2 } from '../../shared/utils/axios2';

const DELAY_10_SECONDS = 1000 * 10;

@provide.singleton()
export class SocketWrapper {
  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  @lazyInject(Axios2)
  axios: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  socket: WebSocket | null = null;
  stompClient: Client | null = null;

  eventToHandler: Map<string, any> = new Map<string, any>();

  @action
  initiate = () =>
    new Promise<void>(resolve => {
      // const socket = new SockJS(`http://dev2.da.inpdev.local:18000/api/da-cnm/ws`, '');
      // const socket = new SockJS(`http://dev2.da.inpdev.local:19094/api/ws`, '');
      // const socket = new SockJS(`http://dev2.da.inpdev.local/api/da-cnm/ws`, '');
      const url = `${location.protocol}//${location.hostname}`;

      // const socket = new SockJS(`${url}/api/da-cnm/ws`, '');

      // this.stompClient = Stomp.over(new WebSocket('ws://dev2.da.inpdev.local:19094/api/ws'));
      console.log('socket initiated');
      // this.stompClient = Stomp.over(socket);
      this.stompClient = new Client();
      this.stompClient.brokerURL = url;
      this.stompClient.reconnectDelay = 5000;

      this.stompClient.onStompError((data: any) => {
        console.log('onStompError', data);
      });

      this.stompClient.onWebSocketClose((data: any) => {
        console.log('onWebSocketClose', data);
      });

      this.stompClient.webSocketFactory = () => {
        return new SockJS(`${url}/api/da-cnm/ws`, '') as IStompSocket;
      };

      this.stompClient.onConnect = frame => {
        console.log('socket connected', frame);
        const usedKeys = Array.from(this.eventToHandler.keys());

        usedKeys.forEach(key => {
          this.subscribeToTopic(key, this.eventToHandler.get(key));
        });

        resolve();
      };
      this.stompClient.onDisconnect = () => {
        setTimeout(() => {
          console.log('socket reload');
          this.initiate();
        }, DELAY_10_SECONDS);
      };

      this.stompClient.activate();
    });

  @action
  subscribeToTopic = <ResponseDTO>(topicUrl: string, handler: (response: ResponseDTO) => void) => {
    if (!this.stompClient) {
      console.warn("Can't subscribe to topic because, stomp client didn't initiate");
      return;
    }
    console.log('try connect: ', topicUrl);

    this.stompClient.subscribe(`/topic/${topicUrl}`, (message: any) => {
      console.log('stompClient.subscribe', message);
      handler(JSON.parse(message.body));
    });

    if (!this.eventToHandler.has(topicUrl)) {
      this.eventToHandler.set(topicUrl, handler);
    }
  };
}
