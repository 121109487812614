import { FC, useEffect, createContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import { EditWeathersPage } from '../../../../../../widgets/pages/EditWeathersPage/EditWeathersPage';
import { FullEditWeathersPage } from '../../../../../../widgets/pages/FullEditWeathersPage/FullEditWeathersPage';
import { EditQuotesPage } from '../../../../../../widgets/pages/EditQuotesPage/EditQuotesPage';
import { FullEditQuotesPage } from '../../../../../../widgets/pages/FullEditQuotesPage/FullEditQuotesPage';
import { MobileProfileMenu } from '../mobile-profile-menu/MobileProfileMenu';
import { ExternalRedirect } from '../../../../../authorization/containers/AuthorizationWrapper/AuthorizationWrapper';

import { ProfileLeftMenu, MenuItem } from './components/ProfileLeftMenu';
import { Profile } from './Profile';
import { ProfileRoutes } from './routes';
import { EditPersonalData } from './pages/editPersonalData';
import { EditWidgets } from './pages/EditWidgets';
import { ProfileGrid } from './style';
import { ProfileStore } from './stores/ProfileStore';
import { OrganizationListingStore } from './pages/organizational/stores/organization.listing.store';
import { OrganizationalRouting } from './pages/organizational/OrganizationalRouting';
import { OrganizationalRoutes } from './pages/organizational/OrganizationalRoutes';
import { EditCompetitionsPage } from './pages/editCompetitions/EditCompetitions';
import { EditInterestsPage } from './pages/editInterests/EditInterestsPage';
import { ChangeLog } from './pages/changeLog/ChangeLog';

export const ProfileStoreContext = createContext<any>(null);

export const ProfileRouter: FC<{ history: any; location: any }> = observer(
  ({ history, location }) => {
    const store = useStore(ProfileStore);
    const { fetchOrganizationsListing } = useStore(OrganizationListingStore);
    const { getUserInfo } = store;

    const externalAgroidProfile =
      process.env?.NODE_ENV === 'development'
        ? ProfileRoutes.ExternalAgroidProfileDev
        : `${window._env_.AUTH_URL}${ProfileRoutes.ExternalAgroidProfile}`;

    useEffect(() => {
      fetchOrganizationsListing();
      getUserInfo();
    }, []);

    const defaultPathTree: Array<MenuItem> = [
      {
        label: 'Виджеты',
        path: ProfileRoutes.Widgets,
        icon: 'add_widget',
      },
      {
        label: 'Профиль',
        path: ProfileRoutes.Dashboard,
        icon: 'user',
      },
    ];

    return (
      <ProfileGrid>
        <ProfileLeftMenu history={history} location={location} pathTree={defaultPathTree} />
        <ProfileStoreContext.Provider value={store}>
          <Switch>
            <Route path={ProfileRoutes.Organizations} component={OrganizationalRouting} />
            <Route path={ProfileRoutes.EditPersonalData} component={EditPersonalData} />
            <Route path={ProfileRoutes.EditCompetitionsData} component={EditCompetitionsPage} />
            <Route path={ProfileRoutes.EditInterestsData} component={EditInterestsPage} />
            <Route path={ProfileRoutes.FullEditWeatherWidgets} component={FullEditWeathersPage} />
            <Route path={ProfileRoutes.FullEditQuoteWidgets} component={FullEditQuotesPage} />
            <Route path={ProfileRoutes.EditWeatherWidgets} component={EditWeathersPage} />
            <Route path={ProfileRoutes.EditQuotesWidgets} component={EditQuotesPage} />
            <Route path={ProfileRoutes.Widgets} component={EditWidgets} />
            <Route path={ProfileRoutes.DashboardMobile} component={MobileProfileMenu} />
            <Route path={ProfileRoutes.ChangeLog} exact component={ChangeLog} />
            <Route path={ProfileRoutes.Dashboard} exact>
              <ExternalRedirect redirectTo={externalAgroidProfile} />
            </Route>
          </Switch>
        </ProfileStoreContext.Provider>
      </ProfileGrid>
    );
  }
);
