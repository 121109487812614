import { action, makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios2 } from '../../shared/utils/axios2';

@provide.singleton()
export class SessionStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
    document.addEventListener('logout', this.logout);
    document.addEventListener('updateTokens', () => {
      this.isLoggedIn = true;
      this.isLoading = false;
    });
  }

  isLoggedIn = false;
  isLoading = true;

  checkIsLoggedIn = async (authKey?: string | null) => {
    this.isLoading = true;

    try {
      if (authKey) {
        const authByKeyResponse = await this.axios2.api
          .erqToken({ code: authKey })
          .then(response => response);

        localStorage.setItem('accessToken', authByKeyResponse['access-token']);
        localStorage.setItem('refreshToken', authByKeyResponse['refresh-token']);
      }

      const accessToken = localStorage.getItem('loginAs') || localStorage.getItem('accessToken');

      if (!accessToken) {
        this.onCheckIsLoggedInError();
        throw new Error('Access token');
      }

      this.axios2.api.verifyAccessToken({});
      this.onCheckPass();
    } catch (err) {
      this.onCheckIsLoggedInError();
    } finally {
      this.isLoading = false;
    }
  };

  onCheckPass = () => {
    this.isLoggedIn = true;
  };

  onCheckIsLoggedInError = () => {
    this.isLoggedIn = false;

    localStorage.removeItem('accessToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');
  };

  logout = () => {
    this.isLoading = false;
    this.isLoggedIn = false;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');
    localStorage.removeItem('loginAs');
  };
}
