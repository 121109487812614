import styled from 'styled-components';

import { PaperWrapper } from '../PaperBlock/style';
import { Colors } from '../../consts/color';
import { Breakpoints } from '../../consts/sizes';

export const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);
  z-index: 101;
  overflow: auto;
`;

export const ModalWrapper = styled(PaperWrapper)`
  max-width: ${({ size }) => (size === 'small' ? '412px' : '1200px')};
  width: 100%;
  padding: 40px ${({ size }) => (size === 'small' ? '60px' : '30px')};
  position: fixed;
  z-index: 102;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow: auto;

  @media (max-width: ${Breakpoints.large}) {
    width: 872px;
    padding: 40px ${({ size }) => (size === 'small' ? '60px' : '21px')};
  }

  @media (max-width: ${Breakpoints.medium}) {
    ${({ size }) =>
      size === 'large' &&
      `
  padding-top: 21px;
  padding-bottom: 21px;
  max-width: 688px
  `}
  }

  @media (max-width: ${Breakpoints.small}) {
    ${({ size }) =>
      size === 'small'
        ? `
      max-width: 343px;
      padding-left: 16px;
      padding-right: 16px;
    `
        : `
      width: 343px;
      padding-left: 16px;
      padding-right: 16px;
      min-height: 550px;
    `}
  }
  @media (max-width: ${Breakpoints.extraSmall}) {
    max-width: 290px;
  }
`;

export const ModalHeader = styled.div`
  padding-bottom: 16px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${Colors.grayBorder};
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.div`
  font-size: 16px;
`;

export const ModalFooter = styled.div`
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid ${Colors.grayBorder};
  display: flex;
  justify-content: space-between;

  & button:nth-child(2) {
    margin-left: 16px;
  }

  & button {
    max-width: 180px;
    justify-content: center;
  }
`;
