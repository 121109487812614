import { FC, useEffect, useState } from 'react';
import { Button, Icon } from '@uralhim-innovative/farmik-ui';

import { ActionsWrapper, ModalTitle } from '../InviteUserForm/style';
import { Colors } from '../../../../../../../../../shared/consts/color';
import { List } from '../../../../../../../../../shared/components/List';
import { TypeUser, TypeUserRole } from '../../../../../../../../../shared/types/users';
import cancel from '../../../../../../../../../shared/assets/images/cancel.png';
import succes from '../../../../../../../../../shared/assets/images/success.png';
import { TableUserType } from '../../stores/organization.users.store';
import { UserRoleMap } from '../../OrganizationUsersList';
import {
  ListItem,
  ListKey,
  ListValue,
  ListWrapper,
} from '../../../../../../../../../shared/components/List/style';

import { DropDown, Image, ModalButton, ModalDescription, NameLabel } from './style';

type Props = {
  chosenUserId: string;
  closeEditUserForm: () => void;
  getUserById: (payload: { organizationId: string }) => Promise<TypeUser>;
  updateUserRole: (
    payload: { role: TypeUserRole; employeeId?: string },
    organizationId: string
  ) => Promise<any>;
  deleteUser: (organizationId: string) => Promise<any>;
  getOrganizationUsersList: (organizationId: string) => void;
  organizationId: string;
  tableUsersList: TableUserType[];
};

export const EditUserForm: FC<Props> = ({
  chosenUserId,
  closeEditUserForm,
  getUserById,
  updateUserRole,
  deleteUser,
  getOrganizationUsersList,
  organizationId,
  tableUsersList,
}) => {
  const [user, setUser] = useState<TypeUser | null>(null);
  const [deleteUserConfirm, setDeleteUserConfirm] = useState(false);
  const [deleteUserSuccess, setDeleteUserSuccess] = useState(false);
  const [changeRoleOpened, setChangeRoleOpened] = useState(false);

  const tableUser = tableUsersList.find(_user => _user.employeeId === chosenUserId);
  const [role, setRole] = useState<TypeUserRole | undefined>(
    tableUser ? tableUser.role : undefined
  );

  const handleUpdateUser = () => {
    if (tableUser && role) {
      updateUserRole(
        {
          role,
          employeeId: tableUser?.employeeId,
        },
        organizationId
      ).then(() => {
        closeEditUserForm();
        getOrganizationUsersList(organizationId);
      });
    }
  };

  const handleDeleteUser = () => {
    deleteUser(organizationId).then(() => {
      setDeleteUserConfirm(false);
      setDeleteUserSuccess(true);
    });
  };

  const handleChangeRole = (_role: TypeUserRole) => {
    setChangeRoleOpened(false);
    setRole(_role);
  };

  if (deleteUserConfirm) {
    return (
      <div>
        <Image src={cancel} />
        <ModalDescription>Вы уверены, что хотите исключить {user?.userName}?</ModalDescription>
        <ActionsWrapper>
          <Button
            type="button"
            color="default"
            onClick={() => {
              setDeleteUserConfirm(false);
            }}
          >
            Нет
          </Button>
          <Button type="button" color="secondary" onClick={handleDeleteUser}>
            Да
          </Button>
        </ActionsWrapper>
      </div>
    );
  }

  if (deleteUserSuccess) {
    return (
      <div>
        <Image src={succes} />
        <ModalDescription>Сотрудник исключен</ModalDescription>
        <ModalButton>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              closeEditUserForm();
              getOrganizationUsersList(organizationId);
            }}
          >
            Хорошо
          </Button>
        </ModalButton>
      </div>
    );
  }

  return (
    <div>
      <ModalTitle>
        Сотрудник{' '}
        <span
          onClick={() => {
            setDeleteUserConfirm(true);
          }}
        >
          Исключить <Icon size="small" fill={Colors.red} icon="close" />
        </span>
      </ModalTitle>
      <NameLabel>{tableUser && tableUser.userName}</NameLabel>
      <ListWrapper>
        <ListItem>
          <ListKey>Роль</ListKey>
          <ListValue
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            status="success"
            onClick={() => setChangeRoleOpened(!changeRoleOpened)}
          >
            <span>{role ? UserRoleMap[role] : ''}</span>
            <Icon fill={Colors.green} size="small" icon="arrow_down" />
            {changeRoleOpened && (
              <DropDown>
                <span onClick={() => handleChangeRole('OWNER')}>{UserRoleMap.OWNER}</span>
                <span onClick={() => handleChangeRole('MANAGER')}>{UserRoleMap.MANAGER}</span>
                <span onClick={() => handleChangeRole('EMPLOYEE')}>{UserRoleMap.EMPLOYEE}</span>
              </DropDown>
            )}
          </ListValue>
        </ListItem>
      </ListWrapper>
      <List
        items={[
          // {
          //   key: 'Специализация',
          //   value: user.specialization ? user.specialization.name : '',
          // },
          // {
          //   key: 'Телефон',
          //   value: user.phone
          //     ? `${user.phone.phoneNumberCode} ${user.phone.phoneNumber}`
          //     : '',
          // },
          // {
          //   key: 'E-mail',
          //   value: user.email ? user.email.email : '',
          // },
          {
            key: 'Дата заявки',
            value: tableUser ? tableUser.organizationJoinDate : '',
          },
        ]}
      />
      <ActionsWrapper>
        <Button type="button" color="default" onClick={closeEditUserForm}>
          Отменить
        </Button>
        <Button type="button" color="primary" onClick={handleUpdateUser}>
          Сохранить
        </Button>
      </ActionsWrapper>
    </div>
  );
};
