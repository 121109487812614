import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeOrganization } from '../modules/shared/types/organization';

type TypeRequest = TypeOrganization & { orgTypeId?: number };

type TypeResponse = {};

export const createOrganization: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/organizations/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
