import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  "needAdditionalInfo"?: boolean,
  "access-token": string,
  "access-token-type"?: string,
  "refresh-token": string,
  "rt-expires-on"?: string
};

type TypeResponse = {
  "needAdditionalInfo": boolean,
  "access-token": string,
  "access-token-type": string,
  "refresh-token": string,
  "rt-expires-on": string
};

export const renewAccessAndRefreshToken: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/users/v2/renew`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
