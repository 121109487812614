import { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import { OrganizationCard } from '../OrganizationCard';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { Modal } from '../../../../../../../../../shared/components/Modal';
import { CreateOraganizationWizard } from '../../../../modules/organizations/components/CreateOraganizationWizard';
import { OrganizationOutgoingStore } from '../../stores/organization.outgoing.store';
import { ApplicationView } from '../ApplicationView';
import { ApplicationType } from '../../types/ApplicationType';
import { OrganizationInvitationStore } from '../../stores/organization.invitation.store';
import { OrganizationCard as OrganizationCardType } from '../../types/OrganizationCard';
import { MyListsControlBar } from '../MyListsControllBar';
import { Grid, WidgetWrapper } from '../OrganizationList/style';
import { RootStoresContext } from '../../../../../../../../../App';
import { UiStore } from '../../../../../../../../../shared/stores/ui.store';
import { BreakpointsNumbers } from '../../../../../../../../../shared/consts/sizes';
import { ProfileMenuMobilePageHeaderWrapper } from '../../../../components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../../../../../../../shared/components/MobilePageHeader/MobilePageHeader';
import { ProfileRoutes } from '../../../../routes';
import { MobileTabs } from '../../../../../../../../../shared/components/MobileTabs/MobileTabs';
import { mobileMenuItems } from '../OrganizationList/constants';

export const OutgoingOrganizationsWidget: FC<RouteComponentProps> = observer(({ history }) => {
  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent } = headerComponentStore;
  const uiStore = useStore(UiStore);

  const { fetchApplicationsAndInvites, applications, idToApplication } = useStore(
    OrganizationOutgoingStore
  );
  const {
    acceptInviteFromOrganization,
    declineInviteFromOrganization,
    acceptApplicationFromUser,
    declineApplicationFromUser,
    deleteInvite,
  } = useStore(OrganizationInvitationStore);
  const [createOrganizationFormShowed, setCreateOrganizationFormShowed] = useState(false);
  const [chosenApplication, setChosenApplication] = useState<ApplicationType | null>(null);

  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small) {
      setHeaderContent(
        <ProfileMenuMobilePageHeaderWrapper withTab={true}>
          <MobilePageHeader
            title={'Организации'}
            onBack={() => {
              history.push(ProfileRoutes.DashboardMobile);
            }}
          />
          <MobileTabs items={mobileMenuItems} />
        </ProfileMenuMobilePageHeaderWrapper>,
        {
          height: '90px',
        }
      );
    }

    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  useEffect(() => {
    fetchApplicationsAndInvites();
  }, [fetchApplicationsAndInvites]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickOnOrganization = (item: OrganizationCardType) => (organizationId: string) => {
    if (item.applicationId) {
      const application = idToApplication.get(item.applicationId);

      if (
        application &&
        application.applicationId &&
        application.state &&
        application.organization
      ) {
        setChosenApplication({
          applicationId: application.applicationId,
          status: application.state,
          type: application.type === 'application' ? 'APPLICATION' : 'INVITATION',
          organization: application.organization,
          createdWhen: application.createdWhen || '',
          comment: application.comment || '',
        });
      }
    }
  };

  return (
    <WidgetWrapper>
      <MyListsControlBar />
      <Grid>
        {applications &&
          applications.map(item => (
            <OrganizationCard
              organizationId={item.organizationId}
              title={item.title}
              roleName={item.roleName}
              type={item.type}
              onClick={handleClickOnOrganization(item)}
              avatar={item.avatar}
              state={item.state}
            />
          ))}
      </Grid>
      <Modal
        shown={createOrganizationFormShowed}
        hideModal={() => {
          setCreateOrganizationFormShowed(false);
        }}
      >
        <CreateOraganizationWizard
          hideWizard={() => {
            setCreateOrganizationFormShowed(false);
          }}
        />
      </Modal>

      {chosenApplication && (
        <Modal
          shown={Boolean(chosenApplication)}
          hideModal={() => {
            setChosenApplication(null);
          }}
        >
          <ApplicationView
            hideApplicationView={() => {
              setChosenApplication(null);
              fetchApplicationsAndInvites();
            }}
            application={chosenApplication}
            acceptInviteFromOrganization={acceptInviteFromOrganization}
            declineInviteFromOrganization={declineInviteFromOrganization}
            acceptApplicationFromUser={acceptApplicationFromUser}
            declineApplicationFromUser={declineApplicationFromUser}
            deleteInvite={deleteInvite}
            isOutgoing={true}
          />
        </Modal>
      )}
    </WidgetWrapper>
  );
});
