import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { FieldType, FieldStatusType } from '../modules/shared/types/Field';

type TypeRequest = {
  'x-user-id': string;
  status?: FieldStatusType;
  search?: string;
  pageable?: {
    page: number;
    size: number;
  };
};

type TypeResponse = {
  content: Array<FieldType>;
} & TypeResponsePageable;

export const getFieldsMine: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/fields/myFields`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-field',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
