import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeNews } from '../modules/main/modules/news/types/type-news';
import { TypeTag } from '../modules/main/modules/news/types/type-tag';

type TypeRequest = {
  search: string;
  size: number;
};

type TypeResponse = {
  content: Array<TypeTag>;
} & TypeResponsePageable;

export const getNewsTags: TypeApiRoute & {
  request: TypeRequest;
  response: any;
} = {
  url: `/api/da-news/tags/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
