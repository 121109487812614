import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WelcomeWrapper = styled.div`
  margin-bottom: 32px;
`;
