import { useContext, useEffect, FC } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { RootStoresContext } from '../../../../../../../App';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { UiStore } from '../../../../../../../shared/stores/ui.store';
import { BreakpointsNumbers } from '../../../../../../../shared/consts/sizes';
import { ProfileMenuMobilePageHeaderWrapper } from '../../components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../../../../../shared/components/MobilePageHeader/MobilePageHeader';
import { ProfileRoutes } from '../../routes';

import { Wrapper, Title, ItemWrapper, Item, SubTitle } from './style';

export const ChangeLog: FC = observer(() => {
  const history = useHistory();
  const { headerComponentStore } = useContext(RootStoresContext);

  const { setHeaderContent, headerContent } = headerComponentStore;
  const uiStore = useStore(UiStore);

  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small) {
      setHeaderContent(
        <ProfileMenuMobilePageHeaderWrapper>
          <MobilePageHeader
            title={'Изменения'}
            onBack={() => {
              history.push(ProfileRoutes.Dashboard);
            }}
          />
        </ProfileMenuMobilePageHeaderWrapper>,
        {
          height: '56px',
        }
      );
    }

    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small && !headerContent) {
      setHeaderContent(
        <MobilePageHeader
          title={'Изменения'}
          onBack={() => {
            history.push(ProfileRoutes.DashboardMobile);
          }}
        />,
        {
          height: '56px',
        }
      );
    } else if (!headerContent) {
      setHeaderContent(null, {
        height: '76px',
      });
    }
  }, [uiStore.windowWidth]);

  return (
    <Wrapper>
      <Title>Версия 1.2.0</Title>
      <ItemWrapper>
        <SubTitle>Новые функции:</SubTitle>
        <Item>Адаптив списка полей</Item>
        <Item>Адаптив формы создания поля</Item>
        <Item>Адаптив селектора культур</Item>
        <Item>Скролл ленты новостей на главной странице</Item>
      </ItemWrapper>
      <Title>Версия 1.1.0</Title>
      <ItemWrapper>
        <SubTitle>Новые функции:</SubTitle>
        <Item>Переработан контент в описании сервиса</Item>
        <Item>Убрано поле специализация из профиля</Item>
      </ItemWrapper>
      <Title>Версия 1.0.1</Title>
      <ItemWrapper>
        <SubTitle>Новые функции:</SubTitle>
        <Item>Адаптив страницы просмотра профиля</Item>
        <Item>Адаптив страницы редактирования данных профиля</Item>
        <Item>Адаптив страницы списка организаций</Item>
        <Item>Адаптив страницы списка пользователей организации</Item>
        <Item>Адаптив страницы заявок в организации</Item>
        <Item>Адаптив детальной страницы виджета погоды</Item>
        <Item>Адаптив детальной страницы виджета котировок</Item>
        <Item>Адаптив просмотра страницы пользователя от 3го лица</Item>
        <Item>Адаптив страницы списка сервисов</Item>
        <Item>Адаптив страницы просмотра сервисов</Item>
      </ItemWrapper>
    </Wrapper>
  );
});
