import { TypeService } from '../modules/shared/types/service';
import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';

type TypeRequest = {
  userId: string;
};

type TypeResponse = Array<string>;

export const getTopicsList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-cnm/websocket/topic/list/${request.userId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
