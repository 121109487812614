import React, { useEffect, useRef, useState } from 'react';
import { Select, Icon } from '@uralhim-innovative/farmik-ui';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import serviceImgFirst from '../../../../../shared/assets/images/service_1.png';
import serviceImgSecond from '../../../../../shared/assets/images/service_2.png';
import { useStore } from '../../../../../shared/utils/IoC';
import { ServicesStore } from '../../stores/services.store';
import { PaperWrapper } from '../../../../../shared/components/PaperBlock/style';
import { ServiceCard } from '../../components/ServiceCard';
import { Carousel } from '../../components/Carousel';
import { Card } from '../../components/Carousel/components/Card';
import calculatorLogo from '../../../../../shared/assets/images/calculator_logo.svg';
import FiltersSvg from '../../../../../shared/assets/images/options.svg';

import {
  CarouselWrapper,
  FiltersWrapper,
  PageWrapper,
  ServicesListWrapper,
  FiltersIconWrapper,
  FilterSearchInput,
  MobileCarouselServiceCardWrapper,
} from './style';

type Props = Record<string, any>;

export const Services = withRouter(
  observer(({ history }: RouteComponentProps & Props) => {
    const [filtersOpened, setFiltersOpened] = useState(false);
    const cardWrapperReference = useRef(null);

    const handleClickOnService = (id: string) => {
      history.push(`/services/${id}`);
    };

    const handleCLickOnCalculator = () => {
      history.push(`/services/calculator`);
    };

    const store = useStore(ServicesStore);
    const {
      categories,
      promos,
      services,
      fetchPromo,
      fetchCategories,
      reset,
      setSearchQuery,
      setCategoryId,
      isPaginationalAvailable,
      load,
      additionalLoad,
      isPaginationLoading,
    } = store;

    useEffect(() => {
      load();
      fetchCategories();
      fetchPromo();

      return () => {
        reset();
      };
    }, []);

    const handleScroll = () => {
      // @ts-ignore
      const { clientHeight, scrollHeight, scrollTop } = cardWrapperReference.current;
      // @ts-ignore
      const difference = scrollHeight - clientHeight;

      // @ts-ignore
      if (isPaginationalAvailable && scrollTop + 100 >= difference && !isPaginationLoading) {
        additionalLoad();
      }
    };

    return (
      <PageWrapper>
        {promos.length > 4 && (
          <CarouselWrapper>
            <Carousel changeFrequencyInSeconds={3}>
              {promos.map(promo => (
                <Card
                  avatar={promo.pictureSmall && serviceImgSecond}
                  title={promo.serviceName}
                  tags={promo.categories.map(category => category.categoryName)}
                  onClick={() => handleClickOnService(promo.id)}
                />
              ))}
            </Carousel>
          </CarouselWrapper>
        )}

        {promos.length > 4 && (
          <MobileCarouselServiceCardWrapper>
            {promos.map(promo => (
              <Card
                avatar={promo.pictureSmall && serviceImgSecond}
                title={promo.serviceName}
                tags={promo.categories.map(category => category.categoryName)}
                onClick={() => handleClickOnService(promo.id)}
              />
            ))}
          </MobileCarouselServiceCardWrapper>
        )}

        <PaperWrapper>
          <FiltersWrapper filtersOpened={filtersOpened}>
            <FilterSearchInput
              startIcon="search"
              placeholder="Поиск по сервисам"
              onChange={_.throttle(setSearchQuery, 100)}
            />
            <FiltersIconWrapper onClick={() => setFiltersOpened(!filtersOpened)}>
              <img src={FiltersSvg} alt="" />
            </FiltersIconWrapper>
            <Select
              placeholder="Категория"
              onChange={setCategoryId}
              options={categories.map(category => ({
                label: category.categoryName,
                value: category.categoryId,
              }))}
            />
          </FiltersWrapper>
          <ServicesListWrapper ref={cardWrapperReference} onScroll={_.throttle(handleScroll, 100)}>
            {/*<ServiceCard*/}
            {/*  title="Калькулятор удобрений"*/}
            {/*  categories={['Планирование']}*/}
            {/*  id="calculator"*/}
            {/*  img={calculatorLogo}*/}
            {/*  onClick={handleCLickOnCalculator}*/}
            {/*/>*/}
            {services.map(service => (
              <ServiceCard
                title={service.serviceName}
                categories={service.categories.map(category => category.categoryName).splice(0, 2)}
                id={service.id}
                img={service.pictureSmall?.downloadUrl || serviceImgFirst}
                onClick={handleClickOnService}
              />
            ))}
          </ServicesListWrapper>
        </PaperWrapper>
      </PageWrapper>
    );
  })
);
