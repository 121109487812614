import styled from 'styled-components';

import { Colors } from '../../../../../../shared/consts/color';

export const Wrapper = styled.div<{ image: string }>`
  width: 100%;
  height: 96px;
  ${({ image }) => `background: url(${image});`};
  background-size: cover;
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  margin-bottom: 20px;
`;

export const GreetingText = styled.span`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;

  color: ${Colors.white};

  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
`;
