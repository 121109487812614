import styled from 'styled-components';

import { Colors } from '../../consts/color';
import { BorderRadius, Breakpoints } from '../../consts/sizes';

export const Wrapper = styled.div``;

export const TableHeader = styled.div<{ columns: number }>`
  padding: 32px 24px;
  background: ${Colors.gray};
  border-top-left-radius: ${BorderRadius.default};
  border-top-right-radius: ${BorderRadius.default};

  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  width: 100%;

  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
`;

export const TableBody = styled.div``;

export const TableRow = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  padding: 32px 24px;
  border-top: 1px solid ${Colors.grayBorder};
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  }
`;

export const TableItem = styled.div<{ hideonMobile: boolean }>`
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${Breakpoints.small}) {
    min-width: 92px;
    ${({ hideonMobile }) => hideonMobile && `display: none;`};
  }
`;

export const EmptyTableMessage = styled.div`
  padding: 40px;
  text-align: center;
`;
