import React, { FC, useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { observer } from 'mobx-react';
import { Input, Button } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import { Modal } from '../../../../../../../shared/components/Modal';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { PaperBlock } from '../../../../../../../shared/components/PaperBlock';
import { EditWidgetStore } from '../../../../../../../../stores/editWidget.store';
import { QuotesEditWidget } from '../../../../../../../../widgets/components/Quotes/QuotesEditWidget/QuotesEditWidget';

import { AddWidgetForm } from './AddWidgetForm';
import { MainPageWidget } from './MainPageWidget';
import { WeathrWidgetGroup } from './WeathrWidgetGroup';
import { QuotesWidgetGroup } from './QuotesWidgetGroup';
import { MainPageWidgetsGroup, EditMainPageWidgetsActionPanel, WidgetListWrapper } from './style';

export const EditWidgets: FC = observer(() => {
  const store = useStore(EditWidgetStore);
  const [addWidgetFormShowed, setAddWidgetFormShowed] = useState(false);
  const history = useHistory();

  useEffect(() => {
    store.initiate();
    return () => {
      store.clear();
    };
  }, []);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <PaperBlock>
          <MainPageWidgetsGroup>
            <MainPageWidget
              onDrop={data => store.setMainPageWidget('1', data)}
              widgetType="king"
              widgetData={store.mainPageWidgets['1']?.id ? store.mainPageWidgets['1'] : undefined}
              onRemove={() => store.removeWidgetFromMainPage('1')}
              dataTestId={'widget-slot-1'}
            />
            <MainPageWidget
              onDrop={data => store.setMainPageWidget('2', data)}
              widgetType="normal"
              widgetData={store.mainPageWidgets['2']?.id ? store.mainPageWidgets['2'] : undefined}
              onRemove={() => store.removeWidgetFromMainPage('2')}
              dataTestId={'widget-slot-2'}
            />
            <MainPageWidget
              onDrop={data => store.setMainPageWidget('3', data)}
              widgetType="normal"
              widgetData={store.mainPageWidgets['3']?.id ? store.mainPageWidgets['3'] : undefined}
              onRemove={() => store.removeWidgetFromMainPage('3')}
              dataTestId={'widget-slot-3'}
            />
          </MainPageWidgetsGroup>
        </PaperBlock>

        <EditMainPageWidgetsActionPanel>
          <Input
            startIcon="search"
            placeholder="Поиск"
            value={store.search}
            dataTestId={'widgets-search'}
            onChange={value => store.setSearch(value)}
          />
          <Button
            color="primary"
            type="button"
            alignment="center"
            dataTestId={'add-widget'}
            onClick={() => {
              setAddWidgetFormShowed(true);
            }}
          >
            Добавить виджет
          </Button>
        </EditMainPageWidgetsActionPanel>

        <WidgetListWrapper>
          {store.widgetsList.map((widget, index) => {
            if (widget.type === 'WEATHER') {
              return (
                <WeathrWidgetGroup
                  widgetData={{
                    name: widget.name,
                    type: widget.type,
                    id: widget.id,
                    widget_config: widget.widget_config,
                  }}
                  id={widget.id}
                  dataTestId={`widget-${index}`}
                  key={widget.id}
                  onClick={() => history.push(`/dashboard/profile/widgets/weather?id=${widget.id}`)}
                />
              );
            }

            if (widget.type === 'QUOTES') {
              return (
                <QuotesWidgetGroup
                  widgetData={{
                    name: widget.name,
                    type: widget.type,
                    id: widget.id,
                    widget_config: widget.widget_config,
                  }}
                  id={widget.id}
                  key={widget.id}
                  dataTestId={`widget-${index}`}
                  onClick={() => history.push(`/dashboard/profile/widgets/quotes?id=${widget.id}`)}
                />
              );
            }

            return 'asd';
          })}
        </WidgetListWrapper>
      </DndProvider>
      <Modal
        shown={addWidgetFormShowed}
        hideModal={() => {
          setAddWidgetFormShowed(false);
        }}
      >
        <AddWidgetForm
          close={() => {
            setAddWidgetFormShowed(false);
          }}
        />
      </Modal>
    </div>
  );
});
