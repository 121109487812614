import { FC, useEffect, useRef, useState } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';
import * as _ from 'lodash';

import { Colors } from '../../../../../shared/consts/color';

import { CardsWrapper, InnerWrapper, LeftControl, RightControl, Wrapper } from './style';

type CarouselProps = {
  changeFrequencyInSeconds?: number;
};

const CARD_MARGIN_RIGHT_IN_PIXELS = 12.5;
const THROTTLE_RATE_IN_MILLISECONDS = 100;

export const Carousel: FC<CarouselProps> = ({ changeFrequencyInSeconds = 1, children }) => {
  const wrapperReference = useRef(null);
  const [shift, setShift] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [timerFlag, setTimerFlag] = useState(false);
  const [isLeftControlDisabled, setIsLeftControlDisabled] = useState(false);
  const [isRightControlDisabled, setIsRightControlDisabled] = useState(false);
  const [cardsAmount, setCardsAmount] = useState(0);
  const timerToClearSomewhere = useRef<NodeJS.Timeout>();
  const containerReference = useRef(null);
  const [touchX, setTouchX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  useEffect(() => {
    if (touchX > touchEndX) {
      handleRightControlClick();
    } else {
      handleLeftControlClick();
    }
  }, [touchEndX]);

  useEffect(() => {
    // @ts-ignore
    const { width } = containerReference.current.getBoundingClientRect();
    if (width >= width - shift) {
      setIsLeftControlDisabled(true);
    } else {
      setIsLeftControlDisabled(false);
    }

    console.log(width, cardsAmount * cardWidth, cardsAmount * CARD_MARGIN_RIGHT_IN_PIXELS, shift);
    console.log(
      cardsAmount * cardWidth + cardsAmount * CARD_MARGIN_RIGHT_IN_PIXELS + shift - width,
      cardWidth
    );
    if (
      cardsAmount * cardWidth + cardsAmount * CARD_MARGIN_RIGHT_IN_PIXELS + shift - width <
      -cardWidth
    ) {
      setIsRightControlDisabled(true);
    } else {
      setIsRightControlDisabled(false);
    }
  }, [shift]);

  useEffect(() => {
    if (!timerFlag) {
      timerToClearSomewhere.current = setInterval(
        handleRightControlClick,
        changeFrequencyInSeconds * 1000
      );
      setTimerFlag(true);
    }

    return () => clearInterval(timerToClearSomewhere.current!);
  }, [cardWidth]);

  useEffect(() => {
    const listeners = [
      ['mouseenter', handleHover],
      ['mouseleave', handleOutHover],
      ['touchstart', handleTouchStart],
      ['touchend', handleTouchEnd],
    ].map(value => [value[0], _.throttle(value[1] as any, THROTTLE_RATE_IN_MILLISECONDS)]);

    if (wrapperReference.current) {
      // @ts-ignore
      const { width } = wrapperReference.current.children[0].getBoundingClientRect();
      setCardWidth(width);
      // setShift(-width / 2);
      // @ts-ignore
      setCardsAmount(wrapperReference.current.children.length);
      // @ts-ignore
      listeners.forEach(value => wrapperReference.current.addEventListener(value[0], value[1]));
    }
    return () => {
      if (wrapperReference.current) {
        listeners.forEach(value =>
          // @ts-ignore
          wrapperReference.current.removeEventListener(value[0], value[1])
        );
      }
    };
  }, [wrapperReference]);

  const handleTouchStart = (event: any) => {
    setTouchX(event.changedTouches[0].pageX);
    handleHover();
  };

  const handleTouchEnd = (event: any) => {
    setTouchEndX(event.changedTouches[0].pageX);
    handleOutHover();
  };

  const handleLeftControlClick = () => {
    if (isLeftControlDisabled) {
      return;
    }
    setShift(prevShift => prevShift + (cardWidth + CARD_MARGIN_RIGHT_IN_PIXELS));
  };

  const handleRightControlClick = () => {
    if (isRightControlDisabled) {
      return;
    }

    setShift(prevShift => prevShift - (cardWidth + CARD_MARGIN_RIGHT_IN_PIXELS));
  };

  useEffect(() => {
    if (isRightControlDisabled) {
      clearInterval(timerToClearSomewhere.current!);
      setTimerFlag(true);
      setTimeout(() => setShift(0), changeFrequencyInSeconds * 1000);
    } else if (!timerFlag) {
      clearInterval(timerToClearSomewhere.current!);
      timerToClearSomewhere.current = setInterval(
        () => {
          handleRightControlClick();
        },

        changeFrequencyInSeconds * 1000
      );
      setTimerFlag(false);
    }
  }, [isRightControlDisabled]);

  const handleHover = () => {
    if (!timerFlag) {
      clearInterval(timerToClearSomewhere.current!);
      setTimerFlag(true);
    }
  };

  const handleOutHover = () => {
    if (timerFlag) {
      timerToClearSomewhere.current = setInterval(
        () => {
          handleRightControlClick();
        },

        changeFrequencyInSeconds * 1000
      );
      setTimerFlag(false);
    }
  };

  return (
    <Wrapper>
      <LeftControl isDisabled={isLeftControlDisabled} onClick={handleLeftControlClick}>
        <Icon size="small" icon="arrow_left" fill={Colors.white} />
      </LeftControl>
      <RightControl isDisabled={isRightControlDisabled} onClick={handleRightControlClick}>
        <Icon size="small" icon="arrow_right" fill={Colors.white} />
      </RightControl>
      <InnerWrapper ref={containerReference}>
        <CardsWrapper
          style={{ transform: `translate3d(${shift}px, 0, 0)` }}
          ref={wrapperReference}
          onMouseEnter={handleHover}
          onMouseLeave={handleOutHover}
        >
          {children}
        </CardsWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};
