import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints, BorderRadius } from '../../../../../shared/consts/sizes';

type WrapperStyledProps = {
  isFixed: boolean;
};

export const Spacer = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  background: ${Colors.gray};
  height: 144px;
  top: 0;
  left: -18px;
  right: -18px;

  @media (max-width: ${Breakpoints.small}) {
    height: 134px;
  }
`;

export const Wrapper = styled.div<WrapperStyledProps>`
  max-width: 912px;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 16px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  // position: ${({ isFixed }) => (isFixed ? 'fixed;top: 100px' : 'absolute')};
  position: sticky;
  top: 98px;

  @media (max-width: 1284px) {
    margin: 0;
    justify-content: space-between;
    border-radius: 0;
    top: 82px;
    max-width: 100%;
  }

  @media (max-width: ${Breakpoints.small}) {
    top: 62px;
  }
`;

export const ControlWrapper = styled.div`
  width: 208px;
  margin-right: 16px;
  @media (max-width: ${Breakpoints.small}) {
    width: 300px;
  }
`;

export const HiddenOnMobileControls = styled.div<{ active: boolean }>`
  @media (max-width: ${Breakpoints.small}) {
    display: ${({ active }) => (active ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
    padding: 16px;

    ${ControlWrapper} {
      margin-bottom: 16px;
    }
  }
`;

export const MobileControlAction = styled.div`
  display: none;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const MobileControlCloseAction = styled.div`
  display: none;
  position: absolute;
  top: 32px;
  right: 20px;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const MobileControlWrapper = styled.div``;
