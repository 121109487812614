import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios2 } from '../../../../shared/utils/axios2';
import { SessionStore } from '../../../../authorization/stores/session.store';

@provide.singleton()
export class DashboardController {
  @lazyInject(Axios2)
  axios: Axios2;

  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  checkIsUserLogined = async (redirectUrl: string) => {
    const accessToken = localStorage.getItem('loginAs') || localStorage.getItem('accessToken');

    if (!accessToken) {
      this.sessionStore.onCheckIsLoggedInError();

      window.location.href = redirectUrl;
    }

    try {
      // is token valid
      await this.axios.api.verifyAccessToken({});
      this.sessionStore.onCheckPass();

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(new Error(e));
    }
  };

  checkIsNoNeedAdditionalInfo = async (redirectUrl: string) => {
    try {
      const response = await this.axios.api.getProfile({});

      return Promise.resolve(response);
    } catch (error) {
      window.location.href = redirectUrl;
      return Promise.reject(error.response.headers['x-need-additional-info']);
    }
  };
}
