import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../modules/shared/consts/sizes';

export const PlantProtectionSection = styled.div`
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  min-height: 224px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 12px;

  @media (max-width: ${Breakpoints.small}) {
    padding: 32px 16px;
  }
`;

export const PlantProtectionHeader = styled.div`
  height: 32px;
  width: 100%;
  border-bottom: 0.5px solid ${Colors.grayBorder};

  p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: ${Colors.black};
  }
`;

export const PlantProtectionContent = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PlantProtectionContentTimeLineWrapper = styled.div`
  max-width: calc(100vw - 289px);
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  @media (max-width: ${Breakpoints.small}) {
    max-width: 100%;
  }
`;

export const PlantProtectionContentTimeLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

type PlantProtectionTimePartType = {
  type: 'common' | 'green' | 'yellow' | 'red';
};

const renderColor = (color: string) => {
  const variants = {
    common: Colors.lightnessGray,
    green: Colors.green,
    yellow: Colors.yellow,
    red: Colors.red,
  };

  return variants[color as PlantProtectionTimePartType['type']];
};

export const PlantProtectionTimePart = styled.div<PlantProtectionTimePartType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${Colors.darkGray};
    margin: 0 0 8px;
  }
  div {
    width: 38px;
    height: 8px;
    background: ${({ type }) => renderColor(type)};
  }

  @media (max-width: ${Breakpoints.large}) {
    background: ${({ type }) => renderColor(type)};
    border-radius: 8px;
    padding: 8px 16px;
    margin-right: 4px;

    p {
      margin-bottom: 0;
      color: ${({ type }) => (type === 'green' ? Colors.white : Colors.black)};
    }

    div {
      display: none;
    }
  }
`;

export const PlantProtectionContentDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;
