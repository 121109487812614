import {action, comparer, computed, makeAutoObservable, observable, toJS} from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../../../../../../../shared/utils/axios2';
import { ExpertiseType } from '../../../stores/EditInterestsStore';
import {NewsModel} from "../models/news.model";
const NEWS_PUBLISHED_STATUS = 'PUBLIC';

@provide.singleton()
export class GuestProfileStore {
  @lazyInject(Axios2)
  protected axios: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  profileId = '';

  @action
  fetchStartData = (id: string) => {
    this.fetchProfileInformation(id);
    this.fetchNews(id);
    this.profileId = id;
  };

  @action
  fetchProfileInformation = (id: string) => {
    this.axios.api
      .getProfileById({ id }, { noValidate: true })
      .then(this.onFetchProfileInformationSuccess)
      .catch(this.onFetchProfileInformationError);
  };

  @action.bound
  protected onFetchProfileInformationSuccess = async (response: TypeApiResponse<any>) => {
    this.firstName = response.firstName;
    this.lastName = response.lastName;
    this.secondName = response.secondName;
    this.specialization = response.specialization?.description;
    this.interests = response.interests.map((i: any) => i.interestName);
    response.expertises.forEach((i: any) =>
      this.idToExpertises.set(i.id, {
        expertiseId: i.id,
        confirmations: i.confirmations,
        expertiseName: i.expertiseName,
        isLiked: false,
      })
    );
    await response.expertises.forEach((expertise: any) => {
      this.fetchExpertiseStatus(expertise.id);
    });
    // eslint-disable-next-line no-unused-expressions
    response.avatar && (this.avatar = response.avatar.downloadUrl);
  };

  @action
  fetchExpertiseStatus = async (id: string) => {
    // eslint-disable-next-line no-return-await
    const exp = this.idToExpertises.get(id);
    if (!exp) {
      return;
    }
    exp.isLiked = await this.axios.api.getExpertiseStatus({ id });
  };

  @action
  toggleExpertise = (id: string, state: boolean) => {

    this.axios.api
      .toggleExpertise({ id, confirm: state })
      .then(() => this.onToggleExpertiseSuccess(id, state))
      .catch(this.onToggleExpertiseError);
  };

  @action
  updateLikes = (id: string, status: boolean) => {
    if (!this.idToNews.has(id)) {
      return
    }

    const news = this.idToNews.get(id);
    news.likedByMe = status;
    if (status) {
      news.likes += 1;
    } else {
      news.likes -= 1;
    }
  }

  @action.bound
  protected onToggleExpertiseSuccess = (id: string, state: boolean) => {
    const exp = this.idToExpertises.get(id);
    if (!exp) {
      return;
    }

    if (state) {
      exp.isLiked = true;
      exp.confirmations += 1;
    } else {
      exp.isLiked = false;
      exp.confirmations -= 1;
    }
  };

  @action.bound
  protected onToggleExpertiseError = () => {
    console.warn('onToggleExpertiseError');
  };

  @action.bound
  protected onFetchProfileInformationError = () => {
    console.log('onFetchProfileInformationError');
  };

  interests = [];
  idToExpertises: Map<string, ExpertiseType & { isLiked: boolean }> = new Map();

  @computed({ keepAlive: true, equals: comparer.structural })
  get expertises() {
    return Array.from(this.idToExpertises.values());
  }

  firstName = '';
  secondName = '';
  lastName = '';
  specialization = '';
  avatar = '';
  page = 0;
  size = 10;
  totalPage = 0;

  @computed
  get isPaginational() {
    return this.page + 1 !== this.totalPage;
  }

  fetchAdditional = () => {
    this.page += 1;
    this.isNewsArticleLoading = true;
    this.fetchNews(this.profileId);
  };
  isNewsArticleLoading = false;

  @action
  fetchNews = (id: string) => {
    this.axios.api
      .getNewsList({
        context: '',
        page: this.page,
        size: this.size,
        status: NEWS_PUBLISHED_STATUS,
        sort: 'publicationDate,desc',
        author: id,
      })
      .then(this.onFetchNewsSuccess)
      .catch(this.onFetchNewsError);
  };

  @action.bound
  protected onFetchNewsSuccess = (response: TypeApiResponse<'getNewsList'>) => {
    this.isNewsArticleLoading = false;
    response.content.forEach(item => this.idToNews.set(item.newsId, item));
  };

  @observable
  idToNews: Map<string, NewsModel> = new Map<string, NewsModel>();

  @computed
  get news() {
    return Array.from(this.idToNews.values());
  }

  @action.bound
  protected onFetchNewsError = () => {
    console.log('error');
  };

  @action
  reset = () => {
    this.avatar = '';
    this.idToExpertises.clear();
    this.interests = [];
    this.specialization = '';
    this.lastName = '';
    this.firstName = '';
    this.secondName = '';
    this.idToNews.clear();
  };
}
