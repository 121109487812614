import { action, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';
type WidgetWeatherConfig = {
  size: 'big' | 'small';
  positions: Array<{
    lat: number;
    lon: number;
    isActive: boolean;
    linkId: string;
    aliasName?: string;
    geoInfo?: string;
  }>;
};

@provide.singleton()
export class WidgetWeathersEditStore {
  @lazyInject(Axios2)
  axios: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  name = '';
  id = '';
  config: WidgetWeatherConfig | null = null;

  widgets: Array<any> = [];

  @action
  fetchWidgetInfo = (id: string) => {
    this.clear();
    this.axios.api
      .getWidgetInfo({ id })
      .then(this.onFetchWidgetInfoSuccess)
      .catch(this.onFetchWidgetInfoError);
  };

  @action
  onFetchWidgetInfoSuccess = (info: TypeApiResponse<'getWidgetInfo'>) => {
    this.config = JSON.parse(info.widget_config);
    this.name = info.name;
    this.id = info.id;
    if (!this.config?.positions) {
      return;
    }
    const ids = this.config.positions.map(i => i.linkId);

    const positions = this.config?.positions.map(item => {
      return { lat: item.lat, lon: item.lon };
    });

    this.fetchWeatherByPoints(positions, ids);
  };

  onFetchWidgetInfoError = () => {
    console.log('onFetchWidgetInfoError');
  };

  @action
  fetchWeatherByPoints = (points: any, ids: Array<string>) => {
    this.axios.api
      .getWeatherByPoints({ points })
      .then(response => this.onFetchWeatherByPointsSuccess(response, ids))
      .catch(this.onFetchWeatherByPointsError);
  };

  onFetchWeatherByPointsSuccess = (
    response: TypeApiResponse<'getWeatherByPoints'>,
    ids: Array<string>
  ) => {
    if (!response || !ids) {
      return;
    }

    try {
      this.widgets = response.map((item, index) => {
        return { ...item, linkId: ids[index] };
      });
    } catch (e) {
      console.log(e);
    }
  };

  onFetchWeatherByPointsError = (e: any) => {
    console.log('onFetchWeatherByPointsError', e);
  };

  coordinatsToAdd: { lat: number; lon: number } = { lat: 0, lon: 0 };

  @action
  setCoords = (lat: number, lon: number) => {
    this.coordinatsToAdd = { lon, lat };
  };

  @action
  clearCoords = () => {
    this.coordinatsToAdd = { lon: 0, lat: 0 };
  };

  indexWidget = 0;

  @action
  setSelectedWidgetIndex = (i: number) => {
    this.indexWidget = i;
  };

  @action
  changeGeoPoint = async (index: number) => {
    if (this.coordinatsToAdd.lat === 0 || this.coordinatsToAdd.lon === 0) {
      return;
    }
    if (!this.config) {
      return;
    }
    this.config.positions[index] = {
      ...this.config.positions[index],
      lon: this.coordinatsToAdd.lon,
      lat: this.coordinatsToAdd.lat,
    };

    await this.updateWidget();
  };

  @action
  changeGeoPointName = async (index: number, aliasName: string | undefined) => {
    if (!this.config) {
      return;
    }

    this.config.positions[index] = {
      ...this.config.positions[index],
      aliasName,
    };

    await this.updateWidget();
  };

  get aliasName() {
    if (!this.config) {
      return '';
    }
    return this.config?.positions?.length
      ? this.config?.positions[this.indexWidget]?.aliasName || ''
      : '';
  }

  @action
  addGeoPoint = async () => {
    if (this.coordinatsToAdd.lat === 0 || this.coordinatsToAdd.lon === 0) {
      return;
    }

    if (!this.config?.positions) {
      this.config = {
        positions: [{ ...this.coordinatsToAdd, linkId: uuidv4(), isActive: true }],
        size: 'big',
      };
    } else {
      this.config.positions.push({ ...this.coordinatsToAdd, linkId: uuidv4(), isActive: true });
    }
    await this.updateWidget();
    this.clearCoords();
    this.fetchWidgetInfo(this.id);
  };

  @action
  updateWidget = async () => {
    const { id } = this;
    const { config } = this;
    const { name } = this;

    let response: TypeApiResponse<'updateWidgetInfo'>;
    try {
      response = await this.axios.api.updateWidgetInfo(
        { id, widget_config: JSON.stringify(config), name },
        { omit: ['id'] }
      );
    } catch (e) {
      console.log('onUpdateErrors');
      return;
    }

    this.config = JSON.parse(response.widget_config);
    this.name = response.name;
    this.id = response.id;
    if (!this.config?.positions) {
      return;
    }

    const linkIds = this.config?.positions.map(i => i.linkId);

    const positions = this.config?.positions.map(item => {
      return { lat: item.lat, lon: item.lon };
    });

    this.fetchWeatherByPoints(positions, linkIds);
  };

  @action
  deleteGeoPoint = async (index: number) => {
    if (!this.config) {
      return;
    }

    this.config.positions = this.config.positions.filter((pos, i) => i !== index);

    await this.updateWidget();
    this.fetchWidgetInfo(this.id);
  };

  @action
  toggleGetPoint = async (index: number) => {
    if (!this.config) {
      return;
    }

    this.config.positions = this.config?.positions.map((i, _index) => {
      if (_index === index) {
        return {
          lat: i.lat,
          lon: i.lon,
          linkId: uuidv4(),
          isActive: !i.isActive,
          aliasName: i.aliasName || '',
        };
      }
      return i;
    });
    await this.updateWidget();
  };

  @action
  clear = () => {
    this.widgets = [];
    this.config = null;
  };
}
