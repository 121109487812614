import React, { FC, useEffect, useRef } from 'react';

import { Arrow } from '../../../QuotesEditWidget/style';
import ARROW_UP from '../../../../../assets/icons/arrow-top.svg';
import ARROW_UP_WHITE from '../../../../../assets/icons/arrow-top-white.svg';
import ARROW_DOWN from '../../../../../assets/icons/arrow-bottom.svg';
import ARROW_DOWN_WHITE from '../../../../../assets/icons/arrow-bottom-white.svg';
import { formatPrice } from '../../../../../../modules/shared/utils/formatPrice';

import {
  Wrapper,
  SecondColumn,
  FirstColumn,
  QuoteWrapper,
  Title,
  Stonks,
  Price,
  Descrition,
  StonksDescription,
  CultureIcon,
} from './style';

type QouteProps = {
  id: string;
  title: string;
  price: number;
  qualityMin: number;
  qualityMax: number;
  tendency?: {
    difference: string;
    differenceInPercentage: string;
    direction: string;
  };
  basis: string;
  cultureClass: string;
  isSelected: boolean;
  onSelect: (id: string) => void;
  icon: string;
  region?: string;
  vat: boolean;
  isActive?: boolean;
  dataTestId?: string;
};

export const Qoute: FC<QouteProps> = ({
  price,
  title,
  basis,
  region,
  tendency,
  onSelect,
  id,
  icon,
  isActive,
  cultureClass,
  vat,
  dataTestId,
}) => {
  const quoteRef = useRef<any | null>(null);

  useEffect(() => {
    if (quoteRef && quoteRef.current && isActive) {
      quoteRef.current.scrollIntoView();
    }
  }, []);

  return (
    <Wrapper
      onClick={() => onSelect(id)}
      // @ts-ignore
      isActive={isActive}
      ref={quoteRef}
      data-test-id={dataTestId}
    >
      <QuoteWrapper>
        <CultureIcon src={icon} />
        <FirstColumn>
          <Title>{title}</Title>
          <Descrition>{cultureClass}</Descrition>
          <Descrition>
            {basis} {region}
          </Descrition>
        </FirstColumn>
        <SecondColumn>
          <Price>{formatPrice(price)} ₽</Price>
          {tendency && (
            <Stonks direction={tendency.direction}>
              {tendency?.direction === 'ASC' && (
                <Arrow src={isActive ? ARROW_UP_WHITE : ARROW_UP} />
              )}
              {tendency?.direction === 'DESC' && (
                <Arrow isDown={true} src={isActive ? ARROW_DOWN_WHITE : ARROW_DOWN} />
              )}
              {tendency.difference} ({tendency.differenceInPercentage}%)
            </Stonks>
          )}
          <StonksDescription>/тонн, {vat ? 'с' : 'без'} НДС</StonksDescription>
        </SecondColumn>
      </QuoteWrapper>
    </Wrapper>
  );
};
