import { FC, ReactNode } from 'react';

import { Overflow, ModalWrapper, ModalHeader, ModalTitle, ModalFooter } from './style';

export type SizeType = 'small' | 'large';

type Props = {
  shown: boolean;
  hideModal?: () => void;
  title?: string;
  headerActions?: ReactNode;
  footerActions?: ReactNode;
  size?: SizeType;
};

export const Modal: FC<Props> = ({
  shown = false,
  children,
  hideModal,
  title,
  headerActions,
  footerActions,
  size = 'small',
}) => {
  if (shown) {
    return (
      <>
        <Overflow onClick={hideModal ? hideModal : undefined} />
        <ModalWrapper size={size}>
          {title && (
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              {headerActions && headerActions}
            </ModalHeader>
          )}
          {children}
          {footerActions ? <ModalFooter>{footerActions}</ModalFooter> : ''}
        </ModalWrapper>
      </>
    );
  }

  return null;
};
