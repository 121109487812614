import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { PaperWrapper } from '../../../../modules/shared/components/PaperBlock/style';
import { DirectionType } from '../../../../modules/shared/types/quotes.dto';

export const CultureIcon = styled.img`
  width: 50px;
`;

export const Wrapper = styled.div`
  width: 340px;
  height: 260px;
  display: flex;
  flex-direction: column;
`;

export const QuotesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, 68px);
  grid-gap: 8px;
`;

export const CircleBackground = styled.div`
  height: 80px;
  width: 80px;
  position: absolute;
  left: -30px;
  bottom: -30px;
  background: ${Colors.darkGray};
  opacity: 0.1;
  border-radius: 50%;
  z-index: 1;
`;

type StocksProps = {
  direction: string;
};

export const Stonks = styled.p<StocksProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin: 0;

  color: ${({ direction }) => {
    switch (direction) {
      case 'ASC':
        return Colors.green;

      case 'DESC':
        return Colors.red;

      default:
        return Colors.darkGray;
    }
  }};
`;
export const QuoteWrapper = styled(PaperWrapper)`
  display: flex;
  height: 68px;
  width: 100%;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  padding: 10px 18px 10px 60px;
  box-shadow: none;
  border-radius: 16px;
  cursor: pointer;
  img {
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 2;
  }
`;

export const QuoteColumn = styled.div`
  max-width: 60%;
`;

export const QuoteColumnRight = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const QuoteColumnFlex = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: column;
`;

export const QuoteTitle = styled.div`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const QuoteSubTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  padding-top: 1px;
  color: ${Colors.darkGray};
`;

export const QuoteDate = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.darkGray};
`;

export const QuotePrice = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Arrow = styled.img<{ isDown?: boolean }>`
  height: 8px;
  margin-right: 2px;
  position: relative !important;

  ${({ isDown }) => isDown && 'transform: scale(1, -1)'}
`;

export const QuotePriceFlex = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

export const QuoteDiff = styled.span<{ ascending: DirectionType }>`
  //margin-left: 5px;
  font-size: 11px;
  color: ${({ ascending }) => {
    switch (ascending) {
      case 'ASC':
        return Colors.green;

      case 'DESC':
        return Colors.red;

      default:
        return Colors.darkGray;
    }
  }};
`;
