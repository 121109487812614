import { TypeApiRoute } from '../models/TypeApiRoute';
import { WidgetProp } from '../modules/shared/types/editWidget.dto';
import { TypeResponsePageable } from '../models/TypeResponsePageable';

type TypeRequest = {
  size?: number;
  page?: number;
  name?: string;
};

type TypeResponse = {
  content: Array<WidgetProp>;
} & TypeResponsePageable;

export const getWidgetsList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/widgets/list`,
  // url: (request: TypeRequest) => `/api/widgets/list?page=${request.page}&size=${request.size}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
