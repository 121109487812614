import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  id: string;
};

type TypeResponse = {};

export const deleteNewsComment: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-news/news/comments/${request.id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
