import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Input, Select, Button } from '@uralhim-innovative/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { EditWidgetStore } from '../../../../../../../../stores/editWidget.store';
import { AddWidgetFormStore } from '../../../../../../../../stores/addWidgetForm.store';
import { ModalAction, ModalButtonWrapper, ModalTitle } from '../editPersonalData/style';
import { InputWrapper } from '../editPersonalData/components/ChangeEmailWizard/style';
import SelectCustom from '../../../../../../../shared/components/SelectCustom/SelectCustom';

type Props = {
  close: () => void;
};

export const AddWidgetForm: FC<Props> = observer(props => {
  const store = useStore(EditWidgetStore);
  const formStore = useStore(AddWidgetFormStore);

  const createWidget = async () => {
    await formStore.createWidget();
    props.close();

    await store.loadWidgetsList();
    formStore.clear();
  };

  return (
    <div>
      <ModalTitle>Добавление виджета</ModalTitle>
      <InputWrapper>
        <SelectCustom
          onChange={formStore.setType}
          options={[
            { label: 'Погода', value: 'WEATHER' },
            { label: 'Котировки', value: 'QUOTES' },
          ]}
          defaultValue={{ label: 'Погода', value: 'WEATHER' }}
          label={'Тип виджета'}
          dataTestId={'add-widget-type'}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label="Название"
          onChange={value => formStore.setName(value)}
          dataTestId={'add-widget-title'}
        />
      </InputWrapper>
      <ModalAction>
        <ModalButtonWrapper>
          <Button
            disabled={!formStore.name}
            color="primary"
            type="button"
            onClick={createWidget}
            dataTestId={'add-widget-add'}
          >
            Добавить
          </Button>
        </ModalButtonWrapper>
        <ModalButtonWrapper>
          <Button
            color="default"
            type="button"
            onClick={() => {
              formStore.clear();
              props.close();
            }}
            dataTestId={'add-widget-cancel'}
          >
            Отмена
          </Button>
        </ModalButtonWrapper>
      </ModalAction>
    </div>
  );
});
