import { FC, useEffect, useState } from 'react';

import { TimesOfDayNameMap } from '../../../../../../shared/consts/date';
import { TimeToProfileIllustration } from '../../../../../../../widgets/utils/TimeToProfileIllustration';
import { getTimesOfDay } from '../../../../../../shared/utils/date';

import { Wrapper, GreetingText } from './style';

type Props = {
  name: string;
};

const greetingsForTimeOfDayMap = {
  [TimesOfDayNameMap.night]: 'Доброй ночи',
  [TimesOfDayNameMap.morning]: 'Доброе утро',
  [TimesOfDayNameMap.evening]: 'Добрый вечер',
  [TimesOfDayNameMap.dayTime]: 'Добрый день',
};

export const Greeting: FC<Props> = ({ name }) => {
  const [greetings, setGreetings] = useState('');

  useEffect(() => {
    const timeOfDay = getTimesOfDay();

    setGreetings(greetingsForTimeOfDayMap[timeOfDay]);
  }, []);

  return (
    <Wrapper image={TimeToProfileIllustration()}>
      <GreetingText data-test-id={'profile-greeting'}>
        {greetings}, <br />
        {name}!
      </GreetingText>
    </Wrapper>
  );
};
