import styled from 'styled-components';

import { Colors } from '../../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../../modules/shared/consts/sizes';

export const ChartWrapper = styled.div`
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 352px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Colors.white};
  overflow: hidden;
  padding-bottom: 30px;
`;

export const ChartWrapperHeader = styled.div`
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 32px;
  height: 64px;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const DateSwitchWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

type DateSwitchProps = {
  isActive?: boolean;
};

const renderState = (isActive: boolean) =>
  isActive
    ? `
      color: ${Colors.black}; 
      border-bottom: 2px solid ${Colors.green};
    `
    : `
      color: ${Colors.darkGray};
      border-bottom: 2px solid transparent;
    `;

export const DateSwitch = styled.div<DateSwitchProps>`
  padding-bottom: 16px;
  padding-left: 10.5px;
  padding-right: 10.5px;
  border-bottom: 2px solid ${Colors.green};
  ${({ isActive }) => renderState(Boolean(isActive))};
  cursor: pointer;
`;

export const Tooltip = styled.div`
  padding: 6px 8px;
  border-radius: 16px;
  position: relative;
  background: ${Colors.white};
  div {
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
    background: ${Colors.white};
    position: absolute;

    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
  }
`;
