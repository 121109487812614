import { TypeApiRoute } from '../models/TypeApiRoute';
import { FieldType } from '../modules/shared/types/Field';

type TypeRequest = {
  name: string;
  area: number;
  coordinate: {
    lat: number;
    lon: number;
  };
  address: string;
  cultureId?: string;
  organizationId: string;
};

type TypeResponse = FieldType;

export const createFieldForOrganization: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-field/fields/createForOrganization?organizationId=${request.organizationId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
