import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/consts/color';

export const WidgetWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1068px;

  & .tableRow,
  & .tableHeader {
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconBUtton = styled.div`
  border: 1px solid ${Colors.grayBorder};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 5px;

  &:hover {
    background: ${Colors.grayBorder};
  }
`;
