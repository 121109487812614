import { FC, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Colors } from '../../../../../modules/shared/consts/color';
import { hexToRgba } from '../../../../../modules/shared/utils/hexToRgba';

import { DateSwitch, Title, ChartWrapper, ChartWrapperHeader, DateSwitchWrapper } from './style';

Highcharts.setOptions({
  lang: {
    loading: 'Загрузка...',
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortMonths: [
      'Янв',
      'Фев',
      'Март',
      'Апр',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сент',
      'Окт',
      'Нояб',
      'Дек',
    ],
    rangeSelectorFrom: 'С',
    rangeSelectorTo: 'По',
    rangeSelectorZoom: 'Период',
    downloadPNG: 'Скачать PNG',
    downloadJPEG: 'Скачать JPEG',
    downloadPDF: 'Скачать PDF',
    downloadSVG: 'Скачать SVG',
    printChart: 'Напечатать график',
  },
});

type ChartProps = {
  data: Array<Array<any>>;
  onChangeZoom: (v: Zoom) => void;
};

export type Zoom = 'WEEK' | 'MONTH' | 'YEAR';

export const Chart: FC<ChartProps> = props => {
  const [selectedZoom, setSelectedZoom] = useState<Zoom>('WEEK');

  const max = Math.max(...props.data.map(i => i[1]));
  const min = Math.min(...props.data.map(i => i[1]));

  const minYAxis = min - min * 0.05;

  const options: Highcharts.Options = {
    chart: {
      margin: 0,
      height: 189,
      marginBottom: 30,
      // width: 100,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      // visible: true,
      minPadding: 0,
      maxPadding: 0,
      height: 160,
    },
    yAxis: {
      min: minYAxis < 0 ? 0 : minYAxis,
      max,
      // visible: true,
      // minPadding: 0,
      // maxPadding: 0,
      // plotLines: [
      //   {
      //     dashStyle: 'LongDash', // Style of the plot line. Default to solid
      //     width: 1, // Width of the line
      //     value: max,
      //     color: Colors.grayBorder,
      //     label: 'MAXIMAL',
      //   },
      //   {
      //     dashStyle: 'LongDash', // Style of the plot line. Default to solid
      //     width: 1, // Width of the line
      //     value: min,
      //     label: 'MINIMUM',
      //     color: Colors.grayBorder,
      //   },
      // ],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      borderColor: 'none',
      borderRadius: 16,
      backgroundColor: `${Colors.white}`,
      formatter() {
        return `${this.y}`;
      },
    },
    colors: [Colors.green],
    series: [
      {
        accessibility: {
          enabled: false,
        },
        pointDescriptionFormatter: () => null,
        type: 'areaspline',
        marker: {
          enabled: false,
        },
        showInLegend: false,
        selected: false,
        allowPointSelect: false,
        gapSize: 0,
        states: {
          select: { enabled: false },
        },
        data: props.data,
        tooltip: {
          valueDecimals: 2,
        },
        fillColor: hexToRgba(Colors.green, 0.12),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const changeZoom = (v: Zoom) => {
    setSelectedZoom(v);
    props.onChangeZoom(v);
  };

  return (
    <ChartWrapper>
      <ChartWrapperHeader>
        <Title>График изменения стоимости</Title>
        <DateSwitchWrapper>
          <DateSwitch isActive={selectedZoom === 'WEEK'} onClick={() => changeZoom('WEEK')}>
            Неделя
          </DateSwitch>
          <DateSwitch isActive={selectedZoom === 'MONTH'} onClick={() => changeZoom('MONTH')}>
            Месяц
          </DateSwitch>
          <DateSwitch isActive={selectedZoom === 'YEAR'} onClick={() => changeZoom('YEAR')}>
            Года
          </DateSwitch>
        </DateSwitchWrapper>
      </ChartWrapperHeader>
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    </ChartWrapper>
  );
};
