import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ProfileRoutes } from '../../../main/modules/dashboard/pages/profile/routes';

import { MobileTabsWrapper, MobileTabsItem, MobileTabsInner } from './style';

type Props = {
  items: {
    label: string;
    path: string;
    hiddenItem?: boolean;
  }[];
};

export const MobileTabs: FC<Props> = ({ items }) => {
  const history = useHistory();
  const location = useLocation();

  console.log('items', items);

  const onChangeRoute = (path: string) => {
    if (
      [ProfileRoutes.Dashboard as string, ProfileRoutes.EditPersonalData as string].includes(path)
    ) {
      window.location.replace(
        process.env?.NODE_ENV === 'development'
          ? ProfileRoutes.ExternalAgroidProfileDev
          : `${window._env_.AUTH_URL}${ProfileRoutes.ExternalAgroidProfile}`
      );
    } else {
      history.push(path);
    }
  };

  return (
    <MobileTabsWrapper>
      <MobileTabsInner>
        {items.map(item => (
          <MobileTabsItem
            active={location.pathname === item.path}
            onClick={() => onChangeRoute(item.path)}
            hiddenItem={item.hiddenItem}
          >
            {item.label}
          </MobileTabsItem>
        ))}
      </MobileTabsInner>
    </MobileTabsWrapper>
  );
};
