import { FC, useState, useEffect } from 'react';
import { Icon, Input } from '@uralhim-innovative/farmik-ui';

import { Colors } from '../../consts/color';
import { OrgType } from '../../../../modules/shared/types/organization';

import {
  OrgTypeSelectBody,
  OrgTypeSelectHeader,
  OrgTypeSelectWrapper,
  Wrapper,
  Error,
  Label,
} from './style';

export type OrgNameInputProps = {
  error?: string;
  onChange: (orgType: string, orgTypeId: number) => void;
  label?: string;
  orgTypes: OrgType[];
  defaultOrgTypeId?: number;
  value: string;
  disabled?: boolean;
};

export const OrgNameInput: FC<OrgNameInputProps> = ({
  defaultOrgTypeId,
  error = '',
  onChange,
  label,
  orgTypes,
  value,
  disabled = false,
}) => {
  const [orgTypeId, setOrgTypeId] = useState<number>(defaultOrgTypeId || 0);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  useEffect(() => {
    onChange(value, orgTypeId);
  }, [value, orgTypeId, onChange]);

  const renderItem = (item: OrgType) => (
    <span onClick={() => handleSelectOrgType(item.orgTypeId)} key={item.orgTypeId}>
      {item.OrgType}
    </span>
  );

  const handleSelectOrgType = (code: number) => {
    setOrgTypeId(code);
    setIsSelectOpen(false);
  };

  const handleChange = (v: string) => {
    onChange(v, orgTypeId);
  };

  const currentOrgType = orgTypes.find(item => item.orgTypeId === orgTypeId);

  return (
    <Wrapper disabled={disabled}>
      {label && <Label>{label}</Label>}
      <Input disabled={disabled} value={value} onChange={_value => handleChange(_value)} error="" />
      {orgTypes.length ? (
        <OrgTypeSelectWrapper>
          <OrgTypeSelectHeader
            disabled={disabled}
            onClick={() => !disabled && setIsSelectOpen(!isSelectOpen)}
          >
            <span>{currentOrgType ? currentOrgType.OrgType : ''}</span>
            <Icon size="small" fill={Colors.darkGray} icon="arrow_down" />
          </OrgTypeSelectHeader>
          {isSelectOpen && (
            <OrgTypeSelectBody>{orgTypes.map(item => renderItem(item))}</OrgTypeSelectBody>
          )}
        </OrgTypeSelectWrapper>
      ) : (
        ''
      )}
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};
