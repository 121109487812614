import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto 30px;
`;
