import { FC } from 'react';

import { Letters, Wrapper, Avatar as AvatarStyled } from './style';

type AvatarProps = {
  image: string;
  name: string;
};

export const Avatar: FC<AvatarProps> = ({ image, name }) => {
  const getLetters = () => {
    const words = name.split(' ');
    if (words.length > 1) {
      return `${words[0][0]}${words[1][0]}`.toUpperCase();
    }
    return `${words[0][0]}`.toUpperCase();
  };
  return (
    <Wrapper className="avatarWrapper">
      {image ? <AvatarStyled src={image} /> : <Letters>{getLetters()}</Letters>}
    </Wrapper>
  );
};
