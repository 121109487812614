import styled from 'styled-components';

import { Colors } from '../../consts/color';

export type SelectHeaderStyledProps = {
  isOpen: boolean;
};

const renderIsOpenState = (isOpen: boolean) =>
  isOpen
    ? `
        border: 0.5px solid ${Colors.grayBorder};
        border-bottom: none;
        
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        
    `
    : `
        border: 0.5px solid ${Colors.grayBorder};
    `;

export const SelectHeader = styled.div`
  width: 100%;
  height: 40px;
  background: ${Colors.lightGray};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  outline: none;
  padding-left: 16px;
`;

export const SelectIcon = styled.div`
  width: 12px;
  height: 12px;
`;

export const SelectInput = styled.input`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.black};
  background: none;
  border: none;
  outline: none;

  &::placeholder {
    color: ${Colors.darkGray};
  }
`;

export const SelectBody = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  border: 0.5px solid ${Colors.grayBorder};
  border-top: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  outline: none;
`;

export const SelectList = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #faf9fd;
  z-index: 100;
`;

export const SelectListItem = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  &:first-child {
    border-top: 0.5px solid ${Colors.grayBorder};
  }
`;

const renderIsOpenStateBody = (isOpen: boolean) =>
  isOpen
    ? `
        display: flex;
    `
    : `
        display: none;
    `;
export const Wrapper = styled.div<SelectHeaderStyledProps>`
  width: 100%;
  height: 40px;
  position: relative;
  outline: none;
  user-select: none;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  ${SelectHeader} {
    ${({ isOpen }) => renderIsOpenState(isOpen)}
  }

  ${SelectBody} {
    ${({ isOpen }) => renderIsOpenStateBody(isOpen)}
  }
`;
