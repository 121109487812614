import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Icon, Checkbox } from '@uralhim-innovative/farmik-ui';

import { Colors } from '../../consts/color';

import {
  Wrapper,
  SelectHeader,
  SelectIcon,
  SelectInput,
  SelectBody,
  SelectList,
  SelectListItem,
  Option,
  ItemText,
  SelectBodyInnerWrapper,
  ClearIcon,
} from './style';

export type Option = {
  value: string;
  label: string;
};

export type SelectSearchCustomProps = {
  placeholder: string;
  onSearch: (v: string) => void;
  options: Array<Option>;
  onChange: (v: Option | null) => void;
  isClearAvailable?: boolean;
  dataTestId?: string;
};

export const SelectSearchCustom: FC<SelectSearchCustomProps> = ({
  placeholder,
  options,
  onSearch,
  onChange,
  isClearAvailable = false,
  dataTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Option | null>();
  const timerRef = useRef();

  const handleSelectOption = (item: Option) => {
    setSelectedItem(item);
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(item);
    setIsOpen(false);
  };

  const renderOptions = (_options: Option[]) =>
    options &&
    options.map(item => (
      <Option
        data-test-id={`${dataTestId}-option`}
        onClick={() => handleSelectOption(item)}
        key={`option-${item.value}`}
      >
        <ItemText>{item.label}</ItemText>
      </Option>
    ));

  const optionsWithoutSelected = options.filter(item => item.value !== selectedItem?.value);

  const renderPlaceholder = () => {
    if (selectedItem) {
      return selectedItem.label;
    }
    return placeholder;
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
    setIsOpen(true);
  };

  const onBlurHandler = () => {
    setIsOpen(false);
    console.log('blur state');
  };

  const onClickChildElement = () => {
    clearTimeout(timerRef.current!);
    setIsOpen(true);
    clearTimeout(timerRef.current!);
  };

  const onBlurInput = () => {
    // @ts-ignore
    timerRef.current = setTimeout(() => setIsOpen(false), 200);
  };

  const handleClearSelect = e => {
    e.stopPropagation();
    setIsOpen(false);
    setSelectedItem(null);
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <Wrapper
      isOpen={isOpen && (Boolean(options.length) || Boolean(options.length))}
      data-id-test={dataTestId}
    >
      <SelectHeader
        onClick={() => setIsOpen(!isOpen)}
        onBlur={onBlurInput}
        tabIndex={-1}
        data-test-id={`${dataTestId}-header`}
      >
        <SelectInput
          placeholder={renderPlaceholder()}
          onChange={onChangeInput}
          data-test-id={`${dataTestId}-input`}
          // onFocus={onClickChildElement}
          // onBlur={onBlurInput}
        />
        {selectedItem && isClearAvailable && (
          <ClearIcon
            onClick={handleClearSelect}
            isLabelSet={false}
            data-test-id={`${dataTestId}-clear`}
          >
            <Icon icon={'close'} size={'small'} fill={Colors.darkGray} />
          </ClearIcon>
        )}
        <SelectIcon>
          <Icon icon={'arrow_down'} size={'small'} fill={Colors.darkGray} />
        </SelectIcon>
      </SelectHeader>
      <SelectBody onFocus={onClickChildElement} onBlur={onBlurHandler} tabIndex={-1}>
        {Boolean(options.length) && (
          <SelectList>
            <SelectBodyInnerWrapper>{renderOptions(optionsWithoutSelected)}</SelectBodyInnerWrapper>
          </SelectList>
        )}
      </SelectBody>
    </Wrapper>
  );
};
