import { FC, useContext, useState } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import { RootStoresContext } from '../../../../modules/App';
import { ForecastDayPart } from '../ForecastDayPart/ForecastDayPart';
import SunsetIcon from '../../../assets/icons/sunset.svg';
import SunriseIcon from '../../../assets/icons/sunrise.svg';
import { BreakpointsNumbers } from '../../../../modules/shared/consts/sizes';

import {
  ForecastContent,
  ForecastContentWrapper,
  ForecastHeader,
  ForecastSelectWrapper,
  ForecastTitle,
  ForecastWrapper,
  SunItem,
  SunsetAndSunrise,
  ForecastLeftControl,
  ForecastRightControl,
  ForecastDayPartCarouselWrapper,
  ForecastDayPartCarouselWrapperLine,
  ForecastHeaderWrapper,
} from './style';

export type ForecastType = {
  title: string;
  hours: string;
  temperature: number;
  condition: string;
  feelsLike: string;
  humidity: string;
  pressure: string;
  dew: string | number;
  changeOfRain: string | number;
  windSpeed: string;
  windGust: string;
  windDirection: string;
  precType: string;
};

type ForecastProps = {
  dayParts: Array<ForecastType>;
  daySunrise: string;
  daySunset: string;
};

export const Forecast: FC<ForecastProps> = ({ dayParts, daySunrise, daySunset }) => {
  const { uiStateStore } = useContext(RootStoresContext);
  const { deviceWidth } = uiStateStore;
  const [shift, setShift] = useState(0);

  const getTitleByIndex = (index: number) => {
    return ['Ночь', 'Утро', 'День', 'Вечер'][index];
  };

  const getHoursByIndex = (index: number) => {
    return ['02:00', '08:00', '14:00', '20:00'][index];
  };

  const sliderWrapperWidth =
    deviceWidth < BreakpointsNumbers.small ? deviceWidth : deviceWidth - 289;

  const maxShiftWidthForSlider = (dayParts.length - Math.floor(sliderWrapperWidth / 262)) * 208;

  const rightMoveDisabled = Math.abs(shift) >= maxShiftWidthForSlider;

  const handleMoveRightSlider = () => {
    if (rightMoveDisabled) {
      return;
    }
    setShift(shift - 208);
  };

  const handleMoveLeftSlider = () => {
    if (shift >= 0) {
      return;
    }

    setShift(shift + 208);
  };

  return (
    <ForecastWrapper>
      <ForecastHeaderWrapper>
        <ForecastHeader>
          <ForecastTitle>Прогноз на день</ForecastTitle>

          <SunsetAndSunrise>
            <SunItem>
              <img src={SunriseIcon} alt="" />
              <p>{daySunrise}</p>
            </SunItem>
            <SunItem>
              <img src={SunsetIcon} alt="" />
              <p>{daySunset}</p>
            </SunItem>
          </SunsetAndSunrise>
          {/* <ForecastSelectWrapper>*/}
          {/* <Select*/}
          {/*  onChange={() => 1}*/}
          {/*  options={[*/}
          {/*    { label: 'Неделя', value: 'week' },*/}
          {/*    { label: 'День', value: 'day' },*/}
          {/*    { label: 'Часы', value: 'hours' },*/}
          {/*  ]}*/}
          {/* />*/}
          {/* </ForecastSelectWrapper>*/}
          {/* <ForecastSelectWrapper />*/}
        </ForecastHeader>
      </ForecastHeaderWrapper>

      <ForecastContentWrapper>
        <ForecastContent>
          {deviceWidth < BreakpointsNumbers.large ? (
            <>
              <ForecastLeftControl disabled={shift >= 0} onClick={handleMoveLeftSlider}>
                <Icon icon={'arrow_left'} size={'small'} />
              </ForecastLeftControl>
              <ForecastDayPartCarouselWrapper>
                <ForecastDayPartCarouselWrapperLine shift={shift}>
                  {dayParts.map((part, index) => (
                    <ForecastDayPart
                      windDirection={part.windDirection}
                      pressure={part.pressure}
                      windSpeed={part.windSpeed}
                      windGust={part.windGust}
                      humidity={part.humidity}
                      temperature={part.temperature}
                      condition={part.condition}
                      dew={part.dew}
                      title={getTitleByIndex(index)}
                      changeOfRain={'35'}
                      feelsLike={part.feelsLike}
                      hours={getHoursByIndex(index)}
                      precType={part.precType}
                    />
                  ))}
                </ForecastDayPartCarouselWrapperLine>
              </ForecastDayPartCarouselWrapper>

              <ForecastRightControl disabled={rightMoveDisabled} onClick={handleMoveRightSlider}>
                <Icon icon={'arrow_right'} size={'small'} />
              </ForecastRightControl>
            </>
          ) : (
            <>
              {dayParts.map((part, index) => (
                <ForecastDayPart
                  windDirection={part.windDirection}
                  pressure={part.pressure}
                  windSpeed={part.windSpeed}
                  windGust={part.windGust}
                  humidity={part.humidity}
                  temperature={part.temperature}
                  condition={part.condition}
                  dew={part.dew}
                  title={getTitleByIndex(index)}
                  changeOfRain={'35'}
                  feelsLike={part.feelsLike}
                  hours={getHoursByIndex(index)}
                  precType={part.precType}
                />
              ))}
            </>
          )}
        </ForecastContent>
      </ForecastContentWrapper>
    </ForecastWrapper>
  );
};
