import styled from 'styled-components';

import { Breakpoints } from '../../../../../shared/consts/sizes';
import { Colors } from '../../../../../shared/consts/color';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: ${Breakpoints.extraSmall}) {
    padding-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MenuItem = styled.div`
  width: 100%;
  height: 68px;
  padding-left: 17px;
  align-items: center;
  display: flex;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-bottom: 8px;
`;

export const IconWrapper = styled.div`
  margin-right: 17px;
  width: 15px;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.black};
`;
