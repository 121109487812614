import styled from 'styled-components';

import { Colors } from '../../../../../../modules/shared/consts/color';

type QuoteProps = {
  isActive: boolean;
};

export const Wrapper = styled.div<QuoteProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    ${QuoteWrapper} {
      background: ${Colors.green};
      ${Title} {
        color: ${Colors.white} !important;
      }
      ${Descrition} {
        color: ${Colors.white} !important;
      }
      ${Price} {
        color: ${Colors.white} !important;
      }
      ${Stonks} {
       color: ${Colors.white} !important;
      }
      ${StonksDescription} {
       color: ${Colors.white} !important;
      }
    }
  `}
`;

export const SelectWrapper = styled.div`
  margin-bottom: 5px;
`;

export const QuoteWrapper = styled.div`
  width: 100%;
  height: 68px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 16px;
  display: flex;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export const CultureIcon = styled.img`
  width: 50px;
`;

export const FirstColumn = styled.div`
  margin-left: 68px;
  display: flex;
  flex-direction: column;
`;

export const SecondColumn = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
`;

export const Descrition = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  height: 18px;

  color: ${Colors.darkGray};
  margin: 0;
`;

export const Price = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
`;

type StonksProps = {
  direction: string;
};

export const Stonks = styled.p<StonksProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.red};
  margin: 0;

  color: ${({ direction }) => {
    switch (direction) {
      case 'ASC':
        return Colors.green;

      case 'DESC':
        return Colors.red;

      default:
        return Colors.darkGray;
    }
  }};
`;

export const StonksDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;
