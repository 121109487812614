import React, { FC, useState, useContext } from 'react';
import moment from 'moment';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import {
  QuoteColumn,
  QuoteDate,
  QuoteDiff,
  QuotePrice,
  QuoteSubTitle,
  QuotesWrapper,
  QuoteTitle,
  QuoteWrapper,
} from '../QuotesEditWidget/style';
import image from '../../../../modules/main/modules/cultures/assets/avatars/2.png';
import { QuotesEditWidget } from '../QuotesEditWidget/QuotesEditWidget';
import {
  ArrowButton,
  CarouselWrapper,
  GeoPointsWrapper,
  GeoPointWrapper,
} from '../../../../modules/main/modules/dashboard/pages/profile/pages/EditWidgets/style';
import { Colors } from '../../../../modules/shared/consts/color';
import { useStore } from '../../../../modules/shared/utils/IoC';
import { WidgetQuoteStore } from '../../../stores/WidgetQuoteStore';
import { RootStoresContext } from '../../../../modules/App';

import { BigWidgetWrapper } from './style';

type QuoteWidgetProps = {
  slot: number;
  isBigWidget: boolean;
};
export const QuoteWidget: FC<QuoteWidgetProps> = observer(({ slot, isBigWidget }) => {
  const { uiStateStore } = useContext(RootStoresContext);
  const { isMobileDevice } = uiStateStore;
  const { widgets, id } = useStore(WidgetQuoteStore);
  const [isSliderPlaying, setIsSliderPlaying] = useState(true);
  const quotes = widgets.get(`${slot}`);

  if (!quotes || quotes.length === 0) {
    return null;
  }

  const size = isMobileDevice ? 1 : isBigWidget ? 6 : 3; // размер подмассива
  const subarray = []; // массив в который будет выведен результат.
  for (let i = 0; i < Math.ceil(quotes.length / size); i++) {
    subarray[i] = quotes.slice(i * size, i * size + size);
  }

  const onEnterSlide = () => {
    setIsSliderPlaying(false);
  };

  const onLeaveSlide = () => {
    setIsSliderPlaying(true);
  };

  return (
    <CarouselWrapper>
      <CarouselProvider
        isPlaying={isSliderPlaying}
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={subarray.length}
        dragEnabled={false}
        touchEnabled={!isMobileDevice}
      >
        <Slider onMouseLeave={onLeaveSlide} onMouseEnter={onEnterSlide}>
          {subarray.map((item: any) => {
            return (
              <Slide index={0}>
                {isBigWidget ? (
                  <BigWidgetWrapper>
                    <QuotesEditWidget
                      quotes={[item[0], item[1], item[2]]}
                      id={quotes[0].widgetId}
                    />
                    <QuotesEditWidget
                      quotes={[item[3], item[4], item[5]]}
                      id={quotes[0].widgetId}
                    />
                  </BigWidgetWrapper>
                ) : (
                  <QuotesEditWidget quotes={item} id={quotes[0].widgetId} />
                )}
              </Slide>
            );
          })}
        </Slider>
        <GeoPointsWrapper dark>
          <ButtonBack>
            <ArrowButton dark>
              <Icon size="large" icon="arrow_left" fill={Colors.darkGray} />
            </ArrowButton>
          </ButtonBack>
          <GeoPointWrapper>
            <DotGroup dotNumbers={false} showAsSelectedForCurrentSlideOnly={true} />
          </GeoPointWrapper>
          <ButtonNext>
            <ArrowButton dark>
              <Icon size="large" icon="arrow_right" fill={Colors.darkGray} />
            </ArrowButton>
          </ButtonNext>
        </GeoPointsWrapper>
      </CarouselProvider>
    </CarouselWrapper>
  );
});
