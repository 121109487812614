import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Quote } from '../../../../modules/shared/types/quotes.dto';
import { ProfileRoutes } from '../../../../modules/main/modules/dashboard/pages/profile/routes';
import ARROW_UP from '../../../assets/icons/arrow-top.svg';
import ARROW_DOWN from '../../../assets/icons/arrow-bottom.svg';
import { formatPrice } from '../../../../modules/shared/utils/formatPrice';

import {
  QuotesWrapper,
  QuoteWrapper,
  QuoteColumn,
  QuoteTitle,
  QuoteSubTitle,
  QuoteDate,
  QuotePrice,
  QuoteDiff,
  Stonks,
  CultureIcon,
  Arrow,
} from './style';

type Props = {
  quotes: Quote[];
  id: string;
  dataTestId?: string;
};

export const QuotesEditWidget: FC<Props> = props => {
  const { quotes, id, dataTestId } = props;
  const history = useHistory();

  return (
    <QuotesWrapper data-test-id={dataTestId}>
      {quotes.map(
        (quote, index) =>
          quote && (
            <QuoteWrapper
              data-test-id={`${dataTestId}-item-${index}`}
              key={quote.id + index}
              onClick={() =>
                quote.linkId &&
                history.push(`${ProfileRoutes.FullEditQuoteWidgets}?id=${id}&point=${quote.linkId}`)
              }
            >
              <CultureIcon
                src={quote.culture && quote.culture.icon && quote.culture.icon.downloadUrl}
                alt=""
              />
              <QuoteColumn>
                <QuoteTitle data-test-id={`${dataTestId}-name`}>
                  {quote && quote.culture && quote.culture.name}
                </QuoteTitle>
                <QuoteSubTitle data-test-id={`${dataTestId}-classname`}>
                  {(quote?.cultureClass && quote?.cultureClass?.name) || ''}
                </QuoteSubTitle>
                <QuoteDate data-test-id={`${dataTestId}-basis-reg`}>
                  {quote.basis && quote.basis.name}{' '}
                  {quote.economicRegion && quote.economicRegion.name}
                </QuoteDate>
              </QuoteColumn>
              <QuoteColumn>
                <QuotePrice>
                  <div style={{ display: 'flex' }}>
                    <span data-test-id={`${dataTestId}-price`}>{formatPrice(quote.price)} ₽</span>
                  </div>
                  <Stonks
                    data-test-id={`${dataTestId}-stonks`}
                    direction={(quote.tendency && quote.tendency?.direction) || 'NONE'}
                  >
                    {quote.tendency?.direction === 'ASC' && <Arrow src={ARROW_UP} />}
                    {quote.tendency?.direction === 'DESC' && (
                      <Arrow isDown={true} src={ARROW_DOWN} />
                    )}
                    {quote.tendency && quote.tendency?.diff} (
                    {quote.tendency && quote.tendency?.diffPercent}%)
                  </Stonks>
                  <QuoteSubTitle data-test-id={`${dataTestId}-wat`}>{`/тонн ${
                    quote.vat ? 'с' : 'без'
                  } НДС`}</QuoteSubTitle>
                </QuotePrice>
              </QuoteColumn>
            </QuoteWrapper>
          )
      )}
    </QuotesWrapper>
  );
};
