import styled from 'styled-components';

import { Colors } from '../../../shared/consts/color';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  background: ${Colors.white};
  padding: 40px;
  margin-top: 20px;
  text-align: justify;
`;
