import { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { WelcomeWidget } from '../../components';
import { ProfileStoreContext } from '../../ProfileRouter';
import { EditCompetitions as EditCompetitionsBlock } from '../../components/EditCompetitions/EditCompetitions';
import { RootStoresContext } from '../../../../../../../App';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { UiStore } from '../../../../../../../shared/stores/ui.store';
import { BreakpointsNumbers } from '../../../../../../../shared/consts/sizes';
import { ProfileMenuMobilePageHeaderWrapper } from '../../components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../../../../../shared/components/MobilePageHeader/MobilePageHeader';
import { ProfileRoutes } from '../../routes';

import { Wrapper, WelcomeWrapper } from './style';

export const EditCompetitionsPage: FC = observer(() => {
  const store = useContext(ProfileStoreContext);
  const { user, collapsedWelcomeWidget, toggleWelcomeWidget } = store;

  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent, headerContent } = headerComponentStore;
  const uiStore = useStore(UiStore);
  const history = useHistory();
  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small) {
      setHeaderContent(
        <ProfileMenuMobilePageHeaderWrapper>
          <MobilePageHeader
            title={'Профиль'}
            onBack={() => {
              history.push(ProfileRoutes.Dashboard);
            }}
          />
        </ProfileMenuMobilePageHeaderWrapper>,
        {
          height: '56px',
        }
      );
    }
    // getSpecializations();
    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  return (
    <Wrapper>
      <WelcomeWrapper>
        <WelcomeWidget
          name={user?.firstName}
          collapsed={collapsedWelcomeWidget}
          toggleBanner={toggleWelcomeWidget}
        />
      </WelcomeWrapper>
      <EditCompetitionsBlock />
    </Wrapper>
  );
});
