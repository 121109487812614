import styled from 'styled-components';

import { Colors } from '../../../modules/shared/consts/color';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 265px 856px;
  grid-gap: 32px;
  min-height: 600px;
`;

export const ButtonsWrapper = styled.div`
  & button {
    margin-top: 20px;
    justify-content: center;
  }
`;

export const ActionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EmptyWrapper = styled.div`
  text-align: center;
`;

export const SuggestItem = styled.div`
  padding: 12px 20px;
  border-top: 1px solid ${Colors.grayBorder};
  cursor: pointer;

  &:hover {
    background: ${Colors.lightGray};
  }

  &:last-child {
    border-bottom: 1px solid ${Colors.grayBorder};
  }
`;

export const SuggestItemTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const SuggestItemSubTitle = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-top: 4px;
  color: ${Colors.darkGray};
`;

export const SuggestItemsWrapper = styled.div`
  margin-top: 28px;
`;
