import React, { FC, useState } from 'react';
import { Button, Checkbox, Input } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';

import { Modal } from '../../../shared/components/Modal';
import { useStore } from '../../../shared/utils/IoC';
import {
  ActionsWrapper,
  ImageWrapper,
  InfoTitle,
} from '../../modules/dashboard/pages/profile/pages/organizational/components/ApplicationView/style';
import success from '../../../shared/assets/images/success.png';
import {
  ModalAction,
  ModalButtonWrapper,
  ModalTitle,
} from '../../modules/dashboard/pages/profile/pages/editPersonalData/style';
import { InputWrapper } from '../../modules/dashboard/pages/profile/pages/editPersonalData/components/ChangeEmailWizard/style';
import { WidgetFeedBackFormStore } from '../../../../stores/widgetFeedBackForm.store';
import { MainRoutes } from '../../routes';

type Props = {
  hideForm: () => void;
};

export const WidgetFeedBackForm: FC<Props> = observer(({ hideForm }) => {
  const store = useStore(WidgetFeedBackFormStore);
  const [successModalShowed, setSuccessModalShowed] = useState(false);
  const [shownFormModal, setShownFormModal] = useState(true);

  const disableSend =
    !store.formData.userMessage ||
    (!store.formData.userEmail && !store.userLogined) ||
    !store.formData.userName ||
    (!store.acceptAgreement && !store.userLogined);

  return (
    <>
      <Modal
        shown={successModalShowed}
        hideModal={() => {
          hideForm();
          store.clearFormData();
        }}
      >
        <>
          <ImageWrapper>
            <img src={success} alt="" />
          </ImageWrapper>
          <InfoTitle>Сообщение отправлено</InfoTitle>
          <ActionsWrapper>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                hideForm();
                store.clearFormData();
              }}
            >
              Хорошо
            </Button>
          </ActionsWrapper>
        </>
      </Modal>
      <Modal
        shown={shownFormModal}
        hideModal={() => {
          setShownFormModal(false);
          hideForm();
          store.clearFormData();
        }}
        size="small"
      >
        <div>
          <ModalTitle>Предложить свой виджет</ModalTitle>
          <InputWrapper>
            <Input
              label="Имя пользователя"
              value={store.formData.userName}
              onChange={(value: string) => {
                store.setFormData({
                  ...store.formData,
                  userName: value,
                });
              }}
            />
          </InputWrapper>
          {!store.userLogined && (
            <InputWrapper>
              <Input
                label="email"
                value={store.formData.userEmail}
                onChange={(value: string) => {
                  store.setFormData({
                    ...store.formData,
                    userEmail: value,
                  });
                }}
              />
            </InputWrapper>
          )}
          <InputWrapper>
            <Input
              label="Сообщение"
              value={store.formData.userMessage}
              onChange={(value: string) => {
                store.setFormData({
                  ...store.formData,
                  userMessage: value,
                });
              }}
            />
          </InputWrapper>

          {!store.userLogined && (
            <Checkbox
              label={
                <span>
                  Согласен на обработку{' '}
                  <a target="_blank" href={MainRoutes.privacyPolicy}>
                    персональных данных
                  </a>
                </span>
              }
              onChange={value => {
                store.setAcceptAgreement(value);
              }}
              value={store.acceptAgreement}
              defaultValue={store.acceptAgreement}
            />
          )}

          <ModalAction>
            <ModalButtonWrapper>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '50% 50%',
                  gridGap: '16px',
                }}
              >
                <Button type="button" color="default" onClick={hideForm}>
                  Отмена
                </Button>
                <Button
                  type="button"
                  color="primary"
                  disabled={disableSend}
                  onClick={() => {
                    if (!disableSend) {
                      store.leaveFeedback(Boolean(store.userLogined)).then(() => {
                        setSuccessModalShowed(true);
                        setShownFormModal(false);
                      });
                    }
                  }}
                >
                  Отправить
                </Button>
              </div>
            </ModalButtonWrapper>
          </ModalAction>
        </div>
      </Modal>
    </>
  );
});
