import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {};

type TypeResponse = {
  smsEnabled: boolean;
  emailEnabled: boolean;
  pushEnabled: boolean;
  socketEnabled: boolean;
  blackList: Array<string>;
};

export const getNotificationSettings: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-notification-dispatcher/config`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
