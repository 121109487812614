import React, { FC, useState } from 'react';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { CarouselWrapperSmall } from '../../../../modules/main/modules/dashboard/pages/profile/pages/EditWidgets/style';
import { useStore } from '../../../../modules/shared/utils/IoC';
import { WidgetQuoteStore } from '../../../stores/WidgetQuoteStore';
import {
  Arrow,
  QuoteColumnFlex,
  QuoteDate,
  QuoteDiff,
  QuotePriceFlex,
  QuoteSubTitle,
  QuoteTitle,
  QuoteWrapper,
} from '../QuotesEditWidget/style';
import { ProfileRoutes } from '../../../../modules/main/modules/dashboard/pages/profile/routes';
import { formatPrice } from '../../../../modules/shared/utils/formatPrice';
import ARROW_UP_WHITE from '../../../assets/icons/arrow-top-white.svg';
import ARROW_UP from '../../../assets/icons/arrow-top.svg';
import ARROW_DOWN_WHITE from '../../../assets/icons/arrow-bottom-white.svg';
import ARROW_DOWN from '../../../assets/icons/arrow-bottom.svg';

import { CultureIcon } from './style';

type QuotesSmallWidgetProps = {
  slot: number;
};

export const QuotesSmallWidget: FC<QuotesSmallWidgetProps> = observer(({ slot }) => {
  const { widgets } = useStore(WidgetQuoteStore);
  const [isSliderPlaying, setIsSliderPlaying] = useState(true);
  const quotes = widgets.get(`${slot}`);
  const history = useHistory();
  if (!quotes || quotes.length === 0) {
    return null;
  }

  const onSliderEnterHandler = () => {
    setIsSliderPlaying(false);
  };

  const onSliderLeaveHandler = () => {
    setIsSliderPlaying(true);
  };

  return (
    <CarouselWrapperSmall>
      <CarouselProvider
        isPlaying={isSliderPlaying}
        naturalSlideWidth={340}
        naturalSlideHeight={80}
        totalSlides={quotes.length}
        dragEnabled={false}
      >
        <Slider onMouseEnter={onSliderEnterHandler} onMouseLeave={onSliderLeaveHandler}>
          {quotes.map((item: any) => {
            return (
              <Slide index={0} style={{ width: '340px' }}>
                <div style={{ width: '340px', outline: 'none', userSelect: 'none' }}>
                  <QuoteWrapper
                    key={item.id}
                    onClick={() =>
                      history.push(
                        `${ProfileRoutes.FullEditQuoteWidgets}?id=${item.widgetId}&point=${item.linkId}`
                      )
                    }
                  >
                    <CultureIcon
                      src={item.culture && item.culture.icon && item.culture.icon.downloadUrl}
                      alt=""
                    />
                    <QuoteColumnFlex>
                      <QuoteTitle>
                        {item.culture && item.culture.name.length > 15
                          ? `${item.culture.name.substr(0, 15)}...`
                          : item.culture
                          ? item.culture.name
                          : ''}
                      </QuoteTitle>
                      <QuoteSubTitle>
                        {(item?.cultureClass && item?.cultureClass?.name) || ''}
                      </QuoteSubTitle>
                      <QuoteDate>
                        {item.basis ? item.basis.name : ''}{' '}
                        {item.economicRegion && item.economicRegion.name}
                      </QuoteDate>
                    </QuoteColumnFlex>
                    <QuoteColumnFlex>
                      <QuotePriceFlex>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span>{formatPrice(item.price)} ₽</span>
                          <div>
                            {item.tendency && (
                              <>
                                <QuoteDiff ascending={item.tendency?.direction}>
                                  {item.tendency?.direction === 'ASC' && <Arrow src={ARROW_UP} />}
                                  {item.tendency?.direction === 'DESC' && (
                                    <Arrow isDown={true} src={ARROW_DOWN} />
                                  )}
                                  {item.tendency?.diff} ({item.tendency?.diffPercent}%)
                                </QuoteDiff>
                              </>
                            )}
                          </div>
                        </div>

                        <QuoteSubTitle>{`/тонн ${item.vat ? 'с' : 'без'} НДС`}</QuoteSubTitle>
                      </QuotePriceFlex>
                    </QuoteColumnFlex>
                  </QuoteWrapper>
                </div>
              </Slide>
            );
          })}
        </Slider>
      </CarouselProvider>
    </CarouselWrapperSmall>
  );
});
