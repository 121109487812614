import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Button } from '@uralhim-innovative/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { Chips } from '../../../../../shared/components/Chips';
import serviceLogo from '../../../../../shared/assets/images/service_4.svg';
import categoryLogo from '../../../../../shared/assets/images/service_2.png';
import serviceHeaderImg from '../../../../../shared/assets/images/service_header.png';
import { SubServiceCard } from '../../components/SubServiceCard';
import { ServiceStore } from '../../stores/service.store';

import {
  Wrapper,
  ServiceHeader,
  ServiceLogo,
  CategoriesWrapper,
  Title,
  SubTitle,
  ContentWrapper,
  ContentTitle,
  ContentText,
  ContentButtonWrapper,
  SubServicesWrapper,
} from './style';

export const ServiceInfo: FC = observer(() => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const { getServiceById, serviceErrorMessage, service } = useStore(ServiceStore);

  useEffect(() => {
    getServiceById(serviceId);
  }, [serviceId]);

  if (!service) {
    return <Wrapper>{serviceErrorMessage || ''}</Wrapper>;
  }

  const {
    categories,
    serviceName,
    descriptionShort,
    descriptionLong,
    subServices,
    serviceLink,
    pictureSmall,
    pictureBig,
  } = service;

  return (
    <Wrapper>
      <ServiceHeader
        style={{ backgroundImage: `url(${pictureBig?.downloadUrl || serviceHeaderImg})` }}
      >
        <ServiceLogo src={pictureSmall?.downloadUrl || serviceLogo} />
        <CategoriesWrapper>
          {categories.map(category => (
            <Chips key={category.categoryId} title={category.categoryName} type="transparent" />
          ))}
        </CategoriesWrapper>
        <Title>{serviceName}</Title>
        <SubTitle>{descriptionShort}</SubTitle>
      </ServiceHeader>
      <ContentWrapper>
        <ContentTitle>Описание услуги</ContentTitle>
        <ContentText dangerouslySetInnerHTML={{ __html: descriptionLong }} />
        <ContentButtonWrapper>
          <a href={serviceLink} target="_blank">
            <Button type="button" color="primary" alignment="center">
              Узнать больше
            </Button>
          </a>
        </ContentButtonWrapper>
      </ContentWrapper>
      <SubServicesWrapper>
        {subServices.map(subService => (
          <SubServiceCard
            title={subService.subServiceName}
            imgUrl={categoryLogo}
            description={subService.subServiceContent}
            serviceUrl={subService.subServiceLink}
          />
        ))}
      </SubServicesWrapper>
    </Wrapper>
  );
});
