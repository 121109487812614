import { FC } from 'react';

import { Avatar } from '../Avatar';

import { Tag, TagsWrapper, Title, Wrapper } from './style';

type CardProps = {
  title: string;
  avatar: string;
  tags: Array<string>;
  onClick: () => void;
};

export const Card: FC<CardProps> = ({ avatar, title, tags, onClick }) => (
  <Wrapper onClick={onClick}>
    <Avatar name={title} image={avatar} />
    <Title>{title}</Title>
    <TagsWrapper>{tags && tags.map((v, i) => <Tag key={v}>{v}</Tag>)}</TagsWrapper>
  </Wrapper>
);
