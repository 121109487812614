import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Icon } from '@uralhim-innovative/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { Table } from '../../../../../../../shared/components/Table';
import { Chips } from '../../../../../../../shared/components/Chips';
import { Modal } from '../../../../../../../shared/components/Modal';
import { Colors } from '../../../../../../../shared/consts/color';

import { OrganizationUsersStore } from './stores/organization.users.store';
import { OrgUsersListsControllBar } from './components/OrgUsersListsControllBar';
import { InviteUserForm } from './components/InviteUserForm';
import { EditUserForm } from './components/EditUserForm';
import { WidgetWrapper, IconBUtton, ActionsWrapper } from './style';

export const UserRoleMap = {
  OWNER: 'Владелец',
  EMPLOYEE: 'Сотрудник',
  MANAGER: 'Менеджер',
};

export const OrganizationUsersList: FC = observer(() => {
  // @ts-ignore
  const { organizationId } = useParams();
  const store = useStore(OrganizationUsersStore);
  const {
    getOrganizationUsersList,
    emptyListMessage,
    filters,
    setFilters,
    clearFilters,
    chosenUserId,
    openEditUserForm,
    closeEditUserForm,
    getUserById,
    updateUserRole,
    deleteUser,
    tableUsersList,
  } = store;
  const [showInviteUserForm, setShowInviteUserForm] = useState(false);

  useEffect(() => {
    getOrganizationUsersList(organizationId);

    return () => {
      clearFilters();
    };
  }, []);

  return (
    <WidgetWrapper>
      <OrgUsersListsControllBar
        organizationId={organizationId}
        filters={filters}
        setFilters={setFilters}
        showInviteUser={() => setShowInviteUserForm(true)}
      />
      <Table
        emptyListMessage={emptyListMessage}
        headers={['Статус', 'Имя', 'Роль', 'Дата подключения', '']}
        ids={tableUsersList.map(item => `${item.id}_${item.userName}_${item.organizationJoinDate}`)}
        rows={tableUsersList.map(item => [
          <Chips
            title={item.status === 'ACTIVE' ? 'Активен' : 'Исключен'}
            type={item.status === 'ACTIVE' ? 'success' : 'error'}
          />,
          item.userName,
          UserRoleMap[item.role],
          item.organizationJoinDate,
          <ActionsWrapper>
            {item.status !== 'FIRED' && (
              <IconBUtton>
                <Icon
                  size="small"
                  icon="account_info"
                  fill={Colors.darkGray}
                  onClick={() => {
                    openEditUserForm(item.employeeId);
                    console.log(item);
                    console.log(store.chosenUserId);
                  }}
                />
              </IconBUtton>
            )}
          </ActionsWrapper>,
        ])}
      />
      {showInviteUserForm && (
        <Modal
          shown={showInviteUserForm}
          hideModal={() => {
            setShowInviteUserForm(false);
          }}
        >
          <InviteUserForm
            hideForm={() => {
              setShowInviteUserForm(false);
            }}
            getOrganizationUsersList={getOrganizationUsersList}
            organizationId={organizationId}
          />
        </Modal>
      )}
      {chosenUserId && (
        <Modal shown={Boolean(chosenUserId)} hideModal={() => closeEditUserForm()}>
          <EditUserForm
            chosenUserId={chosenUserId}
            closeEditUserForm={closeEditUserForm}
            getUserById={getUserById}
            updateUserRole={updateUserRole}
            deleteUser={deleteUser}
            getOrganizationUsersList={getOrganizationUsersList}
            organizationId={organizationId}
            tableUsersList={tableUsersList}
          />
        </Modal>
      )}
    </WidgetWrapper>
  );
});
