import { FC, useRef } from 'react';
import { observer } from 'mobx-react';

import { NewsControl, NewsControlProps } from '../NewsControl';
import { TypeNews } from '../../types/type-news';
import { NewsPreview } from '../NewsPreview';
import { useIsWidgetsDisplayed } from '../../../../../shared/hooks/useIsWidgetsDisplayed';
import { useStore } from '../../../../../shared/utils/IoC';
import { WidgetGridStore } from '../../../../../../widgets/stores/WidgetGridStore';

import { Wrapper, ContentWrapper } from './style';

type NewsContainerProps = {
  news: Array<TypeNews>;
  loadAdditional: () => void;
  isPaginational: boolean;
  isPaginationLoading: boolean;
} & NewsControlProps;

export const NewsContainer: FC<NewsContainerProps> = observer(
  ({
    news,
    onChange,
    onSearchInTags,
    onSearchInArticle,
    options,
    loadAdditional,
    isPaginational,
    isPaginationLoading,
    articleSearchQuery,
    selectedTagIds,
    idToTags,
    isTopBarVisible,
  }) => {
    const listReference = useRef(null);
    const store = useStore(WidgetGridStore);

    const handleScroll = () => {
      // @ts-ignore
      const { clientHeight, scrollHeight, scrollTop } = listReference.current;
      // @ts-ignore
      const difference = scrollHeight - clientHeight;
      // @ts-ignore
      if (scrollTop > 50 && news.length > 2) {
        store.setIsTopBarVisible(false);
      } else {
        store.setIsTopBarVisible(true);
      }

      if (isPaginational && scrollTop + 100 >= difference && !isPaginationLoading) {
        loadAdditional();
      }
    };
    return (
      <Wrapper>
        <NewsControl
          onSearchInTags={onSearchInTags}
          onSearchInArticle={onSearchInArticle}
          onChange={onChange}
          options={options}
          isTopBarVisible={isTopBarVisible}
          articleSearchQuery={articleSearchQuery}
          selectedTagIds={selectedTagIds}
          idToTags={idToTags}
        />
        <ContentWrapper ref={listReference}>
          {news.map((item, index) => (
            <NewsPreview
              dataTestId={`news-${index}`}
              data={item}
              key={item.newsId}
              isLiked={item.likedByMe}
            />
          ))}
        </ContentWrapper>
      </Wrapper>
    );
  }
);
