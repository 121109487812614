import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export type BreadcrumbStyleProps = {
  isActive?: boolean;
};

export const Name = styled.p`
  margin: 0 0 0 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.black};
`;

const ActiveState = `
  background: ${Colors.green};
  & p {
  color: ${Colors.white} !important;
  }
  
`;

export const Avatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 60px;
  
  @media(max-height: 420px) {
    display: none;
  }
`;

export const Wrapper = styled.div<BreadcrumbStyleProps>`
  padding: 12px;
  border-radius: 16px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  ${({isActive}) => isActive && ActiveState};
  min-width: 164px;

  @media (max-width: ${Breakpoints.small}) {
    box-shadow: none;
    padding: 12px 12px 12px 16px;
    border-radius: 16px;
    & ${Avatar} {
      display: none;
    }
  }

  @media (max-height: 420px) {
    padding: 3.5px 16px;
    min-width: auto;
    p {
      margin: 0;
    }
  }
`;

export const ArrowWrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  fill: ${Colors.darkGray};
`;

export const BreadcrumbsWrapper = styled.div<{ isPage?: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 16px;
  @media (max-width: ${Breakpoints.small}) {
    overflow-x: scroll;
    background: #fff;

    &::-webkit-scrollbar {
      width: 0;
    }
    margin-top: ${({ isPage }) => (isPage ? '55px' : '10px')};
    /* ie 10+ */
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
  
  @media(max-height: 420px) {
    padding-top: 4px;
    //border-bottom: 1px solid ${Colors.grayBorder};
  }
`;
