import React, { FC } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import IllustrationImage from '../assets/illustration_edit.png';
import SunIcon from '../assets/icons/sun.svg';
import MapIcon from '../assets/icons/map.svg';
import WindIcon from '../assets/icons/wind.svg';
import RainIcon from '../assets/icons/rain.svg';
import HumidityIcon from '../assets/icons/humidity.svg';
import DewIcon from '../assets/icons/dew.svg';
import DeleteIcon from '../assets/icons/delete.svg';
import EditIcon from '../assets/icons/edit.svg';
import { Toggle } from '../../Toggle/Toggle';
import { WeatherEngToRus } from '../../../utils/WeatherEngToRus';
import { WeatherConditionToIllustration } from '../../../utils/WeatherConditionToIllustrationV2';

import {
  ConditionWrapper,
  Content,
  Illustration,
  LeftBar,
  LineWrapper,
  MapWrapper,
  Overlay,
  RightBar,
  Separator,
  Shadow,
  Temperature,
  Wrapper,
  HideOnSmallSpan,
  HideLessThanContentSpan,
  ShowOnSmallSpan,
} from './style';

type WeatherEditWidgetProps = {
  temperature: number;
  condition: string;
  geoPoint: string;
  wind: number;
  rain: number;
  humidity: number;
  dew: number;
  sunrise: string;
  sunset: string;
  isActive: boolean;
  toggleGetPoint: () => void;
  deletePoint: () => void;
  changeGeoPoint: () => void;
  isBottomVisible: boolean;
  onClick: () => void;
  time?: number;
  dataTestId?: string;
};

export const WeatherEditWidget: FC<WeatherEditWidgetProps> = ({
  condition,
  dew,
  geoPoint,
  humidity,
  rain,
  sunrise,
  sunset,
  temperature,
  wind,
  isActive,
  toggleGetPoint,
  deletePoint,
  changeGeoPoint,
  isBottomVisible,
  onClick,
  time,
  dataTestId,
}) => {
  const precType = (id: number) => {
    return ['без осадков', 'дождь', 'дождь со снегом', 'снег', 'град'][Number(id)];
  };

  return (
    <Wrapper isActive={isActive} data-test-id={`${dataTestId}-widget-wrapper`}>
      <Illustration url={WeatherConditionToIllustration(condition, time, 'common')} />
      <Content>
        <LeftBar>
          <Temperature data-test-id={`${dataTestId}-temp`}>{temperature}°</Temperature>
          <ConditionWrapper>
            <img src={SunIcon} />
            <p data-test-id={`${dataTestId}-condition`}>{WeatherEngToRus(condition)}</p>
          </ConditionWrapper>
          <ShowOnSmallSpan>
            <MapWrapper>
              <img src={MapIcon} alt="" />
              <p data-test-id={`${dataTestId}-map`}>{geoPoint}</p>
            </MapWrapper>
          </ShowOnSmallSpan>
        </LeftBar>
        <RightBar onClick={onClick}>
          <HideOnSmallSpan>
            <MapWrapper>
              <img src={MapIcon} alt="" />
              <p data-test-id={`${dataTestId}-widget-geoPoint`}>{geoPoint}</p>
            </MapWrapper>
          </HideOnSmallSpan>
          <LineWrapper>
            <img src={WindIcon} alt="" />
            <p>
              <HideLessThanContentSpan>Ветер </HideLessThanContentSpan>
              <span data-test-id={`${dataTestId}-wind`}>{wind} м/с</span>
            </p>
          </LineWrapper>
          <LineWrapper>
            <img src={RainIcon} alt="" />
            <p>
              <HideLessThanContentSpan>Осадки </HideLessThanContentSpan>
              <span data-test-id={`${dataTestId}-rain`}>{precType(rain)}</span>
            </p>
          </LineWrapper>
          <LineWrapper>
            <img src={HumidityIcon} alt="" />
            <p>
              <HideLessThanContentSpan>Влажность </HideLessThanContentSpan>
              <span data-test-id={`${dataTestId}-humidity`}>{humidity}%</span>
            </p>
          </LineWrapper>
          <LineWrapper hideOnMobile>
            <img src={DewIcon} alt="" />
            <p>
              <HideLessThanContentSpan>Точка росы </HideLessThanContentSpan>
              <span data-test-id={`${dataTestId}-dew`}>{dew}° С</span>
            </p>
          </LineWrapper>
          <Separator hideOnMobile />
          <LineWrapper hideOnMobile>
            <img src={DewIcon} alt="" />
            <p>
              <HideLessThanContentSpan>Восход </HideLessThanContentSpan>
              <span data-test-id={`${dataTestId}-sunrise`}>{sunrise}</span>
            </p>
          </LineWrapper>
          <LineWrapper hideOnMobile>
            <img src={DewIcon} alt="" />
            <p>
              <HideLessThanContentSpan>Закат </HideLessThanContentSpan>
              <span data-test-id={`${dataTestId}-sunset`}>{sunset}</span>
            </p>
          </LineWrapper>
        </RightBar>
      </Content>
      {isBottomVisible && (
        <Overlay>
          <div style={{ marginRight: 'auto' }}>
            <Toggle isActive={isActive} onChange={toggleGetPoint} />
          </div>
          <img src={EditIcon} onClick={changeGeoPoint} />
          <img src={DeleteIcon} onClick={deletePoint} />
        </Overlay>
      )}
    </Wrapper>
  );
};
