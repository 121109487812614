import { TypeApplication } from '../modules/shared/types/applications';
import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';

type TypeRequest = {
  organizationId: string;
};

type TypeResponse = {
  content: Array<TypeApplication>;
} & TypeResponsePageable;

export const getOrganizationApplications: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/organizations/applications/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
