import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { MainRoutes } from '../../routes';

import { Services } from './pages/servicesList';
import { ServiceInfo } from './pages/serviceInfo';
import { CalculatorService } from './pages/calculatorService';

export const ServicesRouter: FC = () => (
  <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
    <Switch>
      <Route path={MainRoutes.services} exact component={Services} />
      {/*<Route path={`${MainRoutes.services}/calculator`} exact component={CalculatorService} />*/}
      <Route path={`${MainRoutes.services}/:serviceId`} exact component={ServiceInfo} />
    </Switch>
  </div>
);
