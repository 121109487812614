import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  applicationId: string;
};

type TypeResponse = {};

export const updateInvitationDecline: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/organizations/user/inviteDecline/${request.applicationId}`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
