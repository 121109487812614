import { FC } from 'react';
import { Button } from '@uralhim-innovative/farmik-ui';

import {
  Wrapper,
  TopBar,
  ButtonWrapper,
  ImageMock,
  LastLine,
  TopBarRight,
  TopBarRightLine,
} from './style';

export const BigSelectWidgets: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Wrapper>
      <TopBar>
        <ImageMock />
        <TopBarRight>
          <TopBarRightLine />
          <TopBarRightLine />
          <TopBarRightLine />
        </TopBarRight>
      </TopBar>
      <LastLine />
      <ButtonWrapper>
        <Button color={'primary'} type={'button'} onClick={onClick}>
          Предложить свой виджет
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
