import { FC } from 'react';

import { AddCardAvatar, AddCardText, AddCardWrapper } from './OrganizationList/style';
import { AvatarAddIcon } from './AvatarAddIcon';

type Props = {
  onClick: () => void;
};

export const AddCard: FC<Props> = ({ onClick }) => (
  <AddCardWrapper onClick={onClick}>
    <AddCardAvatar>
      <AvatarAddIcon />
    </AddCardAvatar>
    <AddCardText>Создать организацию</AddCardText>
  </AddCardWrapper>
);
