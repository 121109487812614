import { createElement, FC, useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react';

import { NewsContainer } from '../../modules/news/components/NewsContainer';
import { useStore } from '../../../shared/utils/IoC';
import { NewsPageStore } from '../../modules/news/stores/NewsPageStore';
import { Option } from '../../../shared/components/MultiSelectWithSearch';
import { SessionStore } from '../../../authorization/stores/session.store';
import { WeatherBigWidget } from '../../../../widgets/components/Weather/WeatherBigWidget/WeatherBigWidget';
import { WeatherSmallWidget } from '../../../../widgets/components/Weather/WeatherSmallWidget/WeatherSmallWidget';
import { WidgetGridStore } from '../../../../widgets/stores/WidgetGridStore';
import { BigSelectWidgets } from '../../../../widgets/components/SelectWidget/BigSelectWidget/BigSelectWidget';
import { SmallSelectWidget } from '../../../../widgets/components/SelectWidget/SmallSelectWidget/SmallSelectWidget';
import { WidgetType } from '../../../../api';
import { WidgetTypes } from '../../../../widgets/models/WidgetTypes';
import { QuoteWidget } from '../../../../widgets/components/Quotes/QuoteWidget/QuoteWidget';
import { QuotesSmallWidget } from '../../../../widgets/components/Quotes/QuotesSmallWidget/QuotesSmallWidget';
import { WidgetFeedBackForm } from '../../components/WidgetFeedBackForm/WidgetFeedBackForm';
import { RootStoresContext } from '../../../App';

import {
  Wrapper,
  RightBar,
  WidgetWrapper,
  BigWidgetWrapper,
  HorizontalWrapper,
  WidgetsRightBar,
  SmallWidgetWrapper,
} from './style';

export const Main: FC = observer(() => {
  const store = useStore(NewsPageStore);
  const sessionStore = useStore(SessionStore);
  const widgetStore = useStore(WidgetGridStore);
  const { uiStateStore } = useContext(RootStoresContext);
  const { isWidthLessThanContent } = uiStateStore;

  const [feedbackFormShown, setFeedbackFormShown] = useState(false);

  const { isTopBarVisible, fetchCheckWidgets, widgets, isSelectMockShown, clear } = widgetStore;

  const {
    news,
    tags,
    selectTags,
    setTagSearchQuery,
    setArticleSearchQuery,
    fetchNews,
    fetchTags,
    isPaginational,
    isPaginationLoading,
    fetchAdditional,
    fetchNewsPinned,
    articleSearchQuery,
    selectedTagIds,
    idToTags,
  } = store;

  useEffect(() => {
    // Data flow
    fetchNews();
    fetchTags();
    fetchNewsPinned();
    fetchCheckWidgets();
    sessionStore.checkIsLoggedIn();

    document.addEventListener('scroll', handleScroll);

    return () => {
      clear();
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.scrollY > 290) {
      widgetStore.setIsTopBarVisible(false);
    } else {
      widgetStore.setIsTopBarVisible(true);
    }
  });

  const renderWidget = (widget: WidgetType, slot: number) => {
    let widgetComponent = null;

    if (widget.widget_config === '') {
      if (slot === 1) {
        return <BigWidgetWrapper />;
      } else {
        return null;
      }
    }

    if (!widget.widget_config) {
      return;
    }

    const config = JSON.parse(widget.widget_config);
    if (widget.type === WidgetTypes.Weather) {
      widgetComponent = createElement(WeatherBigWidget, { slot, config }, null);
    }
    // <QuotesEditWidget quotes={widget} />
    if (widget.type === WidgetTypes.Quotes) {
      widgetComponent = createElement(
        QuoteWidget,
        { slot, isBigWidget: isWidthLessThanContent ? false : slot === 1 },
        null
      );
    }
    const widgetWrapperComponent = createElement(
      (config.size === 'big' || slot === 1) && !isWidthLessThanContent
        ? BigWidgetWrapper
        : SmallWidgetWrapper,
      null,
      widgetComponent
    );
    return widgetWrapperComponent;
  };

  const renderWidgetSmall = (widget: WidgetType, slot: number) => {
    let widgetComponent = null;
    if (!widget) {
      return null;
    }

    const config = widget.widget_config ? JSON.parse(widget.widget_config) : {};

    if (widget.type === WidgetTypes.Weather) {
      widgetComponent = createElement(WeatherSmallWidget, { slot, config }, null);
    }

    if (widget.type === WidgetTypes.Quotes) {
      widgetComponent = createElement(QuotesSmallWidget, { slot }, null);
    }

    return widgetComponent;
  };

  const handleScroll = () => {
    // @ts-ignore
    const { clientHeight, scrollHeight, scrollTop } = document.documentElement;
    // // @ts-ignore
    const difference = scrollHeight - clientHeight;
    // @ts-ignore
    if (window.scrollY > 290) {
      widgetStore.setIsTopBarVisible(false);
    } else {
      widgetStore.setIsTopBarVisible(true);
    }

    if (isPaginational && scrollTop + 100 >= difference && !isPaginationLoading) {
      fetchAdditional();
    }
  };

  return (
    <Wrapper>
      <WidgetWrapper>
        {widgets.map((widget, key) => {
          return renderWidget(widget, key + 1);
        })}
        {isSelectMockShown && <BigSelectWidgets onClick={() => setFeedbackFormShown(true)} />}
      </WidgetWrapper>
      <HorizontalWrapper>
        <NewsContainer
          news={news}
          options={tags}
          onChange={(_tags: Array<Option>) => selectTags(_tags.map(i => i.value))}
          onSearchInArticle={setArticleSearchQuery}
          onSearchInTags={setTagSearchQuery}
          isPaginational={isPaginational}
          isPaginationLoading={isPaginationLoading}
          loadAdditional={fetchAdditional}
          articleSearchQuery={articleSearchQuery}
          selectedTagIds={selectedTagIds}
          idToTags={idToTags}
          isTopBarVisible={isTopBarVisible}
        />
        <RightBar isVisible={isTopBarVisible}>
          <WidgetsRightBar>
            {widgets.map((widget, key) => renderWidgetSmall(widget, key + 1))}
            {isSelectMockShown && <SmallSelectWidget onClick={() => setFeedbackFormShown(true)} />}
          </WidgetsRightBar>
        </RightBar>
      </HorizontalWrapper>

      {feedbackFormShown && <WidgetFeedBackForm hideForm={() => setFeedbackFormShown(false)} />}
    </Wrapper>
  );
});
