import { FC, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ApplicationRoutes } from '../routes';
import { SessionStore } from '../authorization/stores/session.store';
import { useStore } from '../shared/utils/IoC';

import { AdminViewStore } from './AdminViewStore';

export const AdminView: FC = observer(() => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const { checkIsLoggedIn } = useStore(SessionStore);
  const store = useStore(AdminViewStore);

  useEffect(() => {
    const params = new URLSearchParams(search.slice(1));

    if (!params.get('id')) {
      history.push(ApplicationRoutes.Main);
      return;
    }
    store.authAsUser(params.get('id')!, history, params.get('expireAt')!);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search.slice(1));
    checkIsLoggedIn(params.get('authKey'));
  }, [search]);

  return <div>ADMIN VIEW</div>;
});
