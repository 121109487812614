import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { TypePromo, TypeService, TypeCategory } from '../../../../shared/types/service';
import { Axios2 } from '../../../../shared/utils/axios2';

@provide.singleton()
export class ServicesStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  protected idToPromo: Map<string, TypePromo> = new Map();

  get promos() {
    return Array.from(this.idToPromo.values());
  }

  fetchPromo = () => {
    this.axios2.api
      .getServicePromo({})
      .then(response => {
        runInAction(() => {
          response.forEach(promo => {
            this.idToPromo.set(promo.id, promo);
          });
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  protected idToServices: Map<string, TypeService> = new Map();

  get services() {
    return Array.from(this.idToServices.values());
  }

  currentPage = 0;
  totalPages = 0;

  fetchServices = () => {
    this.axios2.api
      .getServices(
        {
          size: 16,
          page: this.currentPage,
          search: this.searchQuery,
          categoryId: this.categoryId,
        },
        { omit: ['size', 'page'] }
      )
      .then(response => {
        runInAction(() => {
          this.currentPage = response.number;
          this.totalPages = response.totalPages;
          this.isPaginationLoading = false;
          if (this.lastOperation === 'load') {
            this.idToServices.clear();
          }
          response.content.forEach(service => this.idToServices.set(service.id, service));
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  get isPaginationalAvailable() {
    return this.currentPage < this.totalPages;
  }

  lastOperation: 'load' | 'additional_load';

  isPaginationLoading = false;

  additionalLoad = () => {
    this.isPaginationLoading = true;
    this.lastOperation = 'additional_load';
    this.currentPage += 1;
    this.fetchServices();
  };

  load = () => {
    this.lastOperation = 'load';
    this.currentPage = 0;
    this.fetchServices();
  };

  protected idToCategory: Map<string, TypeCategory> = new Map();

  get categories() {
    return [
      { categoryId: '', categoryName: 'Не выбрано' },
      ...Array.from(this.idToCategory.values()),
    ];
  }

  fetchCategories = () => {
    this.axios2.api
      .getDictServiceCategories({})
      .then(response => {
        runInAction(() => {
          response.content.forEach(category =>
            this.idToCategory.set(category.categoryId, category)
          );
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  reset = () => {
    this.idToCategory.clear();
    this.idToServices.clear();
    this.idToPromo.clear();
    this.currentPage = 0;
  };

  searchQuery = '';

  setSearchQuery = (v: string) => {
    this.searchQuery = v;

    console.log(v);
    this.idToServices.clear();
    this.currentPage = 0;

    this.fetchServices();
  };

  categoryId = '';

  setCategoryId = (v: string) => {
    this.categoryId = v;

    this.idToServices.clear();
    this.currentPage = 0;
    this.fetchServices();
  };
}
