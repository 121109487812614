import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../../../shared/consts/sizes';

export const SelectWrapper = styled.div`
  width: 164px;
  margin-right: 16px;

  & > div > p {
    opacity: 0;
  }

  @media (max-width: ${Breakpoints.large}) {
    width: 128px;
  }
  @media (max-width: ${Breakpoints.medium}) {
    width: 102px;
  }
  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const ControlBarWrapper = styled.div`
  width: 100%;
  background: ${Colors.white};
  height: 72px;
  //position: absolute;
  top: 0;
  display: flex;
  left: 0;
  padding: 16px 28px 16px 16px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 99;
  align-items: flex-end;
  position: relative;
  margin-bottom: 20px;
  @media (max-width: ${Breakpoints.small}) {
    & > ${SelectWrapper} {
      display: none;
    }
    border-radius: 0;
    flex-direction: column;
    height: auto;
  }
`;

export const BarParametersWrapper = styled.div`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
  background: ${Colors.white};
  & > ${SelectWrapper} {
    display: flex;
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`;
export const SearchInputWrapper = styled.div`
  width: 256px;
  margin-right: 16px;
  margin-top: 20px !important;
  @media (max-width: ${Breakpoints.large}) {
    width: 142px;
  }
  @media (max-width: ${Breakpoints.small}) {
    width: 102px;
    margin-top: 0 !important;
  }
  @media (max-width: ${Breakpoints.small}) {
    width: 263px;
  }
  @media (max-width: ${Breakpoints.extraSmall}) {
    width: 208px;
  }
`;

export const Role = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  background: ${Colors.grayBorder};
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  height: 28px;
  cursor: pointer;
  & p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
    margin-right: 9px;
  }

  & svg {
    fill: ${Colors.darkGray};
  }

  &:hover svg {
    fill: ${Colors.green};
  }
`;

export const RoleWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 16px;

  & ${Role}:last-child {
    margin-right: 0;
  }
`;

export const Parameters = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 20px;
  display: none !important;
  @media (max-width: ${Breakpoints.small}) {
    display: flex !important;
  }
`;
export const LinkWrapper = styled.div<{ isOpen: boolean }>`
  margin-left: auto;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    display: none;
  }

  @media (max-width: ${Breakpoints.medium}) {
    img {
      display: flex;
    }
    & > div {
      display: none;
    }
    right: 19px;
  }

  @media (max-width: ${Breakpoints.small}) {
    ${({ isOpen }) => (isOpen ? `top: 36px` : 'top: 50%')}
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  //align-items: flex-start;
  width: 100%;
  max-height: 72px;
  ${SearchInputWrapper} {
    margin-top: 0;
  }
`;
