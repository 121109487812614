import { WeatherType } from '../widgets/models/WeatherType';
import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  points: Array<{ lat: number; lon: number }>;
};

type TypeResponse = Array<any>;

export const getWeatherByPoints: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-weather/weather/byPoints`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
