import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  ids: Array<string>;
};

export const saveExpertisesToProfile: TypeApiRoute & {
  request: TypeRequest;
  response: any;
} = {
  url: `/api/da-profile/users/myProfile/expertises`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as any,
};
