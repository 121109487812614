import { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@uralhim-innovative/farmik-ui';

import { BreakpointsNumbers } from '../../consts/sizes';
import { ProfileRoutes } from '../../../main/modules/dashboard/pages/profile/routes';
import LOGO from '../../assets/images/logo.svg';
import { Colors } from '../../consts/color';
import { SessionController } from '../../../authorization/controllers/session.controller';
import { useStore } from '../../utils/IoC';

import {
  Wrapper,
  Logo,
  Description,
  ButtonWrapper,
  SubDescription,
  ButtonCustom,
  SupportText,
  FormWrapper,
  BackButton,
} from './style';
const FULL_HD_WIDTH = 1920;

const list = [
  {
    route: ProfileRoutes.Widgets,
    breakpoint: BreakpointsNumbers.medium,
  },
];

export const AdaptiveMock: FC = () => {
  const sessionController = useStore(SessionController);
  const [windowWidth, setWindowWidth] = useState(FULL_HD_WIDTH);
  const [route, setRoute] = useState('');

  const history = useHistory();

  const resizeHandler = () => {
    if (window.innerWidth !== windowWidth) {
      setWindowWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    const handler = _.throttle(resizeHandler, 100);
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handler);
    setRoute(history.location.pathname);
    history.listen(location => {
      // @ts-ignore
      console.log('route', location.pathname);
      setRoute(location.pathname);
    });
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  if (list.filter(i => i.route === route).length === 0) {
    return null;
  }

  if (windowWidth > list.filter(i => i.route === route)[0].breakpoint) {
    return null;
  }

  return (
    <Wrapper>
      <FormWrapper>
        <BackButton
          onClick={() => {
            console.log('back buttom click', history);
            // @ts-ignore
            history.goBack();
          }}
        >
          <Icon size={'small'} icon={'arrow_left'} fill={`${Colors.darkGray}`} />
          Назад
        </BackButton>
        <Logo src={LOGO} />
        <Description>Данная страница недоступна с мобильного устройства</Description>
        <SubDescription>
          Посетите FARMLINK с вашего комьютера или используйте мобильное приложение
        </SubDescription>
        <SupportText>
          Испытываете проблемы с использованием FARMLINK или у вас есть вопрос?
        </SupportText>
        <ButtonWrapper
          href={'https://digitalagro.atlassian.net/servicedesk/customer/portal/11'}
          $margin="0 0 20px 0"
        >
          <ButtonCustom>Служба поддержки</ButtonCustom>
        </ButtonWrapper>
        <ButtonCustom $bgColor={Colors.red} onClick={() => sessionController.handleLogout()}>
          Выход
        </ButtonCustom>
      </FormWrapper>
    </Wrapper>
  );
};
