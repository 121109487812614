import React, { FunctionComponent, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Icon } from '@uralhim-innovative/farmik-ui';
// eslint-disable-next-line import/order
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { QuotesEditWidget } from '../../../../../../../../widgets/components/Quotes/QuotesEditWidget/QuotesEditWidget';
import { WidgetData } from '../../../../../../../shared/types/editWidget.dto';
import { EditWidgetStore } from '../../../../../../../../stores/editWidget.store';
import { Colors } from '../../../../../../../shared/consts/color';
import { divideArray } from '../../../../../../../shared/utils/divideArrays';
import trash from '../../../../../../../shared/assets/images/trash.svg';

import {
  ArrowButton,
  GeoPointsWrapper,
  GeoPointWrapper,
  CarouselWrapper,
  EmptyWidgetWrapper,
  WidgetDeleteButton,
} from './style';

type Props = {
  id: string;
  widgetData: WidgetData;
  onClick: any;
  dataTestId?: string;
};

export const QuotesWidgetGroup: FunctionComponent<Props> = observer(
  ({ id, widgetData, onClick, dataTestId }) => {
    const store = useStore(EditWidgetStore);
    const [isDraggingState, setIsDraggingState] = useState(false);

    const [{ isDragging }, drag] = useDrag({
      item: { id, type: 'WIDGET', widgetData },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0.4 : 1;

    const widgetPositions = store.widgetsGeoData[id];
    const widgetPositionsDivide = widgetPositions ? divideArray(widgetPositions, 3) : [];

    const isActive = Object.values(store.mainPageWidgets).find(item =>
      item ? item.id === id : false
    );

    return (
      <div
        ref={drag}
        onDragStart={() => setIsDraggingState(true)}
        onDragEnd={() => setIsDraggingState(false)}
        style={{
          opacity,
          width: '340px',
          height: '286px',
          overflow: 'hidden',
          filter: `grayscale(${!isActive ? '100%' : '0%'})`,
        }}
        data-test-id={dataTestId}
      >
        <div style={{ position: 'relative', height: '286px' }}>
          <div
            onClick={onClick}
            style={{
              marginBottom: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <span
              style={{
                fontSize: '16px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 220,
              }}
            >
              {widgetData.name}
            </span>
            <span
              style={{
                fontSize: '12px',
                color: Colors.green,
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {widgetPositions && widgetPositions.length
                ? `${widgetPositions.length} котировок`
                : 'Добавить котировку'}{' '}
              <Icon size="small" icon="arrow_right" fill={Colors.green} />
            </span>
          </div>
          {widgetPositions && widgetPositions?.length < 4 ? (
            isDraggingState ? (
              <EmptyWidgetWrapper>
                <span>Котировки</span>
              </EmptyWidgetWrapper>
            ) : (
              <QuotesEditWidget quotes={widgetPositions} />
            )
          ) : (
            ''
          )}
          {widgetPositions && widgetPositions?.length > 3 ? (
            isDraggingState ? (
              <EmptyWidgetWrapper>
                <span>Котировки</span>
              </EmptyWidgetWrapper>
            ) : (
              <CarouselWrapper>
                <CarouselProvider
                  isPlaying
                  naturalSlideWidth={100}
                  naturalSlideHeight={125}
                  totalSlides={widgetPositionsDivide.length}
                  dragEnabled={false}
                >
                  <Slider>
                    {widgetPositionsDivide.map((item: any) => {
                      return (
                        <Slide index={0}>
                          <QuotesEditWidget quotes={item} />
                        </Slide>
                      );
                    })}
                  </Slider>
                  <GeoPointsWrapper dark>
                    <ButtonBack>
                      <ArrowButton dark>
                        <Icon size="large" icon="arrow_left" fill={Colors.darkGray} />
                      </ArrowButton>
                    </ButtonBack>
                    <GeoPointWrapper>
                      <DotGroup dotNumbers={false} showAsSelectedForCurrentSlideOnly={true} />
                    </GeoPointWrapper>
                    <ButtonNext>
                      <ArrowButton dark>
                        <Icon size="large" icon="arrow_right" fill={Colors.darkGray} />
                      </ArrowButton>
                    </ButtonNext>
                  </GeoPointsWrapper>
                </CarouselProvider>
              </CarouselWrapper>
            )
          ) : (
            ''
          )}
          {!widgetPositions || !widgetPositions.length ? (
            <EmptyWidgetWrapper>
              <span>Нет котировок</span>
            </EmptyWidgetWrapper>
          ) : (
            ''
          )}
        </div>
        <WidgetDeleteButton
          onClick={() => {
            store.removeWidget(id);
          }}
        >
          <img src={trash} alt="" />
        </WidgetDeleteButton>
      </div>
    );
  }
);
