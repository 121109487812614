import queryString from 'querystring';

import React, { FC, useEffect, useState } from 'react';
import { Link } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { useStore } from '../../../modules/shared/utils/IoC';
import { WidgetQuotesEditStore } from '../../stores/WidgetQuotesEditStore';
import { ProfileRoutes } from '../../../modules/main/modules/dashboard/pages/profile/routes';
import { QuoteEditWidget } from '../../components/Quotes/QuoteEditWidget/QuoteEditWidget';
import { SmallQuoteChart } from '../../components/Quotes/SmallQuoteChart/SmallQuoteChart';
import DeleteIcon from '../../assets/icons/delete.svg';
import EditIcon from '../../assets/icons/edit.svg';
import { Toggle } from '../../components/Toggle/Toggle';
import { QuoteAddModal } from '../../components/Quotes/QuoteAddModal/QuoteAddModal';

import {
  WidgetWrapper,
  CardsWrapper,
  PageHeader,
  PageTitle,
  PageWrapper,
  BottomSection,
  BottomSectionImg,
} from './style';

export const EditQuotesPage: FC = observer(() => {
  const store = useStore(WidgetQuotesEditStore);
  const { fetchWidgetInfo, name, clear, id, deleteQuote, toggleQuote } = store;
  const history = useHistory();
  const [changingIndex, setChangingIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [selectedQuoteId, setSelectedQuoteId] = useState();
  useEffect(() => {
    const params: any = queryString.parse(location.search.substr(1));

    if (!params.id) {
      history.push(ProfileRoutes.Widgets);
    }

    fetchWidgetInfo(params.id);
  }, []);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  // const dividedWidgets = divideArray(store.widgets, 3);

  const getLink = (index: number) => {
    history.push(`${ProfileRoutes.FullEditQuoteWidgets}?id=${id}&point=${index}`);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <Link
          color={'default'}
          label={'Назад ко всем виджетам'}
          prefixIcon={'arrow_left'}
          onClick={() => history.push('dashboard/profile/widgets')}
          underlined={true}
        />
        <PageTitle>{name}</PageTitle>
        <Link
          color={'primary'}
          label={'Добавить котировку'}
          onClick={() => setIsModalOpen(true)}
          underlined={true}
        />
      </PageHeader>
      <CardsWrapper>
        {store.widgets &&
          store.widgets.map((widget, index) => {
            console.log('widget info', toJS(widget));
            if (!widget) {
              return null;
            }
            return (
              <WidgetWrapper
                key={widget && widget.id + index}
                // @ts-ignore
                isActive={store.config?.positions[index].isActive}
              >
                <QuoteEditWidget quote={widget} onClick={() => getLink(widget.linkId)} />
                <SmallQuoteChart
                  key={`${widget && widget.id}chart`}
                  data={
                    widget && widget.priceHistory
                      ? widget.priceHistory.values.map((price: any) => [
                          new Date(price.date).getTime(),
                          price.price,
                        ])
                      : []
                  }
                />
                <BottomSection>
                  <div style={{ marginRight: 'auto' }}>
                    <Toggle
                      isActive={
                        (store.config?.positions[index] &&
                          store.config?.positions[index].isActive) ||
                        false
                      }
                      onChange={() => toggleQuote(index)}
                    />
                  </div>
                  <BottomSectionImg
                    src={EditIcon}
                    onClick={() => {
                      setChangingIndex(index);
                      setSelectedQuoteId(widget.id);
                      setIsChangeModalOpen(true);
                    }}
                  />
                  <BottomSectionImg src={DeleteIcon} onClick={() => deleteQuote(index)} />
                </BottomSection>
              </WidgetWrapper>
            );
          })}
      </CardsWrapper>
      {id && isModalOpen && (
        <QuoteAddModal
          isShown={isModalOpen}
          widgetId={id}
          onHide={() => setIsModalOpen(false)}
          onUpdate={() => fetchWidgetInfo(id)}
          type={'add'}
        />
      )}
      {id && isChangeModalOpen && (
        <QuoteAddModal
          isShown={isChangeModalOpen}
          widgetId={id}
          selectedQuoteId={selectedQuoteId}
          onHide={() => setIsChangeModalOpen(false)}
          onUpdate={() => fetchWidgetInfo(id)}
          type={'change'}
          quoteIndex={changingIndex}
        />
      )}
    </PageWrapper>
  );
});
