import styled from 'styled-components';

import { Colors } from '../../../shared/consts/color';
import { Breakpoints } from '../../../shared/consts/sizes';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 16px;

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: row-reverse;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: block;
    padding: 0;
  }
`;

export const NewsWrapper = styled.div`
  max-width: 912px;
  width: 100%;
`;

export const BarWrapper = styled.div`
  width: 340px;
  position: fixed;
  left: 50%;
  transform: translateX(302px);

  @media (max-width: ${Breakpoints.large}) {
    position: relative;
    left: 0;
    transform: translateX(0);
    margin-left: 16px;
  }

  @media (max-width: ${Breakpoints.small}) {
    margin-left: 0;
    margin-bottom: 16px;
    width: 100%;
  }
`;

export const RightBar = styled.div`
  width: 100%;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 32px;

  @media (max-width: ${Breakpoints.small}) {
    border-radius: 0;
  }
`;

export const PublicationDate = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    margin-left: 16px;
    color: ${Colors.black};
  }
`;

export const SocialBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 0.5px solid ${Colors.grayBorder};
  align-items: center;
  color: ${Colors.darkGray};
  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  text-align: center;

  button {
    display: inline-block;
  }
`;
export const SocialNetworkLogo = styled.img`
  width: 20px;
  height: 20px;
`;

export const SocialNetworksWrapper = styled.div`
  ${SocialNetworkLogo} {
    margin-right: 12px;
  }

  ${SocialNetworkLogo}:last-child {
    margin-right: 0;
  }
`;
