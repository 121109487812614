import { FC } from 'react';

import { ContentWrapper, PageWrapper } from './style';

export const Agreement: FC = () => (
  <PageWrapper>
    <ContentWrapper>
      <h2>Политика конфиденциальности</h2>
      <p>
        Настоящая Политика конфиденциальности приложения (далее - Политика) действует в отношении
        всей информации, которую разработчик, издатель, владелец мобильного приложения (далее -
        Владелец) может получить о пользователе во время использования им данного приложения.
      </p>
      <p>Мобильное приложение имеет доступ и использует информацию на Вашем устройстве:</p>
      <ul>
        <li>
          история использования устройства и приложений (получение данных о запущенных приложениях);
        </li>
        <li>идентификационные данные пользователя (поиск аккаунтов на устройстве);</li>
        <li>камера (фото);</li>
        <li>информация о местоположении (по координатам сети и спутникам GPS);</li>
      </ul>
      <p>
        Данный доступ к функциям устройства необходим для полноценного функционирования приложения и
        не используется для причинения вреда пользователю.
      </p>
      <p>
        <strong>
          Использование приложения означает безоговорочное согласие пользователя с настоящей
          Политикой и указанными в ней условиями обработки его персональной информации. В случае
          несогласия с этими условиями пользователь должен воздержаться от использования приложения.
        </strong>
      </p>
      <p>1. Персональная информация пользователей, которую получает и обрабатывает приложение.</p>
      <p>
        1.1. В рамках настоящей Политики под «персональной информацией пользователя» понимаются:
      </p>
      <p>
        1.1.1. Персональная информация, которую пользователь предоставляет о себе самостоятельно при
        регистрации (создании учётной записи) или в процессе использования приложения, включая
        персональные данные пользователя. Информация предоставляется пользователем на его
        усмотрение.
      </p>
      <p>
        1.1.2 Данные, которые автоматически передаются разработчику в процессе их использования с
        помощью установленного на устройстве приложения, в том числе информация из cookie,
        информация об устройстве пользователя.
      </p>
      <p>
        1.2. Владелец в общем случае не проверяет достоверность персональной информации,
        предоставляемой пользователями, и не осуществляет контроль за их дееспособностью. Однако
        Владелец исходит из того, что информация, переданная им от пользователей, является
        достоверной и поддерживает эту информацию в актуальном состоянии.
      </p>
      <p>2. Цели сбора и обработки персональной информации пользователей.</p>
      <p>
        2.1. Приложение собирает и хранит только те персональные данные, которые необходимы для
        предоставления сервисов, входящих в состав приложения.
      </p>
      <p>
        2.2. Персональную информацию пользователя приложения может использовать в следующих целях:
      </p>
      <p>2.2.1. Идентификация стороны в рамках соглашений;</p>
      <p>2.2.2. Предоставление пользователю персонализированных сервисов;</p>
      <p>
        2.2.3. Связь с пользователем, в том числе направление уведомлений, запросов и информации,
        касающихся использования сервисов, оказания услуг, а также обработка запросов и заявок от
        пользователя;
      </p>
      <p>
        2.2.4. Улучшение качества приложения, удобства его использования, разработка новых сервисов
        и услуг.
      </p>
      <p>3. Условия обработки персональной информации пользователя и её передачи третьим лицам</p>
      <p>
        3.1. В отношении персональной информации пользователя сохраняется ее конфиденциальность,
        кроме случаев добровольного предоставления пользователем информации о себе для общего
        доступа неограниченному кругу лиц.
      </p>
      <p>
        3.2. Владелец вправе передать персональную информацию пользователя третьим лицам в следующих
        случаях:
      </p>
      <p>3.2.1. Пользователь выразил свое согласие на такие действия;</p>
      <p>
        3.2.2. Передача необходима в рамках использования пользователем определенного сервиса либо
        для оказания услуги пользователю;
      </p>
      <p>
        3.2.3. В соответствии с законом, судебным порядком, в судебном разбирательстве и/или на
        основании публичных запросов или запросов от государственных органов на территории РФ —
        раскрыть вашу персональную информацию. Мы также можем раскрывать информацию о вас, если мы
        определим, что такое раскрытие необходимо или уместно в целях безопасности, поддержания
        правопорядка или иных общественно важных случаях.
      </p>
      <p>
        3.3. При обработке персональных данных пользователей владелец приложения руководствуется
        Федеральным законом РФ «О персональных данных».
      </p>
      <p>
        4. Меры, применяемые для защиты персональной информации пользователей. Владелец принимает
        все необходимые меры для защиты любых персональных данных, предоставляемых пользователями.
        Доступ к персональным данным имеют только уполномоченные сотрудники Владельца,
        уполномоченные сотрудники сторонних компаний (т. е. поставщиков услуг) или наших деловых
        партнеров, подписавшие договор о конфиденциальности и защите персональных данных. Все
        сотрудники Владельца, имеющие доступ к персональным данным, должны придерживаться политики
        по обеспечению конфиденциальности и защиты персональных данных. В целях обеспечения
        конфиденциальности информации и защиты персональных данных Владелец поддерживает
        соответствующую ИТ-среду и принимает все меры, необходимые для предотвращения
        несанкционированного доступа (хакерства).
      </p>
      <p>6. Изменение Политики конфиденциальности. Применимое законодательство</p>
      <p>
        6.1. Владелец приложения имеет право вносить изменения в настоящую Политику
        конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего
        обновления. Новая редакция Политики вступает в силу с момента ее публикации, если иное не
        предусмотрено новой редакцией Политики.
      </p>
      <p>
        6.2. К настоящей Политике и отношениям между пользователем и владельцем приложения,
        возникающим в связи с применением Политики конфиденциальности, применяется право Российской
        Федерации.
      </p>
      <p>
        7. Обратная связь. Вопросы и предложения. Все предложения или вопросы по поводу настоящей
        Политики следует направлять в Службу поддержки пользователей (support@digitalagro.ru).
      </p>
    </ContentWrapper>
  </PageWrapper>
);
