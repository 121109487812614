import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../shared/consts/sizes';
import { PaperWrapper } from '../../../../../../../shared/components/PaperBlock/style';

export const Wrapper = styled(PaperWrapper)`
  width: 100%;
  padding: 32px;

  @media (max-width: ${Breakpoints.medium}) {
    padding: 32px 16px;
    margin-bottom: 60px;
  }
`;

export const PageTitle = styled.div`
  font-size: 16px;
  padding-bottom: 16px;
  margin-bottom: 35px;
  border-bottom: 1px solid ${Colors.grayBorder};

  @media (max-width: ${Breakpoints.small}) {
    margin-bottom: 28px;
  }
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  & button {
    width: 160px;
    margin-left: 20px;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: block;
    margin-top: 0;

    & button {
      width: 100%;
      margin-left: 0;
    }
  }
`;
