import styled from 'styled-components';

export const PersonalDataWidgetWrapper = styled.div`
  grid-area: personalInfo;
`;

export const PersonalDataName = styled.div`
  font-size: 16px;
  margin-bottom: 28px;
`;

export const AvatarWrapper = styled.div`
  margin-bottom: 24px;
`;
