import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../modules/shared/consts/sizes';

export const Wrapper = styled.div`
  border-radius: 16px;
  filter: drop-shadow(0px 13px 31px rgba(0, 0, 0, 0.26));
  position: relative;
  width: 340px;
  height: 82px;
  overflow: hidden;
`;

type IllustratonProps = {
  url: string;
};

export const Illustration = styled.div<IllustratonProps>`
  position: absolute;
  width: 100%;
  height: 82px;
  border-radius: 16px;
  z-index: -2;

  ${({ url }) => `background: url("${url}");`};
  background-size: cover;

  @media (max-width: ${Breakpoints.small}) {
    background-size: contain;
    height: 110px;
  }
`;

export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 82px;
  border: 16px;
  z-index: -1;
  border-radius: 16px;
  overflow: hidden;
  background: linear-gradient(175.36deg, rgba(0, 0, 0, 0) 19.76%, rgba(0, 0, 0, 0.7) 115.92%);
`;

export const Content = styled.div`
  padding: 14px 14px 14px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LeftBar = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 25px;
`;

export const Temperature = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 28px;
  color: ${Colors.white};
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);

  @media (max-width: ${Breakpoints.small}) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const WeatherIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-left: -11px;
`;

export const RightBar = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 232px;
`;
export const Position = styled.div`
  display: flex;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;

    /* general / white */

    color: ${Colors.white};
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  }
  margin-right: 18px;
`;

export const TopBar = styled.div`
  display: flex;

  ${Position}:last-child {
    margin-right: 0;
  }
`;

export const Separator = styled.div<{ hideOnMobile?: boolean }>`
  width: 100%;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  opacity: 0.2;
  margin-top: 4px;
  margin-bottom: 4px;

  @media (max-width: ${Breakpoints.small}) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
  }
`;

export const MapWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${Colors.white};
    text-transform: capitalize;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const HideOnSmallSpan = styled.div`
  display: inline;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;
