import { FC, useEffect, useState } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import { getAvatar } from '../../utils/getAvatar';

import {
  Avatar,
  Name,
  QuestionBody,
  QuestionDescription,
  QuestionName,
  QuestionWrapper,
  Wrapper,
  NameWrapper,
} from './style';

export type CardProps = {
  id: string;
  onClick: (id: string) => void;
  isLastLevel?: boolean;
  avatar?: {
    downloadUrl: string;
    fileContentType: string;
    fileName: string;
    fileSize: 1300000;
    id: string;
  };
  name: string;
  descriptionName: string;
  description: string;
  code: string;
};

export const Card: FC<CardProps> = ({
  name,
  description,
  descriptionName,
  onClick,
  code,
  avatar,
}) => {
  const [image, setImage] = useState<any>();

  useEffect(() => {
    if (!avatar) {
      setImage(getAvatar());
    }
  }, []);

  return (
    <Wrapper onClick={() => onClick(code)}>
      <Avatar src={avatar?.downloadUrl || image} />
      <NameWrapper>
        <Name>{name}</Name>
      </NameWrapper>

      <QuestionWrapper>
        <Icon icon="question" size="medium" />
        <QuestionBody className="faq">
          <QuestionName>{descriptionName}</QuestionName>
          <QuestionDescription>{description}</QuestionDescription>
        </QuestionBody>
      </QuestionWrapper>
    </Wrapper>
  );
};
