export enum Sizes {
  ExtendedFullScreen = '1316px',
  FullScreen = '1284px',
}

export enum BorderRadius {
  default = '16px',
  small = '12px',
  large = '24px',
}

export enum Breakpoints {
  large = '1365px',
  medium = '1023px',
  small = '767px',
  miniSmall = '600px',
  extraMiniSmall = '450px',
  extraSmall = '374px',
}

export enum BreakpointsForMaxWidth {
  large = '1365px',
  medium = '1023px',
  small = '767px',
  extraSmall = '374px',
}

export enum BreakpointsNumbers {
  large = 1365,
  medium = 1023,
  small = 767,
  extraSmall = 374,
}
