import styled from 'styled-components';

import { Colors } from '../../../modules/shared/consts/color';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PageHeader = styled.div`
  width: 100%;
  height: 64px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
`;

export const PageTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  text-align: center;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type WidgetWrapperProps = {
  isActive: boolean;
};

export const WidgetWrapper = styled.div<WidgetWrapperProps>`
  width: 340px;
  height: 260px;
  margin-bottom: 24px;
  margin-right: 24px;
  background: ${Colors.white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  * {
    ${({ isActive }) => !isActive && `filter: grayscale(100%);-webkit-filter: grayscale(100%);`};
  }
`;

export const BottomSection = styled.div`
  width: 100%;
  height: 52px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: ${Colors.gray};
  backdrop-filter: blur(8px);
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

export const BottomSectionImg = styled.img`
  margin-left: 20px;
  width: 16px;
  height: 16px;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 544px;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${WidgetWrapper}:nth-child(3n) {
    margin-right: 0;
  }
`;
