import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Link as LinkFarmik } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';

import { TypeNews } from '../../types/type-news';
import { MainRoutes } from '../../../../routes';
import { Like } from '../Like';
import { useStore } from '../../../../../shared/utils/IoC';
import { NewsPageStore } from '../../stores/NewsPageStore';

import { Comments } from './Comments';
import {
  BackButtonWrapper,
  Content,
  Header,
  HeaderWrapper,
  SocialBar,
  SocialBarControl,
  SocialBarView,
  Tag,
  TagsWrapper,
  Title,
  Wrapper,
} from './style';

type NewsArticleProps = {
  data: TypeNews;
};

export const NewsArticle: FC<NewsArticleProps> = observer(({ data }) => {
  const store = useStore(NewsPageStore);
  const history = useHistory();
  const {
    pictureBig,
    likes,
    comments,
    newsId,
    newsTags,
    newsTitle,
    views,
    newsContent,
    likedByMe,
  } = data;

  useEffect(() => {
    return () => {
      store.resetNewsArticle();
    };
  }, []);

  return (
    <Wrapper data-test-id={'article-wrapper'}>
      <HeaderWrapper>
        <Header url={(pictureBig && pictureBig.downloadUrl) || ''}>
          <BackButtonWrapper>
            <LinkFarmik
              color="default"
              label="Назад"
              onClick={() => history.push(MainRoutes.main)}
              prefixIcon="arrow_left"
            />
            <Title data-test-id={'article-title'}>{newsTitle}</Title>
            <TagsWrapper>
              {newsTags &&
                newsTags.map((item, index) => (
                  <Tag data-test-id={`article-tag-${index}`} key={item.tagId}>
                    {item.tagName}
                  </Tag>
                ))}
            </TagsWrapper>
          </BackButtonWrapper>
        </Header>
      </HeaderWrapper>
      <Content>
        <div
          data-test-id={'article-content'}
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            __html: newsContent!,
          }}
        />
        <SocialBar>
          <Like amount={`${likes}`} id={newsId} isLiked={likedByMe} data-test-id={'article-like'} />
          <SocialBarControl
            onClick={() => history.push(`/news/${newsId}#comments`)}
            data-test-id={'article-comments'}
          >
            <Icon size={'medium'} icon={'comments'} />
            {comments}
          </SocialBarControl>
          <SocialBarView style={{ marginLeft: 'auto' }} data-test-id={'article-view'}>
            <Icon size={'medium'} icon={'eye'} />
            {views}
          </SocialBarView>
        </SocialBar>
      </Content>
      <Comments newsId={newsId} />
    </Wrapper>
  );
});
