import styled from 'styled-components';

import { Colors } from '../../../shared/consts/color';
import { Sizes, BorderRadius, Breakpoints } from '../../../shared/consts/sizes';

export const HeaderWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : '76px')};
  margin-bottom: 16px;
`;

export const Header = styled.div`
  height: 76px;
  max-width: ${Sizes.FullScreen};
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;

  border-radius: 0 0 ${BorderRadius.default} ${BorderRadius.default};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  z-index: 101;

  @media (max-width: ${Breakpoints.medium}) {
    height: auto;
    min-height: 76px;
    border-radius: 0;
  }
  @media (max-width: ${Breakpoints.small}) {
    height: auto;
    min-height: 56px;
  }
`;

export const MobileNavigationMenu = styled.div`
  display: none;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  height: 44px;
  justify-content: space-around;
  z-index: 10;

  @media (max-width: ${Breakpoints.medium}) {
    display: flex;
    position: fixed;
    //position: -webkit-sticky;
    //position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
    padding-top: 10px;
  }
`;

export const MobileNavigationMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  color: ${Colors.darkGray};
  margin: 0 10px;
  cursor: pointer;
  border-radius: ${BorderRadius.default};
  text-decoration: none;

  &:hover {
    background: #eeeeee;
  }

  & span {
    display: inline-block;
    margin-top: 2px;
  }
`;

export const NavigationMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  padding: 25px 80px 25px 25px;
  cursor: pointer;

  @media (max-width: ${Breakpoints.medium}) {
    padding-right: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const Logo = styled.img``;

export const NavigationMenuItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${Colors.black};
  padding: 15px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: ${BorderRadius.default};
  text-decoration: none;

  &:hover {
    background: #eeeeee;
  }

  & span {
    display: inline-block;
    margin-left: 5px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    display: none;
  }
`;

export const UserActionsMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const UserActionsMenuItem = styled.div<{ noPadding?: boolean; hideOnMobile?: boolean }>`
  display: block;
  padding: ${({ noPadding }) => (noPadding ? '0' : '10px')};
  margin: 0 6px 0 ${({ noPadding }) => (noPadding ? '18px' : '6px')};
  cursor: pointer;
  border-radius: ${BorderRadius.default};
  width: 48px;
  height: 48px;
  overflow: hidden;

  &:hover {
    background: #eeeeee;
  }

  img {
    // border-radius: ${BorderRadius.default};
    width: 48px;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
  }
`;
