import React, { FunctionComponent, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Icon } from '@uralhim-innovative/farmik-ui';
// eslint-disable-next-line import/order
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { WeatherEditWidget } from '../../../../../../../../widgets/components/Weather/WeatherEditWidget/WeatherEditWidget';
import {
  WidgetData,
  WidgetMapTypes,
  WidgetTypes,
} from '../../../../../../../shared/types/editWidget.dto';
import { EditWidgetStore } from '../../../../../../../../stores/editWidget.store';
import { Colors } from '../../../../../../../shared/consts/color';
import trash from '../../../../../../../shared/assets/images/trash.svg';
import trashWhite from '../../../../../../../shared/assets/images/trash_white.svg';

import {
  ArrowButton,
  GeoPointsWrapper,
  GeoPointWrapper,
  CarouselWrapper,
  EmptyWidgetWrapper,
  WidgetDeleteButton,
  WeathrWidgetGroupWrapper,
} from './style';

type Props = {
  id: string;
  widgetData: WidgetData;
  onClick: any;
  dataTestId?: string;
};

export const WeathrWidgetGroup: FunctionComponent<Props> = observer(
  ({ id, widgetData, onClick, dataTestId }) => {
    const store = useStore(EditWidgetStore);
    const [isDraggingState, setIsDraggingState] = useState(false);

    const [{ isDragging }, drag] = useDrag({
      item: { id, type: 'WIDGET', widgetData },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0.4 : 1;

    const widgetGeoData = store.widgetsGeoData[id];

    const isActive = Object.values(store.mainPageWidgets).find(item =>
      item ? item.id === id : false
    );

    const config = widgetData.widget_config ? JSON.parse(widgetData.widget_config) : {};

    return (
      <WeathrWidgetGroupWrapper
        ref={drag}
        onDragStart={() => setIsDraggingState(true)}
        onDragEnd={() => setIsDraggingState(false)}
        opacity={opacity}
        isActive={Boolean(isActive)}
        data-test-id={dataTestId}
      >
        <div style={{ position: 'relative', height: '286px' }}>
          <div
            data-test-id={`${dataTestId}-click`}
            onClick={onClick}
            style={{
              marginBottom: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <span
              style={{
                fontSize: '16px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 220,
              }}
            >
              {widgetData.name}
            </span>
            <span
              data-test-id={`${dataTestId}-add-geo`}
              style={{
                fontSize: '12px',
                color: Colors.green,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {widgetGeoData && widgetGeoData.length
                ? `${widgetGeoData.length} геоточек`
                : 'Добавить геоточку'}{' '}
              <Icon size="small" icon="arrow_right" fill={Colors.green} />
            </span>
          </div>
          {widgetGeoData && widgetGeoData?.length === 1 ? (
            isDraggingState ? (
              <EmptyWidgetWrapper>
                <span>
                  {widgetGeoData && widgetGeoData?.length
                    ? WidgetMapTypes[widgetData.type as WidgetTypes]
                    : 'Нет геоточек'}
                </span>
              </EmptyWidgetWrapper>
            ) : (
              <WeatherEditWidget
                condition={widgetGeoData[0].fact?.condition}
                dew={widgetGeoData[0].fact?.dewPoint && widgetGeoData[0].fact?.dewPoint.toFixed(0)}
                geoPoint={
                  config.positions[0]?.aliasName ||
                  (widgetGeoData[0].geoObject.district &&
                    widgetGeoData[0].geoObject.district.name) ||
                  (widgetGeoData[0].geoObject.locality &&
                    widgetGeoData[0].geoObject.locality.name) ||
                  (widgetGeoData[0].geoObject.province &&
                    widgetGeoData[0].geoObject.province.name) ||
                  (widgetGeoData[0].geoObject.country && widgetGeoData[0].geoObject.country.name) ||
                  'Неопределено'
                }
                dataTestId={dataTestId}
                humidity={widgetGeoData[0].fact?.humidity}
                rain={widgetGeoData[0].fact?.precType}
                sunrise={widgetGeoData[0].forecasts[0].sunrise}
                sunset={widgetGeoData[0].forecasts[0].sunset}
                temperature={widgetGeoData[0].fact?.temp}
                wind={widgetGeoData[0].fact?.windSpeed}
                isActive={Boolean(isActive)}
                toggleGetPoint={() => 1}
                deletePoint={() => 1}
                changeGeoPoint={() => 1}
                isBottomVisible={false}
                time={widgetGeoData[0].fact?.obsTime}
                onClick={() => 1}
              />
            )
          ) : (
            ''
          )}
          {widgetGeoData && widgetGeoData?.length > 1 && !isDraggingState ? (
            <CarouselWrapper>
              <CarouselProvider
                isPlaying
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={widgetGeoData.length}
                dragEnabled={false}
              >
                <Slider>
                  {widgetGeoData.map((item: any, index: number) => {
                    return (
                      <Slide index={0} key={item.id}>
                        <WeatherEditWidget
                          condition={item.fact?.condition}
                          dew={item.fact?.dewPoint && item.fact?.dewPoint.toFixed(0)}
                          geoPoint={
                            config.positions[index]?.aliasName ||
                            (item.geoObject.district && item.geoObject.district.name) ||
                            (item.geoObject.locality && item.geoObject.locality.name) ||
                            (item.geoObject.province && item.geoObject.province.name) ||
                            (item.geoObject.country && item.geoObject.country.name) ||
                            'Неопределено'
                          }
                          humidity={item.fact?.humidity}
                          rain={item.fact?.precType}
                          sunrise={item.forecasts[0].sunrise}
                          sunset={item.forecasts[0].sunset}
                          temperature={item.fact?.temp}
                          wind={item.fact?.windSpeed}
                          isActive={true}
                          toggleGetPoint={() => 1}
                          deletePoint={() => 1}
                          changeGeoPoint={() => 1}
                          isBottomVisible={false}
                          time={item.fact?.obsTime}
                          dataTestId={dataTestId}
                          onClick={() => 1}
                        />
                      </Slide>
                    );
                  })}
                </Slider>
                <GeoPointsWrapper>
                  <ButtonBack>
                    <ArrowButton>
                      <Icon size="large" icon="arrow_left" fill="#fff" />
                    </ArrowButton>
                  </ButtonBack>
                  <GeoPointWrapper>
                    <DotGroup dotNumbers={false} showAsSelectedForCurrentSlideOnly={true} />
                  </GeoPointWrapper>
                  <ButtonNext>
                    <ArrowButton>
                      <Icon size="large" icon="arrow_right" fill="#fff" />
                    </ArrowButton>
                  </ButtonNext>
                </GeoPointsWrapper>
              </CarouselProvider>
            </CarouselWrapper>
          ) : (
            <EmptyWidgetWrapper>
              <span>{widgetGeoData && widgetGeoData?.length ? 'Погода' : 'Нет геоточек'}</span>
            </EmptyWidgetWrapper>
          )}
        </div>
        <WidgetDeleteButton
          onClick={() => {
            store.removeWidget(id);
          }}
        >
          <img
            src={
              widgetGeoData && widgetGeoData?.length > 1 && !isDraggingState ? trashWhite : trash
            }
            alt=""
          />
        </WidgetDeleteButton>
      </WeathrWidgetGroupWrapper>
    );
  }
);
