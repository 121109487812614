import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import { SpinnerLoader, SpinnerLoaderWrapper } from '../../../shared/styles/dotsLoader';

import { MainPreloadController } from './MainPreload.controller';

type Props = {
  hideAuthPreload: () => void;
};

export const MainPreloader: FunctionComponent<Props> = observer(({ hideAuthPreload }) => {
  const {
    location: { search },
  } = useHistory();

  const searchParam = new URLSearchParams(search.slice(1));
  const authKey = searchParam.get('authKey');

  const { checkAuthKey, checkIsUserLoginned } = useStore(MainPreloadController);

  useEffect(() => {
    (async () => {
      if (authKey) {
        try {
          await checkAuthKey(authKey);
        } catch (e) {
          throw e;
        } finally {
          hideAuthPreload();
        }
      } else {
        try {
          await checkIsUserLoginned();
        } catch (e) {
          throw e;
        } finally {
          hideAuthPreload();
        }
      }
    })();
  }, []);

  return (
    <SpinnerLoaderWrapper>
      <SpinnerLoader data-test-id="dots-loader">
        <span />
      </SpinnerLoader>
    </SpinnerLoaderWrapper>
  );
});
