export enum MainRoutes {
  main = '/',
  news = '/news/',
  dashboard = '/dashboard',
  agreement = '/agreement',
  cultures = '/cultures',
  services = '/services',
  calculator = '/services/calculator',
  profile = '/profile/',
  notifications = '/notifications/',
  privacyPolicy = '/privacyPolicy',
}
