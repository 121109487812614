import { action, computed, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../shared/utils/axios2';
import { NotificationType } from '../models/notification-type';
import { SocketNotificationResponseDto } from '../models/socketNotificationResponse.dto';

import { NotificationPageStore } from './NotificationPageStore';
import { SocketWrapper } from './SocketWrapper';

@provide.singleton()
export class NotificationStore {
  @lazyInject(Axios2)
  axios: Axios2;

  @lazyInject(SocketWrapper)
  socket: SocketWrapper;

  allNotificationsStore = new NotificationPageStore('', '');
  commentsNotificationStore = new NotificationPageStore('COMMENT.COMMENT_ANSWERED', '');
  applicationsNotificationStore = new NotificationPageStore('APPLICATION', '');

  constructor() {
    makeAutoObservable(this);
    this.subscribeToTopics();
  }

  @action
  bootstrapAllStore = () => {
    this.allNotificationsStore.initiate();
  };

  @action
  bootstrapCommentsStore = () => {
    this.commentsNotificationStore.initiate();
  };

  @action
  bootstrapApplicationsStore = () => {
    this.applicationsNotificationStore.initiate();
  };

  @action
  addNotification = (body: NotificationType) => {
    this.idToNewNotifications.set(body.id, {
      senderAvatar: body.senderAvatar,
      id: body.id,
      contentHtml: body.contentHtml,
      senderName: body.senderName,
      statusRead: body.statusRead,
      notificationType: body.notificationType,
      creationTime: body.creationTime,
    });
  };

  idToNewNotifications: Map<string, NotificationType> = new Map();

  @action
  hideBanner = () => {
    console.log('hideBanner');
  };

  @computed
  get notifications() {
    return Array.from(this.idToNewNotifications.values());
  }

  @action
  fetchSettings = async () => {
    let response: TypeApiResponse<'getNotificationSettings'>;

    try {
      response = await this.axios.api.getNotificationSettings({});
    } catch (e) {
      console.warn('fetch notification settings');
      return;
    }

    this.showNotificationSetting = response.socketEnabled;
    this.showViaEmailSetting = response.emailEnabled;
    if (response.blackList) {
      this.showAnswersSetting = !response.blackList.includes('COMMENT.COMMENT_ANSWERED');
    } else {
      this.showAnswersSetting = true;
    }

    this.showWithSoundSetting = true;
  };

  showNotificationSetting = false;
  showWithSoundSetting = false;
  showAnswersSetting = false;
  showViaEmailSetting = false;

  @action
  sendConfig = async () => {
    try {
      await this.axios.api.sendNotificationSettings({
        socketEnabled: this.showNotificationSetting,
        blackList: this.showAnswersSetting ? [] : ['COMMENT.COMMENT_ANSWERED'],
        emailEnabled: this.showViaEmailSetting,
        pushEnabled: false,
        smsEnabled: false,
      });
    } catch (e) {
      console.warn('send settings config error');
    }
  };

  @action
  toggleNotificationSetting = () => {
    this.showNotificationSetting = !this.showNotificationSetting;
    this.sendConfig();
  };

  @action
  toggleWithSoundSetting = () => {
    this.showWithSoundSetting = !this.showWithSoundSetting;
    this.sendConfig();
  };

  @action
  toggleAnswersSettings = () => {
    this.showAnswersSetting = !this.showAnswersSetting;
    this.sendConfig();
  };

  @action
  toggleViaEmail = () => {
    this.showViaEmailSetting = !this.showViaEmailSetting;
    this.sendConfig();
  };

  @action
  readNotification = (id: string) => {
    try {
      this.axios.api.readNotification({ id });
    } catch (e) {
      console.warn('readNotification');
      return;
    }

    const message = this.idToUnreadNotification.get(id);
    if (!message) {
      return;
    }
    message.statusRead = true;
  };

  @action
  readPanel = () => {
    this.lastUnreadMessages.forEach(i => {
      this.readNotification(i.id);
    });
  };
  @action
  fetchAllUnread = async () => {
    let response: TypeApiResponse<'getNotificationList'>;
    try {
      response = await this.axios.api.getNotificationList({ size: 4, page: 0, statusRead: false });
    } catch (e) {
      console.warn('fetchAllUnread error', e);
      return;
    }

    if (!response) {
      return;
    }

    if (response.content.length) {
      this.idToUnreadNotification = new Map();

      response.content.forEach(item => {
        this.idToUnreadNotification.set(item.id, {
          contentHtml: item.contentHtml,
          id: item.id,
          notificationType: item.notificationType,
          senderAvatar: item.senderAvatar,
          senderName: item.senderName,
          statusRead: item.statusRead,
          creationTime: item.creationTime,
        });
      });
    } else {
      this.idToUnreadNotification = new Map();
    }
  };

  idToUnreadNotification: Map<string, NotificationType> = new Map();

  @computed
  get lastUnreadMessages() {
    const defaultArray = Array.from(this.idToUnreadNotification.values());
    return defaultArray.slice(0, 4);
  }

  @action
  subscribeToTopics = async () => {
    let userResponse: TypeApiResponse<'getProfile'>;
    try {
      userResponse = await this.axios.api.getProfile({});
    } catch (e) {
      console.warn('getProfile error: ', e);
      return;
    }

    let createTopicResponse: TypeApiResponse<'createTopic'> = '';

    try {
      createTopicResponse = await this.axios.api.createTopic({ userId: userResponse.id });
    } catch (e) {
      console.warn('createTopic error: ', e);
      return;
    }

    await this.socket.initiate();

    this.socket.subscribeToTopic<SocketNotificationResponseDto>(createTopicResponse, response => {
      this.addNotification({
        statusRead: false,
        senderAvatar: response.senderAvatar,
        contentHtml: response.contentHtml,
        senderName: response.senderName,
        notificationType: '',
        id: response.messageId,
        creationTime: response.creationTime,
      });
    });
  };

  @action
  deleteMessage = (id: string) => {
    this.idToNewNotifications.delete(id);
  };
  @computed
  get isUnreadNotifications() {
    if (!this.allNotificationsStore) {
      return false;
    }

    return this.lastUnreadMessages.filter(i => !i.statusRead).length > 0;
  }
}
