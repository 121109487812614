import { FC, useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { ProfileMenuMobilePageHeaderWrapper } from '../../components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../../../../../shared/components/MobilePageHeader/MobilePageHeader';
import { MobileTabs } from '../../../../../../../shared/components/MobileTabs/MobileTabs';
import { RootStoresContext } from '../../../../../../../App';
import { DashboardRoutes } from '../../../../dashboard.routes';
import { ApplicationRoutes } from '../../../../../../../routes';
import { UiStore } from '../../../../../../../shared/stores/ui.store';
import { BreakpointsNumbers } from '../../../../../../../shared/consts/sizes';
import { ProfileRoutes } from '../../routes';

import { GuestProfileStore } from './stores/GuestProfileStore';
import { NewsWrapper } from './components/NewsWrapper/NewsWrapper';
import { PersonBar } from './components/PersonBar/PersonBar';
import { PageWrapper } from './style';
import {SessionStore} from "../../../../../../../authorization/stores/session.store";

export const GuestProfile: FC = observer(() => {
  const store = useStore(GuestProfileStore);
  const history = useHistory();
  const {
    fetchStartData,
    news,
    fetchAdditional,
    isPaginational,
    isNewsArticleLoading,
    specialization,
    firstName,
    lastName,
    interests,
    expertises,
    avatar,
    reset,
    toggleExpertise,
  } = store;

  useEffect(() => {
    fetchStartData(
      history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)
    );
    sessionStore.checkIsLoggedIn();
    return () => {
      reset();
    };
  }, []);

  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent, headerContent } = headerComponentStore;
  const uiStore = useStore(UiStore);
  const sessionStore = useStore(SessionStore);

  useEffect(() => {
    console.log('changed');
  }, [news])
  console.log(news);
  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small) {
      setHeaderContent(
        <ProfileMenuMobilePageHeaderWrapper>
          <MobilePageHeader
            title={'Профиль пользователя'}
            onBack={() => {
              history.push(ApplicationRoutes.Main);
            }}
          />
        </ProfileMenuMobilePageHeaderWrapper>,
        {
          height: '56px',
        }
      );
    }

    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  return (
    <PageWrapper>
      <PersonBar
        avatar={avatar}
        firstName={firstName}
        lastName={lastName}
        specialization={specialization}
        competitions={expertises}
        interests={interests}
        onToggle={toggleExpertise}
      />
      <NewsWrapper
        news={news}
        isPaginational={isPaginational}
        isPaginationLoading={isNewsArticleLoading}
        loadAdditional={fetchAdditional}
      />
    </PageWrapper>
  );
});
