import styled from 'styled-components';

import { Breakpoints } from '../../../../../shared/consts/sizes';

export const ProfileGrid = styled.div`
  display: flex;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const ProfileInnerGrid = styled.div`
  display: grid;
  grid-template-areas:
    'welcome welcome welcome'
    'personalInfo competention interes';
  grid-template-columns: 36% 1fr 1fr;
  grid-gap: 20px;
  width: 100%;

  @media (max-width: ${Breakpoints.medium}) {
    display: flex;
    flex-direction: column;
  }
`;
