import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  widgetId: string;
};

type TypeResponse = {};

export const removeWidget: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/widgets/${request.widgetId}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
