import { HeaderComponentStore } from './headerComponent.store';
import { UiStateStore } from './uiState.store';

export class RootStore {
  headerComponentStore: HeaderComponentStore;
  uiStateStore: UiStateStore;

  constructor() {
    this.headerComponentStore = new HeaderComponentStore(this);
    this.uiStateStore = new UiStateStore(this);
  }
}
