import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../../../shared/consts/sizes';

export const ModalTitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  padding-bottom: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${Colors.grayBorder};
  position: relative;

  & > span {
    position: absolute;
    top: 1px;
    right: 0;
    display: flex;
    color: ${Colors.red};
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    cursor: pointer;
  }

  & > span > div {
    margin-left: 5px;
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 12px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 28px;

  & button:nth-child(2) {
    margin-left: 16px;
  }

  & button {
    justify-content: center;
  }
  @media (max-width: ${Breakpoints.small}) {
    flex-direction: column;
    & button:nth-child(2) {
      margin-left: 0;
      margin-top: 16px;
    }
  }
  @media (max-width: ${Breakpoints.extraSmall}) {
  }
`;

export const ErrorLabel = styled.div`
  color: ${Colors.red};
  font-size: 12px;
  margin-bottom: 24px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 18px;
`;

export const InfoTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`;
