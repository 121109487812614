import { TypeApiRoute, TypeResponsePageable } from '../models';

type TypeRequest = {
  size: number;
  page: number;
};

type TypeResponse = { content: Array<{ id: string; name: string }> } & TypeResponsePageable;

export const getBasisList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-quotes/refs/basis/list?size=${request.size}&page=${request.page}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
