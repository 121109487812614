import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { provide } from '../utils/IoC';

@provide.singleton()
export class UiStore {
  constructor() {
    makeAutoObservable(this);
    this.resizeHandler();
    window.addEventListener('resize', _.throttle(this.resizeHandler, 100));
  }

  windowWidth = 1920;

  resizeHandler = () => {
    this.windowWidth = window.innerWidth;
  };
}
