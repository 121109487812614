import styled from 'styled-components';

import { BorderRadius } from '../../../../../shared/consts/sizes';
import { Colors } from '../../../../../shared/consts/color';

export const Wrapper = styled.div`
  padding: 16px 20px;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  border-radius: ${BorderRadius.large};
  background: ${Colors.lightGray};
  margin: 20px auto 12px;
  width: 72px;
  height: 72px;

  & img {
    border-radius: ${BorderRadius.large};
    width: 72px;
    height: 72px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CategoriesWrapper = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
