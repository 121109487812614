import styled from 'styled-components';

import { Colors } from '../../../modules/shared/consts/color';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1068px;
`;

export const PageHeader = styled.div`
  width: 100%;
  height: 64px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
`;

export const PageTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  text-align: center;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WidgetWrapper = styled.div`
  width: 340px;
  height: 260px;
  margin-bottom: 24px;
  margin-right: 24px;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 544px;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${WidgetWrapper}:nth-child(3n) {
    margin-right: 0;
  }
`;
