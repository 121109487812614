import { FC } from 'react';
import moment from 'moment';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import { UserInfo } from '../UserInfo';
import { Chips } from '../Chips';
import { TypeNews } from '../../types/type-news';
import { Like } from '../Like';
import { MainRoutes } from '../../../../routes';

import {
  Wrapper,
  SocialBar,
  SocialBarControl,
  ContentWrapper,
  ContentArea,
  Date,
  Description,
  ReadMore,
  Title,
  SocialBarView,
  NewsImageWrapper,
  MobileNewsImageWrapper,
  HideOnSmall,
} from './style';
import {observer} from "mobx-react";

type NewsPreviewProps = {
  data: TypeNews;
  isLiked: boolean;
  dataTestId?: string;
};

const DESCRIPTION_MAX_LENGTH = 150;

export const NewsPreview: FC<NewsPreviewProps> = observer(({ data, dataTestId }) => {
  const history = useHistory();
  const goToNewsArticle = () => history.push(`/news/${data.newsId}`);
  return (
    <Wrapper data-test-id={dataTestId}>
      <ContentWrapper>
        {data.pictureSmall?.downloadUrl && (
          <NewsImageWrapper
            url={data.pictureSmall?.downloadUrl}
            data-test-id={`${dataTestId}-image`}
            onClick={goToNewsArticle}
          />
        )}
        <ContentArea isExtended={!data.pictureSmall}>
          <UserInfo
            userName={`${data.author?.firstName} ${data.author?.lastName}`}
            userRole={data.author?.specialization && data.author?.specialization.name}
            avatar={data.author?.avatar && data.author?.avatar.downloadUrl}
            onClick={() => history.push(`${MainRoutes.profile}${data.author.publicId}`)}
          />
          {data.pictureSmall?.downloadUrl && (
            <MobileNewsImageWrapper
              url={data.pictureSmall?.downloadUrl}
              onClick={goToNewsArticle}
            />
          )}
          <HideOnSmall>
            <Chips
              dataTestId={`${dataTestId}-tags-wrapper`}
              chips={data.newsTags.map(tag => tag.tagName)}
            />
          </HideOnSmall>
          <Date onClick={goToNewsArticle} data-test-id={`${dataTestId}-date`}>
            {moment(data.publicationDate).format('DD.MM.yyyy HH:mm')}
          </Date>
          <Title data-test-id={`${dataTestId}-title`} onClick={goToNewsArticle}>
            {data.newsTitle}
          </Title>
          <Description data-test-id={`${dataTestId}-description`} onClick={goToNewsArticle}>
            {data.newsDescription.length > DESCRIPTION_MAX_LENGTH
              ? `${data.newsDescription.substr(0, DESCRIPTION_MAX_LENGTH)}...`
              : data.newsDescription}
          </Description>
          <ReadMore data-test-id={`${dataTestId}-read`} onClick={goToNewsArticle}>
            Читать полностью...
          </ReadMore>
        </ContentArea>
      </ContentWrapper>
      <SocialBar>
        <Like isLiked={data.likedByMe} id={data.newsId} amount={data.likes.toString()} />
        <SocialBarControl onClick={() => history.push(`/news/${data.newsId}#comments`)}>
          <Icon size={'medium'} icon={'comments'} />
          {data.comments}
        </SocialBarControl>
        <SocialBarView style={{ marginLeft: 'auto' }}>
          <Icon size={'medium'} icon={'eye'} />
          {data.views}
        </SocialBarView>
      </SocialBar>
    </Wrapper>
  );
})
