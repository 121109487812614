import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';

export const Wrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  display: flex; ;
`;

export const LeftColumn = styled.div`
  display: flex;
  width: 48px;
  margin-right: 16px;
`;

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 16px;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
`;

export const Content = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
    margin: 0;
  }
  a {
    color: ${Colors.green};
  }
`;

export const Time = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.darkGray};
`;
