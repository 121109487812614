import { lazyInject, provide } from '../../shared/utils/IoC';
import { SessionStore } from '../stores/session.store';
import { AuthorizationRoutes } from '../routes';

@provide.singleton()
export class SessionController {
  @lazyInject(SessionStore)
  protected sessionStore: SessionStore;

  handleLogout = (): void => {
    this.sessionStore.logout();
    window.location.href = `${window._env_.AUTH_URL}${AuthorizationRoutes.logout}`;
  };
}
