import styled from 'styled-components';

import { Breakpoints } from '../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  max-width: 944px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1284px) {
    max-width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  padding: 8px 16px 15px;
  //margin-top: 60px;
  display: -webkit-box;
  /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
  display: -moz-box;
  /* OLD: Firefox (buggy) */
  display: -ms-flexbox;
  /* MID: IE 10 */
  display: -webkit-flex;
  /* NEW, Chrome 21–28, Safari 6.1+ */
  display: flex;
  -webkit-flex-flow: row wrap;
  -webkit-justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${Breakpoints.small}) {
    padding-left: 0;
    padding-right: 0;
  }
`;
