import styled from 'styled-components';

import { Breakpoints } from '../../../../../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  background: #ffffff;
  /* shadow */

  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 340px;
  margin-left: 16px;
  //margin-right: 32px;
  display: inline-table;

  flex-direction: column;
  padding: 32px;
  position: sticky;
  top: 92px;
  height: calc(100vh - 92px);

  @media (max-width: ${Breakpoints.medium}) {
    position: static;
    height: auto;
    width: 100%;
    margin-bottom: 12px;
    margin-left: 0;
    padding: 24px 16px;
  }
`;
