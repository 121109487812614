import styled from 'styled-components';

import { Breakpoints } from '../../../modules/shared/consts/sizes';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SliderWrapper = styled.div`
  margin-bottom: 32px;
  width: 100%;

  @media (max-width: ${Breakpoints.small}) {
    margin-bottom: 6px;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 12px;
`;

export const ChartWrapper = styled.div`
  margin-bottom: 12px;
`;

export const InformationWrapper = styled.div`
  margin-bottom: 32px;
`;
