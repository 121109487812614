import { FC } from 'react';

import CloudIcon from '../../../assets/full-weather-conditions/cloudy.svg';
import { ForecastType } from '../Forecast/Forecast';
import { WeatherEngToRus } from '../../../utils/WeatherEngToRus';
import { WindDirectionEngToRus } from '../../../utils/WindDirectionEngToRus';

import {
  Wrapper,
  Title,
  Header,
  Condition,
  ConditionIcon,
  DescriptionLine,
  Temperature,
  TemperatureAndConditionWrapper,
  Time,
} from './style';

export const ForecastDayPart: FC<ForecastType> = ({
  feelsLike,
  windGust,
  windDirection,
  pressure,
  dew,
  humidity,
  temperature,
  condition,
  title,
  hours,
  windSpeed,
  precType,
}) => {
  const precTypeMap = (id: number) => {
    return ['без осадков', 'дождь', 'дождь со снегом', 'снег', 'град'][Number(id)];
  };

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <Time>{hours}</Time>
      </Header>
      <TemperatureAndConditionWrapper>
        <ConditionIcon src={CloudIcon} alt="" />
        <Temperature>{temperature}°</Temperature>
        <Condition>{WeatherEngToRus(condition)}</Condition>
      </TemperatureAndConditionWrapper>
      <DescriptionLine>
        <p>
          Ощущается как <span>{feelsLike}° С</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p>
          Влажность <span>{humidity} %</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p>
          Осадки <span>{precTypeMap(Number(precType))}</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p>
          Давление <span>{pressure} мм рт.ст.</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p>
          Точка росы <span>{dew}° С</span>
        </p>
      </DescriptionLine>
      {/* <DescriptionLine>*/}
      {/*  <p>*/}
      {/*    Вероятность дождя <span>{changeOfRain}%</span>*/}
      {/*  </p>*/}
      {/* </DescriptionLine>*/}
      <DescriptionLine>
        <p>
          Скорость ветра <span>{windSpeed} м/с</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p>
          Порывы ветра <span>{windGust} м/с</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p>
          Направление ветра <span>{WindDirectionEngToRus(windDirection)}</span>
        </p>
      </DescriptionLine>
    </Wrapper>
  );
};
