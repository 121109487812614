import { FC } from 'react';

import { Loader } from '../../../shared/components/Loader';

import { LoaderWrapper, Wrapper } from './style';

export const LoadingMockScreen: FC = () => (
  <Wrapper>
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  </Wrapper>
);
