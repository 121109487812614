import styled from 'styled-components';

import IllustrationBackgroundSvg from '../../assets/illustration.svg';
import { Colors } from '../../../shared/consts/color';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${IllustrationBackgroundSvg}) center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  padding: 60px;
  color: ${Colors.white};
`;
