import { action, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';

import { WidgetWeatherConfig } from './WidgetQuotesEditStore';

@provide.singleton()
export class WidgetQuoteStore {
  @lazyInject(Axios2)
  axios: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  name = '';
  id = '';
  config: WidgetWeatherConfig | null = null;

  widgets: Map<string, any[]> = new Map<string, any>();
  slotToId: Map<string, string> = new Map<string, string>();

  @action
  fetchWidgetInfo = async (
    slot: string,
    positions: Array<any>,
    id: string,
    linkIds: Array<string>
  ) => {
    const item = await this.axios.api.getQuotesByPositions(positions, {
      noValidate: true,
    });
    this.id = id;
    // this.slotToId.set(slot, id)
    this.widgets.set(
      slot,
      item.map((_item, index) => {
        return { ..._item, widgetId: id, linkId: linkIds[index] };
      })
    );
  };

  @action
  clear = () => {
    this.config = null;
    this.slotToId.clear();
    this.widgets.clear();
  };
}
