import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 32px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 72px;
  box-sizing: border-box;
  background: ${Colors.white};

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const SearchTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
  span {
    margin: 4px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.darkGray};
  }
`;

type GridContainerStyledProps = {
  isLevelSelected?: boolean;
  isPage?: boolean;
};

export const CardsWrapper = styled.div``;

export const GridArea = styled.div<{ isPage?: boolean }>`
  padding: 20px 32px 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  overflow-x: fragments;
  overflow-y: scroll;

  max-height: 688px;
  ::-webkit-scrollbar {
    display: none;
  }
  & > div:nth-child(8n - 1) {
    .faq {
      left: -356px;
    }
  }

  & > div:nth-child(8n) {
    margin-right: 0;

    .faq {
      left: -356px;
    }
  }
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
  }

  @media (max-width: ${Breakpoints.medium}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  }

  
  
  @media (max-width: ${Breakpoints.small}) {
    max-height: ${({ isPage }) => (isPage ? 'none !important' : '688px')};
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 20px 16px 16px;
    padding-top: 76px;
  }

  @media (max-width: ${Breakpoints.miniSmall}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${Breakpoints.extraMiniSmall}) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (max-width: ${Breakpoints.extraSmall}) {
    padding-left: 12px;
    padding-right: 12px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const MobileGridHeader = styled.div`
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 56px;
  width: 100%;
  border-bottom: 1px solid ${Colors.grayBorder};
  position: absolute;
  display: none;
  z-index: 99;
  padding-left: 16px;
  padding-right: 16px;
  background: ${Colors.white};
  @media (max-width: ${Breakpoints.small}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: ${Colors.black};
  }
`;

export const GridContainer = styled.div<GridContainerStyledProps>`
  background: ${Colors.white};
  max-height: 740px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow-x: visible;
  position: relative;
  margin-top: 20px;
  padding-top: ${({ isLevelSelected }) => (isLevelSelected ? '100px;' : '0;')};
  ${({ isLevelSelected }) =>
    isLevelSelected
      ? `
    padding-top: 80px;
    & ${GridArea} {
      max-height: 588px;
    }
  `
      : `padding-top: 0`};

  @media (max-width: ${Breakpoints.small}) {
    max-height: ${({ isPage }) => (isPage ? 'none !important' : '740px')};
  }
`;

export const MobileSearchInputWrapper = styled.div`
  @media (max-width: ${Breakpoints.small}) {
    width: 271px;
  }
  @media (max-width: ${Breakpoints.extraSmall}) {
    width: 236px;
  }
`;

export const BreadcrumbWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 82px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: ${Colors.white};
  border-bottom: 0.5px solid ${Colors.grayBorder};
  backdrop-filter: blur(20px);
  z-index: 100;

  @media (max-width: ${Breakpoints.small}) {
    padding-left: 16px;
    padding-right: 16px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    background: none;
    height: auto;
  }
`;

export const LoaderWrapper = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
