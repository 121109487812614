import styled from 'styled-components';

import { Colors } from '../../../shared/consts/color';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding-top: 24px;
  padding-bottom: 12px;
  width: 100%;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  margin-bottom: 20px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    color: ${Colors.black};
  }
`;

export const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-right: 15px;
    color: ${Colors.darkGray};
  }
`;
