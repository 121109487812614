import { ProfileRoutes } from '../../../../routes';
import { OrganizationalRoutes } from '../../OrganizationalRoutes';

export const mobileMenuItems = [
  {
    label: 'Мои организации',
    path: ProfileRoutes.Organizations,
  },
  {
    label: 'Входящие',
    path: OrganizationalRoutes.incoming,
  },
  {
    label: 'Исходящие',
    path: OrganizationalRoutes.outgoing,
  },
];
