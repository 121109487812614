import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/consts/color';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Colors.grayBorder};
  margin-bottom: 12px;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.black};
`;

export const Tag = styled.p`
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid ${Colors.grayBorder};
  background: ${Colors.gray};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  display: flex;
  color: ${Colors.darkGray};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${Tag} {
    margin-top: 8px;
    margin-right: 8px;
  }
`;

export const AccardionButton = styled(Tag)`
  color: ${Colors.white};
  background: ${Colors.darkGray};
  cursor: pointer;
`;
