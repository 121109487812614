import DayClearKing from '../assets/background-illustrations-v2/day/tsar/day-clear-tsar.png';
import DayClearCommon from '../assets/background-illustrations-v2/day/common/day-clear-common.png';
import DayClearShort from '../assets/background-illustrations-v2/day/short/day-clear-short.png';
import DayParlyCloudyKing from '../assets/background-illustrations-v2/day/tsar/day-partly-cloud-tsar.png';
import DayParlyCloudyCommon from '../assets/background-illustrations-v2/day/common/day-partly-cloud-common.png';
import DayParlyCloudyShort from '../assets/background-illustrations-v2/day/short/day-partly-cloud-short.png';
import DayOvercastKing from '../assets/background-illustrations-v2/day/tsar/day-mainly-clody-tsar.png';
import DayOvercastCommon from '../assets/background-illustrations-v2/day/common/day-mainly-clody-common.png';
import DayOvercastShort from '../assets/background-illustrations-v2/day/short/day-mainly-clody-short.png';
import DayFogKing from '../assets/background-illustrations-v2/day/tsar/day-fog-tsar.png';
import DayFogCommon from '../assets/background-illustrations-v2/day/common/day-fog-common.png';
import DayFogShort from '../assets/background-illustrations-v2/day/short/day-fog-short.png';
import DayRainKing from '../assets/background-illustrations-v2/day/tsar/day-rain-tsar.png';
import DayRainCommon from '../assets/background-illustrations-v2/day/common/day-rain-common.png';
import DayRainShort from '../assets/background-illustrations-v2/day/short/day-rain-short.png';
import DayHeavyRainKing from '../assets/background-illustrations-v2/day/tsar/day-heavy-rain-tsar.png';
import DayHeavyRainCommon from '../assets/background-illustrations-v2/day/common/day-heavy-rain-common.png';
import DayHeavyRainShort from '../assets/background-illustrations-v2/day/short/day-heavy-rain-short.png';
import DayThunderKing from '../assets/background-illustrations-v2/day/tsar/day-thunder-tsar.png';
import DayThunderCommon from '../assets/background-illustrations-v2/day/common/day-thunder-common.png';
import DayThunderShort from '../assets/background-illustrations-v2/day/short/day-thunder-short.png';
import DayRainAndSnowKing from '../assets/background-illustrations-v2/day/tsar/day-snow-and-rain-tsar.png';
import DayRainAndSnowCommon from '../assets/background-illustrations-v2/day/common/day-snow-and-rain-common.png';
import DayRainAndSnowShort from '../assets/background-illustrations-v2/day/short/day-snow-and-rain-short.png';
import DaySnowKing from '../assets/background-illustrations-v2/day/tsar/day-snow-tsar.png';
import DaySnowCommon from '../assets/background-illustrations-v2/day/common/day-snow-common.png';
import DaySnowShort from '../assets/background-illustrations-v2/day/short/day-snow-short.png';
import NightClearKing from '../assets/background-illustrations-v2/night/tsar/night-clear-tsar.png';
import NightClearCommon from '../assets/background-illustrations-v2/night/common/night-clear-common.png';
import NightClearShort from '../assets/background-illustrations-v2/night/short/night-clear-short.png';
import NightParlyCloudyKing from '../assets/background-illustrations-v2/night/tsar/night-partly-cloudy-tsar.png';
import NightParlyCloudyCommon from '../assets/background-illustrations-v2/night/common/night-partly-cloudy-common.png';
import NightParlyCloudyShort from '../assets/background-illustrations-v2/night/short/night-partly-cloudy-short.png';
import NightOvercastKing from '../assets/background-illustrations-v2/night/tsar/night-mainly-cloudy-tsar.png';
import NightOvercastCommon from '../assets/background-illustrations-v2/night/common/night-mainly-cloudy-common.png';
import NightOvercastShort from '../assets/background-illustrations-v2/night/short/night-mainly-cloudy-short.png';
import NightFogKing from '../assets/background-illustrations-v2/night/tsar/night-mainly-cloudy-tsar.png';
import NightFogCommon from '../assets/background-illustrations-v2/night/common/night-fog-common.png';
import NightFogShort from '../assets/background-illustrations-v2/night/short/night-fog-short.png';
import NightRainKing from '../assets/background-illustrations-v2/night/tsar/night-rain-tsar.png';
import NightRainCommon from '../assets/background-illustrations-v2/night/common/night-rain-common.png';
import NightRainShort from '../assets/background-illustrations-v2/night/short/night-raint-short.png';
import NightHeavyRainKing from '../assets/background-illustrations-v2/night/tsar/night-heavy-rain-tsar.png';
import NightHeavyRainCommon from '../assets/background-illustrations-v2/night/common/night-heavy-rain-common.png';
import NightHeavyRainShort from '../assets/background-illustrations-v2/night/short/night-heavy-rain-short.png';
import NightThunderKing from '../assets/background-illustrations-v2/night/tsar/night-thunder-tsar.png';
import NightThunderCommon from '../assets/background-illustrations-v2/night/common/night-thunder-common.png';
import NightThunderShort from '../assets/background-illustrations-v2/night/short/night-thunder-short.png';
import NightRainAndSnowKing from '../assets/background-illustrations-v2/night/tsar/night-rain-and-snow-tsar.png';
import NightRainAndSnowCommon from '../assets/background-illustrations-v2/night/common/night-rain-and-snow-common.png';
import NightRainAndSnowShort from '../assets/background-illustrations-v2/night/short/night-rain-and-snow-short.png';
import NightSnowKing from '../assets/background-illustrations-v2/night/tsar/night-snow-tsar.png';
import NightSnowCommon from '../assets/background-illustrations-v2/night/common/night-snow-common.png';
import NightSnowShort from '../assets/background-illustrations-v2/night/short/night-snow-short.png';

export function WeatherConditionToIllustration(
  condition: string,
  time: number,
  type: 'king' | 'common' | 'short'
) {
  const conditions = {
    day: {
      clear: {
        king: [DayClearKing],
        common: [DayClearCommon],
        short: [DayClearShort],
      },
      'partly-cloud': {
        king: [DayParlyCloudyKing],
        common: [DayParlyCloudyCommon],
        short: [DayParlyCloudyShort],
      },
      cloudy: {
        king: [DayParlyCloudyKing],
        common: [DayParlyCloudyCommon],
        short: [DayParlyCloudyShort],
      },
      overcast: {
        king: [DayOvercastKing],
        common: [DayOvercastCommon],
        short: [DayOvercastShort],
      },
      drizzle: {
        king: [DayFogKing],
        common: [DayFogCommon],
        short: [DayFogShort],
      },
      'light-rain': {
        king: [DayRainKing],
        common: [DayRainCommon],
        short: [DayRainShort],
      },
      rain: {
        king: [DayRainKing],
        common: [DayRainCommon],
        short: [DayRainShort],
      },
      'moderate-rain': {
        king: [DayRainKing],
        common: [DayRainCommon],
        short: [DayRainShort],
      },
      'heavy-rain': {
        king: [DayHeavyRainKing],
        common: [DayHeavyRainCommon],
        short: [DayHeavyRainShort],
      },
      'continuous-heavy-rain': {
        king: [DayHeavyRainKing],
        common: [DayHeavyRainCommon],
        short: [DayHeavyRainShort],
      },
      showers: {
        king: [DayHeavyRainKing],
        common: [DayHeavyRainCommon],
        short: [DayHeavyRainShort],
      },
      hail: {
        king: [DayHeavyRainKing],
        common: [DayHeavyRainCommon],
        short: [DayHeavyRainShort],
      },
      thunderstorm: {
        king: [DayThunderKing],
        common: [DayThunderCommon],
        short: [DayThunderShort],
      },
      'thunderstorm-with-rain': {
        king: [DayThunderKing],
        common: [DayThunderCommon],
        short: [DayThunderShort],
      },
      'thunderstorm-with-hail': {
        king: [DayThunderKing],
        common: [DayThunderCommon],
        short: [DayThunderShort],
      },
      'wet-snow': {
        king: [DayRainAndSnowKing],
        common: [DayRainAndSnowCommon],
        short: [DayRainAndSnowShort],
      },
      'light-snow': {
        king: [DaySnowKing],
        common: [DaySnowCommon],
        short: [DaySnowShort],
      },
      snow: {
        king: [DaySnowKing],
        common: [DaySnowCommon],
        short: [DaySnowShort],
      },
      'snow-showers': {
        king: [DaySnowKing],
        common: [DaySnowCommon],
        short: [DaySnowShort],
      },
    },
    night: {
      clear: {
        king: [NightClearKing],
        common: [NightClearCommon],
        short: [NightClearShort],
      },
      'partly-cloud': {
        king: [NightParlyCloudyKing],
        common: [NightParlyCloudyCommon],
        short: [NightParlyCloudyShort],
      },
      cloudy: {
        king: [NightParlyCloudyKing],
        common: [NightParlyCloudyCommon],
        short: [NightParlyCloudyShort],
      },
      overcast: {
        king: [NightOvercastKing],
        common: [NightOvercastCommon],
        short: [NightOvercastShort],
      },
      drizzle: {
        king: [NightFogKing],
        common: [NightFogCommon],
        short: [NightFogShort],
      },
      'light-rain': {
        king: [NightRainKing],
        common: [NightRainCommon],
        short: [NightRainShort],
      },
      rain: {
        king: [NightRainKing],
        common: [NightRainCommon],
        short: [NightRainShort],
      },
      'moderate-rain': {
        king: [NightRainKing],
        common: [NightRainCommon],
        short: [NightRainShort],
      },
      'heavy-rain': {
        king: [NightHeavyRainKing],
        common: [NightHeavyRainCommon],
        short: [NightHeavyRainShort],
      },
      'continuous-heavy-rain': {
        king: [NightHeavyRainKing],
        common: [NightHeavyRainCommon],
        short: [NightHeavyRainShort],
      },
      showers: {
        king: [NightHeavyRainKing],
        common: [NightHeavyRainCommon],
        short: [NightHeavyRainShort],
      },
      hail: {
        king: [NightHeavyRainKing],
        common: [NightHeavyRainCommon],
        short: [NightHeavyRainShort],
      },
      thunderstorm: {
        king: [NightThunderKing],
        common: [NightThunderCommon],
        short: [NightThunderShort],
      },
      'thunderstorm-with-rain': {
        king: [NightThunderKing],
        common: [NightThunderCommon],
        short: [NightThunderShort],
      },
      'thunderstorm-with-hail': {
        king: [NightThunderKing],
        common: [NightThunderCommon],
        short: [NightThunderShort],
      },
      'wet-snow': {
        king: [NightRainAndSnowKing],
        common: [NightRainAndSnowCommon],
        short: [NightRainAndSnowShort],
      },
      'light-snow': {
        king: [NightSnowKing],
        common: [NightSnowCommon],
        short: [NightSnowShort],
      },
      snow: {
        king: [NightSnowKing],
        common: [NightSnowCommon],
        short: [NightSnowShort],
      },
      'snow-showers': {
        king: [NightSnowKing],
        common: [NightSnowCommon],
        short: [NightSnowShort],
      },
    },
  };

  const hours = new Date(time * 1000).getHours();

  let objectRoot = null;

  if (hours >= 18 || hours <= 5) {
    objectRoot = conditions.night;
  } else {
    objectRoot = conditions.day;
  }

  // @ts-ignore
  const picture = objectRoot[condition];

  if (!picture) {
    return DayClearKing;
  }

  if (type === 'king') {
    return picture.king;
  } else if (type === 'common') {
    return picture.common;
  } else if (type === 'short') {
    return picture.short;
  }
}
