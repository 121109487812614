import { TypeApiRoute } from '../models';
import { WeatherType } from '../widgets/models/WeatherType';

type TypeRequest = {
  lat: number;
  lon: number;
};

type TypeResponse = {
  geoObject: any;
  fact: WeatherType;
  forecasts: any;
};

export const getCommonWeatherByPoint: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-weather/weather/commonByPoint`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
