import { TypeApiRoute } from '../models/TypeApiRoute';
import { FieldType } from '../modules/shared/types/Field';

type TypeRequest = {
  name: string;
  area: number;
  coordinate: {
    lat: number;
    lon: number;
  };
  address: string;
  cultureId?: string;
};

type TypeResponse = FieldType;

export const createFieldForUser: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/fields/create`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-field',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
