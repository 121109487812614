import { TypeApiRoute } from '../models/TypeApiRoute';
import { FieldType } from '../modules/shared/types/Field';

type TypeRequest = {
  name: string;
  area: number;
  coordinate: {
    lat: number;
    lon: number;
  };
  address: string;
  fieldId: string;
  cultureId?: string;
};

type TypeResponse = FieldType;

export const updateField: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: (request: TypeRequest) => `/api/fields/${request.fieldId}`,
  method: 'PUT',
  headers: {
    'DA-HOST': 'da-field',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
