import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeOrganization } from '../modules/shared/types/organization';

type TypeRequest = {
  roles: Array<string>;
  nameOrInn: string;
};

type TypeResponse = { content: Array<TypeOrganization> } & TypeResponsePageable;

function url(request: TypeRequest) {
  let baseUrl = `/api/organizations/myOrganizationsList`;

  const roles = request.roles.map(role => `employeeRole=${role}`).join('&');

  const getParams = [request.nameOrInn ? `nameOrInn=${request.nameOrInn}` : '', roles]
    .filter(Boolean)
    .join('&');

  if (getParams.length > 0) baseUrl += `?${getParams}`;

  return baseUrl;
}

export const getOrganizationsMine: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
