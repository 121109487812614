import { FC } from 'react';

import SearchSvg from '../../../../assets/search.svg';

import { Card, SearchIcon, Title, Wrapper, Description } from './style';

export const NothingFound: FC = () => (
  <Wrapper>
    <Card>
      <SearchIcon src={SearchSvg} />
      <Title>Ничего не найдено</Title>
      <Description>
        У нас зарегистрировано более 200 000 организаций, но мы не смогли ничего найти по вашему
        запросу <br /> <br /> Попробуйте ввести другое название или ИНН
      </Description>
    </Card>
  </Wrapper>
);
