import { FC, useEffect } from 'react';
import { Button } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';

import CultureMock from '../../../assets/culture-icons/search-mock.svg';
import { useStore } from '../../../../modules/shared/utils/IoC';
import { QuoteCreationStore } from '../../../stores/QuoteCreationStore';
import SelectCustom from '../../../../modules/shared/components/SelectCustom/SelectCustom';
import { SelectSearchCustom } from '../../../../modules/shared/components/SelectSearchCustom/SelectSearchCustom';

import {
  Overflow,
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalBottom,
  RightBar,
  LeftBar,
  ModalContent,
  ButtonWrapper,
  LineWrapper,
  Separator,
  SearchMock,
  ListColumn,
  Label,
} from './style';
import { Qoute } from './components/Qoute/Qoute';

type QuoteAddModalProps = {
  isShown: boolean;
  widgetId: string;
  selectedQuoteId?: string;
  onHide: () => void;
  onUpdate: () => void;
  type: 'add' | 'change';
  quoteIndex?: number;
};

export const QuoteAddModal: FC<QuoteAddModalProps> = observer(
  ({ isShown, widgetId, onHide, onUpdate, type, quoteIndex, selectedQuoteId }) => {
    const store = useStore(QuoteCreationStore);
    const {
      fetchData,
      cultures,
      basis,
      selectBasis,
      selectCulture,
      fetchQuotes,
      resultQuotes,
      selectedIds,
      selectQuote,
      finishSelecting,
      setNameFilter,
      selectedQuote,
      isAvailableAddButton,
      economicRegions,
      cultureClasses,
      selectCultureClass,
      selectRegion,
      reset,
      finishChanging,
      fetchAllQuotes,
    } = store;

    useEffect(() => {
      fetchAllQuotes(selectedQuoteId || '');
      fetchData();
      fetchQuotes(widgetId);
    }, []);

    useEffect(() => {
      if (isShown) {
        fetchQuotes(widgetId);
      }
    }, [isShown]);

    if (!isShown) {
      return null;
    }

    const handleFinishChange = async () => {
      await finishChanging(quoteIndex!);
      reset();
      onUpdate();
      onHide();
    };

    return (
      <>
        <Overflow
          onClick={() => {
            onHide();
            reset();
          }}
        />
        <ModalWrapper>
          <ModalHeader>
            <ModalTitle>{type === 'add' ? 'Добавление' : 'Изменение'} котировки</ModalTitle>
          </ModalHeader>
          <ModalContent>
            <LeftBar>
              <LineWrapper>
                <Label>Культура</Label>
                <SelectSearchCustom
                  dataTestId={'qoute-search'}
                  onChange={selectCulture}
                  options={[...cultures]}
                  placeholder={'Выберите культуру'}
                  onSearch={setNameFilter}
                  isClearAvailable={true}
                />
              </LineWrapper>
              <LineWrapper>
                <SelectCustom
                  label={'Класс'}
                  dataTestId={'qoute-class'}
                  options={[
                    ...cultureClasses.map(item => {
                      return { label: item.name, value: item.id };
                    }),
                  ]}
                  // @ts-ignore
                  onChange={selectCultureClass}
                  placeholder={'Выберите класс'}
                  isClearAvailable={true}
                />
              </LineWrapper>
              <Separator />
              <LineWrapper>
                <SelectCustom
                  dataTestId={'qoute-basis'}
                  label={'Базис'}
                  options={[...basis]}
                  // @ts-ignore
                  onChange={selectBasis}
                  placeholder={'Выберите базис'}
                  isClearAvailable={true}
                />
              </LineWrapper>
              <LineWrapper>
                <SelectCustom
                  label={'Локация'}
                  dataTestId={'qoute-location'}
                  options={[
                    ...economicRegions.map(item => {
                      return { label: item.name, value: item.id };
                    }),
                  ]}
                  // @ts-ignore
                  onChange={selectRegion}
                  isClearAvailable={true}
                  placeholder={'Выберите локацию'}
                />
              </LineWrapper>
            </LeftBar>
            <RightBar>
              {resultQuotes.length > 0 ? (
                <ListColumn>
                  {resultQuotes.map((item, index) => {
                    return (
                      <Qoute
                        dataTestId={`qoute-item-${index}`}
                        icon={item.culture.icon && item.culture.icon.downloadUrl}
                        id={item.id}
                        onSelect={selectQuote}
                        isSelected={selectedIds.has(item.id)}
                        price={item.price}
                        title={item.culture && item.culture.name}
                        basis={item.basis && item.basis.name}
                        qualityMin={item.qualityMin}
                        qualityMax={item.qualityMax}
                        tendency={{
                          differenceInPercentage: item.tendency.diffPercent,
                          difference: item.tendency.diff,
                          direction: item.tendency.direction,
                        }}
                        vat={item.vat}
                        cultureClass={item.cultureClass ? item.cultureClass?.name : ''}
                        region={item.economicRegion && item.economicRegion.name}
                        isActive={selectedQuote ? selectedQuote?.id === item.id : false}
                      />
                    );
                  })}
                </ListColumn>
              ) : (
                <SearchMock>
                  <img src={CultureMock} alt="" />
                  <h1>
                    Выберите <br /> культуру
                  </h1>
                  <p>
                    После ввода данных, выберите
                    <br /> котировки нужных вам культур
                  </p>
                </SearchMock>
              )}
            </RightBar>
          </ModalContent>
          <ModalBottom>
            {/* <BottomText>*/}
            {/*  {quotesAmount === 0*/}
            {/*    ? 'Не выбрано ни одной котировки'*/}
            {/*    : `Выбрано ${quotesAmount} котировки`}*/}
            {/* </BottomText>*/}
            <ButtonWrapper>
              <Button
                type={'button'}
                color={'default'}
                data-test-id={'qoute-cancel'}
                onClick={() => {
                  onHide();
                  reset();
                }}
              >
                Отменить
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              {type === 'add' ? (
                <Button
                  type={'button'}
                  color={'primary'}
                  dataTestId={'qoute-add'}
                  onClick={async () => {
                    await finishSelecting();
                    reset();
                    onUpdate();
                    onHide();
                  }}
                  disabled={!isAvailableAddButton}
                >
                  Добавить
                </Button>
              ) : (
                <Button
                  type={'button'}
                  color={'primary'}
                  onClick={handleFinishChange}
                  disabled={!isAvailableAddButton}
                  dataTestId={'qoute-change'}
                >
                  Изменить
                </Button>
              )}
            </ButtonWrapper>
          </ModalBottom>
        </ModalWrapper>
      </>
    );
  }
);
