import styled from 'styled-components';

import { BorderRadius, Breakpoints } from '../../../../../shared/consts/sizes';
import { Colors } from '../../../../../shared/consts/color';
import { PaperWrapper } from '../../../../../shared/components/PaperBlock/style';

export const Wrapper = styled(PaperWrapper)`
  padding: 32px;

  @media (max-width: ${Breakpoints.small}) {
    padding: 16px;
  }
`;

export const ServiceHeader = styled.div`
  position: relative;
  padding: 32px 40px;
  margin-bottom: 32px;
  border-radius: ${BorderRadius.default};
  background-size: cover;
`;

export const ServiceLogo = styled.img`
  position: absolute;
  width: 72px;
  height: 72px;
  border-radius: ${BorderRadius.large};
  top: 40px;
  right: 40px;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const CategoriesWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Title = styled.div`
  font-size: 40px;
  font-weight: 700;
  color: ${Colors.white};
  margin-bottom: 12px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${Breakpoints.small}) {
    font-size: 24px;
    width: 100%;
  }
`;

export const SubTitle = styled.div`
  font-size: 12px;
  color: ${Colors.white};
  line-height: 16px;
  max-width: 542px;
`;

export const ContentWrapper = styled.div`
  padding: 0 40px 32px;
  border-bottom: 1px solid ${Colors.grayBorder};

  @media (max-width: ${Breakpoints.small}) {
    padding: 0 0 28px;
  }
`;

export const ContentTitle = styled.div`
  font-size: 16px;
  margin-bottom: 12px;
`;

export const ContentText = styled.div`
  font-size: 14px;
  line-height: 23px;
  color: ${Colors.darkGray};
  margin-bottom: 24px;

  max-width: 100%;
  a {
    color: ${Colors.green};
  }
  a:visited {
    color: ${Colors.green};
  }
  img {
    max-width: 100%;
    border-radius: 16px;
  }
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.black};
  text-align: justify;
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    padding-left: 16px;
    padding-right: 16px;
  }
 \`;
`;

export const ContentButtonWrapper = styled.div`
  max-width: 200px;

  & button {
    justify-content: center;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${Breakpoints.small}) {
    max-width: 100%;
  }
`;

export const SubServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 224px);
  grid-template-rows: repeat(auto-fill, 278px);
  grid-gap: 25px;
  margin-top: 32px;

  @media (max-width: 1330px) {
    grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
  }
`;
