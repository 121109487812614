import styled from 'styled-components';

import NewsArticleMock from '../../../../assets/news-mock.png';
import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  width: 100%;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media (max-width: ${Breakpoints.small}) {
    margin-bottom: 16px;
    border-radius: 0;
  }
`;

export const HeaderWrapper = styled.div`
  padding: 32px 32px 52px 32px;

  @media (max-width: ${Breakpoints.medium}) {
    padding: 20px 16px;
    border-bottom: 1px solid ${Colors.lightnessGray};
  }
`;

export const Header = styled.div<{ url: string }>`
  padding: 32px 40px;

  background: url(${({ url }) => (url ? url : NewsArticleMock)});
  background-size: cover;
  display: flex;
  flex-direction: column;
  //height: 200px;
  width: 100%;
  border-radius: 16px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 16px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    background: none;
    padding: 0;

    &:before {
      display: none;
    }
  }
`;

export const BackButtonWrapper = styled.div`
  position: relative;

  * {
    color: ${Colors.white} !important;
    fill: ${Colors.white} !important;
  }
`;

export const Tag = styled.p`
  background: rgba(255, 255, 255, 0.14);
  border: 0.5px solid rgba(233, 230, 240, 0.19);
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 8px;
  padding: 6px 12px;
  margin: 0 0 8px;
  display: inline-block;

  @media (max-width: ${Breakpoints.medium}) {
    color: ${Colors.darkGray} !important;
    font-size: 12px;
    background: ${Colors.lightGray};
  }
`;

export const TagsWrapper = styled.div`
  display: block;
  ${Tag} {
    margin-right: 8px;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 39px;
  color: ${Colors.white};
  margin: 16px 0 25px;

  @media (max-width: ${Breakpoints.medium}) {
    color: ${Colors.black} !important;
  }
`;
export const Content = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  max-width: 100%;
  a {
    color: ${Colors.green};
  }
  img {
    max-width: 100%;
    border-radius: 16px;
  }
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.black};
  text-align: justify;
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const SocialBar = styled.div`
  border-top: 0.5px solid ${Colors.grayBorder};
  display: flex;
  align-items: center;
  height: 56px;
  margin-top: 28px;
`;

export const SocialBarControl = styled.div`
  display: flex;
  height: 16px;
  align-items: center;
  color: ${Colors.darkGray};
  fill: ${Colors.black};
  margin-right: 15px;
  font-size: 12px;
  line-height: 12px;
  *:first-child {
    margin-right: 3px;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;

export const SocialBarView = styled.div`
  display: flex;
  height: 16px;
  align-items: center;
  color: ${Colors.darkGray};
  font-size: 12px;
  line-height: 12px;
  *:first-child {
    margin-right: 3px;
    fill: ${Colors.darkGray} !important;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;
