import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../modules/shared/consts/sizes';

export const Wrapper = styled.div<{ isActive: boolean }>`
  border-radius: 16px;
  position: relative;
  width: 100%;
  height: 260px;
  overflow: hidden;

  * {
    ${({ isActive }) => !isActive && `filter: grayscale(100%);-webkit-filter: grayscale(100%);`};
  }
`;

type IllustratonProps = {
  url: string;
};

export const Illustration = styled.div<IllustratonProps>`
  position: absolute;
  width: 100%;
  height: 260px;
  border-radius: 16px;
  z-index: -2;

  ${({ url }) => `background: url("${url}");`};
  background-size: cover;

  @media (max-width: ${Breakpoints.small}) {
    height: 110px;
    background-position-y: bottom;
  }
`;

export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 260px;
  border: 16px;
  z-index: -1;
  background: linear-gradient(181.52deg, rgba(0, 0, 0, 0) 37.61%, rgba(22, 20, 1, 0.5) 107.33%);
`;

export const Content = styled.div`
  padding: 28px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.small}) {
    padding: 16px;
  }
`;

export const LeftBar = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
    margin-bottom: 0;
    padding-top: 0;
  }
`;

export const Temperature = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 52px;
  color: ${Colors.white};
  margin: 0;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);

  @media (max-width: ${Breakpoints.small}) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const ConditionWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${Breakpoints.small}) {
    padding-top: 0;
  }

  img {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    @media (max-width: ${Breakpoints.small}) {
      width: 14px;
      height: 20px;
    }
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${Colors.white};
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
    max-width: 80px;
    white-space: normal;

    @media (max-width: ${Breakpoints.small}) {
      font-size: 12px;
      text-transform: capitalize;
    }
  }
`;

export const RightBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
`;

export const MapWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    @media (max-width: ${Breakpoints.small}) {
      width: 12px;
      height: 12px;
    }
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${Colors.white};
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
    margin: 0;
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: ${Breakpoints.small}) {
      font-size: 12px;
    }
  }
  margin-bottom: 8px;
`;

export const LineWrapper = styled.div<{ hideOnMobile?: boolean }>`
  width: 100% !important;
  display: flex;
  margin-bottom: 8px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    color: ${Colors.white};
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);

    @media (max-width: 1284px) {
      width: auto;
    }
    span {
      font-weight: bold;
      margin-left: auto;
    }
  }

  @media (max-width: 1284px) {
    justify-content: flex-end;
    width: auto;
    margin-bottom: 5px;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`;

export const Separator = styled.div<{ hideOnMobile?: boolean }>`
  border-top: 0.5px solid ${Colors.white};
  opacity: 0.2;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;

  @media (max-width: ${Breakpoints.small}) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  height: 48px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  padding-left: 28px;
  padding-right: 24px;

  img {
    margin-left: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
`;

export const HideOnSmallSpan = styled.div`
  display: inline;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const HideLessThanContentSpan = styled.div`
  display: inline;

  @media (max-width: 1284px) {
    display: none;
  }
`;

export const ShowOnSmallSpan = styled.div`
  display: none;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;
