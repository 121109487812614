import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeUserRole } from '../modules/shared/types/users';

type TypeRequest = {
  fio: string;
  email: string;
  comment: string;
  position: TypeUserRole;
  organizationId: string;
};

type TypeResponse = {};

export const sendEmailInvite: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/organizations/user/invite/email/${request.organizationId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
