import { FC } from 'react';

import { UserInfo } from '../../../../../../../news/components/UserInfo';
import { Accardion } from '../Accardion/Accardion';
import { ExpertiseType } from '../../../../stores/EditInterestsStore';

import { Wrapper } from './style';

type PersonBarProps = {
  firstName: string;
  lastName: string;
  specialization: string;
  avatar: string;
  competitions: Array<ExpertiseType & { isLiked: boolean }>;
  interests: Array<string>;
  onToggle: (id: string, v: boolean) => void;
};

export const PersonBar: FC<PersonBarProps> = ({
  avatar,
  firstName,
  lastName,
  specialization,
  competitions,
  interests,
  onToggle,
}) => {
  return (
    <Wrapper>
      <UserInfo
        size={'large'}
        userName={`${firstName} ${lastName}`}
        userRole={specialization}
        avatar={avatar}
      />
      {competitions.length > 0 && (
        <Accardion
          title={'Компетенции'}
          tags={competitions}
          type={'expertise'}
          onToggle={onToggle}
        />
      )}
      {interests.length > 0 && (
        <Accardion title={'Интересы'} tags={interests} type={'interest'} onToggle={onToggle} />
      )}
    </Wrapper>
  );
};
