import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/consts/color';
import { PaperWrapper } from '../../../../../../../shared/components/PaperBlock/style';
import { Breakpoints } from '../../../../../../../shared/consts/sizes';

export const Wrapper = styled(PaperWrapper)`
  width: 100%;
  padding: 32px;

  @media (max-width: ${Breakpoints.small}) {
    padding: 32px 16px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  border-bottom: 1px solid ${Colors.lightnessGray};
  padding-bottom: 16px;
  margin-bottom: 32px;
`;

export const ItemWrapper = styled.div`
  margin-bottom: 16px;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  margin-bottom: 14px;
  padding-left: 8px;
`;

export const Item = styled.div`
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 4px;
  color: #6f6f6f;
`;
