import styled from 'styled-components';
import { Input } from '@uralhim-innovative/farmik-ui';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export const FiltersWrapper = styled.div<{ filtersOpened: boolean }>`
  display: grid;
  max-height: 73px;
  padding: 16px 32px;
  border-bottom: 1px solid ${Colors.grayBorder};
  grid-template-columns: 256px 164px;
  grid-gap: 16px;
  transition: max-height ease-in-out 0.5s;

  @media (max-width: ${Breakpoints.small}) {
    padding: 16px;
    grid-template-columns: 1fr 24px;
    align-items: center;
    overflow: ${({ filtersOpened }) => (filtersOpened ? 'visible' : 'hidden')};
    max-height: ${({ filtersOpened }) => (filtersOpened ? '300px' : '73px')};
  }
`;

export const ServicesListWrapper = styled.div`
  padding: 20px 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(218px, 1fr));
  grid-template-rows: repeat(auto-fill, 232px);
  grid-gap: 25px;
  overflow: auto;
  height: calc(100vh - 181px);

  @media (max-width: ${Breakpoints.small}) {
    padding: 20px 16px;
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  margin-top: -15px;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const FiltersIconWrapper = styled.div`
  display: none;
  opacity: 0.5;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const FilterSearchInput = styled(Input)`
  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
  }
`;

export const MobileCarouselServiceCardWrapper = styled.div`
  display: none;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 15px;
  margin-top: -15px;
  margin-left: -16px;
  margin-right: -16px;
  overflow-y: hidden;

  &:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: flex;

    .avatarWrapper {
      display: inline-flex;
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }
  }

  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    margin-right: 12px;
    width: 236px;
    flex: 0 0 236px;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
