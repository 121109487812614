import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { Axios2 } from '../../../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationInvitationStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  acceptInviteFromOrganization = (applicationId: string) =>
    this.axios2.api.updateInvitationAccept({ applicationId });

  declineInviteFromOrganization = (applicationId: string) =>
    this.axios2.api.updateInvitationDecline({ applicationId });

  acceptApplicationFromUser = (applicationId: string) =>
    this.axios2.api.updateApplicationAccept({ applicationId });

  declineApplicationFromUser = (applicationId: string) =>
    this.axios2.api.updateApplicationDecline({ applicationId });

  deleteInvite = (applicationId: string) => this.axios2.api.deleteInvitation({ applicationId });
}
