import {lazyInject, provide} from "../shared/utils/IoC";
import {action, computed, makeAutoObservable} from "mobx";
import {SessionStore} from "../authorization/stores/session.store";
import {ApplicationRoutes} from "../routes";
import {DashboardRoutes} from "../main/modules/dashboard/dashboard.routes";
import {logoutEvent} from "../shared/events/logoutEvent";
import {updateTokensEvent} from "../shared/events/updateTokensEvent";

@provide.singleton()
export class AdminViewStore {
  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  constructor() {
    makeAutoObservable(this);
    const timestamp = localStorage.getItem('loginAsExpireIn');
    if (Number(timestamp) > new Date().getTime()) {
      setTimeout(() => {

      }, Number(timestamp) - new Date().getTime());
    }
  }

  @action
  authAsUser = (token: string, history: any, timestamp: string) => {
    if (!token) return;
    token = token.replaceAll('==398==', '.');
    localStorage.setItem('loginAs', token);
    localStorage.setItem('loginAsExpireIn', timestamp);
    document.dispatchEvent(updateTokensEvent);
    setTimeout(() => history.push(DashboardRoutes.profile), 500);
    if (Number(timestamp) > new Date().getTime()) {
      setTimeout(() => {
        localStorage.removeItem('loginAs');
        localStorage.removeItem('loginAsExpireIn');
        document.dispatchEvent(logoutEvent);
      }, Number(timestamp) - new Date().getTime());
    }
  }

  @computed
  get isAdminPanelActive() {
    return Boolean(localStorage.getItem('loginAs')) && this.sessionStore.isLoggedIn
  }
}