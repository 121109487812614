import { action, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { TypeOrganization } from '../../../../../../shared/types/organization';
import { Axios2, TypeApiResponse } from '../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  organization: TypeOrganization | undefined = undefined;
  organizationUpdateInProgress = true;

  getOrganizationInfo = (organizationId: string) => {
    this.organizationUpdateInProgress = true;

    this.axios2.api
      .getOrganization({ organizationId }, { omit: ['organizationId'] })
      .then(response => {
        this.organization = response;
        this.organizationUpdateInProgress = false;
      });
  };
}
