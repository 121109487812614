import { FC } from 'react';

import { Title, Wrapper, Header, Content } from './style';

type InformationProps = {
  content: string;
};

export const Information: FC<InformationProps> = ({ content }) => {
  return (
    <Wrapper>
      <Header>
        <Title>О Культуре</Title>
      </Header>
      <Content data-test-id={'quote-full-content'}>{content}</Content>
    </Wrapper>
  );
};
