import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  like: boolean;
  commentId: string;
};

type TypeResponse = {};

export const updateCommentLike: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-news/news/comments/like/${request.commentId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
