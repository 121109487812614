import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

const CARD_GAP = '40px';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow-y: hidden;
  height: 262px;
`;

export type ControlProps = {
  isDisabled: boolean;
};

const disabledState = `
  background: ${Colors.white};
  cursor: not-allowed;
`;

export const LeftControl = styled.div<ControlProps>`
  background: ${Colors.green};
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  width: 20px;
  height: 232px;
  position: absolute;
  left: 0;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  ${({ isDisabled }) => isDisabled && disabledState}
`;

export const RightControl = styled.div<ControlProps>`
  background: ${Colors.green};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 20px;
  height: 232px;
  position: absolute;
  right: 0;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  ${({ isDisabled }) => isDisabled && disabledState}
`;

export const InnerWrapper = styled.div`
  width: calc(100% - ${CARD_GAP});
  height: 262px;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  left: 20px;
`;

export const CardsWrapper = styled.div`
  width: 9999px;
  height: 262px;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  padding-left: 25px;
  * {
    user-select: none;
  }
  & > div {
    margin-right: 25px;
  }
  transition: 300ms;
`;
