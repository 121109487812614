import { FunctionComponent } from 'react';

import { PaperBlock } from '../../../../../shared/components/PaperBlock';
import { Chips } from '../../../../../shared/components/Chips';
import { Avatar } from '../Carousel/components/Avatar';

import { Wrapper, ImageWrapper, Title, CategoriesWrapper } from './style';

type Props = {
  img: string;
  title: string;
  categories: string[];
  onClick: (id: string) => void;
  id: string;
};

export const ServiceCard: FunctionComponent<Props> = ({ title, categories, id, img, onClick }) => {
  const handleClick = () => {
    onClick(id);
  };

  return (
    <PaperBlock hoverable>
      <Wrapper onClick={handleClick}>
        <ImageWrapper>
          <Avatar image={img} name={title} />
        </ImageWrapper>
        <Title>{title}</Title>
        <CategoriesWrapper>
          {categories.map(name => (
            <Chips title={name} type="default" />
          ))}
        </CategoriesWrapper>
      </Wrapper>
    </PaperBlock>
  );
};
