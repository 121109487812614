import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {};

type TypeResponse = {
  emails: string[];
  phoneNumbers: string[];
};

export const getPendingContracts: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/users/myProfile/pendingContacts`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
