import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeAnswer } from '../modules/shared/types/comments';

type TypeRequest = {
  commentId: string;
};

type TypeResponse = Array<TypeAnswer>;

export const getNewsCommentAnswers: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-news/news/comments/${request.commentId}/answers`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
