import { TypeApiRoute } from '../models/TypeApiRoute';
import { UserInfoType } from '../modules/shared/types/users';

type TypeRequest = {
  id: string;
};

type TypeResponse = UserInfoType;

export const getProfileById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => `/api/da-profile/users/shortInfo/${req.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
