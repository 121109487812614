import styled from 'styled-components';

import { Colors } from '../../../shared/consts/color';
import { Breakpoints } from '../../../shared/consts/sizes';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  height: auto;
  min-height: 800px;
`;

export const MenuColumn = styled.div`
  width: 196px;
  background: ${Colors.white};
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);

  @media (max-width: ${Breakpoints.medium}) {
    display: none;
  }
`;

export const ContentColumn = styled.div`
  max-width: 708px;
  width: 708px;
  @media (max-width: ${Breakpoints.large}) {
    max-width: 486px;
  }

  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
  }

  border-radius: 16px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  background: ${Colors.white};
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  @media (max-width: ${Breakpoints.large}) {
    max-width: 262px;
  }
  @media (max-width: ${Breakpoints.medium}) {
    max-width: 232px;
  }
  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const SettingsColumn = styled.div`
  height: 100%;

  @media (max-width: ${Breakpoints.medium}) {
    height: auto;
  }
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border-radius: 16px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  background: ${Colors.white};
`;

export const MobileMenuWrapper = styled.div`
  display: none;
  @media (max-width: ${Breakpoints.medium}) {
    display: flex;
  }
  margin-bottom: 16px;
  width: 100%;
  background: ${Colors.white};
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
`;
