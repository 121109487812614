import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { Axios2 } from '../../../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationSendInviteFormStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  comment = '';

  setComment = (comment: string) => {
    this.comment = comment;
  };

  joinToOrganization = (organizationId: string) =>
    this.axios2.api.updateApplicationCreate(
      { organizationId, comment: this.comment },
      { omit: ['organizationId'] }
    );
}
