import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

type AvatarProps = {
  size: 'common' | 'large';
};

export const AvatarWrapper = styled.div<AvatarProps>`
  box-sizing: border-box;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: ${Colors.black};
  background: ${Colors.gray};
  overflow: hidden;
  cursor: pointer;

  p {
    margin: 0;
  }

  ${({ size }) => size === 'large' && 'height: 68px; width: 68px;'};
`;

export const Avatar = styled.img<AvatarProps>`
  width: 48px;
  border-radius: 16px;
  ${({ size }) => size === 'large' && 'width: 68px;'};
`;

export const Username = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
  margin-left: 16px;
  cursor: pointer;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.darkGray};
  }
`;

export const Publications = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: ${Colors.green};
  text-decoration: underline;
`;
