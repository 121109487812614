import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../modules/shared/consts/sizes';

export const MainInformationWrapper = styled.div`
  height: 344px;
  display: grid;
  margin-bottom: 12px;
  grid-template-columns: minmax(500px, 1fr) minmax(256px, 448px);
  grid-gap: 12px;

  @media (max-width: ${Breakpoints.medium}) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

export const MainInformation = styled.div`
  //width: 608px;
  height: 344px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 32px;

  @media (max-width: ${Breakpoints.small}) {
    height: auto;
    padding: 30px 16px;
  }
`;

export const MainInformationHeader = styled.div`
  border-bottom: 0.5px solid ${Colors.grayBorder};
  align-items: flex-start;
  justify-content: flex-end;
  height: 32px;
  display: flex;
`;

export const MainInformationHeaderTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0 auto 0 0;
  color: ${Colors.black};

  @media (max-width: ${Breakpoints.small}) {
    & > span {
      display: none;
    }
  }
`;

export const MainInformationHeaderTime = styled.div`
  margin-left: 20px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  p {
    margin: 0;
    padding-top: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
  }
`;

export const MapWrapper = styled.div`
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  height: 344px;

  @media (max-width: ${Breakpoints.small}) {
    height: 146px;
  }
`;

export const LeftBar = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const WeatherConditionIcon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 12px;

  @media (max-width: ${Breakpoints.small}) {
    float: left;
    margin-right: 28px;
  }

  @media (max-width: ${Breakpoints.extraSmall}) {
    margin-right: 20px;
  }
`;

export const TemperatureNow = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 56px;
  color: ${Colors.black};
  margin: 0 0 32px;
  @media (max-width: ${Breakpoints.small}) {
    margin-bottom: 0;
    padding-top: 5px;
  }
`;

export const TemperatureFeelsLike = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const RightBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;

  @media (max-width: ${Breakpoints.medium}) {
    width: 100%;
    margin-left: 72px;
  }

  @media (max-width: ${Breakpoints.small}) {
    margin-left: 0;
    margin-top: 40px;
  }
`;

export const LineWeatherDescription = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  img {
    width: 16px;
    height: 16px;
    margin-right: 9px;
  }
  p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    width: 100%;
    color: ${Colors.darkGray};
    span {
      color: ${Colors.black};
      margin-left: auto;
    }
  }
`;

export const GeoPointNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    line-height: 16px;
    color: ${Colors.green};
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;
