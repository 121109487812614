import styled from 'styled-components';

import { Colors } from '../../../shared/consts/color';

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type MenuItemStyle = {
  isActive: boolean;
};

export const MenuItem = styled.div<MenuItemStyle>`
  background: ${Colors.white};
  width: 100%;
  padding-left: 32px;
  padding-top: 24px;
  padding-bottom: 20px;
  color: ${Colors.black};
  background: ${Colors.white};
  font-size: 14px;
  cursor: pointer;
  ${({ isActive }) => isActive && `color: ${Colors.green}; background: ${Colors.gray}`}
`;
