import { TypeApiRoute } from '../models';

type TypeRequest = {
  id: string;
};

export type QuoteType = {
  id: string;
  culture: {
    id: string;
    name: string;
    icon: any;
  };
  economicRegion: {
    id: string;
    name: string;
  };
  cultureClass?: {
    id: string;
    name: string;
  };
  basis: {
    id: string;
    name: string;
  };
  region: {
    id: string;
    name: string;
  };
  provider: {
    id: string;
    name: string;
  };
  port: {
    id: string;
    name: string;
  };
  currency: {
    id: string;
    name: string;
  };
  units: {
    id: string;
    name: string;
  };
  qualityMin: number;
  qualityMax: number;
  price: number;
  vat: boolean;
  dateOfObs: string;
  tendency: {
    diff: string;
    diffPercent: string;
    direction: 'DESC' | 'ASC';
  };
};

type TypeResponse = QuoteType;

export const getQuoteById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-quotes/quotes/${request.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
