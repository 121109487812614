import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  id: string;
  widget_config?: string;
  type?: string;
  name?: string;
};

type TypeResponse = {
  id: string;
  type: string;
  name: string;
  widget_config: string;
};

export const updateWidgetInfo: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/widgets/${request.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
