import React, { FC, useState, useEffect } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import { Colors } from '../../consts/color';
import { ClearIcon } from '../SelectSearchCustom/style';

import {
  Field,
  Label,
  Placeholder,
  SelectBody,
  SelectBodyInnerWrapper,
  Value,
  Wrapper,
  Option,
  ItemText,
  Error,
} from './style';

type OptionType = {
  label: string;
  value: string;
};

export type SelectProps = {
  label?: string;
  defaultValue?: OptionType;
  placeholder?: string;
  options: OptionType[];
  onChange: (v: string | null) => void;
  error?: string;
  dataTestId?: string;
  isClearAvailable?: boolean;
};

const SelectCustom: FC<SelectProps> = ({
  label,
  placeholder,
  defaultValue,
  options,
  onChange,
  error,
  dataTestId,
  isClearAvailable = false,
}) => {
  const [value, setValue] = useState<OptionType | null>(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isErrorShown, setIsErrorShown] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    defaultValue && setValue(defaultValue);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    error && setIsErrorShown(true);
  }, [error]);

  const handleClick = () => {
    setIsOpened(!isOpened);
    setIsErrorShown(false);
  };

  const handleSelectOption = (item: OptionType) => {
    setValue(item);
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(item.value);
    setIsOpened(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderOptions = (options: OptionType[]) =>
    options &&
    options.map(item => (
      <Option
        data-test-id={`${dataTestId}-option`}
        onClick={() => handleSelectOption(item)}
        key={`option-${item.value}`}
      >
        <ItemText>{item.label}</ItemText>
      </Option>
    ));

  const optionsWithoutSelected = options.filter(item => item.value !== value?.value);

  const handleOnBlur = () => {
    setIsOpened(false);
  };

  const handleClearSelect = e => {
    e.stopPropagation();
    setIsOpened(false);
    setValue(null);
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <Wrapper
      isErrorVisible={isErrorShown}
      tabIndex={0}
      onBlur={handleOnBlur}
      data-test-id={dataTestId}
    >
      {label ? <Label>{label}</Label> : ''}
      <Field onClick={handleClick} isOpened={isOpened} data-test-id={`${dataTestId}-field`}>
        {value ? (
          <Value data-test-id={`${dataTestId}-value`}>{value.label}</Value>
        ) : (
          placeholder && <Placeholder>{placeholder}</Placeholder>
        )}
        {value && isClearAvailable && (
          <ClearIcon
            onClick={handleClearSelect}
            isLabelSet={Boolean(label)}
            data-test-id={`${dataTestId}-clear`}
          >
            <Icon icon={'close'} size={'small'} fill={Colors.darkGray} />
          </ClearIcon>
        )}
        <Icon size={'small'} icon={'arrow_down'} fill={Colors.darkGray} />
      </Field>
      {isOpened && (
        <SelectBody>
          <SelectBodyInnerWrapper>{renderOptions(optionsWithoutSelected)}</SelectBodyInnerWrapper>
        </SelectBody>
      )}
      {isErrorShown && error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default SelectCustom;
