import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 340px;
  height: 80px;
  position: relative;
  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
`;

export const QuoteWrapperContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, 68px);
  grid-gap: 8px;
`;
export const CultureIcon = styled.img`
  width: 50px;
`;
