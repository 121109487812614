import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export const Chip = styled.p`
  color: ${Colors.darkGray};
  padding: 6px 12px;
  border: 0.5px solid ${Colors.grayBorder};
  background: ${Colors.gray};
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  white-space: nowrap;
  display: inline-block;
  margin: 0 0 8px;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  ${Chip} {
    margin-right: 8px;
  }

  ${Chip}:last-child {
    margin-right: 0;
  }

  @media (max-width: ${Breakpoints.medium}) {
    display: block;
  } ;
`;
