import styled from 'styled-components';

import { Colors } from '../../../modules/shared/consts/color';

type ToggleStyledProps = {
  isActive: boolean;
};

const renderState = (isActive: boolean) =>
  isActive
    ? `
    background: ${Colors.green};
    justify-content: flex-end;
    & ${Circle} {
      background: ${Colors.white}
    }
`
    : ` 
    background: ${Colors.grayBorder};
    justify-content: flex-start;
    & ${Circle} {
      background: ${Colors.white}
    }
`;
export const Wrapper = styled.div<ToggleStyledProps>`
  width: 52px !important;
  padding-right: 2px;
  padding-left: 2px;
  height: 32px;
  display: flex;
  flex-shrink: 0;
  transition: 300ms;
  align-items: center;
  ${({ isActive }) => renderState(isActive)};
  border-radius: 16px;
`;

export const Circle = styled.div`
  background: ${Colors.white};
  height: 28px;
  width: 28px;
  border-radius: 16px;
  border: 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
`;
