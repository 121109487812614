import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  id: string;
};

type TypeResponse = {
  parentId: string;
  code: string;
  description: string;
  name: string;
  originalLink: string;
  picLink: string;
  level: number;
  id: string;
  version: number;
  path: string;
  hasChild: boolean;
  attrs: any;
  original: boolean;
};

export const getCultureById: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: req => `/api/da-dictionary/culture/${req.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
