import { useEffect, useState } from 'react';

import { useStore } from '../utils/IoC';
import { SessionStore } from '../../authorization/stores/session.store';

export const useIsAuthorized = () => {
  const store = useStore(SessionStore);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(store.isLoggedIn);
  }, [store.isLoggedIn]);

  return isLoggedIn;
}
