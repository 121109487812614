import { FC, useState } from 'react';
import { Input, Link as LinkFarmik, Select } from '@uralhim-innovative/farmik-ui';

import { FilterType } from '../../stores/organization.users.store';
import { TypeUserRole, TypeUserStatus } from '../../../../../../../../../shared/types/users';
import AddWidget from '../../../../../../../../assets/add-widget.svg';
import ParameterSVG from '../../../../../../../../assets/settings.svg';

import { Parameters } from './style';
import {
  ControlBarWrapper,
  LinkWrapper,
  SearchInputWrapper,
  SelectWrapper,
  BarParametersWrapper,
  MobileWrapper,
} from './style';

type OrgUsersListsControllBaType = {
  filters: FilterType;
  setFilters: (filters: FilterType, organizationId: string) => void;
  showInviteUser: () => void;
  organizationId: string;
};

// const Icon: FC = () => {
//   return (
//     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <g opacity="0.4">
//         <path
//           d="M6 11C8.73529 11 11 8.73529 11 6C11 3.26961 8.73039 1 5.9951 1C3.26471 1 1 3.26961 1 6C1 8.73529 3.26961 11 6 11ZM6 10.1667C3.68627 10.1667 1.83824 8.31373 1.83824 6C1.83824 3.69118 3.68137 1.83333 5.9951 1.83333C8.30882 1.83333 10.1618 3.69118 10.1667 6C10.1716 8.31373 8.31373 10.1667 6 10.1667ZM4.32353 8.07843C4.43137 8.07843 4.53431 8.03431 4.60294 7.95588L5.9951 6.55882L7.39216 7.95588C7.46569 8.02941 7.55882 8.07843 7.67157 8.07843C7.88726 8.07843 8.06373 7.89706 8.06373 7.68137C8.06373 7.56863 8.02451 7.47549 7.94608 7.40196L6.55392 6.0098L7.95098 4.60784C8.03431 4.52451 8.06863 4.44118 8.06863 4.33333C8.06863 4.11275 7.89216 3.94118 7.67647 3.94118C7.57353 3.94118 7.4902 3.97549 7.40686 4.05882L5.9951 5.46078L4.59314 4.06373C4.52451 3.98529 4.43137 3.95098 4.32353 3.95098C4.10784 3.95098 3.93137 4.11765 3.93137 4.33824C3.93137 4.44608 3.97059 4.53922 4.04902 4.61275L5.44118 6.0098L4.04902 7.40686C3.97059 7.47549 3.93137 7.57353 3.93137 7.68137C3.93137 7.89706 4.10784 8.07843 4.32353 8.07843Z"
//           fill="#151F32"
//         />
//       </g>
//     </svg>
//   );
// };

const rolesOptions = [
  {
    label: 'Не выбрано',
    value: '',
  },
  {
    value: 'OWNER',
    label: 'Владелец',
  },
  {
    value: 'EMPLOYEE',
    label: 'Сотрудник',
  },
  {
    value: 'MANAGER',
    label: 'Менеджер',
  },
];

export const statusOptions = [
  {
    label: 'Не выбрано',
    value: '',
  },
  {
    label: 'Активен',
    value: 'ACTIVE',
  },
  {
    label: 'Исключен',
    value: 'FIRED',
  },
];

export const OrgUsersListsControllBar: FC<OrgUsersListsControllBaType> = ({
  filters,
  setFilters,
  showInviteUser,
  organizationId,
}) => {
  const [isSettingBarVisible, setIsSettingBarVisible] = useState(false);
  const handleSearch = (value: string) => {
    if (!value || value.length > 3) {
      setFilters(
        {
          ...filters,
          userName: value || undefined,
        },
        organizationId
      );
    }
  };

  return (
    <ControlBarWrapper>
      <MobileWrapper>
        <SearchInputWrapper>
          <Input
            type="text"
            startIcon="search"
            error=""
            placeholder="Поиск ФИО"
            onChange={handleSearch}
          />
        </SearchInputWrapper>
        <SelectWrapper>
          <Select
            label="Роль"
            placeholder="Роль"
            options={rolesOptions}
            onChange={value =>
              setFilters(
                {
                  ...filters,
                  role: value as TypeUserRole,
                },
                organizationId
              )
            }
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            label="Статус"
            placeholder="Статус"
            options={statusOptions}
            onChange={value =>
              setFilters(
                {
                  ...filters,
                  status: value as TypeUserStatus,
                },
                organizationId
              )
            }
          />
        </SelectWrapper>
        <LinkWrapper isOpen={isSettingBarVisible}>
          <div>
            <LinkFarmik label="Добавить сотрудника" onClick={showInviteUser} color="primary" />
          </div>

          <img style={{ marginLeft: '20px' }} src={AddWidget} onClick={showInviteUser} />
          <Parameters
            src={ParameterSVG}
            onClick={() => setIsSettingBarVisible(!isSettingBarVisible)}
          />
        </LinkWrapper>
      </MobileWrapper>

      {isSettingBarVisible && (
        <BarParametersWrapper>
          <SelectWrapper>
            <Select
              label="Роль"
              placeholder="Роль"
              options={rolesOptions}
              onChange={value =>
                setFilters(
                  {
                    ...filters,
                    role: value as TypeUserRole,
                  },
                  organizationId
                )
              }
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              label="Статус"
              placeholder="Статус"
              options={statusOptions}
              onChange={value =>
                setFilters(
                  {
                    ...filters,
                    status: value as TypeUserStatus,
                  },
                  organizationId
                )
              }
            />
          </SelectWrapper>
        </BarParametersWrapper>
      )}
    </ControlBarWrapper>
  );
};
