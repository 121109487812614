import queryString from 'querystring';

import { FC, useEffect, useState } from 'react';
import { Button, Input, Link } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { WeatherEditWidget } from '../../components/Weather/WeatherEditWidget/WeatherEditWidget';
import { useStore } from '../../../modules/shared/utils/IoC';
import { WidgetWeathersEditStore } from '../../stores/WidgetWeathersEditStore';
import { ProfileRoutes } from '../../../modules/main/modules/dashboard/pages/profile/routes';
import { Modal } from '../../../modules/shared/components/Modal';
import { MapWeather } from '../../components/MapWeather/MapWeather';
import {
  ModalAction,
  ModalButtonWrapper,
  ModalTitle,
} from '../../../modules/main/modules/dashboard/pages/profile/pages/editPersonalData/style';
import { InputWrapper } from '../../../modules/main/modules/dashboard/pages/profile/pages/editPersonalData/components/ChangeEmailWizard/style';

import { WidgetWrapper, CardsWrapper, PageHeader, PageTitle, PageWrapper } from './style';

export const EditWeathersPage: FC = observer(() => {
  const store = useStore(WidgetWeathersEditStore);
  const {
    fetchWidgetInfo,
    name,
    addGeoPoint,
    setCoords,
    clearCoords,
    changeGeoPoint,
    setSelectedWidgetIndex,
    indexWidget,
    clear,
    id,
    changeGeoPointName,
    aliasName,
  } = store;
  const history = useHistory();
  const [isGeoPointModalOpen, setIsGeoPointModalOpen] = useState(false);
  const [isChangeGeoPointModalOpen, setIsChangeGeoPointModalOpen] = useState(false);
  const [isCreateAliasFormShowed, setIsCreateAliasFormShowed] = useState(false);
  const [geoPointName, setGeoPointName] = useState<string | null>(null);

  useEffect(() => {
    const params: any = queryString.parse(location.search.substr(1));

    if (!params.id) {
      history.push(ProfileRoutes.Widgets);
    }

    fetchWidgetInfo(params.id);
  }, []);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);
  const onOpenGeoPointModal = () => {
    setIsGeoPointModalOpen(true);
  };

  const onHideGeoPointModal = () => {
    setIsGeoPointModalOpen(false);
  };

  const getPrecByHour = (hours: any) => {
    const dateNow = new Date();
    return hours[dateNow.getHours()].precType;
  };

  const getAddressName = (address: string) => {
    const strArray = address.split(',');

    return strArray[strArray.length - 1].trim();
  };

  return (
    <PageWrapper>
      <Modal shown={isGeoPointModalOpen} size={'large'} hideModal={onHideGeoPointModal}>
        <MapWeather
          finishForm={() => {
            addGeoPoint();
            onHideGeoPointModal();
            setIsCreateAliasFormShowed(true);
            setSelectedWidgetIndex(store.widgets.length);
          }}
          defaultAddress={''}
          defaultCoords={[]}
          handleChangeCoords={(coords, address) => {
            setCoords(coords[0], coords[1]);
            // if (address && address.trim()) {
            //   setGeoPointName(getAddressName(address));
            // }
          }}
          hideForm={() => {
            onHideGeoPointModal();
            clearCoords();
          }}
        />
      </Modal>
      <Modal
        shown={isChangeGeoPointModalOpen}
        size={'large'}
        hideModal={() => setIsChangeGeoPointModalOpen(false)}
      >
        <MapWeather
          finishForm={() => {
            changeGeoPoint(indexWidget);
            setIsChangeGeoPointModalOpen(false);
            setIsCreateAliasFormShowed(true);
          }}
          defaultAddress={''}
          defaultCoords={[store.coordinatsToAdd.lat, store.coordinatsToAdd.lon]}
          handleChangeCoords={(coords, address) => {
            setCoords(coords[0], coords[1]);
            // if (address && address.trim()) {
            //   setGeoPointName(getAddressName(address));
            // }
          }}
          hideForm={() => {
            setIsChangeGeoPointModalOpen(false);
            clearCoords();
          }}
        />
      </Modal>
      <Modal
        shown={isCreateAliasFormShowed}
        hideModal={() => {
          setIsCreateAliasFormShowed(false);
          setGeoPointName('');
        }}
      >
        <ModalTitle>Название геоточки</ModalTitle>
        <InputWrapper>
          <Input
            dataTestId={'geopoint-input-name'}
            value={
              geoPointName !== null
                ? geoPointName
                : store.config?.positions?.length
                ? store.config?.positions[indexWidget]?.aliasName || ''
                : ''
            }
            onChange={value => {
              setGeoPointName(value);
            }}
          />
        </InputWrapper>
        <ModalAction>
          <ModalButtonWrapper>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                if (geoPointName && geoPointName.trim()) {
                  changeGeoPointName(indexWidget, geoPointName.trim());
                }
                setIsCreateAliasFormShowed(false);
                setGeoPointName('');
              }}
            >
              Применить
            </Button>
          </ModalButtonWrapper>
        </ModalAction>
      </Modal>
      <PageHeader>
        <Link
          color={'default'}
          label={'Назад ко всем виджетам'}
          prefixIcon={'arrow_left'}
          onClick={() => history.push('dashboard/profile/widgets')}
          underlined={true}
        />
        <PageTitle>{name}</PageTitle>
        <Link
          color={'primary'}
          label={'Добавить геоточку'}
          onClick={onOpenGeoPointModal}
          underlined={true}
        />
      </PageHeader>
      <CardsWrapper>
        {store.widgets.length > 0 &&
          store.widgets.map((widget, index) => (
            <WidgetWrapper key={widget.linkId}>
              <WeatherEditWidget
                onClick={() =>
                  history.push(
                    `${ProfileRoutes.FullEditWeatherWidgets}?id=${id}&point=${widget.linkId}`
                  )
                }
                isBottomVisible={true}
                condition={widget.fact?.condition}
                dew={widget.fact?.dewPoint && widget.fact?.dewPoint.toFixed(0)}
                geoPoint={
                  (store.config?.positions &&
                    store.config?.positions[index] &&
                    store.config?.positions[index]?.aliasName) ||
                  (widget.geoObject.district && widget.geoObject.district.name) ||
                  (widget.geoObject.locality && widget.geoObject.locality.name) ||
                  (widget.geoObject.province && widget.geoObject.province.name) ||
                  (widget.geoObject.country && widget.geoObject.country.name) ||
                  'Неопределено'
                }
                humidity={widget.fact?.humidity}
                rain={getPrecByHour(widget.forecasts[0].hours)}
                sunrise={widget.forecasts[0].sunrise}
                sunset={widget.forecasts[0].sunset}
                temperature={widget.fact?.temp}
                wind={widget.fact?.windSpeed}
                isActive={
                  store.config?.positions && store.config?.positions[index]
                    ? store.config?.positions[index].isActive
                    : false
                }
                toggleGetPoint={() => store.toggleGetPoint(index)}
                deletePoint={() => store.deleteGeoPoint(index)}
                changeGeoPoint={() => {
                  setSelectedWidgetIndex(index);
                  setIsChangeGeoPointModalOpen(true);
                  const coords = store.config?.positions[index];
                  if (!coords) {
                    return;
                  }
                  setCoords(coords.lat, coords.lon);
                }}
                time={widget.fact?.obsTime}
              />
            </WidgetWrapper>
          ))}
      </CardsWrapper>
    </PageWrapper>
  );
});
