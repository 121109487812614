import {FC} from "react";
import {PanelWrapper} from "./style";
import {observer} from "mobx-react";
import {useStore} from "../../utils/IoC";
import {AdminViewStore} from "../../../admin-view/AdminViewStore";
import {Button} from "@uralhim-innovative/farmik-ui";

export const AdminPanel: FC = observer(() => {

  const handleClick = () => {
    localStorage.removeItem('loginAs');
    window.close();
  }

  if (!localStorage.getItem('loginAs')) {
    return null;
  }

  return (
    <PanelWrapper>
      <p>Админка</p>
      <div>
        <Button color={'primary'} type={'button'} onClick={handleClick}>Выйти</Button>
      </div>

    </PanelWrapper>
  )
})