import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  width: 224px;
  height: 232px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;

  &:hover {
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
  }
  transition: 300ms;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
    white-space: nowrap;
    padding: 12px;
    height: 120px;
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  min-height: 32px;
  color: ${Colors.black};
  margin-top: 12px;
  margin-bottom: 8px;
  text-align: center;

  @media (max-width: ${Breakpoints.small}) {
    display: inline-block;
    white-space: normal;
    margin: 0 0 0 10px;
    font-size: 14px;
    vertical-align: middle;
    text-align: left;
    min-height: 0;
  }
`;

export const Tag = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  background: ${Colors.gray};
  border-radius: 8px;
  margin: 0;
  padding: 4px 12px;
  height: 24px;
  box-sizing: border-box;
  border: 0.5px solid ${Colors.grayBorder};
`;

export const TagsWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & ${Tag} {
    margin: 4px;
  }
`;
