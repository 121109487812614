import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps, withRouter, useHistory } from 'react-router-dom';

import { useStore } from '../../../shared/utils/IoC';
import { SessionStore } from '../../stores/session.store';
import { LoadingMockScreen } from '../../components/LoadingMockScreen';

type ExternalRedirectProps = {
  redirectTo: string;
};

export const ExternalRedirect: FC<ExternalRedirectProps> = ({ redirectTo }) => {
  useEffect(() => {
    location.replace(redirectTo);
  }, []);

  return <div>загрузка</div>;
};

type AuthorizationWrapperProps = {
  excludedRoutes: Array<string>;
  redirectTo: string;
  isZoneForAuthorized: boolean;
  children: any;
} & RouteComponentProps;

export const AuthorizationWrapper = withRouter(
  observer(
    ({
      children,
      location,
      isZoneForAuthorized,
      redirectTo,
      excludedRoutes,
    }: AuthorizationWrapperProps) => {
      const { isLoading, isLoggedIn, checkIsLoggedIn } = useStore(SessionStore);
      const {
        location: { pathname, search },
      } = useHistory();

      useEffect(() => {
        if (!isLoggedIn) {
          const searchParams = new URLSearchParams(search.slice(1));

          checkIsLoggedIn(searchParams.get('authKey'));
        }
      }, [search, isLoggedIn]);

      const isRouteExcluded = () => {
        const matches = excludedRoutes.filter(item => pathname.includes(item));
        return matches.length > 0;
      };

      if (isLoading) {
        return <LoadingMockScreen />;
      }
      if (isZoneForAuthorized) {
        if (isLoggedIn || isRouteExcluded()) {
          return children;
        }
        return <ExternalRedirect redirectTo={redirectTo} />;
      }

      if (!isLoggedIn || isRouteExcluded()) {
        return children;
      }

      return <ExternalRedirect redirectTo={redirectTo} />;
    }
  )
);
