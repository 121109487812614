import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  id: string;
};

type TypeResponse = boolean;

export const getExpertiseStatus: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => `/api/da-profile/userExpertise/confirm/${req.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
