import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  systemId: string;
  redirectUrl: string;
  scope: string;
};

type TypeResponse = {};

export const externalRequestsRedirect: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/externalRequests/redirect`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
