import { FC, useState, useContext } from 'react';
import {
  Input,
  Link as LinkFarmik,
  MultiSelect,
  Icon as IconFarmik,
} from '@uralhim-innovative/farmik-ui';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

import { RootStoresContext } from '../../../../../../../../../App';
import { OrganizationalRoutes } from '../../OrganizationalRoutes';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { OrganizationListingStore } from '../../stores/organization.listing.store';
import { EmployeeRole } from '../../types/employeeRole';
import FiltersSvg from '../../../../../../../../../shared/assets/images/options.svg';

import {
  ControlBarWrapper,
  LinkWrapper,
  Role,
  RoleWrapper,
  SearchInputWrapper,
  SelectWrapper,
  MobileOptionsWrapper,
  ControlBarInner,
  Spacer,
} from './style';

const Icon: FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        d="M6 11C8.73529 11 11 8.73529 11 6C11 3.26961 8.73039 1 5.9951 1C3.26471 1 1 3.26961 1 6C1 8.73529 3.26961 11 6 11ZM6 10.1667C3.68627 10.1667 1.83824 8.31373 1.83824 6C1.83824 3.69118 3.68137 1.83333 5.9951 1.83333C8.30882 1.83333 10.1618 3.69118 10.1667 6C10.1716 8.31373 8.31373 10.1667 6 10.1667ZM4.32353 8.07843C4.43137 8.07843 4.53431 8.03431 4.60294 7.95588L5.9951 6.55882L7.39216 7.95588C7.46569 8.02941 7.55882 8.07843 7.67157 8.07843C7.88726 8.07843 8.06373 7.89706 8.06373 7.68137C8.06373 7.56863 8.02451 7.47549 7.94608 7.40196L6.55392 6.0098L7.95098 4.60784C8.03431 4.52451 8.06863 4.44118 8.06863 4.33333C8.06863 4.11275 7.89216 3.94118 7.67647 3.94118C7.57353 3.94118 7.4902 3.97549 7.40686 4.05882L5.9951 5.46078L4.59314 4.06373C4.52451 3.98529 4.43137 3.95098 4.32353 3.95098C4.10784 3.95098 3.93137 4.11765 3.93137 4.33824C3.93137 4.44608 3.97059 4.53922 4.04902 4.61275L5.44118 6.0098L4.04902 7.40686C3.97059 7.47549 3.93137 7.57353 3.93137 7.68137C3.93137 7.89706 4.10784 8.07843 4.32353 8.07843Z"
        fill="#151F32"
      />
    </g>
  </svg>
);

export const MyListsControlBar = withRouter(
  observer(({ history }: RouteComponentProps) => {
    const { uiStateStore } = useContext(RootStoresContext);
    const { deviceWidth } = uiStateStore;

    const store = useStore(OrganizationListingStore);
    const [shownMobileOptions, setShownMobileOptions] = useState(false);

    const { searchQuery, setSearchQuery, setTags, selectedTags } = store;
    const handleLinkClick = () => {
      history.push(OrganizationalRoutes.search);
    };

    const idToTag = (v: EmployeeRole) => {
      const variants: Record<EmployeeRole, string> = {
        OWNER: 'Владелец',
        MANAGER: 'Менеджер',
        EMPLOYEE: 'Сотрудник',
      };
      return variants[v];
    };

    return (
      <>
        <Spacer />
        <ControlBarWrapper>
          <ControlBarInner>
            <SearchInputWrapper>
              <Input
                type="text"
                onChange={setSearchQuery}
                value={searchQuery}
                startIcon="search"
                error=""
                placeholder={deviceWidth < 375 ? 'Поиск' : 'Поиск по названию или ИНН'}
              />
            </SearchInputWrapper>
            <SelectWrapper>
              <MultiSelect
                label="Роль"
                defaultValue={selectedTags.map(i => ({ value: i, label: '' }))}
                options={[
                  { label: 'Владелец', value: 'OWNER' },
                  { label: 'Сотрудник', value: 'EMPLOYEE' },
                  { label: 'Менеджер', value: 'MANAGER' },
                ]}
                onChange={setTags}
              />
            </SelectWrapper>

            <LinkWrapper>
              <LinkFarmik
                label="Присоединиться к организации"
                onClick={handleLinkClick}
                color="primary"
              />
            </LinkWrapper>

            <LinkWrapper hidden={shownMobileOptions} isMobile={true} style={{ opacity: '0.5' }}>
              <IconFarmik onClick={handleLinkClick} icon="add_widget" size="large" />
            </LinkWrapper>

            <LinkWrapper hidden={shownMobileOptions} isMobile={true} className="options">
              <img
                src={FiltersSvg}
                alt=""
                onClick={() => setShownMobileOptions(!shownMobileOptions)}
              />
            </LinkWrapper>

            <LinkWrapper
              hidden={!shownMobileOptions}
              isMobile={true}
              className="options"
              style={{ opacity: '0.5' }}
            >
              <IconFarmik
                onClick={() => setShownMobileOptions(!shownMobileOptions)}
                icon="close"
                size="small"
              />
            </LinkWrapper>
          </ControlBarInner>

          <MobileOptionsWrapper shownMobileOptions={shownMobileOptions}>
            <SelectWrapper isMobile={true}>
              <MultiSelect
                label="Роль"
                defaultValue={selectedTags.map(i => ({ value: i, label: '' }))}
                options={[
                  { label: 'Владелец', value: 'OWNER' },
                  { label: 'Сотрудник', value: 'EMPLOYEE' },
                  { label: 'Менеджер', value: 'MANAGER' },
                ]}
                onChange={setTags}
              />
            </SelectWrapper>
          </MobileOptionsWrapper>
        </ControlBarWrapper>
        {selectedTags.length > 0 ? (
          <RoleWrapper style={{ marginTop: 15, marginLeft: 0 }}>
            {selectedTags.map(item => (
              <Role onClick={() => setTags(selectedTags.filter(i => i !== item))}>
                <p>{idToTag(item as EmployeeRole)}</p>
                <Icon />
              </Role>
            ))}
          </RoleWrapper>
        ) : (
          ''
        )}
        {deviceWidth < 768 ? (
          <RoleWrapper
            isMobile={true}
            style={{ marginLeft: 16, paddingTop: 24 }}
          >
            {selectedTags.map(item => (
              <Role onClick={() => setTags(selectedTags.filter(i => i !== item))}>
                <p>{idToTag(item as EmployeeRole)}</p>
                <Icon />
              </Role>
            ))}
          </RoleWrapper>
        ) : (
          ''
        )}
      </>
    );
  })
);
