import { FC } from 'react';

import { PaperWrapper } from './style';

export type PaperBlockProps = {
  hoverable?: boolean;
  size?: any;
};

export const PaperBlock: FC<PaperBlockProps> = ({ children, hoverable = false }) => (
  <PaperWrapper hoverable={hoverable}>{children}</PaperWrapper>
);
