import { FC, useState } from 'react';
import { Input, Icon } from '@uralhim-innovative/farmik-ui';

import {
  MultiSelectWithSearch,
  Option,
} from '../../../../../shared/components/MultiSelectWithSearch';
import { TypeTag } from '../../types/type-tag';
import { Colors } from '../../../../../shared/consts/color';
import FiltersSvg from '../../../../../shared/assets/images/options.svg';

import {
  Wrapper,
  ControlWrapper,
  HiddenOnMobileControls,
  MobileControlAction,
  MobileControlCloseAction,
  Spacer,
} from './style';

export type NewsControlProps = {
  onSearchInArticle: (v: string) => void;
  onSearchInTags: (v: string) => void;
  options: Array<TypeTag>;
  onChange: (v: Array<any>) => void;
  articleSearchQuery: string;
  selectedTagIds: string[];
  idToTags: { [key: string]: TypeTag };
  isTopBarVisible: boolean;
};

export const NewsControl: FC<NewsControlProps> = ({
  options,
  onSearchInArticle,
  onSearchInTags,
  onChange,
  isTopBarVisible,
  articleSearchQuery,
  selectedTagIds,
  idToTags,
}) => {
  const [active, setActive] = useState(false);

  const getSelectedOptions: Array<Option> = selectedTagIds.map(
    id =>
      ({
        value: id,
        label: idToTags[id] ? idToTags[id].tagName : '',
      } as Option)
  );

  return (
    <>
      <Spacer isVisible={!isTopBarVisible} />
      <Wrapper isFixed={false}>
        <ControlWrapper>
          <Input
            startIcon={'search'}
            placeholder={'Поиск по статьям'}
            onChange={onSearchInArticle}
            dataTestId="search-news-button"
            value={articleSearchQuery}
          />
        </ControlWrapper>
        <HiddenOnMobileControls active={active}>
          <ControlWrapper>
            <MultiSelectWithSearch
              onChange={onChange}
              placeholder={'Теги'}
              onSearch={onSearchInTags}
              options={options.map(item => ({
                value: item.tagId,
                label: item.tagName,
              }))}
              selected={getSelectedOptions}
            />
          </ControlWrapper>
          <MobileControlCloseAction
            onClick={() => {
              setActive(false);
            }}
          >
            <Icon size="small" icon="close" fill={Colors.darkGray} />
          </MobileControlCloseAction>
        </HiddenOnMobileControls>
        <MobileControlAction
          onClick={() => {
            setActive(!active);
          }}
        >
          <img src={FiltersSvg} alt="" />
        </MobileControlAction>
      </Wrapper>
    </>
  );
};
