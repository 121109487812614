import styled from 'styled-components';

import { Breakpoints } from '../../../../../../../shared/consts/sizes';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.medium}) {
    display: block;
  }
`;
