import { action, makeAutoObservable, observable } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { CultureResponseModel, EntityType } from '../types/culture.response.model';
import { Node } from '../components/Breadcrumbs';
import { Axios2, TypeApiResponse } from '../../../../shared/utils/axios2';

@provide.singleton()
export class CulturesStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  idToCulture: Map<string, CultureResponseModel> = new Map();

  get cultures() {
    return Array.from(this.idToCulture.values());
  }

  currentPage = 0;
  size = 32;
  totalPage = 1;
  isLoading = true;
  isAdditionalLoading = false;
  lastOperation: 'load' | 'additional_load' = 'load';

  get isAdditionalLoadVisible() {
    return this.currentPage !== this.totalPage;
  }

  // get isInitialLoad() {
  //   return this.breadcrumbsUrl === '/';
  // }

  parentId = '';

  fetchAdditionalLoadCultures = () => {
    this.currentPage += 1;
    this.isAdditionalLoading = true;
    this.lastOperation = 'additional_load';
    const requestBody = {
      pathEquals: this.breadcrumbsUrl,
    };

    this.axios2.api
      .getCultures(requestBody)
      .then(this.onFetchAdditionalSuccess)
      .catch(this.onFetchAdditionalError);
  };

  protected onFetchAdditionalSuccess = (response: TypeApiResponse<'getCultures'>) => {
    this.isAdditionalLoading = false;
    if (this.lastOperation !== 'additional_load') {
      return;
    }
    this.totalPage = response.totalPages;
    response.content.forEach(culture => this.idToCulture.set(culture.id, culture));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onFetchAdditionalError = (response: any) => {
    this.isAdditionalLoading = false;
  };

  fetchCultures = () => {
    this.isLoading = true;
    this.lastOperation = 'load';

    let requestBody = {};

    if (this.searchQuery) {
      requestBody = {
        latestVersion: true,
        nameFilter: this.searchQuery,
      };
    } else if (this.parentId) {
      requestBody = {
        parentId: this.parentId,
        latestVersion: true,
      };
    } else {
      requestBody = {
        level: 1,
        latestVersion: true,
      };
    }

    this.axios2.api
      .getCultures(requestBody)
      .then(this.onFetchCulturesSuccess)
      .catch(this.onFetchCulturesError);
  };

  protected onFetchCulturesSuccess = (response: TypeApiResponse<'getCultures'>) => {
    this.isLoading = false;
    if (this.lastOperation !== 'load') {
      return;
    }
    this.idToCulture.clear();
    this.totalPage = response.totalPages;
    response.content.forEach(culture => this.idToCulture.set(culture.id, culture));
  };

  protected onFetchCulturesError = () => {
    this.isLoading = false;
    this.isAdditionalLoading = false;
  };

  @observable
  searchQuery = '';

  @action
  setSearchQuery = (v: string) => {
    console.log(v);
    this.searchQuery = v;
    this.resetAll();
    this.parentId = '';
    this.breadcrumbs = [];

    this.fetchCultures();
  };

  breadcrumbs: Array<Node> = [];
  entity: EntityType | null = null;

  get breadcrumbsUrl() {
    return `/${this.breadcrumbs.map(crumb => crumb.code).join('/')}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeLevel = (id: string, chainIndex?: number) => {
    if (id) {
      this.axios2.api
        .getCulturesBreadcrumbs({ id }, { omit: ['id'] })
        .then(this.onChangeLevelSuccess)
        .catch(this.onChangeLevelError);

      this.searchQuery = '';
      this.parentId = id;
      this.resetLevel();
      this.resetPagination();
      this.fetchCultures();
    } else {
      this.breadcrumbs = [];
      this.entity = null;
      this.searchQuery = '';
      this.parentId = '';
      this.resetLevel();
      this.resetPagination();
      this.fetchCultures();
    }
    this.searchQuery = '';
  };

  onChangeLevelSuccess = (response: TypeApiResponse<'getCulturesBreadcrumbs'>) => {
    this.breadcrumbs = response.crumbs.map((i: any) => ({
      avatar: i.picLink ? i.picLink.downloadUrl : '',
      name: i.name,
      code: i.code,
      id: i.id,
    }));

    this.entity = response.entity as EntityType;

    this.breadcrumbs = [
      ...this.breadcrumbs,
      {
        id: response.culture.id,
        code: response.culture.code,
        name: response.culture.name,
        avatar: response.culture.picLink ? response.culture.picLink.downloadUrl : '',
      },
    ];
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeLevelError = (response: any) => false;

  protected resetLevel = () => {
    this.idToCulture.clear();
  };

  protected resetPagination = () => {
    this.currentPage = 1;
    this.size = 0;
    this.totalPage = 1;
  };

  @action
  resetAll = () => {
    this.breadcrumbs = [];
    this.parentId = '';
    this.idToCulture.clear();
    this.entity = null;
    this.resetLevel();
    this.resetPagination();
  };

  @action
  reset = () => {
    this.resetAll();
    this.searchQuery = '';
  };
}
