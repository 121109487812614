import { ReactNode } from 'react';
import { makeAutoObservable } from 'mobx';

import { provide } from '../../modules/shared/utils/IoC';

import { RootStore } from './rootStore';

type OptionsType = {
  height?: string;
};

@provide.singleton()
export class HeaderComponentStore {
  root: RootStore;

  options: OptionsType = {
    height: '76px',
  };

  constructor(root: RootStore) {
    makeAutoObservable(this);

    this.root = root;
  }

  headerContent: ReactNode | null = null;

  setHeaderContent = (node: ReactNode | null, options?: OptionsType) => {
    this.headerContent = node;

    if (options) {
      this.options = {
        ...this.options,
        ...options,
      };
    }
  };
}
