import { TypeApiRoute } from '../models';

type TypeRequest = {
  serviceName: string;
  ownerId: string;
  fileName: string;
  fileSize: number;
  fileContentType: string;
};

type TypeResponse = { id: string; uploadUrl: string };

export const uploadFile: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/files/upload`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
