import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/consts/color';
import { BorderRadius, Breakpoints } from '../../../../../../../shared/consts/sizes';
import { PaperWrapper } from '../../../../../../../shared/components/PaperBlock/style';

type MainPageWidgetWrapperType = {
  isKingSize: boolean;
  filled: boolean;
};

const borderDashedSvg = (color: string) =>
  `url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect rx='15' width='100%' height='100%' style='fill: none; stroke: ${color}; stroke-width: 2; stroke-dasharray: 17 12'/></svg>")`.replace(
    '#',
    '%23'
  );

export const MainPageWidgetsGroup = styled.div`
  display: grid;
  grid-template-columns: 380px 292px 292px;
  grid-gap: 20px;
  padding: 30px;
  justify-content: space-around;
`;

export const MainPageWidgetWrapper = styled.div<MainPageWidgetWrapperType>`
  background-color: ${Colors.lightGray};
  background-image: ${({ filled }) => borderDashedSvg(filled ? Colors.green : Colors.grayBorder)};
  position: relative;
  border-radius: ${BorderRadius.default};

  .mainPageInner {
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .mainPageWidgetSubIcon {
    position: absolute;
    bottom: 24px;
    right: 24px;
    cursor: pointer;
  }
`;

export const MainPageWidgetTitle = styled.div`
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  white-space: nowrap;
  font-size: 16px;
`;

export const MainPageWidgetSubTitle = styled.div`
  max-width: 220px;
  text-align: center;
  color: ${Colors.darkGray};
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditMainPageWidgetsActionPanel = styled(PaperWrapper)`
  padding: 12px;
  margin-top: 30px;
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 164px;
    justify-content: center;
  }

  input {
    width: 270px;
  }
`;

export const GeoPointsWrapper = styled.div<{ dark?: boolean }>`
  display: flex;
  position: absolute;
  left: ${({ dark }) => (dark ? '1px' : '20px')};
  bottom: ${({ dark }) => (dark ? '1px' : '10px')};
  z-index: 2;
  align-items: center;

  @media (max-width: ${Breakpoints.small}) {
    left: auto;
    right: ${({ dark }) => (dark ? '0' : '16px')};
    bottom: ${({ dark }) => (dark ? '1px' : '10px')};
  }

  button {
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    display: inline-block;

    @media (max-width: ${Breakpoints.small}) {
      margin-left: 8px;
      width: 20px;
      height: 20px;
    }
  }

  .carousel__dot-group button {
    border-radius: 50%;
    background: ${({ dark }) => (dark ? Colors.darkGray : '#fff')};
    margin-right: 12px;
    width: 8px;
    height: 8px;
    opacity: 0.5;
    flex: 0 0 8px;
  }

  .carousel__dot-group button.carousel__dot--selected {
    opacity: 1;
  }
`;

export const GeoPointWrapper = styled.div`
  max-width: 110px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  .carousel__dot-group {
    display: flex;
    align-items: center;
    margin: 0 12px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  .carousel__dot-group::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const ArrowButton = styled.div<{ dark?: boolean }>`
  padding: 11px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  background: ${({ dark }) => (dark ? '#fff' : 'rgba(255, 255, 255, 0.24)')};
  backdrop-filter: ${({ dark }) => (dark ? 'none' : 'blur(8px)')};
  box-shadow: ${({ dark }) => (dark ? '0px 0px 3px rgb(0 0 0 / 10%)' : 'none')};
  width: 32px;
  height: 32px;

  &:last-child {
    margin-left: 0;
  }

  @media (max-width: ${Breakpoints.small}) {
    padding: 5px;
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }
`;

export const CarouselWrapperSmall = styled.div`
  outline: none;
  * {
    outline: none;
    user-select: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  *::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  overflow: hidden;
  li {
    outline: none;
    width: 340px !important;
  }

  .carousel__inner-slide:focus,
  .carousel__inner-slide {
    outline: none !important;
    height: 82px;
  }
  ul {
    display: flex;
    flex-direction: row;
  }
  height: 82px;
  overflow: hidden;
  position: relative;
`;

export const CarouselWrapper = styled.div`
  outline: none;
  * {
    outline: none !important;
  }
  border-radius: 16px;
  overflow: hidden;
  li {
    outline: none;
    padding-bottom: 260px !important;

    @media (max-width: ${Breakpoints.small}) {
      padding-bottom: 110px !important;
    }
  }

  .carousel__inner-slide:focus,
  .carousel__inner-slide {
    outline: none !important;
    height: 260px;
  }
  position: relative;
`;

export const EmptyWidgetWrapper = styled.div`
  background-color: ${Colors.lightGray};
  background-image: ${() => borderDashedSvg(Colors.grayBorder)};
  position: relative;
  border-radius: ${BorderRadius.default};
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${Breakpoints.small}) {
    height: 140px;
  }
`;

export const WidgetDeleteButton = styled.div`
  position: absolute;
  bottom: 14px;
  right: 20px;
  cursor: pointer;
`;

export const WidgetListWrapper = styled.div`
  display: grid;
  grid-template-columns: 340px 340px 340px;
  grid-template-rows: repeat(auto-fill, 290px);
  grid-gap: 20px;
  margin-top: 20px;
  vertical-align: top;
  overflow-y: auto;
  height: 286px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const WeathrWidgetGroupWrapper = styled.div<{ opacity: number; isActive: boolean }>`
  opacity: ${({ opacity }) => opacity};
  width: 340px;
  height: 286px;
  overflow: hidden;
  filter: grayscale(${({ isActive }) => (!isActive ? '100%' : '0%')});
`;
