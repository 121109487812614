import { action, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../modules/shared/utils/IoC';
import { Axios2 } from '../modules/shared/utils/axios2';

@provide.singleton()
export class AddWidgetFormStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  name = '';
  type = 'WEATHER';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setName = (name: string) => {
    this.name = name;
  };

  @action
  setType = (type: string) => {
    this.type = type;
  };

  @action
  createWidget = async () => {
    await this.axios2.api.createWidget({
      name: this.name,
      type: this.type,
      widget_config: JSON.stringify(''),
    });
  };

  @action
  clear = () => {
    this.name = '';
    this.type = 'WEATHER';
  };
}
