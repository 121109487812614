import { TypeApiRoute } from '../models/TypeApiRoute';

type Crumb = {
  id: string;
  code: string;
  name: string;
  picLink?: string | null;
};

type TypeRequest = {
  id: string;
};

type TypeResponse = {
  culture: {
    id: string;
    code: string;
    name: string;
    path: string;
    level: number;
    version: number;
    hasChild: boolean;
    original: boolean;
    description: string;
    originalLink: string;
    parentId?: string;
    picLink?: {
      downloadUrl: string;
      fileContentType: string;
      fileName: string;
      fileSize: number;
      id: string;
    };
  };
  crumbs: Array<Crumb>;
  entity: {
    code: string;
    description: string;
    hasChild: boolean;
    id: string;
    level: number;
    name: string;
    original: boolean;
    originalLink: string;
    parentId: string;
    path: string;
    version: number;
  };
};

export const getCulturesBreadcrumbs: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-dictionary/dictionary/culture/breadcrumbs/${request.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
