import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TypeUserRole, TypeUserStatus, TypeUser } from '../../../../../../../../shared/types/users';
import { Axios2 } from '../../../../../../../../shared/utils/axios2';

export type TableTypeUser = {
  status: TypeUserStatus;
  userName: string;
  role: TypeUserRole;
  organizationJoinDate: string;
  employeeId: string;
};

export type FilterType = {
  status?: TypeUserStatus | undefined;
  userName?: string | undefined;
  role?: TypeUserRole | undefined;
  organizationJoinDate?: string | undefined;
};

@provide.singleton()
export class OrganizationUsersStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  tableUsersList: TableTypeUser[] = [];
  emptyListMessage = '';
  chosenUserId = '';

  onGetOrganizationUsersListError = () => {
    this.emptyListMessage = 'Не удалось загрузить список сотрудников';
  };

  normalizeUsersListForTable = (usersList: TypeUser[]) => {
    this.tableUsersList = usersList.map(user => {
      const organizationJoinDate = user.organizationJoinDate.slice(0, 10);
      const [year, month, day] = organizationJoinDate.split('-');

      return {
        status: user.status,
        userName: user.userName,
        role: user.role,
        organizationJoinDate: `${day}.${month}.${year}`,
        employeeId: user.employeeId,
      };
    });
  };

  openEditUserForm = (employeeId: string) => {
    this.chosenUserId = employeeId;
  };

  closeEditUserForm = () => {
    this.chosenUserId = '';
  };

  getUserById = ({ organizationId }: { organizationId: string }) =>
    this.axios2.api.getOrganizationUser(
      { userId: this.chosenUserId, organizationId },
      { omit: ['userId', 'organizationId'] }
    );
}
