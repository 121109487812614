import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  search: Array<string>;
};

type TypeResponse = Array<{
  id: string;
  type: string;
  content: string;
}>;

export const getActiveBanners: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-notification-dispatcher/banners/active`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
