import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeCategory } from '../modules/shared/types/service';
import { TypeResponsePageable } from '../models/TypeResponsePageable';

type TypeRequest = {};

type TypeResponse = {
  content: Array<TypeCategory>;
} & TypeResponsePageable;

export const getDictServiceCategories: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/services/category/list`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-services',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
