import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeCommentRoot } from '../modules/shared/types/comments';

type TypeRequest = {
  newsId: string;
  page: number;
  size: number;
};

type TypeResponse = {
  comments: Array<TypeCommentRoot>;
  lastPage: boolean;
};

export const getNewsComments: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-news/news/comments/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
