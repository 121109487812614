import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';

export const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  padding: 12px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;
