import { FC, useState } from 'react';
import { Button, Input } from '@uralhim-innovative/farmik-ui';

import { ModalAction, ModalButtonWrapper, ModalDescription, ModalTitle } from '../../style';
import phoneImg from '../../../../../../../../../shared/assets/images/phone.png';
import { checkIsEmail } from '../../../../../../../../../shared/utils/checkIsEmail';

import { InputWrapper, Image } from './style';

type Props = {
  email: string;
  setChangeEmailModal: (val: boolean) => void;
  confirmEmail: (val: string) => Promise<any>;
};

export const ChangeEmailWizard: FC<Props> = ({ setChangeEmailModal, email, confirmEmail }) => {
  const [emailInput, setEmailInput] = useState(email);
  const [emailErrors, setEmailError] = useState('');
  const [checkSuccess, setCheckSuccess] = useState(false);

  const handleConfirmEmail = () => {
    if (checkIsEmail(emailInput)) {
      confirmEmail(emailInput)
        .then(() => {
          setCheckSuccess(true);
        })
        .catch(() => {
          setEmailError('Произошла ошибка');
        });
    } else {
      setEmailError('Указан некорректный email');
    }
  };

  const handleChangeEmailInput = (value: string) => {
    setEmailError('');
    setEmailInput(value);
  };

  if (checkSuccess) {
    return (
      <>
        <Image src={phoneImg} alt="" />
        <ModalDescription>Ссылка на подтверждение отправлена на {emailInput}</ModalDescription>
        <ModalButtonWrapper>
          <Button
            data-test-id={'profile-edit-email-approve'}
            type="button"
            color="primary"
            onClick={() => {
              setChangeEmailModal(false);
            }}
          >
            Хорошо
          </Button>
        </ModalButtonWrapper>
      </>
    );
  }

  return (
    <>
      <ModalTitle>Изменение email</ModalTitle>
      <ModalDescription>
        На указанный Вами email придет ссылка для подтверждения почты
      </ModalDescription>
      <InputWrapper>
        <Input
          data-test-id={'profile-edit-email-input-modal'}
          label="Email"
          error={emailErrors}
          value={emailInput}
          onChange={handleChangeEmailInput}
        />
      </InputWrapper>
      <ModalAction>
        <ModalButtonWrapper>
          <Button
            data-test-id={'profile-edit-email-continue'}
            disabled={!emailInput.length}
            type="button"
            color="primary"
            onClick={handleConfirmEmail}
          >
            Продолжить
          </Button>
        </ModalButtonWrapper>
        <ModalButtonWrapper>
          <Button
            type="button"
            color="default"
            data-test-id={'profile-edit-email-cancel'}
            onClick={() => {
              setChangeEmailModal(false);
            }}
          >
            Отмена
          </Button>
        </ModalButtonWrapper>
      </ModalAction>
    </>
  );
};
