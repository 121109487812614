import styled from 'styled-components';

import { Colors } from '../../../../../../shared/consts/color';

export const CommentAvatar = styled.div`
  height: 48px;
  width: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
`;

export const CommentAvatarImage = styled.img`
  width: 48px;
`;

export const CommentDeleteIconWrapper = styled.div`
  margin-left: 5px;
  border: 1px solid ${Colors.darkGray};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;

  svg {
    width: 6px;
    height: 6px;
  }
`;

export const CommentDelete = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${Colors.darkGray};
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  line-height: 16px;
  display: flex;

  &:hover {
    color: ${Colors.red};

    ${CommentDeleteIconWrapper} {
      border-color: ${Colors.red};

      svg path {
        fill: ${Colors.red};
      }
    }
  }
`;

export const CommentContent = styled.div`
  margin-left: 16px;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  position: relative;
`;

export const CommentAnswers = styled.div``;

export const CommentMeta = styled.div`
  display: flex;
  flex-flow: row nowrap;
  line-height: 16px;
  position: relative;
  padding-bottom: 16px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
`;

export const CommentItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  padding: 16px 0 0 0;
`;

export const CommentAuthor = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
`;

export const CommentText = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding: 4px 0;
  width: 100%;
  word-break: break-word;

  > p:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }
`;

export const CommentDate = styled.div`
  font-size: 12px;
  color: ${Colors.darkGray};
  margin-right: 20px;
`;

export const CommentAnswerButton = styled.div`
  font-size: 14px;
  color: ${Colors.green};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${Colors.greenHover};
  }
`;

export const CommentLikes = styled.div<{ likedByMe: boolean }>`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  color: ${Colors.darkGray};
  font-size: 12px;
  line-height: 12px;
  padding: 5px;

  img {
    margin-right: 5px;
  }

  ${({ likedByMe }) =>
    likedByMe &&
    `
  color: ${Colors.red};
  `})
`;

export const NextCommentsButton = styled.div`
  color: ${Colors.green};
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 16px;
  white-space: nowrap;

  &:hover {
    color: ${Colors.greenHover};
  }
`;
