import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeNews } from '../modules/main/modules/news/types/type-news';

type TypeRequest = {
  userId: string;
};

type TypeResponse = string;

export const createTopic: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-cnm/websocket/topic/create/${request.userId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
