import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button } from '@uralhim-innovative/farmik-ui';

import { NewsArticle } from '../../modules/news/components/NewsArticle';
import { useStore } from '../../../shared/utils/IoC';
import { NewsPageStore } from '../../modules/news/stores/NewsPageStore';
import { MainRoutes } from '../../routes';
import { UserInfo } from '../../modules/news/components/UserInfo';
import VK_LOGO from '../../assets/vk.svg';
import GOOGLE_LOGO from '../../assets/google.svg';
import FB_LOGO from '../../assets/fb.svg';
import { getAdminUrl } from '../../../shared/utils/getAdminLink';
import { SessionStore } from '../../../authorization/stores/session.store';
import { ApplicationRoutes } from '../../../routes';

import {
  Wrapper,
  BarWrapper,
  NewsWrapper,
  RightBar,
  PublicationDate,
  SocialBar,
  SocialNetworkLogo,
  SocialNetworksWrapper,
  ButtonWrapper,
} from './style';

export const News: FC = observer(() => {
  const store = useStore(NewsPageStore);
  const history = useHistory();
  const { fetchNewsArticle, resetNewsArticle, newsArticle, isNewsArticleLoading } = store;
  const sessionStore = useStore(SessionStore);

  useEffect(() => {
    sessionStore.checkIsLoggedIn();
    const id = history.location.pathname.substr(6);
    if (id) {
      fetchNewsArticle(id);
      return;
    }
    history.push(MainRoutes.main);
    return () => {
      resetNewsArticle();
    };
  }, []);

  if (isNewsArticleLoading) {
    return null;
  }
  if (!newsArticle) {
    history.push(ApplicationRoutes.Main);
    return null;
  }

  return (
    <Wrapper>
      <BarWrapper>
        <RightBar>
          <UserInfo
            userName={`${newsArticle.author && newsArticle.author.firstName} ${
              newsArticle.author && newsArticle.author.lastName ? newsArticle.author.lastName : ''
            }`}
            userRole={
              newsArticle.author &&
              newsArticle.author.specialization &&
              newsArticle.author.specialization.name
            }
            avatar={
              (newsArticle.author &&
                newsArticle.author.avatar &&
                newsArticle.author.avatar.downloadUrl) ||
              ''
            }
          />
          <PublicationDate>
            Дата публикации:
            <span data-test-id={`news-date`}>
              {moment(newsArticle.publicationDate).format('DD.MM.yyyy HH:mm')}
            </span>
          </PublicationDate>
          {newsArticle.permissions?.canEdit && (
            <ButtonWrapper>
              <a href={newsArticle.permissions.editLink}>
                <Button
                  color={'primary'}
                  type={'button'}
                  onClick={() => 1}
                  dataTestId={`news-edit`}
                >
                  Редактировать новость
                </Button>
              </a>
            </ButtonWrapper>
          )}
          {/* <SocialBar>*/}
          {/*  <p>Поделиться в соц.сетях:</p>*/}
          {/*  <SocialNetworksWrapper>*/}
          {/*    <SocialNetworkLogo src={VK_LOGO} />*/}
          {/*    <SocialNetworkLogo src={GOOGLE_LOGO} />*/}
          {/*    <SocialNetworkLogo src={FB_LOGO} />*/}
          {/*  </SocialNetworksWrapper>*/}
          {/* </SocialBar>*/}
        </RightBar>
      </BarWrapper>
      <NewsWrapper>{<NewsArticle data={newsArticle} />}</NewsWrapper>
    </Wrapper>
  );
});
