export enum ApplicationRoutes {
  Main = '/',
  Authorization = '/authorization/',
  ExternalAuthorization = '/logout?systemId=farmlink',
  ExternalAuthorizationDev = 'http://localhost:3100/logout?systemId=farmlink',
  Rights = '/rights',
  AdminView = '/admin-view',
  ExternalRegistrationStep2 = '/sign_up/?step=2&systemId=farmlink',
  ExternalRegistrationStep2Dev = 'http://localhost:3100/sign_up/?step=2',
}
