import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TimesOfDayNameMap } from '../../../../../../../shared/consts/date';
import { getTimesOfDay } from '../../../../../../../shared/utils/date';
import { TimeToProfileIllustration } from '../../../../../../../../widgets/utils/TimeToProfileIllustration';
import CollapseSVG from '../../../../../../assets/xmark.svg';
import { ProfileRoutes } from '../../routes';

import { WelcomeWidgetWrapper, ToggleWidget, VersionWrapper } from './style';

type Props = {
  name: string;
  collapsed: boolean;
  toggleBanner: (collapsed: boolean) => void;
};

const greetingsForTimeOfDayMap = {
  [TimesOfDayNameMap.night]: 'Доброй ночи',
  [TimesOfDayNameMap.morning]: 'Доброе утро',
  [TimesOfDayNameMap.evening]: 'Добрый вечер',
  [TimesOfDayNameMap.dayTime]: 'Добрый день',
};

export const WelcomeWidget: FC<Props> = ({ name, collapsed, toggleBanner }) => {
  const [greetings, setGreetings] = useState('');
  const history = useHistory();

  useEffect(() => {
    const timeOfDay = getTimesOfDay();

    setGreetings(greetingsForTimeOfDayMap[timeOfDay]);
  }, []);

  return (
    <WelcomeWidgetWrapper
      image={TimeToProfileIllustration()}
      collapsed={collapsed}
      onClick={() => {
        toggleBanner(!collapsed);
      }}
    >
      <span data-test-id={'profile-greeting'}>
        {greetings}, {name}!
      </span>
      <ToggleWidget>
        {collapsed ? '' : 'Свернуть'}
        {!collapsed && <img src={CollapseSVG} />}
      </ToggleWidget>
      <VersionWrapper onClick={() => history.push(ProfileRoutes.ChangeLog)}>v1.3.0</VersionWrapper>
    </WelcomeWidgetWrapper>
  );
};
