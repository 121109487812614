import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Icon, Checkbox } from '@uralhim-innovative/farmik-ui';

import { Colors } from '../../consts/color';

import {
  Wrapper,
  SelectHeader,
  SelectIcon,
  SelectInput,
  SelectBody,
  SelectList,
  SelectListItem,
} from './style';

export type Option = {
  value: string;
  label: string;
};

export type MultiSelectWithSearchProps = {
  placeholder: string;
  onSearch: (v: string) => void;
  options: Array<Option>;
  onChange: (v: Array<Option>) => void;
  selected?: Array<Option>;
};

export const MultiSelectWithSearch: FC<MultiSelectWithSearchProps> = ({
  placeholder,
  options,
  onSearch,
  onChange,
  selected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Array<Option>>(selected ? selected : []);
  const timerRef = useRef();

  const selectValue = (option: Option) => {
    if (selectedItems.filter(item => item.value === option.value)[0]) {
      setSelectedItems(selectedItems.filter(item => item.value !== option.value));
      onChange(selectedItems.filter(item => item.value !== option.value));
    } else {
      setSelectedItems([...selectedItems, option]);
      onChange([...selectedItems, option]);
    }
  };

  const renderSelectedItems = (_options: Array<Option>) =>
    _options.map(item => (
      <SelectListItem key={`${item.value}-${Date.now()}`} onClick={onClickChildElement}>
        <Checkbox label={item.label} value={true} onChange={() => selectValue(item)} />
      </SelectListItem>
    ));

  const renderItems = (_options: Array<Option>) => {
    const excludedOptions = _options.filter(
      item => !selectedItems.map(selectedItem => selectedItem.value).includes(item.value)
    );

    return excludedOptions.map(item => (
      <SelectListItem key={`${item.value}-${Date.now()}`} onClick={onClickChildElement}>
        <Checkbox label={item.label} value={false} onChange={() => selectValue(item)} />
      </SelectListItem>
    ));
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
    setIsOpen(true);
  };

  const onBlurHandler = () => {
    setIsOpen(false);
    console.log('blur state');
  };

  const onClickChildElement = () => {
    clearTimeout(timerRef.current!);
    setIsOpen(true);
    clearTimeout(timerRef.current!);
  };

  const onBlurInput = () => {
    // @ts-ignore
    timerRef.current = setTimeout(() => setIsOpen(false), 200);
  };

  const renderPlaceholder = () => {
    const MAX_SIZE = 15;
    if (selectedItems.length) {
      const sequence = selectedItems.map(i => i.label).join(', ');

      return sequence.length > MAX_SIZE ? `${sequence.substr(0, MAX_SIZE)}...` : sequence;
    } else {
      return placeholder;
    }
  };

  return (
    <Wrapper isOpen={isOpen && (Boolean(selectedItems.length) || Boolean(options.length))}>
      <SelectHeader onClick={() => setIsOpen(!isOpen)} onBlur={onBlurInput} tabIndex={-1}>
        <SelectInput
          placeholder={renderPlaceholder()}
          onChange={onChangeInput}
          // onFocus={onClickChildElement}
          // onBlur={onBlurInput}
        />
        <SelectIcon>
          <Icon icon={'arrow_down'} size={'small'} fill={Colors.darkGray} />
        </SelectIcon>
      </SelectHeader>
      <SelectBody onFocus={onClickChildElement} onBlur={onBlurHandler} tabIndex={-1}>
        {(Boolean(selectedItems.length) || Boolean(options.length)) && (
          <SelectList>
            {renderSelectedItems(selectedItems)}
            {renderItems(options)}
          </SelectList>
        )}
      </SelectBody>
    </Wrapper>
  );
};
