import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Icon, Link } from '@uralhim-innovative/farmik-ui';

import { RootStoresContext } from '../../../../../App';
import { ProfileMenuMobilePageHeaderWrapper } from '../profile/components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../../../shared/components/MobilePageHeader/MobilePageHeader';
import { MainRoutes } from '../../../../routes';
import { ProfileStoreContext } from '../profile/ProfileRouter';
import { ProfileRoutes } from '../profile/routes';
import { OrganizationalRoutes } from '../profile/pages/organizational/OrganizationalRoutes';
import { Colors } from '../../../../../shared/consts/color';
import { useStore } from '../../../../../shared/utils/IoC';
import { SessionStore } from '../../../../../authorization/stores/session.store';

import { Wrapper, Label, MenuItem, IconWrapper, ButtonWrapper } from './style';
import { Greeting } from './Greeting/Greeting';

const defaultPathTree: Array<any> = [
  {
    label: 'Виджеты1',
    path: ProfileRoutes.Widgets,
    icon: 'add_widget',
  },
  {
    label: 'Профиль',
    path: ProfileRoutes.Dashboard,
    icon: 'user',
  },
];

export const MobileProfileMenu = observer(() => {
  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent } = headerComponentStore;
  const history = useHistory();
  const sessionStore = useStore(SessionStore);

  useEffect(() => {
    setHeaderContent(
      <MobilePageHeader
        title={'Личный кабинет'}
        onBack={() => {
          history.push(MainRoutes.main);
        }}
      />,
      {
        height: '56px',
      }
    );

    return () => {
      setHeaderContent(null, {
        height: '56px',
      });
    };
  }, []);

  const store = useContext(ProfileStoreContext);

  const { user } = store;

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');
    sessionStore.logout();
  };
  return (
    <Wrapper>
      <Greeting name={user?.firstName} />
      {defaultPathTree.map((item, key) => {
        // @ts-ignore
        return (
          <MenuItem key={item.path + key} onClick={() => history.push(item.path)}>
            <IconWrapper>
              <Icon size={'medium'} icon={item.icon} fill={`${Colors.black}`} />
            </IconWrapper>
            <Label>{item.label}</Label>
          </MenuItem>
        );
      })}
      <ButtonWrapper>
        <Link label={'Выход'} color={'default'} onClick={() => handleLogout()} />
      </ButtonWrapper>
    </Wrapper>
  );
});
