import { FC } from 'react';

import { Wrapper, Avatar, AvatarWrapper, Username, Publications } from './style';

type UserInfoProps = {
  avatar?: string;
  userName: string;
  userRole?: string;
  isNewsArticlePage?: boolean;
  publicationsAmount?: number;
  size?: 'common' | 'large';
  onClick?: () => void;
  dataTestId?: string;
};

export const UserInfo: FC<UserInfoProps> = ({
  avatar,
  userName,
  userRole,
  isNewsArticlePage,
  publicationsAmount,
  size = 'common',
  onClick,
  dataTestId,
}) => (
  <Wrapper>
    <AvatarWrapper size={size} data-test-id="avatar-icon-wrapper" onClick={onClick}>
      {avatar ? <Avatar src={avatar} size={size} /> : <p>{userName[0]}</p>}
    </AvatarWrapper>
    <Username onClick={onClick} data-test-id="avatar-user-name">
      {userName}
      {userRole && (
        <>
          <br /> <span data-test-id="avatar-user-specialization">{userRole}</span>
        </>
      )}
      {isNewsArticlePage && (
        <>
          <br />{' '}
          <Publications data-test-id="avatar-user-publications">
            {publicationsAmount} публикаций
          </Publications>
        </>
      )}
    </Username>
  </Wrapper>
);
