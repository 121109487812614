import { FC, useState } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import RainIcon from '../../../assets/full-weather-conditions/slider/rain.svg';
import RainIconWhite from '../../../assets/full-weather-conditions/slider/rain-white.svg';
import { WeatherEngToRus } from '../../../utils/WeatherEngToRus';

import {
  Wrapper,
  LeftControl,
  RightControl,
  Line,
  GeoPoint,
  RightBar,
  GeoPointTitle,
  Temperature,
  ConditionIcon,
  Condition,
  ContentWrapper,
  MobileSliderWrapper,
  MobileLine,
} from './style';

type SliderProps = {
  cards: Array<{
    id: number;
    geoPoint: string;
    temperature: number;
    condition: string;
    active: boolean;
    linkId: string;
  }>;
  onClick: (v: string) => void;
};

export const Slider: FC<SliderProps> = ({ cards, onClick }) => {
  // const [isLeftControlAvailable, setIsLeftControlAvailable] = useState(false);
  // const [isRightControlAvailable, setIsRightsControlAvailable] = useState(false);
  const [shift, setShift] = useState(0);

  const handleLeftClick = () => {
    if (shift === 0) {
      return;
    }
    setShift(shift + 220);
  };

  const handleRightClick = () => {
    if (Math.abs(shift) > (cards.length + 1) * 168) {
      return;
    }
    setShift(shift - 220);
  };

  return (
    <>
      <Wrapper>
        <LeftControl onClick={handleLeftClick} isActive={shift !== 0}>
          <Icon icon={'arrow_left'} size={'small'} />
        </LeftControl>
        <ContentWrapper>
          <Line shift={shift}>
            {cards &&
              cards.map((card, index) => {
                return (
                  <GeoPoint
                    key={`desktop-${card.linkId}`}
                    active={card.active}
                    onClick={() => onClick(card.linkId)}
                  >
                    <GeoPointTitle>{card.geoPoint}</GeoPointTitle>
                    <RightBar>
                      <div>
                        <ConditionIcon src={card.active ? RainIconWhite : RainIcon} />
                        <Temperature>{card.temperature}°С</Temperature>
                      </div>
                      <Condition>{WeatherEngToRus(card.condition)}</Condition>
                    </RightBar>
                  </GeoPoint>
                );
              })}
          </Line>
        </ContentWrapper>

        <RightControl
          onClick={handleRightClick}
          isActive={Math.abs(shift) < (cards.length + 1) * 168}
        >
          <Icon icon={'arrow_right'} size={'small'} />
        </RightControl>
      </Wrapper>

      <MobileSliderWrapper>
        <MobileLine>
          {cards &&
            cards.map((card, index) => {
              return (
                <GeoPoint
                  key={`mobile-${card.linkId}`}
                  active={card.active}
                  onClick={() => onClick(card.linkId)}
                >
                  <GeoPointTitle>{card.geoPoint}</GeoPointTitle>
                  <RightBar>
                    <div>
                      <ConditionIcon src={card.active ? RainIconWhite : RainIcon} />
                      <Temperature>{card.temperature}°С</Temperature>
                    </div>
                    <Condition>{WeatherEngToRus(card.condition)}</Condition>
                  </RightBar>
                </GeoPoint>
              );
            })}
        </MobileLine>
      </MobileSliderWrapper>
    </>
  );
};
