import { FC } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Colors } from '../../../../modules/shared/consts/color';

import { ChartWrapper } from './style';

import './style.css';
import { hexToRgba } from '../../../../modules/shared/utils/hexToRgba';

import { toJS } from 'mobx';

type SmallQuoteChartProps = {
  data: Array<Array<any>>;
};

export const SmallQuoteChart: FC<SmallQuoteChartProps> = props => {
  console.log('chart data ', toJS(props.data));
  // const getOptions = () => {
  //   const opt = { ...options };
  //   if (opt.series) {
  //     opt.series[0].data = props.data;
  //   }
  //   return opt;
  // };

  const options: Highcharts.Options = {
    chart: {
      height: 128,
      width: 290,
      margin: 0,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      height: 200,
      visible: false,
      minPadding: 0,
      maxPadding: 0,
    },
    yAxis: {
      visible: false,
      minPadding: 0,
      maxPadding: 0,
      plotLines: [
        {
          color: Colors.gray,
          dashStyle: 'Dash',
          value: 10,
        },
      ],
    },
    legend: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
      formatter: () => null,
    },
    colors: [Colors.green],
    series: [
      {
        accessibility: {
          enabled: false,
        },
        pointDescriptionFormatter: () => null,
        type: 'areaspline',
        marker: {
          enabled: false,
        },
        showInLegend: false,
        selected: false,
        allowPointSelect: false,
        gapSize: 0,
        states: {
          select: { enabled: false },
        },
        data: props.data,
        tooltip: {
          valueDecimals: 2,
        },
        fillColor: hexToRgba(Colors.green, 0.12),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <ChartWrapper>
      <HighchartsReact highcharts={Highcharts} options={options} {...props} oneToOne={true} />
    </ChartWrapper>
  );
};
