import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { NofiticationsPage } from '../notifications/pages/NotificationsPage/NotificationsPage';
import { PrivacyPolicy } from '../privacyPolicy';
import { AdminPanel } from '../shared/components/AdminPanel/AdminPanel';

import { MainRoutes } from './routes';
import { Main } from './pages/main/Main';
import { HeaderComponent } from './components/HeaderComponent';
import { Layout } from './style';
import { DashboardRouter } from './modules/dashboard';
import { Agreement } from './pages/agreement';
import { CulturesPage } from './pages/cultures';
import { ServicesRouter } from './modules/services/ServicesRouter';
import { News } from './pages/news';
import { GuestProfile } from './modules/dashboard/pages/profile/pages/GuestProfile/GuestProfile';

export const MainRouter: FC = () => {
  return (
    <>
      <HeaderComponent />
      <AdminPanel />
      <Layout>
        <Switch>
          <Route path={MainRoutes.agreement} component={Agreement} />
          <Route path={`${MainRoutes.profile}:id`} component={GuestProfile} />
          <Route path={MainRoutes.cultures} component={CulturesPage} />
          <Route path={MainRoutes.dashboard} component={DashboardRouter} />
          <Route path={MainRoutes.services} component={ServicesRouter} />
          <Route path={MainRoutes.news} component={News} />
          <Route path={MainRoutes.main} exact component={Main} />
          <Route path={MainRoutes.notifications} component={NofiticationsPage} />
          <Route path={MainRoutes.privacyPolicy} component={PrivacyPolicy} />
        </Switch>
      </Layout>
    </>
  );
};
