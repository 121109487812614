import { FC, useContext, useEffect } from 'react';
import { Icon, Button, Input } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { ProfileStoreContext } from '../../ProfileRouter';
import IconCheckbox from '../../assets/checkbox.svg';
import IconCheckboxGreen from '../../assets/checkbox-green.svg';
import IconDelete from '../../assets/delete.svg';
import { Autosuggest } from '../../../../../../../shared/components/Autosuggest/Autosuggest';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { EditExpertiseStore } from '../../stores/EditExpertiseStore';
import { ProfileRoutes } from '../../routes';

import {
  Wrapper,
  Header,
  InputWrapper,
  FirstDiv,
  SecondDiv,
  Competition,
  DeleteIcon,
  ExpertiseWrapper,
  OkIcon,
  CustomButton,
  Footer,
  FooterButtonWrapper,
} from './style';

export const EditCompetitions: FC = observer(() => {
  const expertiseStore = useStore(EditExpertiseStore);
  const history = useHistory();
  const context = useContext(ProfileStoreContext);

  const {
    expertiseOptions,
    setExpertise,
    fetchExpertiseList,
    expertises,
    addToList,
    selectExpertise,
    deleteExpertise,
    saveExpertises,
    addToExpertises,
    reset,
    searchExpertise,
  } = expertiseStore;
  const store = useContext(ProfileStoreContext);

  useEffect(() => {
    addToExpertises().then(() => {
      fetchExpertiseList();
    });
  }, []);

  const {
    loading,
    fullName,
    user,
    personalDataItems,
    competentionsList,
    toggleWelcomeWidget,
    collapsedWelcomeWidget,
  } = store;

  const onSave = async () => {
    await saveExpertises();
    reset();
    history.push(ProfileRoutes.Dashboard);
  };

  const onCancel = () => {
    reset();
    history.push(ProfileRoutes.Dashboard);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    addToList();
  };

  return (
    <Wrapper>
      <Header>
        <p>Редактирование компетенций</p>
      </Header>
      <InputWrapper onSubmit={submitHandler}>
        <FirstDiv>
          <Autosuggest
            data-test-id={'profile-edit-expertise-suggest'}
            onChange={selectExpertise}
            onSearch={setExpertise}
            value={searchExpertise}
            options={expertiseOptions}
            placeholder={'Садоводство'}
            label={'Добавление новой компетенции'}
          />
        </FirstDiv>
        <SecondDiv>
          <CustomButton type={'submit'} data-test-id={'profile-edit-expertise-add'}>
            Добавить
          </CustomButton>
        </SecondDiv>
      </InputWrapper>
      <ExpertiseWrapper>
        {expertises.length > 0
          ? expertises.map((item, index) => (
              <Competition key={item.expertiseId} data-test-id={`profile-edit-expertise-${index}`}>
                <DeleteIcon src={IconDelete} onClick={() => deleteExpertise(item.expertiseId)} />
                <p>{item.expertiseName}</p>
                <OkIcon src={item.confirmations ? IconCheckboxGreen : IconCheckbox} />
                <p>{item.confirmations}</p>
              </Competition>
            ))
          : 'Не выбраны компетенции'}
      </ExpertiseWrapper>
      <Footer>
        <FooterButtonWrapper>
          <Button
            color={'default'}
            type={'button'}
            onClick={onCancel}
            data-test-id={'profile-edit-expertise-cancel'}
          >
            Отменить
          </Button>
        </FooterButtonWrapper>
        <FooterButtonWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={onSave}
            data-test-id={'profile-edit-expertise-save'}
          >
            Сохранить
          </Button>
        </FooterButtonWrapper>
      </Footer>
    </Wrapper>
  );
});
