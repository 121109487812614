import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/consts/color';

export const PersonalDataWidgetWrapper = styled.div`
  grid-area: competention;
`;

export const Competition = styled.div`
  padding: 6px 12px;
  background: ${Colors.gray};
  border: 0.5px solid ${Colors.grayBorder};
  color: ${Colors.darkGray};
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }

  img {
    width: 16px;
    height: 16px;
    margin-left: 9px;
    margin-right: 4px;
  }
`;

export const WrapperCompetition = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
