import { FC } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';

import AvatarMock from '../../assets/avatar_mock.svg';
import { EntityType } from '../../types/culture.response.model';

import { ArrowWrapper, Avatar, BreadcrumbsWrapper, Name, Wrapper } from './style';

export type Node = {
  avatar?: string;
  name: string;
  id: string;
  code: string;
};

export type BreadcrumbsProps = {
  breadcrumbs: Array<Node>;
  onChangeNode: (code: string, id: string, name: string, chainIndex?: number) => void;
  onReset: () => void;
  isLoading: boolean;
  isPage?: boolean;
  entity: EntityType;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  onChangeNode,
  isLoading,
  entity,
  isPage,
}) => {
  const bc: Array<Node> = [
    { id: '', code: '', name: 'Культуры', avatar: AvatarMock },
    ...breadcrumbs,
    {
      id: '',
      code: '',
      name: entity.name,
      avatar: entity.picLink ? entity.picLink.downloadUrl : AvatarMock,
    },
  ];
  return (
    <BreadcrumbsWrapper isPage={isPage}>
      {bc &&
        bc.map((item, index) => (
          <>
            <Wrapper
              onClick={
                !isLoading && index === bc.length - 1
                  ? () => 1
                  : () => onChangeNode(item.code, item.id, item.name, index - 1)
              }
              isActive={index === bc.length - 1}
            >
              <Avatar src={item.avatar} />
              <Name>{item.name}</Name>
            </Wrapper>
            {index !== bc.length - 1 && (
              <ArrowWrapper>
                <Icon size="small" icon="arrow_right" />
              </ArrowWrapper>
            )}
          </>
        ))}
    </BreadcrumbsWrapper>
  );
};
