import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  name: string;
  type: string;
  widget_config: string;
};

type TypeResponse = {
  id: string;
};

export const createWidget: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/widgets/create`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
