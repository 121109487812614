import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { FieldType, FieldStatusType } from '../modules/shared/types/Field';

type TypeRequest = {
  size: number;
  page?: number;
  name: string;
};

type TypeResponse = {
  content: Array<{
    id: string;
    name: string;
  }>;
} & TypeResponsePageable;

export const getEconomicRegiones: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => `/api/da-quotes/refs/economicRegion/list?size=${req.size}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
