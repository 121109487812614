import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  size: number;
  page: number;
  name: string;
};

type TypeResponse = { content: Array<{ id: string; name: string }> } & TypeResponsePageable;

export const getCultureClassList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-quotes/refs/cultureClass/list?size=${request.size}&page=${request.page}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
