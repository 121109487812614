import { TypeUser } from '../modules/shared/types/users';
import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  userId: string;
  organizationId: string;
};

type TypeResponse = TypeUser;

export const getOrganizationUser: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/organizations/${request.organizationId}/user/${request.userId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
