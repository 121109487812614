import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeService } from '../modules/shared/types/service';

type TypeRequest = {
  serviceId: string;
};

type TypeResponse = TypeService;

export const getService: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-services/services/service/${request.serviceId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
