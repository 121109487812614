import { FC, useEffect } from 'react';
import { Switch, Route, RouteComponentProps, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationFields } from '../profile/pages/OrganizationFields';
import { ProfileGrid } from '../profile/style';
import { ProfileLeftMenu, MenuItem } from '../profile/components/ProfileLeftMenu';
import { ProfileRoutes } from '../profile/routes';

import { OrganizationStore } from './stores/organization.store';
import { OrganizationRoutes } from './organization.routes';
import { OrganizationInfo } from './pages/organizationInfo';
import { OrganizationUsersList } from './pages/organizationUsersList';
import { OrganizationEdit } from './pages/organizationEdit';
import { OrganizationInvitesApplications } from './pages/organizationInvitesApplications';

export const OrganizationRouter: FC<RouteComponentProps> = observer(({ history, location }) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const store = useStore(OrganizationStore);
  const { organization, getOrganizationInfo } = store;

  useEffect(() => {
    getOrganizationInfo(organizationId);
  }, [organizationId]);

  const pathTree: Array<MenuItem> = [
    {
      label: 'Организация',
      path: `${OrganizationRoutes.main}/${organizationId}`,
    },
    // {
    //   label: 'Поля',
    //   path: `${OrganizationRoutes.main}/${organizationId}/fields`,
    // },
    {
      label: 'Сотрудники',
      path: `${OrganizationRoutes.main}/${organizationId}/users`,
    },
    {
      label: 'Заявки и приглашения',
      path: `${OrganizationRoutes.main}/${organizationId}/invites-applications`,
      hiddenItem: !organization?.canViewInvites,
    },
  ];

  return (
    <ProfileGrid>
      <ProfileLeftMenu
        backButtonText="Назад"
        backButtonPath={ProfileRoutes.Organizations}
        history={history}
        location={location}
        pathTree={pathTree}
        showLogout={false}
      />
      <Switch>
        <Route
          path={`${OrganizationRoutes.main}/:organizationId/invites-applications`}
          component={OrganizationInvitesApplications}
        />
        <Route
          path={`${OrganizationRoutes.main}/:organizationId/edit`}
          component={OrganizationEdit}
        />
        <Route
          path={`${OrganizationRoutes.main}/:organizationId/users`}
          component={OrganizationUsersList}
        />
        <Route path={`${OrganizationRoutes.main}/:organizationId`} component={OrganizationInfo} />
      </Switch>
    </ProfileGrid>
  );
});
