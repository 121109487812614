import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeOrganization } from '../modules/shared/types/organization';

type TypeRequest = TypeOrganization & { orgTypeId?: number; logo?: string };

type TypeResponse = {};

export const updateOrganization: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/organizations/${request.organizationId}`,
  method: 'PUT',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
