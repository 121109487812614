import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Colors } from '../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../shared/consts/sizes';
import { PaperWrapper } from '../../../../../../../shared/components/PaperBlock/style';

export const Wraper = styled(PaperWrapper)`
  width: 100%;
  padding: 20px 32px;

  @media (max-width: ${Breakpoints.small}) {
    padding: 32px 16px;
  }
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid ${Colors.grayBorder};
  margin-bottom: 28px;
`;

export const Title = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const ListWrapper = styled.div`
  max-width: 360px;
  line-height: 21px;
`;

export const EditButton = styled(Link)`
  display: flex;
  color: ${Colors.green};
  text-decoration: underline;
  font-size: 12px;

  img {
    margin-right: 6px;
  }
`;

export const LongTitle = styled.span`
  display: inline;

  @media (max-width: ${Breakpoints.extraSmall}) {
    display: none;
  }
`;

export const ShortTitle = styled.span`
  display: none;

  @media (max-width: ${Breakpoints.extraSmall}) {
    display: inline;
  }
`;
