import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  width: 142px;
  height: 160px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  position: relative;
  padding: 17px 10px;
  text-align: center;
  margin-bottom: 10px;
  
  @media(max-width: ${Breakpoints.large}) {
    width: 128px;
  }
  
  
`;

export const Avatar = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 60px;
  margin-bottom: 6px;
  @media (max-width: ${Breakpoints.medium}) {
    height: 64px;
    width: 64px;
    margin-bottom: 12px;
  }
`;
export const NameWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  line-height: 16px;
  color: ${Colors.black};
`;

export const QuestionBody = styled.div.attrs(props => ({ className: props.className }))`
  position: absolute;
  display: none;
  left: 26px;
  top: -10px;
  width: 256px;
  z-index: 999;
  background: ${Colors.white};
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  flex-direction: column;
  padding: 12px 7px 5px 12px;
`;

export const QuestionWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  * {
    fill: ${Colors.darkGray} !important;
  }

  &:hover {
    * {
      fill: ${Colors.green} !important;
    }
    ${QuestionBody} {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const QuestionName = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 16px;
`;

export const QuestionDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
`;
