import styled from 'styled-components';

import { Sizes, Breakpoints } from '../shared/consts/sizes';

export const Layout = styled.div`
  max-width: ${Sizes.ExtendedFullScreen};
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${Breakpoints.medium}) {
    padding-bottom: 64px;
  }
`;
