import { action, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';

export type WidgetWeatherConfig = {
  size: 'big' | 'small';
  positions: Array<{ lat: number; lon: number; isActive: boolean; linkId: string }>;
};

@provide.singleton()
export class WidgetQuotesEditStore {
  @lazyInject(Axios2)
  axios: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  name = '';
  id = '';
  config: WidgetWeatherConfig | null = null;

  widgets: Array<any> = [];

  @action
  fetchWidgetInfo = (id: string) => {
    this.axios.api
      .getWidgetInfo({ id })
      .then(this.onFetchWidgetInfoSuccess)
      .catch(this.onFetchWidgetInfoError);
  };

  @action
  onFetchWidgetInfoSuccess = async (info: TypeApiResponse<'getWidgetInfo'>) => {
    this.config = JSON.parse(info.widget_config);
    console.log('config', this.config);
    this.name = info.name;
    this.id = info.id;
    if (!this.config?.positions) {
      return;
    }
    const ids = this.config.positions.map(i => i.linkId);
    const positions = this.config?.positions.map((item: any) => {
      return {
        basisId: item.basisId,
        providerId: item.providerId,
        cultureId: item.cultureId,
        portId: item.portId,
        currencyId: item.currencyId,
        unitsId: item.unitsId,
        economicRegionId: item.economicRegionId,
        cultureClassId: item.cultureClassId,
        vat: item.vat,
      };
    });

    this.widgets = (
      await this.axios.api.getQuotesByPositions(positions, {
        noValidate: true,
      })
    ).map((i, index) => {
      return { ...i, linkId: ids[index] };
    });
  };

  onFetchWidgetInfoError = () => {
    console.log('onFetchWidgetInfoError');
  };

  indexWidget = 0;

  @action
  toggleQuote = async (index: number) => {
    await this.axios.api.updateWidgetInfo(
      {
        id: this.id,
        widget_config: JSON.stringify({
          positions: this.config?.positions.map((item, i) => {
            if (index === i) {
              return {
                ...item,
                isActive: !item.isActive,
              };
            }
            return item;
          }),
        }),
      },
      {
        omit: ['id'],
      }
    );
    this.fetchWidgetInfo(this.id);
  };

  @action
  setSelectedWidgetIndex = (i: number) => {
    this.indexWidget = i;
  };

  @action
  addMockedQuotes = async () => {
    await this.axios.api.updateWidgetInfo(
      {
        id: this.id,
        widget_config: JSON.stringify({
          positions: [
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: '7f0af30a-c627-4408-a02b-8123f3aa910f',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              vat: true,
              isActive: true,
            },
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: '3d7312f0-785f-11eb-8b34-49ac92a6a8f1',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              vat: true,
              isActive: true,
            },
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: '98430780-40b5-11eb-9b02-67084feaa621',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              vat: true,
              isActive: true,
            },
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: 'b6c335c0-6d2b-11eb-8d42-dd19d7aaf478',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              vat: true,
              isActive: true,
            },
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: '46260450-6d2b-11eb-8d42-dd19d7aaf478',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              cultureClassId: 'd23a5d90-4a69-4c7e-9d8f-90f1b2d166cb',
              vat: true,
              isActive: true,
            },
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: '43cc0480-7a6d-11eb-8b34-49ac92a6a8f1',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              cultureClassId: 'd23a5d90-4a69-4c7e-9d8f-90f1b2d166cb',
              vat: true,
              isActive: true,
            },
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: '43cc0480-7a6d-11eb-8b34-49ac92a6a8f1',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              cultureClassId: '6907ab29-1e07-4467-86da-885457010642',
              vat: true,
              isActive: true,
            },
            {
              basisId: 'c3cef714-37a1-40dd-b2be-31f100940011',
              providerId: '3c7cbaff-06ba-4650-ae8e-9bee8480eb88',
              cultureId: '43cc0480-7a6d-11eb-8b34-49ac92a6a8f1',
              economicRegionId: 'b32a761e-7a44-4c8b-b908-5d70d5914b73',
              currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
              unitsId: '3e7d9884-6cfd-4f09-882b-256814d2fc4f',
              cultureClassId: '4d240986-2b86-4f21-a5de-f6aedd02befa',
              vat: true,
              isActive: true,
            },
          ],
        }),
      },
      {
        omit: ['id'],
      }
    );

    this.fetchWidgetInfo(this.id);
  };

  @action
  deleteQuote = async (index: number) => {
    await this.axios.api.updateWidgetInfo(
      {
        widget_config: JSON.stringify({
          positions: this.config?.positions.filter((item, i) => index !== i),
        }),
        id: this.id,
      },
      {
        omit: ['id'],
      }
    );
    this.fetchWidgetInfo(this.id);
    this.widgets.splice(index, 1);
  };

  @action
  clear = () => {
    this.widgets = [];
    this.config = null;
  };
}
