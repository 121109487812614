import { FC, useRef } from 'react';

import { NewsContainer } from '../../../../../../../news/components/NewsContainer';
import { NewsPreview } from '../../../../../../../news/components/NewsPreview';

import { Wrapper, ContentWrapper } from './style';
import {observer} from "mobx-react";

type NewsWrapperProps = {
  news: Array<any>;
  loadAdditional: () => void;
  isPaginational: boolean;
  isPaginationLoading: boolean;
};

export const NewsWrapper: FC<NewsWrapperProps> = observer(({
  news,
  isPaginationLoading,
  isPaginational,
  loadAdditional,
}) => {
  const listReference = useRef(null);

  const handleScroll = () => {
    // @ts-ignore
    const { clientHeight, scrollHeight, scrollTop } = listReference.current;
    // @ts-ignore
    const difference = scrollHeight - clientHeight;
    // @ts-ignore
    if (isPaginational && scrollTop + 100 >= difference && !isPaginationLoading) {
      loadAdditional();
    }
  };
  return (
    <Wrapper>
      <ContentWrapper ref={listReference} onScroll={handleScroll}>
        {news.map(item => (
          <NewsPreview data={item} key={item.newsId} isLiked={item.likedByMe} />
        ))}
      </ContentWrapper>
    </Wrapper>
  );
});
