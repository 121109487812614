import { FC } from 'react';

import {
  PlantProtectionContent,
  PlantProtectionContentDescription,
  PlantProtectionContentTimeLine,
  PlantProtectionHeader,
  PlantProtectionSection,
  PlantProtectionTimePart,
  PlantProtectionContentTimeLineWrapper,
} from './style';

type PlantProtectionProps = {
  parts: Array<{ type: 'common' | 'green' | 'yellow' | 'red'; time: string }>;
};

export const PlantProtection: FC<PlantProtectionProps> = ({ parts }) => {
  return (
    <PlantProtectionSection>
      <PlantProtectionHeader>
        <p>Окна внесения СЗР</p>
      </PlantProtectionHeader>
      <PlantProtectionContent>
        <PlantProtectionContentTimeLineWrapper>
          <PlantProtectionContentTimeLine>
            {parts &&
              parts.map(item => (
                <PlantProtectionTimePart type={item.type}>
                  <p>{item.time}</p>
                  <div />
                </PlantProtectionTimePart>
              ))}
          </PlantProtectionContentTimeLine>
        </PlantProtectionContentTimeLineWrapper>
        <PlantProtectionContentDescription>
          1. Окна внесения СЗР сформированы для наземного полевого опрыскивания. <br />
          2. Опрыскивание должно проводиться в соответствии с "Регламентом применения разрешенных
          пестицидов и агрохимикатов" и рекомендациями производителей пестицидов и агрохимикатов.
          <br />
          3. Если в течение 4 или 6 часов прогнозируется выпадение осадков, опрыскивание запрещено.
          <br />
          4. Если на растениях присутствует роса, опрыскивание запрещено.
        </PlantProtectionContentDescription>
      </PlantProtectionContent>
    </PlantProtectionSection>
  );
};
