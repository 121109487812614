import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TypeUserRole } from '../../../../../../../../shared/types/users';
import { checkIsEmail } from '../../../../../../../../shared/utils/checkIsEmail';
import { Axios2, TypeApiRequest } from '../../../../../../../../shared/utils/axios2';

type ErrorsType = {
  email: string;
  position: string;
  fio: string;
  formErrors: string;
};

const defaultErrors = {
  email: '',
  position: '',
  fio: '',
  formErrors: '',
};

const requiredErrorText = 'Поле обязательно для заполнения';

@provide.singleton()
export class OrganizationInviteUserFormStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  email = '';
  position: TypeUserRole = 'OWNER';
  comment = '';
  fio = '';
  errors: ErrorsType = defaultErrors;
  isValid = false;

  setEmail = (email: string) => {
    this.errors.email = '';
    this.email = email;
  };

  setFio = (fio: string) => {
    this.errors.fio = '';
    this.fio = fio;
  };

  setPosition = (position: TypeUserRole) => {
    this.errors.position = '';
    this.position = position;
  };

  setComment = (comment: string) => {
    this.comment = comment;
  };

  inviteUserByEmail = (payload: TypeApiRequest<'sendEmailInvite'>) =>
    this.axios2.api.sendEmailInvite(payload, { omit: ['organizationId'] });

  validateForm = () => {
    const formErrors: ErrorsType = { ...defaultErrors };
    let hasError = false;

    if (!this.email.length) {
      formErrors.email = requiredErrorText;
      hasError = true;
    }

    if (!checkIsEmail(this.email)) {
      formErrors.email = 'Не валидный email';
      hasError = true;
    }

    if (!this.fio.length) {
      formErrors.fio = requiredErrorText;
      hasError = true;
    }

    this.isValid = !hasError;

    return {
      isValid: !hasError,
      formErrors,
    };
  };

  setErrors = (errors: ErrorsType) => {
    this.errors = errors;
  };

  setFormErrors = (errors: string) => {
    this.errors = {
      ...this.errors,
      formErrors: errors,
    };
  };

  resetForm = () => {
    this.errors = defaultErrors;
    this.fio = '';
    this.comment = '';
    this.email = '';
    this.position = 'OWNER';
    this.isValid = false;
  };
}
