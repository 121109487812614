import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../../../shared/consts/sizes';

export const ControlBarWrapper = styled.div`
  width: 100%;
  background: ${Colors.white};
  position: sticky;
  top: 92px;
  left: 0;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 99;

  @media (max-width: ${Breakpoints.small}) {
    border-radius: 0;
  }
`;

export const Spacer = styled.div`
  display: block;
  position: fixed;
  background: ${Colors.gray};
  height: 144px;
  top: 0;
  left: -18px;
  right: -18px;
  z-index: 1;
`;

export const ControlBarInner = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 28px 16px 16px;
  display: flex;
  align-items: center;
`;

export const SearchInputWrapper = styled.div`
  width: 256px;
  margin-right: 16px;

  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
  }
`;

export const SelectWrapper = styled.div<{ isMobile?: boolean }>`
  width: 164px;

  @media (max-width: ${Breakpoints.small}) {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
    flex: ${({ isMobile }) => (isMobile ? '0 0 auto' : '0 0 164px')};
    margin-bottom: 16px;
    margin-right: 16px;
    width: 100%;
  }
`;

export const Role = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  background: ${Colors.grayBorder};
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  height: 28px;
  cursor: pointer;
  & p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
    margin-right: 9px;
  }

  & svg {
    fill: ${Colors.darkGray};
  }

  &:hover svg {
    fill: ${Colors.green};
  }
`;

export const RoleWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  margin-left: 16px;
  margin-right: 16px;

  max-width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${Breakpoints.small}) {
    margin: 0;
    position: relative;
    display: ${({ isMobile }) => (isMobile ? 'flex' : 'none')};
    z-index: ${({ isMobile }) => (isMobile ? '0' : '0')};
  }
`;

export const LinkWrapper = styled.div<{ isMobile?: boolean; hidden?: boolean }>`
  margin-left: auto;
  cursor: pointer;

  display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};

  @media (max-width: ${Breakpoints.medium}) {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};

    &.options {
      display: none;
    }

    & > img {
      margin-left: 20px;
      width: 18px;
      position: relative;
      top: 1px;
    }
  }

  @media (max-width: ${Breakpoints.small}) {
    &.options {
      display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
    }
  }

  ${({ hidden }) => hidden && 'display: none !important'}
`;

export const MobileOptionsWrapper = styled.div<{ shownMobileOptions: boolean }>`
  display: none;
  flex-basis: 100%;
  padding: 0 57px 16px 16px;
  flex-wrap: nowrap;
  align-items: baseline;
  width: 100%;

  @media (max-width: ${Breakpoints.small}) {
    display: ${({ shownMobileOptions }) => (shownMobileOptions ? 'flex' : 'none')};
  }
`;
