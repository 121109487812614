import { TypeApiRoute } from '../models/TypeApiRoute';
import { Quote } from '../modules/shared/types/quotes.dto';

type TypeRequest = any;

type TypeResponse = Array<Quote>;

export const getQuotesByPositions: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-quotes/quotes/info`,
  method: 'POST',
  headers: {},
  request: [] as TypeRequest,
  response: {} as TypeResponse,
};
