import { FC, createContext, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { RootStore } from '../stores/rootStores/rootStore';

import { ApplicationRoutes } from './routes';
import { MainRouter } from './main';
import { AdaptiveMock } from './shared/components/AdaptiveMock/AdaptiveMock';
import { AdminView } from './admin-view/AdminView';
import { Axios2 } from './shared/utils/axios2';
import { useStore } from './shared/utils/IoC';
import { ExternalRedirect } from './authorization/containers/AuthorizationWrapper';
import { MainPreloader } from './main/containers/MainPreloader/MainPreloader';

const rootStores = new RootStore();
export const RootStoresContext = createContext<RootStore>(rootStores);

export const App: FC = () => {
  const [userDefined, setUserDefined] = useState(false);
  const [showedLoader, setShowedLoader] = useState(true);

  const handleSmothHidePreload = () => {
    setUserDefined(true);

    setTimeout(() => {
      setShowedLoader(false);
    }, 3000);
  };

  return (
    <>
      {userDefined && (
        <>
          <RootStoresContext.Provider value={rootStores}>
            <Switch>
              <Route path={ApplicationRoutes.AdminView} component={AdminView} />
              <Route path={ApplicationRoutes.Main} component={MainRouter} />
              <Redirect to={ApplicationRoutes.Main} />
            </Switch>
          </RootStoresContext.Provider>
          <AdaptiveMock />
        </>
      )}
      {showedLoader && <MainPreloader hideAuthPreload={handleSmothHidePreload} />}
    </>
  );
};
