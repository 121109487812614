import queryString from 'querystring';

import { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ProfileRoutes } from '../../../modules/main/modules/dashboard/pages/profile/routes';
import { useStore } from '../../../modules/shared/utils/IoC';
import { FullQuoteStore } from '../../stores/FullQuoteStore';
import { RootStoresContext } from '../../../modules/App';
import { UiStore } from '../../../modules/shared/stores/ui.store';
import { BreakpointsNumbers } from '../../../modules/shared/consts/sizes';
import { ProfileMenuMobilePageHeaderWrapper } from '../../../modules/main/modules/dashboard/pages/profile/components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../modules/shared/components/MobilePageHeader/MobilePageHeader';
import { MainRoutes } from '../../../modules/main/routes';

import {
  ChartWrapper,
  HeaderWrapper,
  PageWrapper,
  InformationWrapper,
  SliderWrapper,
} from './style';
import { Header } from './components/Header/Header';
import { Chart } from './components/Chart/Chart';
import { Information } from './components/Information/Information';
import { Slider } from './components/Slider/Slider';

export const FullEditQuotesPage: FC = observer(() => {
  const history = useHistory();
  const store = useStore(FullQuoteStore);
  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent } = headerComponentStore;
  const uiStore = useStore(UiStore);

  const {
    fetchWidget,
    selectQuote,
    quote,
    chartPoints,
    fetchHistoryData,
    description,
    sliderCards,
  } = store;

  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small) {
      setHeaderContent(
        <ProfileMenuMobilePageHeaderWrapper>
          <MobilePageHeader
            title={'Котировки'}
            onBack={() => {
              history.push(MainRoutes.main);
            }}
          />
        </ProfileMenuMobilePageHeaderWrapper>,
        {
          height: '56px',
        }
      );
    }
    // getSpecializations();
    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  useEffect(() => {
    const params: any = queryString.parse(location.search.substr(1));
    if (!params.id || !params.point) {
      history.push(ProfileRoutes.Widgets);
    }

    fetchWidget(params.id);
    selectQuote(params.point);
  }, []);

  if (!quote) {
    return null;
  }
  return (
    <PageWrapper>
      <SliderWrapper>
        <Slider onClick={index => selectQuote(index)} cards={sliderCards} />
      </SliderWrapper>
      <HeaderWrapper>
        <Header
          price={quote.price}
          title={quote.culture.name}
          tendency={{
            amount: quote.tendency.diff,
            direction: quote.tendency.direction,
            percent: quote.tendency.diffPercent,
          }}
          port={quote.economicRegion && quote.economicRegion.name}
          basis={quote.basis && quote.basis.name}
          qualityString={quote.qualityString}
          cultureClass={quote.cultureClass}
          image={(quote.culture.icon && quote.culture.icon.downloadUrl) || ''}
        />
      </HeaderWrapper>
      <ChartWrapper data-test-id={'quote-full-chart-wrapper'}>
        <Chart data={chartPoints} onChangeZoom={fetchHistoryData} />
      </ChartWrapper>

      <InformationWrapper>
        <Information content={description} />
      </InformationWrapper>
    </PageWrapper>
  );
});
