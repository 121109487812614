import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import LikeEmptySvg from '../../assets/hearth-default.svg';
import LikeFilledSvg from '../../assets/hearth-fill.svg';
import { useStore } from '../../../../../shared/utils/IoC';
import { NewsPageStore } from '../../stores/NewsPageStore';
import { useIsAuthorized } from '../../../../../shared/hooks/useIsAuthorized';
import { AuthorizationRoutes } from '../../../../../authorization/routes';
import { ModalNews } from '../ModalNews';
import { Modal } from '../../../../../shared/components/Modal';

import { LikeWrapper } from './style';
import {observer} from "mobx-react";

type LikeProps = {
  id: string;
  isLiked: boolean;
  amount: string;
};

export const Like: FC<LikeProps> = observer(({ isLiked, id, amount }) => {
  const store = useStore(NewsPageStore);
  const isAuthorized = useIsAuthorized();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    if (!isAuthorized) {
      setIsModalOpen(true);
      return;
    }

    store.likeNews(id, !isLiked);
  };

  return (
    <LikeWrapper>
      <Modal
        shown={isModalOpen}
        hideModal={() => {
          setIsModalOpen(false);
        }}
      >
        <ModalNews />
      </Modal>
      <img onClick={handleClick} src={isLiked ? LikeFilledSvg : LikeEmptySvg} alt={'like'} />
      {amount}
    </LikeWrapper>
  );
});
