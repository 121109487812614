import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slider } from 'pure-react-carousel';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import IllustrationPNG from '../assets/illustration_small.png';
import SunIcon from '../assets/icons/sun.svg';
import WindIcon from '../assets/icons/wind.svg';
import RainIcon from '../assets/icons/rain.svg';
import HumidityIcon from '../assets/icons/humidity.svg';
import MapIcon from '../assets/icons/map.svg';
import { useStore } from '../../../../modules/shared/utils/IoC';
import { WidgetWeatherStore } from '../../../stores/WidgetWeatherStore';
import {
  ArrowButton,
  CarouselWrapperSmall,
  GeoPointsWrapper,
  GeoPointWrapper,
} from '../../../../modules/main/modules/dashboard/pages/profile/pages/EditWidgets/style';
import { WeatherConditionToIllustration } from '../../../utils/WeatherConditionToIllustrationV2';
import { ProfileRoutes } from '../../../../modules/main/modules/dashboard/pages/profile/routes';

import {
  Wrapper,
  Illustration,
  Shadow,
  LeftBar,
  Content,
  Temperature,
  WeatherIcon,
  TopBar,
  RightBar,
  Position,
  Separator,
  MapWrapper,
} from './style';

type WeatherBigWidgetProps = {
  slot: number;
  config?: any;
};

export const WeatherSmallWidget: FC<WeatherBigWidgetProps> = observer(({ slot, config }) => {
  const store = useStore(WidgetWeatherStore);
  const { widgetIdToWeather, slotToId } = store;
  const widgets = widgetIdToWeather.get(slot.toString());
  if (!widgets) {
    return null;
  }
  const getPrecByHour = (hours: any) => {
    const dateNow = new Date();
    return hours[dateNow.getHours()].prec_mm;
  };

  const history = useHistory();

  return (
    <CarouselWrapperSmall>
      <CarouselProvider
        isPlaying
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={widgets.length}
        dragEnabled={false}
      >
        <Slider>
          {widgets.map((item: any, index) => {
            return (
              <Wrapper
                onClick={() =>
                  history.push(
                    `${ProfileRoutes.FullEditWeatherWidgets}?id=${slotToId.get(`${slot}`)}&point=${
                      item.linkId
                    }`
                  )
                }
                data-test-id="widget-wrapper-small"
              >
                <Illustration
                  url={WeatherConditionToIllustration(
                    item.fact?.condition,
                    item.fact?.obsTime,
                    'short'
                  )}
                />
                <Shadow />
                <Content>
                  <LeftBar>
                    <Temperature>{item.fact?.temp}°</Temperature>
                    <WeatherIcon src={SunIcon} />
                  </LeftBar>
                  <RightBar>
                    <TopBar>
                      <Position>
                        <img src={WindIcon} />
                        <p>{item.fact?.windSpeed} м/c</p>
                      </Position>
                      {/* <Position>*/}
                      {/*  <img src={RainIcon} />*/}
                      {/*  <p>{getPrecByHour(widgets[0].forecasts[0].hours)} мм</p>*/}
                      {/* </Position>*/}
                      <Position>
                        <img src={HumidityIcon} />
                        <p>{item.fact?.humidity} %</p>
                      </Position>
                    </TopBar>
                    <Separator />
                    <MapWrapper>
                      <img src={MapIcon} alt="" />
                      <p data-test-id="widget-small-name">
                        {config.positions[index]?.aliasName ||
                          (item.geoObject?.district && item.geoObject?.district?.name) ||
                          (item.geoObject?.locality && item.geoObject?.locality?.name) ||
                          (item.geoObject?.province && item.geoObject?.province?.name) ||
                          (item.geoObject?.country && item.geoObject?.country?.name) ||
                          'Неопределено'}
                      </p>
                    </MapWrapper>
                  </RightBar>
                </Content>
              </Wrapper>
            );
          })}
        </Slider>
      </CarouselProvider>
    </CarouselWrapperSmall>
  );
});
