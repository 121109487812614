import styled from 'styled-components';

import { Colors } from '../../../modules/shared/consts/color';
import { Breakpoints } from '../../../modules/shared/consts/sizes';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  user-select: none;
`;

export const CarouselWrapper = styled.div`
  height: 64px;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: ${Breakpoints.small}) {
    margin-bottom: 32px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 32px;
  height: 64px;
  align-items: center;
  margin-bottom: 12px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  user-select: none;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;
export const HeaderTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.black};
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// PLANT PROTECTION SECTION

// FORECAST SECTION
