import React, { FC, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import SunIcon from '../assets/icons/sun.svg';
import MapIcon from '../assets/icons/map.svg';
import WindIcon from '../assets/icons/wind.svg';
import RainIcon from '../assets/icons/rain.svg';
import HumidityIcon from '../assets/icons/humidity.svg';
import DewIcon from '../assets/icons/dew.svg';
import SunRiseIcon from '../assets/icons/sunrise.svg';
import SunSetIcon from '../assets/icons/sunset.svg';
import { useStore } from '../../../../modules/shared/utils/IoC';
import { WidgetWeatherStore } from '../../../stores/WidgetWeatherStore';
import { WeatherEngToRus } from '../../../utils/WeatherEngToRus';
import { WeatherEditWidget } from '../WeatherEditWidget/WeatherEditWidget';
import {
  ArrowButton,
  CarouselWrapper,
  GeoPointsWrapper,
  GeoPointWrapper,
} from '../../../../modules/main/modules/dashboard/pages/profile/pages/EditWidgets/style';
import { ProfileRoutes } from '../../../../modules/main/modules/dashboard/pages/profile/routes';
import { WeatherConditionToIllustration } from '../../../utils/WeatherConditionToIllustrationV2';
import { RootStoresContext } from '../../../../modules/App';

import {
  Wrapper,
  Illustration,
  Content,
  LeftBar,
  Temperature,
  WeatherTitleWrapper,
  FirstLine,
  SecondLine,
  RightBar,
  Line,
  Separator,
  HideLessThanContentSpan,
} from './style';

type WeatherBigWidgetProps = {
  slot: number;
  config?: any;
};

export const WeatherBigWidget: FC<WeatherBigWidgetProps> = observer(({ slot, config }) => {
  const store = useStore(WidgetWeatherStore);
  const { uiStateStore } = useContext(RootStoresContext);
  const { isMobileDevice, isWidthLessThanContent } = uiStateStore;
  const { widgetIdToWeather, slotToId } = store;
  const widgets = widgetIdToWeather.get(slot.toString()) as [];
  const [isSliderPlaying, setIsSliderPlaying] = useState(true);

  if (!widgets) {
    return null;
  }

  const history = useHistory();
  const getPrecByHour = (hours: any) => {
    const dateNow = new Date();
    // const variant = {
    //   '0': 'без осадков',
    //   '1': 'дождь',
    //   '2': 'дождь со снегом',
    //   '3': 'снег',
    //   '4': 'град',
    // };

    // return variant[hours[dateNow.getHours()].precType]
    //   ? variant[hours[dateNow.getHours()].precType]
    //   : variant['0'];

    return hours[dateNow.getHours()].precType || '0';
  };

  const precType = (id: string) => {
    const precTypeMap: { [key: string]: string } = {
      '0': 'без осадков',
      '1': 'дождь',
      '2': 'дождь со снегом',
      '3': 'снег',
      '4': 'град',
    };

    return precTypeMap[id];
  };
  const onMouseEnterHandler = () => {
    setIsSliderPlaying(false);
  };

  const onMouseLeaveHandler = () => {
    setIsSliderPlaying(true);
  };

  if (slot === 1 && !isWidthLessThanContent) {
    return (
      <CarouselWrapper>
        <CarouselProvider
          isPlaying={isSliderPlaying}
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={widgets.length}
          dragEnabled={false}
          touchEnabled={!isMobileDevice}
        >
          <Slider onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
            {widgets.map((item: any, index) => {
              return (
                <Slide index={0}>
                  <Wrapper
                    onClick={() =>
                      history.push(
                        `${ProfileRoutes.FullEditWeatherWidgets}?id=${slotToId.get(
                          `${slot}`
                        )}&point=${item.linkId}`
                      )
                    }
                    data-test-id="widget-wrapper"
                  >
                    <Illustration
                      url={WeatherConditionToIllustration(
                        item.fact?.condition,
                        item.fact?.obsTime,
                        'king'
                      )}
                    />
                    <Content>
                      <LeftBar>
                        <FirstLine>
                          <Temperature>{item.fact?.temp}°</Temperature>
                          <WeatherTitleWrapper>
                            <img src={SunIcon} alt="" />
                            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                            <p>
                              {item.fact && item.fact.condition
                                ? WeatherEngToRus(item.fact.condition!)
                                : ''}
                            </p>
                          </WeatherTitleWrapper>
                        </FirstLine>
                        <SecondLine>
                          <img src={MapIcon} alt="" />
                          <p data-test-id="widget-map-name">
                            {config.positions[index]?.aliasName ||
                              (item.geoObject?.district && item.geoObject?.district.name) ||
                              (item.geoObject?.locality && item.geoObject?.locality.name) ||
                              (item.geoObject?.province && item.geoObject?.province.name) ||
                              (item.geoObject?.country && item.geoObject?.country.name) ||
                              'Неопределено'}
                          </p>
                        </SecondLine>
                      </LeftBar>
                      <RightBar>
                        <Line>
                          <img src={WindIcon} alt="" />
                          <p>
                            <HideLessThanContentSpan>Ветер</HideLessThanContentSpan>{' '}
                            <span>{item.fact?.windSpeed} м/с</span>
                          </p>
                        </Line>
                        <Line>
                          <img src={RainIcon} alt="" />
                          <p>
                            <HideLessThanContentSpan>Осадки</HideLessThanContentSpan>{' '}
                            <span>{precType(item.fact?.precType)}</span>
                          </p>
                        </Line>
                        <Line>
                          <img src={HumidityIcon} alt="" />
                          <p>
                            <HideLessThanContentSpan>Влажность</HideLessThanContentSpan>{' '}
                            <span>{item.fact?.humidity} %</span>
                          </p>
                        </Line>
                        <Line hideOnMobile>
                          <img src={DewIcon} alt="" />
                          <p>
                            <HideLessThanContentSpan>Точка росы </HideLessThanContentSpan>
                            <span>{item.fact?.dewPoint && item.fact?.dewPoint.toFixed(0)}° С</span>
                          </p>
                        </Line>
                        <Separator hideOnMobile />
                        <Line hideOnMobile>
                          <img src={SunRiseIcon} alt="" />
                          <p>
                            <HideLessThanContentSpan>Восход </HideLessThanContentSpan>
                            <span>{item.forecasts && item.forecasts[0].sunrise}</span>
                          </p>
                        </Line>
                        <Line hideOnMobile>
                          <img src={SunSetIcon} alt="" />
                          <p>
                            <HideLessThanContentSpan>Закат </HideLessThanContentSpan>
                            <span>{item.forecasts && item.forecasts[0].sunset}</span>
                          </p>
                        </Line>
                      </RightBar>
                    </Content>
                  </Wrapper>
                </Slide>
              );
            })}
          </Slider>
          {widgets.length > 1 && (
            <GeoPointsWrapper>
              <ButtonBack>
                <ArrowButton>
                  <Icon size="large" icon="arrow_left" fill="#fff" />
                </ArrowButton>
              </ButtonBack>
              <GeoPointWrapper>
                <DotGroup dotNumbers={false} />
              </GeoPointWrapper>
              <ButtonNext>
                <ArrowButton>
                  <Icon size="large" icon="arrow_right" fill="#fff" />
                </ArrowButton>
              </ButtonNext>
            </GeoPointsWrapper>
          )}
        </CarouselProvider>
      </CarouselWrapper>
    );
  } else {
    return (
      <CarouselWrapper>
        <CarouselProvider
          isPlaying
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={widgets.length}
          dragEnabled={false}
          touchEnabled={!isMobileDevice}
        >
          <Slider>
            {widgets.map((item: any, index) => {
              return (
                <Slide index={0}>
                  <WeatherEditWidget
                    time={item.fact.obsTime}
                    isBottomVisible={false}
                    condition={item.fact.condition}
                    dew={item.fact.dewPoint && item.fact.dewPoint.toFixed(0)}
                    geoPoint={
                      config.positions[index]?.aliasName ||
                      (item.geoObject.district && item.geoObject.district.name) ||
                      (item.geoObject.locality && item.geoObject.locality.name) ||
                      (item.geoObject.province && item.geoObject.province.name) ||
                      (item.geoObject.country && item.geoObject.country.name) ||
                      'Неопределено'
                    }
                    humidity={item.fact.humidity}
                    rain={getPrecByHour(item.forecasts[0].hours)}
                    sunrise={item.forecasts[0].sunrise}
                    sunset={item.forecasts[0].sunset}
                    temperature={item.fact.temp}
                    wind={item.fact.windSpeed}
                    isActive={true}
                    toggleGetPoint={() => 1}
                    deletePoint={() => 1}
                    changeGeoPoint={() => 1}
                    onClick={() =>
                      history.push(
                        `${ProfileRoutes.FullEditWeatherWidgets}?id=${slotToId.get(
                          `${slot}`
                        )}&point=${item.linkId}`
                      )
                    }
                  />
                </Slide>
              );
            })}
          </Slider>
          {widgets.length > 1 && (
            <GeoPointsWrapper>
              <ButtonBack>
                <ArrowButton>
                  <Icon size="large" icon="arrow_left" fill="#fff" />
                </ArrowButton>
              </ButtonBack>
              <GeoPointWrapper>
                <DotGroup dotNumbers={false} />
              </GeoPointWrapper>
              <ButtonNext>
                <ArrowButton>
                  <Icon size="large" icon="arrow_right" fill="#fff" />
                </ArrowButton>
              </ButtonNext>
            </GeoPointsWrapper>
          )}
        </CarouselProvider>
      </CarouselWrapper>
    );
  }
});
