import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Input, Button, Separator, Checkbox } from '@uralhim-innovative/farmik-ui';

import creatingIcon from '../../../../../../../../../shared/assets/images/creating.png';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { CreateOrganizationFormStore } from '../../stores/CreateOrganizationFormStore';
import { OrgNameInput } from '../../../../../../../../../shared/components/OrgNameInput';

import {
  CreateOraganizationWizardWrapper,
  CreateOraganizationWizardHeader,
  CreateOraganizationWizardFormWrapper,
  CreateOraganizationWizardActions,
  CreateOraganizationWizardInputsGrid,
  CreateOraganizationWizardStepCurrent,
  CreateOraganizationWizardStepTotal,
  CreateOraganizationWizardSteps,
  FieldWrapper,
  ButtonWrapper,
  ErrorLabel,
} from './style';

type CreateOraganizationWizardProps = {
  hideWizard: () => void;
};

export const CreateOraganizationWizard: FC<CreateOraganizationWizardProps> = observer(
  ({ hideWizard }) => {
    const store = useStore(CreateOrganizationFormStore);
    const {
      name,
      setName,
      INN,
      setINN,
      KPP,
      setKPP,
      OGRN,
      setOGRN,
      OKVED,
      setOKVED,
      OKPO,
      setOKPO,
      addressJur,
      setAddressJur,
      addressFact,
      setAddressFact,
      createOrganization,
      orgTypes,
      getOrgTypes,
      errors,
      resetFields,
      // orgTypeId,
      setFormError,
    } = store;

    const [checkNameAndInn, setCheckNameAndInn] = useState(false);
    const [factAddressCompareWithJuristic, setfactAddressCompareWithJuristic] = useState(true);
    const [creatingSuccess, setCreatingSuccess] = useState(false);

    useEffect(() => {
      getOrgTypes();
    }, [getOrgTypes]);

    const handleHideWizard = () => {
      resetFields();
      hideWizard();
    };

    const handleCheckNameAndInn = () => {
      setCheckNameAndInn(true);
    };

    const handleCheckFactAndJuristic = (value: boolean) => {
      setfactAddressCompareWithJuristic(value);
    };

    const handleSubmit = () => {
      createOrganization()
        .then(() => {
          setCreatingSuccess(true);
        })
        .catch(err => {
          console.log(err);
          if (err && err.response.data && err.response.data.error.title) {
            setFormError(err.response.data.error.source, err.response.data.error.title);
          }
        });
    };

    if (creatingSuccess) {
      return (
        <CreateOraganizationWizardWrapper>
          <img src={creatingIcon} alt="" />
          <CreateOraganizationWizardHeader>
            <span>Вы успешно создали организацию</span>
          </CreateOraganizationWizardHeader>
          <CreateOraganizationWizardActions>
            <ButtonWrapper>
              <Button type="button" color="primary" onClick={handleHideWizard}>
                Хорошо
              </Button>
            </ButtonWrapper>
          </CreateOraganizationWizardActions>
        </CreateOraganizationWizardWrapper>
      );
    }

    return (
      <CreateOraganizationWizardWrapper>
        <CreateOraganizationWizardHeader>
          Создание организации
          <CreateOraganizationWizardSteps>
            <CreateOraganizationWizardStepCurrent>
              {checkNameAndInn ? '2' : '1'}
            </CreateOraganizationWizardStepCurrent>
            <CreateOraganizationWizardStepTotal>/2</CreateOraganizationWizardStepTotal>
          </CreateOraganizationWizardSteps>
        </CreateOraganizationWizardHeader>
        <Separator />
        {!checkNameAndInn ? (
          <>
            <CreateOraganizationWizardFormWrapper>
              <FieldWrapper>
                <OrgNameInput
                  orgTypes={orgTypes}
                  label="Название организации"
                  onChange={setName}
                  value={name}
                  error={errors.name}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Input
                  label="ИНН"
                  placeholder="0000000000"
                  value={INN}
                  onChange={setINN}
                  error={errors.INN}
                />
              </FieldWrapper>
            </CreateOraganizationWizardFormWrapper>
            <CreateOraganizationWizardActions>
              <ButtonWrapper>
                <Button
                  disabled={!name}
                  type="button"
                  color="primary"
                  onClick={handleCheckNameAndInn}
                >
                  Далее
                </Button>
              </ButtonWrapper>

              <ButtonWrapper>
                <Button type="button" color="default" onClick={handleHideWizard}>
                  Отмена
                </Button>
              </ButtonWrapper>
            </CreateOraganizationWizardActions>
          </>
        ) : (
          <>
            <CreateOraganizationWizardFormWrapper>
              <FieldWrapper>
                <OrgNameInput
                  orgTypes={orgTypes}
                  label="Название организации"
                  onChange={setName}
                  value={name}
                  error={errors.name}
                />
              </FieldWrapper>

              <CreateOraganizationWizardInputsGrid>
                <Input
                  label="ИНН"
                  placeholder="0000000000"
                  value={INN}
                  onChange={setINN}
                  error={errors.INN}
                />
                <Input
                  label="КПП"
                  placeholder="0000000000"
                  value={KPP}
                  onChange={setKPP}
                  error={errors.KPP}
                />
                <Input
                  label="ОГРН"
                  placeholder="0000000000"
                  value={OGRN}
                  onChange={setOGRN}
                  error={errors.OGRN}
                />
                <Input
                  label="ОКВЭД"
                  placeholder="0000000000"
                  value={OKVED}
                  onChange={setOKVED}
                  error={errors.OKVED}
                />
              </CreateOraganizationWizardInputsGrid>
              <FieldWrapper>
                <Input
                  label="ОКПО"
                  placeholder="0000000000"
                  value={OKPO}
                  onChange={setOKPO}
                  error={errors.OKPO}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Input
                  label="Юридический адрес"
                  placeholder="337483, Москва, ул.Строительная, д.5, к.3"
                  value={addressJur}
                  onChange={setAddressJur}
                  error={errors.addressJur}
                />
              </FieldWrapper>

              {!factAddressCompareWithJuristic && (
                <FieldWrapper>
                  <Input
                    label="Фактический адрес"
                    placeholder="337483, Москва, ул.Строительная, д.5, к.3"
                    value={addressFact}
                    onChange={setAddressFact}
                    error=""
                  />
                </FieldWrapper>
              )}

              <FieldWrapper>
                <Checkbox
                  label="Фактический адрес совпадает с юридическим"
                  onChange={handleCheckFactAndJuristic}
                  value={factAddressCompareWithJuristic}
                  defaultValue={factAddressCompareWithJuristic}
                />
              </FieldWrapper>

              {errors.formError && <ErrorLabel>{errors.formError}</ErrorLabel>}
            </CreateOraganizationWizardFormWrapper>
            <CreateOraganizationWizardActions>
              <ButtonWrapper>
                <Button type="button" color="primary" onClick={handleSubmit}>
                  Создать
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button type="button" color="default" onClick={handleHideWizard}>
                  Отмена
                </Button>
              </ButtonWrapper>
            </CreateOraganizationWizardActions>
          </>
        )}
      </CreateOraganizationWizardWrapper>
    );
  }
);
