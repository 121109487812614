import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeNews } from '../modules/main/modules/news/types/type-news';

type TypeRequest = {
  id: string;
};

type TypeResponse = TypeNews;

export const getNewsById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-news/news/${request.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
