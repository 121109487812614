import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/consts/color';
import { EditPersonalDataEditFormActionButton } from '../../../../../profile/pages/editPersonalData/style';

export const NameLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-top: 28px;
  margin-bottom: 28px;
`;

export const ModalDescription = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${Colors.lightnessGray};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto 30px;
`;

export const ModalButtonWrapper = styled(EditPersonalDataEditFormActionButton)`
  width: 100%;
  margin-left: 0;
  margin-bottom: 10px;
`;

export const ModalButton = styled.div`
  & button {
    justify-content: center;
  }
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  background: #ffffff;
  border: 0.5px solid ${Colors.lightnessGray};
  border-top: none;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 5px;
  margin-top: -10px;
  margin-left: -17px;
  text-align: center;
  color: ${Colors.darkGray};
  font-size: 12px;
  position: absolute;
  top: 31px;
  left: 0;
  z-index: 100;

  & span {
    margin-bottom: 10px;
    cursor: pointer;
  }
`;
