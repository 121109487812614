import { FC, ReactNode } from 'react';

import { PaperBlock } from '../PaperBlock';

import { Wrapper, TableHeader, TableItem, TableRow, TableBody, EmptyTableMessage } from './style';

type RowsType = ReactNode[];

type TableProps = {
  headers: string[];
  rows: RowsType[];
  ids: string[];
  emptyListMessage?: string;
};
const hide_colums = new Set([2, 3]);
export const Table: FC<TableProps> = ({ headers, rows, ids, emptyListMessage }) => (
  <Wrapper>
    <PaperBlock>
      <TableHeader className="tableHeader" columns={headers.length}>
        {headers.map((header, index) => (
          <TableItem hideonMobile={hide_colums.has(index)} key={header}>
            {header}
          </TableItem>
        ))}
      </TableHeader>

      <TableBody className="tableBody">
        {ids.length ? (
          rows.map((row, index) => (
            <TableRow className="tableRow" key={ids[index]} columns={headers.length}>
              {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
              {row.map((item, index) => (
                <TableItem hideonMobile={hide_colums.has(index)}>{item}</TableItem>
              ))}
            </TableRow>
          ))
        ) : (
          <EmptyTableMessage>
            {emptyListMessage || 'Не удалось отобразить таблицу'}
          </EmptyTableMessage>
        )}
      </TableBody>
    </PaperBlock>
  </Wrapper>
);
