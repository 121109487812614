import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../../../../../../../shared/consts/sizes';

export const ControlBarWrapper = styled.div`
  width: 100%;
  background: ${Colors.white};
  height: 72px;
  position: sticky;
  top: 92px;
  display: flex;
  left: 0;
  padding: 16px 28px 16px 28px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 99;
  align-items: center;

  @media (max-width: ${Breakpoints.small}) {
    left: -16px;
    right: -16px;
    width: auto;
    border-radius: 0;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const SearchTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0 0 4px;
`;

export const SearchDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
`;

export const SearchFieldWrapper = styled.div`
  width: 408px;
  margin-left: 40px;
  margin-right: 40px;

  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
    margin-right: 0;
    margin-left: 10px;
  }
`;

export const CancelButton = styled.div`
  color: ${Colors.darkGray};
  fill: ${Colors.darkGray};
  cursor: pointer;
  display: flex;
  align-items: center;

  & p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-left: 5px;
    line-height: 16px;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const HideOnSmall = styled.span`
  @media (max-width: ${Breakpoints.medium}) {
    display: none;
  }
`;
