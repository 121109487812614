import { FC } from 'react';

export const AvatarAddIcon: FC = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="30" fill="white" />
    <path
      d="M19.333 30C19.333 31.1782 20.2881 32.1333 21.4663 32.1333H27.8663V38.5333C27.8663 39.7116 28.8214 40.6667 29.9997 40.6667C31.1779 40.6667 32.133 39.7116 32.133 38.5333V32.1333H38.533C39.7112 32.1333 40.6663 31.1782 40.6663 30C40.6663 28.8218 39.7112 27.8667 38.533 27.8667H32.133V21.4667C32.133 20.2884 31.1779 19.3333 29.9997 19.3333C28.8214 19.3333 27.8663 20.2884 27.8663 21.4667V27.8667H21.4663C20.2881 27.8667 19.333 28.8218 19.333 30Z"
      fill="#E9E6F0"
    />
  </svg>
);
