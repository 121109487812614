import queryString from 'querystring';

import { FC, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { Link } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import { RootStoresContext } from '../../../modules/App';
import { Forecast } from '../../components/FullWeatherPage/Forecast/Forecast';
import { PlantProtection } from '../../components/FullWeatherPage/PlantProtection/PlantProtection';
import { MainInformation } from '../../components/FullWeatherPage/MainInformation/MainInformation';
import { ProfileRoutes } from '../../../modules/main/modules/dashboard/pages/profile/routes';
import { useStore } from '../../../modules/shared/utils/IoC';
import { WidgetWeathersFullStore } from '../../stores/WidgetWeathersFullStore';
import { Slider } from '../../components/FullWeatherPage/Slider/Slider';
import { WindDirectionEngToRus } from '../../utils/WindDirectionEngToRus';
import { BreakpointsNumbers } from '../../../modules/shared/consts/sizes';
import { UiStore } from '../../../modules/shared/stores/ui.store';
import { ProfileMenuMobilePageHeaderWrapper } from '../../../modules/main/modules/dashboard/pages/profile/components/ProfileLeftMenu/style';
import { MobilePageHeader } from '../../../modules/shared/components/MobilePageHeader/MobilePageHeader';
import { MainRoutes } from '../../../modules/main/routes';

import { PageWrapper, CarouselWrapper, HeaderTitle, HeaderWrapper } from './style';

export const FullEditWeathersPage: FC = observer(() => {
  const { uiStateStore } = useContext(RootStoresContext);
  const { deviceWidth } = uiStateStore;
  const { headerComponentStore } = useContext(RootStoresContext);
  const { setHeaderContent } = headerComponentStore;
  const uiStore = useStore(UiStore);

  const history = useHistory();
  const store = useStore(WidgetWeathersFullStore);
  const {
    fetchWidget,
    selectGeoPoint,
    geoPointsSlider,
    weatherCard,
    coordinates,
    plantProtection,
    aliasName,
    weathers,
  } = store;

  useEffect(() => {
    if (uiStore.windowWidth <= BreakpointsNumbers.small) {
      setHeaderContent(
        <ProfileMenuMobilePageHeaderWrapper>
          <MobilePageHeader
            title={'Погода'}
            onBack={() => {
              history.push(MainRoutes.main);
            }}
          />
        </ProfileMenuMobilePageHeaderWrapper>,
        {
          height: '56px',
        }
      );
    }
    // getSpecializations();
    return () => {
      setHeaderContent(null, {
        height: '76px',
      });
    };
  }, []);

  useEffect(() => {
    const params: any = queryString.parse(location.search.substr(1));

    if (!params.id || !params.point) {
      history.push(ProfileRoutes.Widgets);
    }

    fetchWidget(params.id);
    selectGeoPoint(params.point === 'undefined' ? params.id : params.point);
  }, []);

  const renderTitle = () =>
    aliasName ||
    (weatherCard.geoObject.district && weatherCard.geoObject.district.name) ||
    (weatherCard.geoObject.locality && weatherCard.geoObject.locality.name) ||
    (weatherCard.geoObject.province && weatherCard.geoObject.province.name) ||
    (weatherCard.geoObject.country && weatherCard.geoObject.country.name) ||
    'Неизвестно';

  return (
    <PageWrapper>
      <CarouselWrapper>
        <Slider cards={weathers.length ? geoPointsSlider : []} onClick={id => selectGeoPoint(id)} />
      </CarouselWrapper>
      {weatherCard && (
        <>
          <HeaderWrapper>
            <Link
              onClick={() => history.push(`${ProfileRoutes.Widgets}`)}
              label={deviceWidth < BreakpointsNumbers.medium ? 'Назад' : 'Назад ко всем виджетам'}
              color={'default'}
              prefixIcon={'arrow_left'}
              underlined={true}
            />
            <HeaderTitle>{renderTitle()}</HeaderTitle>
            <div />
            {/* <Link*/}
            {/*  onClick={() => 1}*/}
            {/*  label={'Изменить виджет'}*/}
            {/*  color={'primary'}*/}
            {/*  underlined={false}*/}
            {/* />*/}
          </HeaderWrapper>
          <MainInformation
            condition={weatherCard.fact?.condition}
            geoPoint={renderTitle()}
            sunset={weatherCard.forecasts[0].sunset}
            sunrise={weatherCard.forecasts[0].sunrise}
            temperature={weatherCard.fact?.temp}
            feelsLike={weatherCard.fact?.feelsLike}
            humidity={weatherCard.fact?.humidity}
            windGust={weatherCard.fact?.windGust}
            windSpeed={weatherCard.fact?.windSpeed}
            pressure={weatherCard.fact?.pressureMm}
            rain={weatherCard.fact?.precStrength}
            rainChance={weatherCard.fact?.precType}
            windDirection={
              weatherCard.fact?.windDirection &&
              WindDirectionEngToRus(weatherCard.fact?.windDirection)
            }
            coordinates={coordinates}
            dew={weatherCard.fact?.dewPoint}
          />
          <PlantProtection parts={plantProtection} />
          <Forecast
            daySunrise={weatherCard.forecasts[0].sunrise}
            daySunset={weatherCard.forecasts[0].sunset}
            dayParts={[
              weatherCard.forecasts[0].parts.night,
              weatherCard.forecasts[0].parts.morning,
              weatherCard.forecasts[0].parts.day,
              weatherCard.forecasts[0].parts.evening,
            ].map(item => {
              return {
                hours: item.hour < 10 ? `0${item.hour}:00` : `${item.hour}:00`,
                feelsLike: item.feelsLike,
                dew: 0,
                condition: item.condition,
                temperature: item.tempAvg,
                humidity: item.humidity,
                windGust: item.windGust,
                windSpeed: item.windSpeed,
                pressure: item.pressureMm,
                windDirection: item.windDirection,
                changeOfRain: item.cloudness * 100,
                title: '',
                precType: item.precType,
              };
            })}
          />
        </>
      )}
    </PageWrapper>
  );
});
