import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  id: string;
};

type TypeResponse = any;

export const hideBanner: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-notification-dispatcher/banners/hide/${request.id}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
