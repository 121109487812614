import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';

type TypeRequest = {
  size?: any;
  page?: any;
  statusRead?: boolean;
  notificationGroup?: string;
  notificationType?: string;
};

type TypeResponse = {
  content: Array<{
    id: string;
    notificationType: string;
    senderName: string;
    senderAvatar: {
      id: string;
      fileName: string;
      fileContentType: string;
      fileSize: number;
      downloadUrl: string;
    };
    contentText: string;
    contentHtml: string;
    statusRead: boolean;
    creationTime: string;
  }>;
} & TypeResponsePageable;

export const getNotificationList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req =>
    `/api/da-notification-dispatcher/notifications/list?size=${req.size}&page=${req.page}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
