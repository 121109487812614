import styled from 'styled-components';

import { Colors } from '../../../../../shared/consts/color';
import { Breakpoints } from '../../../../../shared/consts/sizes';

export const Wrapper = styled.div`
  padding: 20px 28px 16px;
  text-align: center;

  & button {
    justify-content: center;
    max-width: 248px;
    margin: 0 auto;
  }

  & a {
    text-decoration: none;
  }
`;

export const SubServiceImage = styled.img`
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 60px;
  margin: 0 auto 16px;
`;

export const SubServiceTitle = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  min-height: 32px;
  line-height: 16px;
  padding: 0 15px;
`;

export const SubServiceDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
  font-weight: 300;
  min-height: 32px;
  padding: 0 15px;
  height: 32px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
