import styled from 'styled-components';

import { Colors } from '../../consts/color';

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.darkGray};
`;

export type FieldStyledProps = {
  isOpened: boolean;
};

const openedState = `
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
`;

const closeState = `
  border-radius: 12px;
`;

export const Field = styled.div<FieldStyledProps>`
  background: ${Colors.gray};
  border: 0.5px solid ${Colors.grayBorder};
  ${({ isOpened }) => (isOpened ? openedState : closeState)};
  box-sizing: border-box;
  height: 40px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
`;

export const Placeholder = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.darkGray};
`;

export const Value = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.black};
`;

export const SelectBody = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border: 1px solid ${Colors.grayBorder};
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: ${Colors.gray};
  z-index: 100;
`;

export const SelectBodyInnerWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 0.5px solid ${Colors.grayBorder};
  background: ${Colors.gray};
  width: 100%;
`;

export const Option = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  color: ${Colors.darkGray};

  &:hover {
    color: ${Colors.green};
  }
`;

export const ItemText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
`;

export const Error = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.red};
`;

type WrapperStyledProps = {
  isErrorVisible: boolean;
};

export const Wrapper = styled.div<WrapperStyledProps>`
  position: relative;
  outline: none;
  & * {
    box-sizing: border-box;
  }

  user-select: none;
  ${({ isErrorVisible }) =>
    isErrorVisible &&
    `
    & ${Field} {
      border-color: ${Colors.red};
      
    }
    
    & ${Value} {
      color: ${Colors.red}
    } 
    
    & ${SelectBody} {
      border-color: ${Colors.red};
    }
  `}
`;
