import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TypeApplication } from '../../../../../../../../shared/types/applications';
import { Axios2 } from '../../../../../../../../shared/utils/axios2';

@provide.singleton()
export class OrganizationApplicationStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  applications: TypeApplication[] = [];

  getApplications = (organizationId: string) => {
    this.axios2.api.getOrganizationApplications({ organizationId }).then(response => {
      this.applications = response.content;
    });
  };

  acceptApplication = (applicationId: string) =>
    this.axios2.api.updateApplicationAccept({ applicationId });

  declineApplication = (applicationId: string) =>
    this.axios2.api.updateApplicationDecline({ applicationId });

  cancelInvitation = (applicationId: string) =>
    this.axios2.api.updateInvitationCancel({ applicationId });
}
