import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';
import { TypeOrganization } from '../modules/shared/types/organization';

type TypeRequest = {
  nameOrInn: string;
};

type TypeResponse = { content: Array<TypeOrganization> } & TypeResponsePageable;

export const getOrganizations: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/organizations/list`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
