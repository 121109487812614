import { lazyInject, provide } from '../../../shared/utils/IoC';
import { Axios2 } from '../../../shared/utils/axios2';
import { SessionStore } from '../../../authorization/stores/session.store';

@provide.singleton()
export class MainPreloadController {
  @lazyInject(Axios2)
  axios: Axios2;

  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  checkIsUserLoginned = async () => {
    // is has AccessToken
    const accessToken = localStorage.getItem('loginAs') || localStorage.getItem('accessToken');

    if (!accessToken) {
      this.sessionStore.onCheckIsLoggedInError();

      return Promise.reject(new Error('cant find accessToken in localStorage'));
    }

    try {
      // is token valid
      await this.axios.api.verifyAccessToken({});
      this.sessionStore.onCheckPass();

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(new Error(e));
    }
  };

  checkIsNoNeedAdditionalInfo = async () => {
    try {
      const response = await this.axios.api.getProfile({});

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response.headers['x-need-additional-info']);
    }
  };

  checkAuthKey = async (authKey: string) => {
    try {
      const authByKeyResponse = await this.axios.api
        .erqToken({
          code: authKey,
        })
        .then(response => response);

      localStorage.setItem('accessToken', authByKeyResponse['access-token']);
      localStorage.setItem('refreshToken', authByKeyResponse['refresh-token']);

      return Promise.resolve();
    } catch (e) {
      window.history.replaceState({}, document.title, '/');
      return Promise.reject(new Error(e));
    }
  };
}
