// @ts-ignore
import { TypeApiRoute, TypeResponsePageable } from '../models';

type TypeRequest = {
  size?: number;
  page?: number;
  cultureId?: string;
  basisId?: string;
  actualOnly: boolean;
  economicRegionId?: string;
  cultureClassId?: string;
};

export type QuoteType = {
  id: string;
  culture: {
    id: string;
    name: string;
  };
  cultureClassId?: {
    id: string;
    name: string;
  };
  basis: {
    id: string;
    name: string;
  };
  region: {
    id: string;
    name: string;
  };
  provider: {
    id: string;
    name: string;
  };
  port: {
    id: string;
    name: string;
  };
  currency: {
    id: string;
    name: string;
  };
  units: {
    id: string;
    name: string;
  };
  qualityMin: number;
  qualityMax: number;
  price: number;
  vat: boolean;
  dateOfObs: string;
};

type TypeResponse = { content: Array<QuoteType> } & TypeResponsePageable;

export const getQuotesList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    request.size && request.page
      ? `/api/da-quotes/quotes/list?size=${request.size}&page=${request.page}`
      : '/api/da-quotes/quotes/list',
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
