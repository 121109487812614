import { FC } from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import { observer } from 'mobx-react';

import { PaperBlock } from '../../../../../../../shared/components/PaperBlock';
import { List, ListItem } from '../../../../../../../shared/components/List';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { OrganizationStore } from '../../stores/organization.store';
import { OrganizationRoutes } from '../../organization.routes';
import EditSvg from '../../../../../../../shared/assets/images/edit.svg';
import { UserInfo } from '../../../../../news/components/UserInfo';
import { AvatarWrapper } from '../../../profile/components/PersonalDataWidget/style';

import { EditButton, ListWrapper, PageTitle, Title, Wraper, LongTitle, ShortTitle } from './style';

export const OrganizationInfo: FC<RouteComponentProps> = observer(() => {
  // @ts-ignore
  const { organizationId } = useParams();
  const store = useStore(OrganizationStore);
  const { organization, organizationUpdateInProgress } = store;

  if (organizationUpdateInProgress) {
    return <>Загрузка...</>;
  }

  if (!organizationUpdateInProgress && !organization) {
    return (
      <PaperBlock>
        <Wraper>Не удалось загрузить данные о компании</Wraper>
      </PaperBlock>
    );
  }

  const organizationReq: ListItem[] = [
    {
      key: 'ИНН',
      value: organization?.INN || '',
    },
    {
      key: 'КПП',
      value: organization?.KPP || '',
    },
    {
      key: 'ОГРН',
      value: organization?.OGRN || '',
    },
    {
      key: 'ОКВЭД',
      value: organization?.OKVED || '',
    },
    {
      key: 'ОКПО',
      value: organization?.OKPO || '',
    },
    {
      key: 'Юридический адрес',
      value: organization?.addressJur?.addressfull || '',
    },
    {
      key: 'Фактический адрес',
      value: organization?.addressFact?.addressfull || '',
    },
  ];

  return (
    <Wraper>
      <PageTitle>
        Данные компании
        {organization?.canEdit && (
          <EditButton to={`${OrganizationRoutes.main}/${organizationId}/edit`}>
            <img src={EditSvg} alt="" />
            <LongTitle>Редактировать</LongTitle>
            <ShortTitle>Ред.</ShortTitle>
          </EditButton>
        )}
      </PageTitle>
      <AvatarWrapper data-test-id="profile-avatar-wrapper">
        <UserInfo
          userName={(organization && organization?.name) || ''}
          avatar={organization && organization.logo && organization.logo.downloadUrl}
          size={'large'}
        />
      </AvatarWrapper>
      {/* <Title>{organization?.name}</Title>*/}
      <ListWrapper>
        <List items={organizationReq} />
      </ListWrapper>
    </Wraper>
  );
});
