import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  smsEnabled?: boolean;
  emailEnabled?: boolean;
  pushEnabled?: boolean;
  socketEnabled?: boolean;
  blackList: any | Array<string>;
};

type TypeResponse = {};

export const sendNotificationSettings: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-notification-dispatcher/config`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
