import { TypeApiRoute } from '../models';
import { Zoom } from '../widgets/pages/FullEditQuotesPage/components/Chart/Chart';

type TypeRequest = {
  id: string;
  freq: Zoom;
};

type TypeResponse1 = {
  freq: Zoom;
  values: Array<{ date: string; price: number }>;
};

export const getPriceHistory: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse1;
} = {
  url: req => `/api/da-quotes/quotes/${req.id}/priceHistory`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse1,
};
