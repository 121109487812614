import styled from 'styled-components';

import { Colors } from '../../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../../modules/shared/consts/sizes';

export const Wrapper = styled.div`
  width: 100%;
  height: 178px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 24px 32px 32px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  @media (max-width: ${Breakpoints.small}) {
    padding: 24px 16px 16px;
    height: auto;
  }
`;

export const LeftBar = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const RightBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 56px;
  color: ${Colors.black};
  margin-bottom: 22px;
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${Breakpoints.small}) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 8px;
    margin-top: 0;
  }
`;

export const PriceLine = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

export const Price = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: ${Colors.black};
  margin: 0 12px 0 0;

  @media (max-width: ${Breakpoints.small}) {
    display: inline-block;
  }
`;

type StonksProps = {
  direction: string;
};

export const LeftBackButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-right: 4px;
  margin-left: 4px;
  color: ${Colors.darkGray};
  align-items: center;
  display: flex;
  margin: 0;
  text-decoration: underline;
  & > div {
    margin-right: 12px;
  }

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

export const Stonks = styled.p<StonksProps>`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0 32px 0 0;

  color: ${({ direction }) => {
    switch (direction) {
      case 'ASC':
        return Colors.green;

      case 'DESC':
        return Colors.red;

      default:
        return Colors.darkGray;
    }
  }};

  @media (max-width: ${Breakpoints.small}) {
    display: inline-block;
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
  margin-left: 20px;

  @media (max-width: ${Breakpoints.small}) {
    margin: 12px 0 0;
  }
`;

export const Icon = styled.img`
  position: absolute;
  transform: rotate(-90deg);
  height: 150px;
  bottom: -18px;
  right: 0;
`;
