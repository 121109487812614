import { action, makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';
import { WidgetType } from '../../api';
import { WidgetTypes } from '../models/WidgetTypes';
import { WeatherConfig } from '../models/WeatherConfig';

import { WidgetWeatherStore } from './WidgetWeatherStore';
import { WidgetQuoteStore } from './WidgetQuoteStore';

@provide.singleton()
export class WidgetGridStore {
  @lazyInject(Axios2)
  axios: Axios2;

  @lazyInject(WidgetWeatherStore)
  weatherStore: WidgetWeatherStore;

  @lazyInject(WidgetQuoteStore)
  quotesStore: WidgetQuoteStore;

  constructor() {
    makeAutoObservable(this);
  }

  isTopBarVisible = true;

  setIsTopBarVisible = (v: boolean) => {
    this.isTopBarVisible = v;
  };
  widgets: Array<WidgetType> = [
    { type: 'mock', widget_config: '', name: '', id: 0 },
    { type: 'mock', widget_config: '', name: '', id: 0 },
    { type: 'mock', widget_config: '', name: '', id: 0 },
  ];

  fetchCheckWidgets = () => {
    this.widgets = [
      { type: 'mock', widget_config: '', name: '', id: 0 },
      { type: 'mock', widget_config: '', name: '', id: 0 },
      { type: 'mock', widget_config: '', name: '', id: 0 },
    ];
    this.axios.api
      .getCheckPanel({})
      .then(this.onCheckWidgetSuccess)
      .catch(this.onCheckWidgetsError);
  };

  onCheckWidgetSuccess = async (response: TypeApiResponse<'getCheckPanel'>) => {
    await this.fetchDefaultWidgets();
    if (response) {
      this.fetchPanelWidgets();
    }
  };

  onCheckWidgetsError = () => {
    this.fetchDefaultWidgets();
  };

  fetchDefaultWidgets = async () => {
    await this.axios.api
      .getDefaultWidgets({})
      .then(this.onFetchDefaultWidgetsSuccess)
      .catch(this.onFetchDefaultWidgetsError);
  };

  onFetchDefaultWidgetsSuccess = (response: any) => {
    this.handleWidgetInput(response);
  };

  onFetchDefaultWidgetsError = () => {
    console.log('Error -> onFetchDefaultWidgetsError');
  };

  fetchPanelWidgets = () => {
    this.axios.api
      .getPanelWidgets({})
      .then(this.onFetchPanelWidgetsSuccess)
      .catch(this.onFetchPanelWidgetsError);
  };

  slots: Array<string> = [];
  isSelectMockShown = true;

  @action.bound
  onFetchPanelWidgetsSuccess = (response: any) => {
    const slots = Object.keys(response.widgets);
    if (slots.length === 0) {
      this.fetchDefaultWidgets();
      return;
    }
    if (slots.includes('3')) {
      const hasActiveWidgets = JSON.parse(response.widgets[3].widget_config).positions.length
        ? JSON.parse(response.widgets[3].widget_config).positions.find((item: any) => item.isActive)
        : false;

      this.isSelectMockShown = !hasActiveWidgets;
    }
    this.handleWidgetInput(response);
  };

  onFetchPanelWidgetsError = () => {
    console.log('Error -> onFetchPanelWidgetsError');
  };

  @action
  handleWidgetInput = (response: TypeApiResponse<'getDefaultWidgets'>) => {
    const keys: string[] = Object.keys(response.widgets);

    if (!keys) {
      return;
    }

    keys.forEach(key => {
      const widget = response.widgets[key];
      if (!JSON.parse(widget.widget_config).positions.filter(i => i.isActive === true).length) {
        return;
      }

      this.widgets[parseInt(key, 10) - 1] = widget;

      if (widget.type === WidgetTypes.Weather) {
        const config: WeatherConfig = JSON.parse(widget.widget_config) as WeatherConfig;

        const points = config.positions
          .filter(i => i.isActive)
          .map(i => {
            return {
              lat: i.lat,
              lon: i.lon,
              linkId: i.linkId,
            };
          });

        if (points.length > 0) {
          if (points.length === 1) {
            this.weatherStore.fetchWeatherByPoint(
              points[0].lat,
              points[0].lon,
              key,
              widget.id,
              points[0].linkId
            );
          } else {
            this.weatherStore.fetchWeatherByPoints(points, key, widget.id);
          }
        }
      }

      if (widget.type === 'QUOTES') {
        const config: WeatherConfig = JSON.parse(widget.widget_config) as WeatherConfig;

        if (!config?.positions) {
          return;
        }
        const ids = config?.positions.map(i => i.linkId);
        const positions = config?.positions
          .filter(i => i.isActive)
          .map((item: any) => {
            return {
              basisId: item.basisId,
              providerId: item.providerId,
              cultureId: item.cultureId,
              portId: item.portId,
              currencyId: item.currencyId,
              unitsId: item.unitsId,
              economicRegionId: item.economicRegionId,
              cultureClassId: item.cultureClassId,
              vat: item.vat,
            };
          });

        if (positions.length > 0) {
          this.quotesStore.fetchWidgetInfo(key, positions, widget.id, ids);
        }
      }
    });
  };

  @action
  clear = () => {
    console.warn('clear main screen');
    this.widgets = [
      { type: 'mock', widget_config: '', name: '', id: 0 },
      { type: 'mock', widget_config: '', name: '', id: 0 },
      { type: 'mock', widget_config: '', name: '', id: 0 },
    ];
    this.slots = [];
    this.isSelectMockShown = true;
    this.weatherStore.clear();
    this.quotesStore.clear();
  };
}
