import styled from "styled-components";
import {Colors} from "../../consts/color";

export const PanelWrapper = styled.div`
  width: 80px;
  height: 100px;
  transition: 300ms;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background: ${Colors.white};
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  :hover {
    width: 200px;
  }
  p {
    text-align: center;
  }

  div {
    text-align: center;

    button {
      display: inline-block;
    }
  }
`