import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  id: string;
};

type TypeResponse = {
  id: string;
  type: string;
  name: string;
  widget_config: string;
};

export const getWidgetInfo: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/widgets/${request.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
