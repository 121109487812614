import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/consts/color';
import { ApplicationInvitationState } from '../../types/OrganizationCard';
import { Breakpoints } from '../../../../../../../../../shared/consts/sizes';

export type CardStyledProps = {
  type: 'participate' | 'invitation' | 'application' | 'search';
  status?: ApplicationInvitationState;
};

export const Wrapper = styled.div<CardStyledProps>`
  //width: 168px;
  border: 1px solid ${Colors.white};
  //height: 188px;
  background: ${Colors.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  * {
    transition: 300ms;
  }
  ${({ type }) => renderType(type)};

  ${({ status }) =>
    (status === 'SENT' || status === 'ACCEPTED') &&
    `
    & ${OrganizationRole} {
      color: ${Colors.green};
    }
  `}

  ${({ status }) =>
    (status === 'DECLINED' || status === 'CANCELLED') &&
    `
    & ${OrganizationRole} {
      color: ${Colors.red};
    }
  `}
`;

export const AvatarWrapper = styled.div`
  width: 80px;
  height: 60px;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 20px;
  border-radius: 16px;
  text-align: center;
`;

export const Avatar = styled.img`
  max-width: 100%;
`;

export const OrganizationNameWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 8px;
`;

export const OrganizationName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  max-width: 140px;
  text-align: center;
  color: ${Colors.black};
`;

export const OrganizationRole = styled.p`
  margin: 0;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
`;

const TypeParticipate = `
  & ${OrganizationRole} {
    color: ${Colors.darkGray};
  };
  
  & ${OrganizationName} {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
  }
  
  &:hover {
    border: 1px solid ${Colors.green};
    & ${OrganizationName} {
      color: ${Colors.green};
    }
  }
`;

const TypeInvitation = `
  & ${OrganizationRole} {
    color: ${Colors.green};
  };
  
  & ${OrganizationName} {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.darkGray};
  }
  
  & ${Avatar} {
    opacity: 0.5
  }
  
  & {
    background: none !important;
    box-shadow: none;
    border: 1px solid ${Colors.grayBorder};
  }
  
  &:hover {
    border: 1px solid ${Colors.green};
    & ${OrganizationName} {
    
    }
    & ${Avatar} {
      opacity: 1
    }
  }
`;

const TypeSearch = `
  & ${OrganizationRole} {
    color: ${Colors.green};
    text-decoration: underline;
  };
  
  & ${OrganizationName} {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.black};
  }
  
  &:hover {
    border: 1px solid ${Colors.green};
    & ${OrganizationName} {
      color: ${Colors.green};
    }
  }
`;
const renderType = (v: CardStyledProps['type']) => {
  const variants: Record<CardStyledProps['type'], any> = {
    search: TypeSearch,
    participate: TypeParticipate,
    application: TypeInvitation,
    invitation: TypeInvitation,
  };
  return variants[v];
};
