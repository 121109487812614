import { FC, useState } from 'react';
import { Link } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';

import { Competition, DeleteIcon, OkIcon } from '../../../../components/EditCompetitions/style';
import IconDelete from '../../../../assets/delete.svg';
import IconCheckbox from '../../../../assets/checkbox.svg';
import IconCheckboxGreen from '../../../../assets/checkbox-green.svg';

import { Wrapper, Header, Title, Tag, AccardionButton, Container } from './style';

type AccardionProps = {
  title: string;
  tags: Array<any>;
  type: 'interest' | 'expertise';
  onToggle: (id: string, status: boolean) => void;
};

export const Accardion: FC<AccardionProps> = observer(({ title, tags, type, onToggle }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        {tags.length > 5 && (
          <Link
            label={isOpened ? 'Скрыть' : 'Показать все'}
            color={'primary'}
            underlined={true}
            onClick={() => setIsOpened(!isOpened)}
          />
        )}
      </Header>
      <Container>
        {type === 'expertise' ? (
          <>
            {tags.length < 5 &&
              tags.map(item => (
                <Competition
                  style={{ cursor: 'pointer' }}
                  key={item.expertiseId}
                  onClick={() => onToggle(item.expertiseId, !item.isLiked)}
                >
                  <p>{item.expertiseName}</p>
                  <OkIcon src={item.confirmations ? IconCheckboxGreen : IconCheckbox} />
                  <p>{item.confirmations}</p>
                </Competition>
              ))}
            {tags.length >= 5 &&
              isOpened &&
              tags.map(item => (
                <Competition
                  style={{ cursor: 'pointer' }}
                  key={item.expertiseId}
                  onClick={() => onToggle(item.expertiseId, !item.isLiked)}
                >
                  <p>{item.expertiseName}</p>
                  <OkIcon src={item.confirmations ? IconCheckboxGreen : IconCheckbox} />
                  <p>{item.confirmations}</p>
                </Competition>
              ))}
            {tags.length >= 5 &&
              !isOpened &&
              tags.slice(0, 5).map(item => (
                <Competition
                  style={{ cursor: 'pointer' }}
                  key={item.expertiseId}
                  onClick={() => onToggle(item.expertiseId, !item.isLiked)}
                >
                  <p>{item.expertiseName}</p>
                  <OkIcon src={item.confirmations ? IconCheckboxGreen : IconCheckbox} />
                  <p>{item.confirmations}</p>
                </Competition>
              ))}
            {tags.length > 5 && !isOpened && (
              <AccardionButton onClick={() => setIsOpened(true)}>
                +{tags.length - 5}
              </AccardionButton>
            )}
          </>
        ) : (
          <>
            {tags.length < 5 && tags.map(item => <Tag>{item}</Tag>)}
            {tags.length >= 5 && isOpened && tags.map(item => <Tag>{item}</Tag>)}
            {tags.length >= 5 && !isOpened && tags.slice(0, 5).map(item => <Tag>{item}</Tag>)}
            {tags.length > 5 && !isOpened && (
              <AccardionButton onClick={() => setIsOpened(true)}>
                +{tags.length - 5}
              </AccardionButton>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  );
});
