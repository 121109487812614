import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  confirm: boolean;
};

type TypeResponse = {};

export const confirmAgreement: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-profile/users/myProfile/confirmAgreement?confirm=${request.confirm}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
