import styled from 'styled-components';

import { Breakpoints } from '../../../shared/consts/sizes';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const RightBar = styled.div<WidgetWrapperStyledProps>`
  max-width: 372px;
  max-height: 560px;
  width: 100%;
  display: flex;
  padding-right: 16px;
  position: fixed;
  top: 100px;
  left: 50%;

  transform: translateX(303px);
  div {
    display: ${({ isVisible }) => (isVisible ? 'none' : 'flex')};
  }

  @media (max-width: 1284px) {
    & {
      display: none;
    }
  }
`;

export type WidgetWrapperStyledProps = {
  isVisible: boolean;
};

export const WidgetWrapper = styled.div`
  width: 100%;
  height: 260px;
  margin-bottom: 32px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;

  & {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: ${Breakpoints.small}) {
    height: 110px;
    display: block;
    margin-bottom: 24px;
  }
`;

export const BigWidgetWrapper = styled.div`
  width: 540px;
  height: 260px;
  display: inline-block;
  vertical-align: top;

  @media (max-width: ${Breakpoints.small}) {
    width: 280px;
    height: 110px;
    margin-right: 12px;
  }
`;
export const SmallWidgetWrapper = styled.div`
  width: 340px;
  height: 260px;
  display: inline-block;
  vertical-align: top;
  margin-left: 32px;

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: ${Breakpoints.small}) {
    width: 280px;
    height: 110px;
    margin-right: 12px;
    margin-left: 0;
  }
`;

export const WidgetsRightBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  & > div {
    margin-bottom: 8px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;
