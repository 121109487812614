import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@uralhim-innovative/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TypeApplication } from '../../../../../../../../../shared/types/applications';
import {
  ModalHeader,
  Orgtitle,
  ListWrapper,
  Comment,
  ActionsWrapper,
} from '../../../../../profile/pages/organizational/components/ApplicationView/style';
import { List } from '../../../../../../../../../shared/components/List';
import { statusInvitationMap } from '../../OrganizationInvitesApplications';
import { OrganizationUsersStore } from '../../stores/organization.users.store';
import { TypeUser } from '../../../../../../../../../shared/types/users';
import { UserRoleMap } from '../../../organizationUsersList';

type Props = {
  application: TypeApplication;
  hideModal: () => void;
  handleAccept: (applicationId: string) => void;
  handleDecline: (applicationId: string) => void;
  organizationId: string;
};

export const UserApplicationView: FC<Props> = observer(
  ({ application, handleAccept, handleDecline, organizationId }) => {
    const { getUserById } = useStore(OrganizationUsersStore);
    const [user, setUser] = useState<TypeUser | null>(null);

    const organizationJoinDate = application.creationDate.slice(0, 10);
    const [year, month, day] = organizationJoinDate.split('-');

    useEffect(() => {
      if (application) {
        // getUserById({ organizationId }).then(response => {
        //   setUser(response);
        // });
      }
    }, [application]);

    return (
      <>
        <ModalHeader>
          {application.type === 'INVITATION' ? 'Приглашение' : 'Новая заявка'}
        </ModalHeader>
        <Orgtitle>{application.userName}</Orgtitle>
        <ListWrapper>
          <List
            items={[
              {
                key: 'Статус',
                value: statusInvitationMap[application.status],
              },
              {
                key: 'Роль',
                // @ts-ignore
                value: application.employeeRole ? UserRoleMap[application.employeeRole] : '',
              },

              {
                key: 'E-mail',
                value: application.userEmail || '',
              },
              {
                key: 'Дата заявки',
                value: `${day}.${month}.${year}`,
              },
            ]}
          />
        </ListWrapper>
        <Comment>{application.comment}</Comment>
        {!['ACCEPTED', 'DECLINED', 'CANCELLED'].includes(application.status) && (
          <ActionsWrapper>
            <Button
              type="submit"
              color="secondary"
              onClick={() => {
                handleDecline(application.applicationId);
              }}
            >
              Отклонить
            </Button>
            {application.type !== 'INVITATION' && (
              <Button
                type="submit"
                color="primary"
                onClick={() => {
                  handleAccept(application.applicationId);
                }}
              >
                Принять
              </Button>
            )}
          </ActionsWrapper>
        )}
      </>
    );
  }
);
