import { FC } from 'react';
import { Button } from '@uralhim-innovative/farmik-ui';

import { Wrapper } from './style';

export const SmallSelectWidget: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Wrapper>
      <Button type={'button'} color={'primary'} onClick={onClick}>
        Предложить свой виджет
      </Button>
    </Wrapper>
  );
};
