import { action, computed, makeAutoObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';
import { QuoteType } from '../../api';
import { Option } from '../../modules/shared/components/SelectSearchCustom/SelectSearchCustom';

import { WidgetQuotesEditStore } from './WidgetQuotesEditStore';

@provide.singleton()
export class QuoteCreationStore {
  @lazyInject(Axios2)
  protected axios: Axios2;

  @lazyInject(WidgetQuotesEditStore)
  protected quotesStore: WidgetQuotesEditStore;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  fetchData = () => {
    this.fetchCultures();
    this.fetchBasis();
    this.fetchEconomicRegions();
    this.fetchCultureClass();
  };

  id = '';

  idToCulture: Map<string, { value: string; label: string }> = new Map();

  @computed
  get cultures(): Array<any> {
    return Array.from(this.idToCulture.values());
  }

  idToBasis: Map<string, { value: string; label: string }> = new Map();

  @computed
  get basis() {
    return Array.from(this.idToBasis.values());
  }

  @action
  fetchBasis = () => {
    this.axios.api
      .getBasisList({ page: 0, size: 100 }, { noValidate: true })
      .then(this.onFetchBasisSuccess)
      .catch(this.onFetchBasisError);
  };

  @action.bound
  protected onFetchBasisSuccess = (response: TypeApiResponse<'getBasisList'>) => {
    response.content.forEach((item: any) => {
      this.idToBasis.set(item.id, { value: item.id, label: item.name });
    });
  };

  @action.bound
  protected onFetchBasisError = () => {
    console.error('onFetchBasisError');
  };

  nameFilter = '';

  @action
  setNameFilter = (v: string) => {
    this.nameFilter = v;
    this.fetchCultures();
  };

  @action
  fetchCultures = () => {
    this.axios.api
      .getCultures({
        size: 10,
        latestVersion: true,
        attrs: {
          useInQuotes: true,
        },
        nameFilter: this.nameFilter,
      })
      .then(this.onFetchCulturesSuccess)
      .catch(this.onFetchCulturesError);
  };

  @action.bound
  protected onFetchCulturesSuccess = (response: TypeApiResponse<'getCultures'>) => {
    this.idToCulture.clear();
    response.content.forEach(item => {
      this.idToCulture.set(item.id, { value: item.id, label: item.name });
    });
  };

  @action.bound
  protected onFetchCulturesError = () => {
    console.log('onFetchCulturesError');
  };

  @action
  selectCulture = (option: Option | null) => {
    this.selectedCultureId = option ? option.value : '';
    this.fetchQuotesList();
  };

  idToCultureClass: Map<string, { id: string; name: string }> = new Map();

  @computed
  get cultureClasses() {
    return Array.from(this.idToCultureClass.values());
  }

  @action
  fetchCultureClass = () => {
    this.axios.api
      .getCultureClassList({ name: '', size: 10, page: 0 })
      .then(this.onFetchCultureClassSuccess)
      .catch(this.onFetchCultureClassError);
  };

  @action
  selectCultureClass = (id: string) => {
    this.selectedClassId = id;
    this.fetchQuotesList();
  };

  @action.bound
  protected onFetchCultureClassSuccess = (response: TypeApiResponse<'getCultureClassList'>) => {
    response.content.forEach(item => {
      this.idToCultureClass.set(item.id, item);
    });
  };

  @action.bound
  protected onFetchCultureClassError = () => {
    console.warn('onFetchCultureClassError');
  };

  @action
  fetchQuotesList = () => {
    // this.selectedQuote = null;
    this.foundIdToQuotes.clear();
    this.axios.api
      .getQuotesList(
        {
          size: 100,
          page: 0,
          basisId: this.selectedBasisId,
          cultureId: this.selectedCultureId,
          economicRegionId: this.selectedRegionId,
          cultureClassId: this.selectedClassId,
          actualOnly: true,
        },

        { noValidate: true }
      )
      .then(response =>
        this.onFetchQuotesListSuccess(response, this.selectedQuote ? this.selectedQuote.id : '')
      )
      .catch(this.onFetchQuotesListError);
  };

  @action
  fetchAllQuotes = (selectedId: string) => {
    this.axios.api
      .getQuotesList(
        {
          actualOnly: true,
        },

        { noValidate: true }
      )
      .then(response => this.onFetchQuotesListSuccess(response, selectedId))
      .catch(this.onFetchQuotesListError);
  };

  @action.bound
  protected onFetchQuotesListSuccess = (
    response: TypeApiResponse<'getQuotesList'>,
    selecteId: string
  ) => {
    this.foundIdToQuotes.clear();
    response.content.forEach((item: any) => {
      this.foundIdToQuotes.set(item.id, item);
    });
    this.selectQuote(selecteId);
  };

  @action.bound
  protected onFetchQuotesListError = () => {
    console.log('onFetchQuotesListError');
  };

  @observable
  selectedQuote: QuoteType | null;

  @action
  selectQuote = (id: string) => {
    const quote = this.foundIdToQuotes.get(id);
    if (quote) {
      this.selectedQuote = quote;
    }
  };

  @action
  fetchQuotes = (id: string) => {
    this.id = id;
    this.axios.api
      .getWidgetInfo({ id })
      .then(this.onFetchWidgetInfoSuccess)
      .catch(this.onFetchWidgetInfoError);
  };

  config: any;

  @action.bound
  onFetchWidgetInfoSuccess = (response: TypeApiResponse<'getWidgetInfo'>) => {
    const config = JSON.parse(response.widget_config);
    this.config = config;
  };

  @action.bound
  onFetchWidgetInfoError = () => {
    console.log('onFetchWidgetInfoError');
  };

  selectedCultureId = '';

  foundIdToQuotes: Map<string, QuoteType> = new Map();
  selectedIdToQuotes: Map<string, QuoteType> = new Map();

  selectedIds: Set<string> = new Set();

  @computed
  get quotesAmount() {
    return this.selectedIds.size;
  }

  @computed
  get resultQuotes() {
    return [...Array.from(this.foundIdToQuotes.values())];
  }

  @computed
  get foundQuotes() {
    return Array.from(this.foundIdToQuotes.values());
  }
  @computed
  get isAvailableAddButton() {
    return Boolean(this.selectedQuote);
  }

  @action
  selectClass = (id: string) => {
    this.selectedClassId = id;
  };

  @action
  selectBasis = (id: string) => {
    this.selectedBasisId = id;
    this.fetchQuotesList();
  };

  // basisId: item.basisId,
  // providerId: item.providerId,
  // cultureId: item.cultureId,
  // portId: item.portId,
  // currencyId: item.currencyId,
  // unitsId: item.unitsId,

  @action
  finishChanging = async (index: number) => {
    if (!this.selectedQuote) {
      return;
    }
    const copy = this.config.positions[index];

    this.config.positions[index] = {
      basisId: this.selectedQuote.basis.id,
      providerId: this.selectedQuote.provider.id,
      cultureId: this.selectedQuote.culture.id,
      portId: this.selectedQuote.port && this.selectedQuote.port.id,
      currencyId: this.selectedQuote.currency.id,
      unitsId: this.selectedQuote.units.id,
      price: this.selectedQuote.price,
      vat: this.selectedQuote.vat,
      economicRegionId: this.selectedQuote.economicRegion.id,
      isActive: copy.isActive,
      linkId: uuidv4(),
      cultureClassId: this.selectedQuote.cultureClass?.id,
    };
    await this.axios.api.updateWidgetInfo(
      { id: this.id, widget_config: JSON.stringify(this.config) },
      { omit: ['id'] }
    );
  };

  @action
  finishSelecting = async () => {
    if (!this.selectedQuote) {
      return;
    }

    if (!this.config) {
      this.config = {
        positions: [],
      };
    }

    this.config.positions.push({
      basisId: this.selectedQuote.basis.id,
      providerId: this.selectedQuote.provider.id,
      cultureId: this.selectedQuote.culture.id,
      portId: this.selectedQuote.port && this.selectedQuote.port.id,
      currencyId: this.selectedQuote.currency.id,
      unitsId: this.selectedQuote.units.id,
      price: this.selectedQuote.price,
      cultureClassId: this.selectedQuote.cultureClass?.id,
      vat: this.selectedQuote.vat,
      economicRegionId: this.selectedQuote.economicRegion.id,
      isActive: true,
      linkId: uuidv4(),
    });

    await this.axios.api.updateWidgetInfo(
      { id: this.id, widget_config: JSON.stringify(this.config) },
      { omit: ['id'] }
    );
  };

  selectedClassId = '';
  selectedBasisId = '';
  selectedRegionId = '';

  @action
  selectRegion = (id: string) => {
    this.selectedRegionId = id;
    this.fetchQuotesList();
  };

  idToEconomicRegion: Map<string, { id: string; name: string }> = new Map();

  @computed
  get economicRegions() {
    return Array.from(this.idToEconomicRegion.values());
  }

  @action
  fetchEconomicRegions = () => {
    this.axios.api
      .getEconomicRegiones({ size: 10, name: '' })
      .then(this.onFetchEconomicRegionsSuccess)
      .catch(this.onFetchEconomicRegionsError);
  };

  @action.bound
  onFetchEconomicRegionsSuccess = (response: TypeApiResponse<'getEconomicRegiones'>) => {
    response.content.forEach((item: any) => {
      this.idToEconomicRegion.set(item.id, item);
    });
  };

  @action.bound
  onFetchEconomicRegionsError = () => {
    console.warn('onFetchEconomicRegionsError');
  };

  @action
  reset = () => {
    this.selectedQuote = null;
    this.foundIdToQuotes.clear();
    this.selectedClassId = '';
    this.selectedRegionId = '';
    this.selectedBasisId = '';
    this.selectedCultureId = '';
  };
}
