import styled from 'styled-components';

import { Colors } from '../../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../../modules/shared/consts/sizes';

export const Wrapper = styled.div`
  user-select: none;
  width: 1070px;
  height: 96px;
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
  transition: 300ms;

  @media (max-width: 1316px) {
    max-width: calc(100vw - 289px);
  }

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  } ;
`;

export const ContentWrapper = styled.div`
  width: 1032px;
  height: 96px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
`;

type ControlStyledProps = {
  isActive: boolean;
};

const renderColor = (isActive: boolean) =>
  isActive
    ? `
  svg * {
    fill: ${Colors.green} !important;
  }
`
    : `
  svg * {
    fill: ${Colors.grayBorder} !important;
  }
`;

export const LeftControl = styled.div<ControlStyledProps>`
  position: absolute;
  left: 0;
  top: 16px;
  width: 32px;
  height: 64px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  background: ${Colors.white};
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  ${({ isActive }) => renderColor(isActive)};
`;

export const RightControl = styled.div<ControlStyledProps>`
  position: absolute;
  right: 0;
  top: 16px;
  width: 32px;
  height: 64px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  background: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
  z-index: 2;
  ${({ isActive }) => renderColor(isActive)};
`;

type LineStyledProps = {
  shift: number;
};

type GeoPointType = {
  active: boolean;
};

const renderColors = (isActive: boolean) => {
  return isActive
    ? `
      background: ${Colors.green}; 
      }
      ${Temperature}, ${GeoPointTitle}, ${Condition} {
        color: ${Colors.white} !important; 
      }
      `
    : `background: ${Colors.white}; color: ${Colors.darkGray}; `;
};

export const GeoPoint = styled.div<GeoPointType>`
  cursor: pointer;
  user-select: none;
  width: 220px;
  height: 64px;
  margin-right: 8px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  ${({ active }) => renderColors(active)}
`;

export const GeoPointTitle = styled.p`
  max-width: 107px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.darkGray};
`;

export const RightBar = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const ConditionIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const Temperature = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.darkGray};
`;

export const Condition = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const Line = styled.div<LineStyledProps>`
  width: 9999px;
  height: 64px;
  display: flex;
  padding-left: 24px;
  ${GeoPoint}:last-child {
    margin-right: 0;
  }
  transition: 300ms;
  ${({ shift }) => `transform: translate3d(${shift}px, 0, 0 );`};
`;

type QuoteSlideProps = {
  isActive: boolean;
};

const renderActive = (v: boolean) =>
  v
    ? `
      background: ${Colors.green};
      ${Tendency}, ${QuoteName}, ${QuotePrice}, ${QuotePosition} {
        color: ${Colors.white};
      }
`
    : `

`;

export const QuoteSlide = styled.div<QuoteSlideProps>`
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 248px;
  height: 64px;
  padding: 16px 16px 16px 56px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
  filter: ${({ isActive }) => (isActive ? 'none' : 'grayscale(100%)')};
  -webkit-filter: ${({ isActive }) => (isActive ? 'none' : 'grayscale(100%)')};
  ${({ isActive }) => renderActive(isActive)};
`;

export const QuoteIcon = styled.img`
  position: absolute;
  width: 40px;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

export const BackgroundCircle = styled.div`
  position: absolute;
  left: -40px;
  bottom: -40px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: ${Colors.white};
  z-index: 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuoteName = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const QuotePosition = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const QuotePrice = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const Tendency = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;
