import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../modules/shared/utils/IoC';
import { Axios2 } from '../modules/shared/utils/axios2';

export type FormDataType = {
  userEmail: string;
  userName: string;
  userMessage: string;
};

@provide.singleton()
export class WidgetFeedBackFormStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
    this.checkUserData();
  }

  formData: FormDataType = {
    userEmail: '',
    userName: '',
    userMessage: '',
  };

  userLogined = false;

  acceptAgreement = false;

  setAcceptAgreement = (value: boolean) => {
    this.acceptAgreement = value;
  };

  leaveFeedback = async (userLogined: boolean) => {
    if (userLogined) {
      await this.axios2.api.confirmAgreement(
        {
          confirm: true,
        },
        {
          omit: ['confirm'],
        }
      );
    }

    await this.axios2.api.leaveFeedback({
      userEmail: this.formData.userEmail,
      userName: this.formData.userName,
      userMessage: this.formData.userMessage,
    });
  };

  setFormData = (formData: FormDataType) => {
    this.formData = formData;
  };

  clearFormData = () => {
    this.formData = {
      userEmail: '',
      userName: '',
      userMessage: '',
    };
  };

  checkUserData = async () => {
    try {
      const response = await this.axios2.api.getProfile({});

      this.userLogined = true;
      this.formData = {
        ...this.formData,
        userEmail: response.email?.email || '',
        userName: `${response.firstName || ''} ${response.lastName || ''}`,
      };

      console.log(response);
    } catch (err) {
      console.log(err);

      this.userLogined = false;
    }
  };
}
