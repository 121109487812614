import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Menu, MenuItem } from './style';
type NotificationMenuProps = {
  links: Array<{
    alias: string;
    href: string;
  }>;
};

export const NotificationMenu: FC<NotificationMenuProps> = ({ links }) => {
  const history = useHistory();
  return (
    <Menu>
      {links.map((item, index) => (
        <MenuItem
          data-test-id={`notifications-menu-${index}`}
          isActive={history.location.pathname === item.href}
          onClick={() => history.push(item.href)}
        >
          {item.alias}
        </MenuItem>
      ))}
    </Menu>
  );
};
