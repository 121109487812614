import { action, makeAutoObservable, runInAction } from 'mobx';

import { UserInfoType } from '../../../../../../shared/types/users';
import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { ProfileRoutes as ProfileRoutesStatic } from '../routes';
import { PersonalDataType, SpecializationsItemsType } from '../types/personalData';
import { getPurePhone } from '../../../../../../shared/utils/getPurePhone';
import { Axios2, TypeApiRequest, TypeApiResponse } from '../../../../../../shared/utils/axios2';

import { ExpertiseType } from './EditExpertiseStore';

const defaultErrorsFields = {
  firstName: '',
  secondName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',
};

@provide.singleton()
export class ProfileStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  constructor() {
    makeAutoObservable(this);
  }

  loading = true;
  user: UserInfoType | null = null;
  fullName = '';
  personalDataItems: PersonalDataType[] = [];
  competentionsList: string[] = [];
  collapsedWelcomeWidget = false;
  expertises: Array<ExpertiseType> = [];

  toggleWelcomeWidget = (collapsed: boolean) => {
    this.collapsedWelcomeWidget = collapsed;
  };

  pendingContracts: TypeApiResponse<'getPendingContracts'> = {
    emails: [],
    phoneNumbers: [],
  };

  getPendingContracts = () => {
    this.loading = true;

    return this.axios2.api.getPendingContracts({}).then(response => {
      runInAction(() => {
        this.pendingContracts = response;
        this.loading = false;
      });
    }).catch(e => {
      console.log(e);
      this.loading = false;
    });;
  }

  interests: Array<any>;
  getUserInfo = () =>
    this.getPendingContracts().then(() => {
      this.axios2.api
        .getProfile({})
        .then(response => {
          runInAction(() => {
            this.user = response;

            const { firstName, secondName, lastName } = response;

            this.setFullName(firstName, secondName, lastName);
            this.setPersonalDataItems(response);
            this.expertises = response.expertises;
            this.interests = response.interests;
            this.loading = false;
          });
        })
        .catch(error => {
          console.error(error);

          this.loading = false;

          this.user = null;
        });
    });

  setFullName = (firstName?: string, secondName?: string, lastName?: string) => {
    this.fullName = [lastName, firstName, secondName].join(' ');
  };

  setPersonalDataItems = (user: UserInfoType) => {
    const { gender, dateOfBirth, phone, email } = user;

    // eslint-disable-next-line no-nested-ternary
    const emailValue = this.pendingContracts.emails.length
      ? this.pendingContracts.emails[0]
      : email
      ? email.email
      : '';

    this.personalDataItems = [
      {
        key: 'Пол',
        value: gender ? gender.genderName : '',
      },
      {
        key: 'Дата рождения',
        value: dateOfBirth || '',
      },
      {
        key: 'Телефон',
        value: phone ? `${phone.phoneNumberCode} ${phone.phoneNumber}` : '',
      },
      {
        key: 'E-mail',
        value: emailValue,
        status: this.pendingContracts.emails.length ? 'error' : 'default',
        helperActionText: this.pendingContracts.emails.length ? 'Отправить еще раз' : '',
        helperAction: this.pendingContracts.emails.length
          ? () => {
              this.confirmEmail(this.pendingContracts.emails[0]);
            }
          : undefined,
        helper: this.pendingContracts.emails.length
          ? 'Необходимо подтвердить свой\n электронный адрес. На указанный\n адрес отправлено письмо.\n'
          : '',
      },
    ];
  };

  changePersonalDataInStore = (user: UserInfoType) => {
    this.user = user;
  };

  updatePersonalData = (history: any) => {
    if (this.user && this.validateUserData()) {
      const { firstName, lastName, secondName, gender, dateOfBirth } = this.user;
      const updatedUserData: TypeApiRequest<'updateProfile'> = {
        firstName,
        lastName,
        secondName,
        dateOfBirth,
        gender: gender?.genderEnum,
        avatar: this.avatarHash || this.user.avatar?.id || '',
      };

      return this.axios2.api.updateProfile(updatedUserData).then(() => {
        this.getUserInfo();
        history.push(ProfileRoutesStatic.Dashboard);
      });
    }
  };

  confirmPhoneNumber = async (phoneNumber: string, countryCode: string) => {
    console.log('confirmPhoneNumber');
    let result = null
    try {
      result = await this.axios2.api.userSendSmsCode({
        phoneNumber: getPurePhone(phoneNumber, countryCode),
        countryCode,
      });
    } catch (r) {
      return Promise.reject(r);
    }

    console.log('confirmPhoneNumber finish');
    return result;
  }


  checkSMSCode = async (data: TypeApiRequest<'verifySmsCode'>) => {
    let response = null;
    try {
      response = await this.axios2.api.checkUserSmsCode(data);
    } catch (r) {
      return Promise.reject(r);
    }
    localStorage.setItem('accessToken', response.token);
    return this.getUserInfo();
  }


  confirmEmail = (email: string) =>
    this.axios2.api.sendEmailVerification({ email }, { omit: ['email'] });

  validateField = (field: string) =>
    // @ts-ignore
    !this.user[field];

  validateUserData = () => {
    this.errorsFields = defaultErrorsFields;

    const validateUserData = ['firstName', 'lastName', 'dateOfBirth', 'gender'].filter(
      this.validateField
    );

    validateUserData.forEach(field => {
      // @ts-ignore
      this.errorsFields[field] = 'Поле обязательно для заполнения';
    });

    return !validateUserData.length;
  };

  errorsFields = defaultErrorsFields;

  avatarHash = '';

  avatarFile: File;

  @action
  uploadFile = (file: File) => {
    console.log(file);
    this.avatarFile = file;
    this.axios2.api
      .uploadFile({
        ownerId: this.user?.id!,
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(this.onUploadFileSuccess)
      .catch(this.onUploadFileError);
  };

  @action.bound
  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    this.avatarHash = response.id;
    this.axios2.api
      .uploadFileFinish({ data: this.avatarFile, url: response.uploadUrl })
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  @action.bound
  onUploadFileError = () => {
    console.log('onUploadFileError');
  };

  @action.bound
  onUploadFileFinishSuccess = () => {
    console.log('File uploaded');
  };

  @action.bound
  onUploadFileFinishError = () => {
    console.log('onUploadFileFinishError');
  };
}
