import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {};

type TypeResponse = {};

export const counterLogin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-calculator/counter/login`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
