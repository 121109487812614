import {
  action,
  autorun,
  IReactionDisposer,
  makeAutoObservable,
  observable,
  runInAction, toJS,
} from 'mobx';
import _ from 'lodash';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios2, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { TypeNews } from '../types/type-news';
import { TypeTag } from '../types/type-tag';
import { TypeCommentRoot } from '../../../../shared/types/comments';
import {GuestProfileStore} from "../../dashboard/pages/profile/pages/GuestProfile/stores/GuestProfileStore";

const NEWS_PUBLISHED_STATUS = 'PUBLIC';

const commentsPaginationInitial = {
  size: 10,
  page: 0,
};

@provide.singleton()
export class NewsPageStore {
  @lazyInject(Axios2)
  protected axios2: Axios2;

  @lazyInject(GuestProfileStore)
  guestStore: GuestProfileStore;

  constructor() {
    makeAutoObservable(this);

    this.autorunOnCommentsPaginationChanged();
  }

  isPaginationLoading = false;

  size = 10;
  page = 0;
  totalPage = 0;

  get isPaginational() {
    return this.page + 1 !== this.totalPage;
  }

  fetchNewsPinned = () => {
    this.axios2.api
      .getNewsPinned({})
      .then(this.onFetchPinnedSuccess)
      .catch(this.onFetchPinnedError);
  };

  pinnedNews: null | TypeNews;

  onFetchPinnedSuccess = (news: TypeNews) => {
    this.pinnedNews = news;
  };

  onFetchPinnedError = () => {
    console.log('Error of fetching pinned news');
  };

  fetchNews = async () => {
    this.isPaginationLoading = true;

    await this.axios2.api
      .getNewsList({
        context: this.articleSearchQuery,
        page: this.page,
        size: this.size,
        tags: this.selectedTagIds,
        status: NEWS_PUBLISHED_STATUS,
        sort: 'publicationDate,desc',
      })
      .then(this.onFetchNewsSuccess)
      .catch(this.onFetchNewsError);
  };

  idToNews: { [key: string]: TypeNews & { isLiked: boolean } } = {};

  get news() {
    return this.pinnedNews
      ? [this.pinnedNews, ...Array.from(Object.values(this.idToNews))]
      : Array.from(Object.values(this.idToNews));
  }

  onFetchNewsSuccess = (response: TypeApiResponse<'getNewsList'>) => {
    response.content.forEach(item => {
      Object.assign(this.idToNews, { [item.newsId]: item });
    });
    this.totalPage = response.totalPages;

    this.isPaginationLoading = false;
  };

  onFetchNewsError = () => {
    console.log('Error of fetching news');
    this.isPaginationLoading = false;
  };

  idToTags: { [key: string]: TypeTag } = {};

  get tags() {
    return Array.from(Object.values(this.idToTags));
  }

  lastOperation: 'load' | 'additional_load' = 'load';

  fetchTags = () => {
    this.axios2.api
      .getNewsTags({ search: this.tagSearchQuery, size: 5 })
      .then(this.onFetchTagSuccess)
      .catch(this.onFetchTagError);
  };

  onFetchTagSuccess = (response: any) => {
    response.content.map((value: TypeTag) =>
      Object.assign(this.idToTags, { [value.tagId]: value })
    );
  };

  onFetchTagError = () => {
    console.log('Error of fetching news tags');
    [
      { tagId: '1', tagName: 'Огурец' },
      { tagId: '2', tagName: 'Помидор' },
      { tagId: '3', tagName: 'Баклажан' },
      { tagId: '4', tagName: 'Тыква' },
    ].map((value: TypeTag) => Object.assign(this.idToTags, { [value.tagId]: value }));
  };

  selectedTagIds: Array<string> = [];

  @observable tagSearchQuery = '';

  @action
  setTagSearchQuery = (v: string) => {
    this.tagSearchQuery = v;
    this.idToTags = {};
    this.fetchTags();
  };

  selectTags = (tags: Array<string>) => {
    const scroll = window.scrollY;
    this.selectedTagIds = tags;
    this.idToNews = {};
    this.resetPagination();
    this.fetchNews().then(() => {
      window.scroll(0, scroll);
    });
  };

  articleSearchQuery = '';

  setArticleSearchQuery = (v: string) => {
    const scroll = window.scrollY;
    this.articleSearchQuery = v;
    this.idToNews = {};
    this.resetPagination();
    this.fetchNews().then(() => {
      window.scroll(0, scroll);
    });
  };

  resetPagination = () => {
    this.page = 0;
    this.totalPage = 1;
  };

  resetCollections = () => {
    this.idToNews = {};
    this.idToTags = {};
    this.selectedTagIds = [];
  };

  reset = () => {
    this.resetCollections();
    this.resetPagination();
  };

  fetchAdditional = () => {
    this.page += 1;
    this.fetchNews();
  };

  newsArticle: null | TypeNews;
  isNewsArticleLoading = true;
  isNewsArticleFound = false;

  resetNewsArticle = () => {
    this.isNewsArticleLoading = true;
    this.isNewsArticleFound = false;
    this.newsArticle = null;
    this.newsComments = [];
    this.currentUserId = '';
    this.commentsPagination = commentsPaginationInitial;
    this.noMoreComments = false;
    this.commentAreFetching = false;
  };

  fetchNewsArticle = (_id: string) => {
    this.isNewsArticleLoading = true;
    this.isNewsArticleFound = false;
    this.axios2.api
      .getNewsById({ id: _id })
      .then(this.onFetchNewsArticleSuccess)
      .catch(this.onFetchNewsArticleError);
  };

  onFetchNewsArticleSuccess = (_newsArticle: TypeNews) => {
    this.newsArticle = _newsArticle;
    this.isNewsArticleLoading = false;
    this.isNewsArticleFound = true;
  };

  onFetchNewsArticleError = () => {
    this.isNewsArticleLoading = false;
    this.isNewsArticleFound = false;
  };

  likeNews = (id: string, status: boolean) => {
    this.axios2.api.updateNewsLike({ id, like: status }, { omit: ['id'] }).then(() => {
      runInAction(() => {
        if (this.newsArticle?.newsId === id) {
          this.newsArticle.likedByMe = status;
          if (status) {
            this.newsArticle.likes += 1;
          } else {
            this.newsArticle.likes -= 1;
          }
        }
        // const newsInList = this.idToNews[id];
        this.guestStore.updateLikes(id, status);


        if (this.idToNews[id]) {
          this.idToNews[id].likedByMe = status;
          if (status) {
            this.idToNews[id].likes += 1;
          } else {
            this.idToNews[id].likes -= 1;
          }
        }

        if (this.pinnedNews?.newsId === id) {
          this.pinnedNews.likedByMe = status;
          if (status) {
            this.pinnedNews.likes += 1;
          } else {
            this.pinnedNews.likes -= 1;
          }
        }
      });
    });
  };

  /** COMMENTS */

  newsComments: Array<TypeCommentRoot> = [];
  commentsPagination = _.cloneDeep(commentsPaginationInitial);
  commentsPaginationSetters = _.mapValues(
    commentsPaginationInitial,
    (_initialValue, fieldName) => (newValue: any) => {
      // @ts-ignore
      this.commentsPagination[fieldName] = newValue;
    }
  );
  currentUserId: string;
  noMoreComments = false;
  commentAreFetching = false;

  private disposers: IReactionDisposer[] = [];

  autorunOnCommentsPaginationChanged() {
    this.disposers.push(
      autorun(() => {
        if (JSON.stringify(this.commentsPagination) && this.newsArticle?.newsId) {
          this.fetchNewsComments();
        }
      })
    );
  }

  fetchNewsCommentAnswers = (commentId: string) => {
    return this.axios2.api
      .getNewsCommentAnswers({ commentId }, { omit: ['commentId'] })
      .then(response => {
        runInAction(() => {
          const targetComment = this.newsComments.find(comment => comment.commentId === commentId);

          if (!targetComment) {
            return console.error('No comment in newsComments for id', commentId);
          }

          targetComment.answers = response;
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  fetchNewsComments = () => {
    this.commentAreFetching = true;

    return this.axios2.api
      .getNewsComments({ newsId: this.newsArticle?.newsId!, ...this.commentsPagination })
      .then(response => {
        runInAction(() => {
          if (response.lastPage) {
            this.noMoreComments = true;
          }

          response.comments.forEach(comment => {
            const targetCommentIndex = this.newsComments.findIndex(
              _comment => _comment.commentId === comment.commentId
            );

            if (targetCommentIndex !== -1) {
              this.newsComments.splice(targetCommentIndex, 1);
            }

            this.newsComments.push(comment);
          });

          this.commentAreFetching = false;
        });
      })
      .catch(error => {
        console.error(error);
        this.commentAreFetching = false;
      });
  };

  fetchNewsCommentById = (id: string) => {
    return this.axios2.api
      .getNewsCommentById({ id }, { omit: ['id'] })
      .then(response => {
        runInAction(() => {
          const targetComment = this.newsComments.find(comment => comment.commentId === id);
          const targetAnswer = this.newsComments
            .map(({ answers }) => answers)
            .flat()
            .find(({ answerId }) => answerId === id);

          if (targetComment || targetAnswer) {
            Object.assign(targetComment || targetAnswer, _.omit(response, ['answers']));
          } else if (!targetComment) {
            this.newsComments.unshift(response as TypeCommentRoot);
          } else if (!targetAnswer) {
            console.error('No answer in newsComments for id', id);
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  createNewsComment = ({ commentContent }: { commentContent: string }) => {
    return this.axios2.api
      .createNewsComment({ commentContent, newsId: this.newsArticle?.newsId! })
      .then(response => {
        // @ts-ignore
        // eslint-disable-next-line operator-assignment
        this.newsArticle.comments = this.newsArticle.comments + 1;

        return response;
      });
  };

  createNewsCommentAnswer = (params: TypeApiRequest<'createNewsCommentAnswer'>) => {
    return this.axios2.api.createNewsCommentAnswer(params).then(response => {
      // @ts-ignore
      // eslint-disable-next-line operator-assignment
      this.newsArticle.comments = this.newsArticle.comments + 1;

      return response;
    });
  };

  updateCommentLike = (params: TypeApiRequest<'updateCommentLike'>) => {
    return this.axios2.api.updateCommentLike(params, { omit: ['commentId'] });
  };

  deleteNewsComment = (id: string, parentCommentId?: string) => {
    return this.axios2.api.deleteNewsComment({ id }, { omit: ['id'] }).then(() => {
      if (parentCommentId) {
        this.fetchNewsCommentAnswers(parentCommentId);

        // @ts-ignore
        // eslint-disable-next-line operator-assignment
        this.newsArticle.comments = this.newsArticle.comments - 1;
      } else {
        const targetComment = this.newsComments.find(comment => comment.commentId === id);

        if (targetComment) {
          const counterDecrease = targetComment.answers.length + 1;

          // @ts-ignore
          // eslint-disable-next-line operator-assignment
          this.newsArticle.comments = this.newsArticle.comments - counterDecrease;
        }

        this.newsComments.forEach((comment, index) => {
          if (comment.commentId === id) this.newsComments.splice(index, 1);
        });
      }
    });
  };

  getCurrentUserId = () => {
    return this.axios2.api.getProfile({}).then(response => {
      runInAction(() => {
        this.currentUserId = response.publicId;
      });
    });
  };
}
