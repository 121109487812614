import { TypeApiRoute } from '../models/TypeApiRoute';
import { TypeResponsePageable } from '../models/TypeResponsePageable';

type TypeRequest = {
  level?: number;
  status?: string;
  parentId?: string;
  nameFilter?: string;
  codeFilter?: string;
  pathEquals?: string;
  pathPrefix?: string;
  originalOnly?: boolean;
  latestVersion?: boolean;
  attrs?: any;
  size?: number;
  page?: number;
};

type TypeResponse = {
  content: Array<{
    id: string;
    path: string;
    code: string;
    name: string;
    level: number;
    version: number;
    picLink?: string;
    parentId?: string;
    original: boolean;
    hasChild: boolean;
    description: string;
    originalLink: string;
  }>;
} & TypeResponsePageable;

export const getCultures: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: req => `/api/da-dictionary/dictionary/culture/findAll?size=${req.size}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
