import { FC, useEffect, useState, useRef, useLayoutEffect, RefObject } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { observer } from 'mobx-react';
import _ from 'lodash';
import autosize from 'autosize';
import { Redirect, useHistory } from 'react-router-dom';

import { useStore } from '../../../../../../shared/utils/IoC';
import { getElementOffset } from '../../../../../../shared/utils/getElementOffset';
import { NewsPageStore } from '../../../stores/NewsPageStore';
import { TypeApiRequest } from '../../../../../../shared/utils/axios2';
import { AuthorizationRoutes, RedirectRoutes } from '../../../../../../authorization/routes';
import { CommentItemComponent } from '../CommentItemComponent';
import iconWaring from '../warning.svg';
import { ProfileStore } from '../../../../dashboard/pages/profile/stores/ProfileStore';

import {
  Gutter,
  ButtonAuth,
  ButtonRegister,
  CommentsWrapper,
  LeaveCommentAvatar,
  LeaveCommentButton,
  LeaveCommentContent,
  LeaveCommentWrapper,
  LeaveCommentFieldWrapper,
  LeaveCommentNotificationText,
  LeaveCommentContentUnregistered,
  LeaveCommentNotificationIconWrapper,
  LeaveCommentNoComments,
  LeaveCommentGrid,
  LeaveCommentTextArea,
  LeaveCommentTextAreaWrapper,
  LeaveCommentAuthorName,
} from './style';

function useHandleStickyFooter(
  refCommentsWrapper: RefObject<HTMLDivElement>,
  refLeaveCommentsWrapper: RefObject<HTMLDivElement>
) {
  const onScrollHandler = _.throttle(() => {
    if (refCommentsWrapper.current && refLeaveCommentsWrapper.current) {
      const { offsetHeight: leaveCommentHeight } = refLeaveCommentsWrapper.current;
      const {
        top: distanceFromViewportToComments,
      } = refCommentsWrapper.current.getBoundingClientRect();

      const commentsUnderLeaveDiff =
        window.innerHeight - distanceFromViewportToComments - leaveCommentHeight;
      const newTransform = `translateY(${
        commentsUnderLeaveDiff < 0 ? -commentsUnderLeaveDiff : 0
      }px)`;

      if (newTransform !== refLeaveCommentsWrapper.current.style.transform) {
        refLeaveCommentsWrapper.current.style.transform = newTransform;
      }
    }
  }, 10);

  useLayoutEffect(() => {
    onScrollHandler();

    window.addEventListener('scroll', onScrollHandler);
    window.addEventListener('resize', onScrollHandler);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
      window.removeEventListener('resize', onScrollHandler);
    };
  }, []);
}

function useHandleCommentsLazyLoad(
  store: NewsPageStore,
  refLeaveCommentsWrapper: RefObject<HTMLDivElement>
) {
  useEffect(() => {
    const onScrollHandler = _.throttle(() => {
      if (store.noMoreComments || store.commentAreFetching || store.newsComments.length === 0)
        return;

      if (refLeaveCommentsWrapper.current) {
        const { offsetHeight: leaveCommentHeight } = refLeaveCommentsWrapper.current;
        const { body } = document;
        const bodyHeight = Math.max(body.scrollHeight, body.offsetHeight);
        const maxScrollYPoint = bodyHeight - window.innerHeight - leaveCommentHeight;

        if (window.scrollY > maxScrollYPoint) {
          store.commentsPaginationSetters.page(store.commentsPagination.page + 1);
        }
      }
    }, 10);

    onScrollHandler();

    window.addEventListener('scroll', onScrollHandler);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, []);
}

function useSetMinCommentsWrapperHeight(
  refCommentsWrapper: RefObject<HTMLDivElement>,
  setMinCommentsHeight: any
) {
  useLayoutEffect(() => {
    const onResizeHandler = _.throttle(() => {
      if (refCommentsWrapper.current) {
        setMinCommentsHeight(
          window.innerHeight - getElementOffset(refCommentsWrapper.current).top - 100
        );
      }
    }, 10);

    onResizeHandler();

    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);
}

function useHandleTextareaResize(
  refLeaveCommentsTextarea: RefObject<HTMLTextAreaElement>,
  leaveCommentValue: string
) {
  const [textareaIsMultiline, setTextareaIsMultiline] = useState(false);
  const [autosizeApplied, setAutosizeApplied] = useState(false);

  useEffect(() => {
    if (!refLeaveCommentsTextarea.current) return;

    if (!autosizeApplied) {
      autosize(refLeaveCommentsTextarea.current);
      setAutosizeApplied(true);
    }

    const isMultiline = leaveCommentValue.indexOf('\n') !== -1;

    setTextareaIsMultiline(isMultiline);

    if (!isMultiline) {
      refLeaveCommentsTextarea.current.style.height = '41px';
    }
  }, [leaveCommentValue, refLeaveCommentsTextarea.current]);

  return textareaIsMultiline;
}

function scrollToComment(commentId: string) {
  return new Promise(resolve => {
    // wait mount to DOM
    setTimeout(() => {
      const commentElement = document.getElementById(`Comment_${commentId}`);

      if (commentElement) {
        window.scrollTo(0, getElementOffset(commentElement).top - 100);
      }

      resolve(null);
    }, 10);
  });
}

type CommentsProps = {
  newsId: string;
};

export const Comments: FC<CommentsProps> = observer(props => {
  const { newsId } = props;

  const store = useStore(NewsPageStore);
  const { getUserInfo, user } = useStore(ProfileStore);

  const [answeredAuthor, setAnsweredAuthor] = useState('');
  const [answeredCommentId, setAnsweredCommentId] = useState('');
  const [leaveCommentValue, setLeaveCommentValue] = useState('');
  const [minCommentsHeight, setMinCommentsHeight] = useState(0);
  const refCommentsWrapper = useRef<HTMLDivElement>(null);
  const refLeaveCommentsWrapper = useRef<HTMLDivElement>(null);
  const refLeaveCommentsTextarea = useRef<HTMLTextAreaElement>(null);

  useHandleStickyFooter(refCommentsWrapper, refLeaveCommentsWrapper);
  useHandleCommentsLazyLoad(store, refLeaveCommentsWrapper);
  useSetMinCommentsWrapperHeight(refCommentsWrapper, setMinCommentsHeight);

  const textareaIsMultiline = useHandleTextareaResize(refLeaveCommentsTextarea, leaveCommentValue);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (newsId) store.getCurrentUserId();
  }, [newsId]);

  const handleSubmit = () => {
    if (!leaveCommentValue) return Promise.resolve();

    if (answeredCommentId) {
      return store
        .createNewsCommentAnswer({
          commentId: answeredCommentId,
          commentContent: leaveCommentValue,
        })
        .then(({ commentId }) =>
          store.fetchNewsCommentAnswers(answeredCommentId).then(() => scrollToComment(commentId))
        )
        .then(() => setLeaveCommentValue(''))
        .then(() => setAnsweredCommentId(''))
        .then(() => setAnsweredAuthor(''));
    }

    return store
      .createNewsComment({ commentContent: leaveCommentValue })
      .then(({ commentId }) =>
        store.fetchNewsCommentById(commentId).then(() => scrollToComment(commentId))
      )
      .then(() => setLeaveCommentValue(''))
      .then(() => setAnsweredAuthor(''));
  };

  const handleSendLike = (params: TypeApiRequest<'updateCommentLike'>) => {
    return store.updateCommentLike(params).then(() => store.fetchNewsCommentById(params.commentId));
  };

  const handleDeleteComment = (id: string, parentCommentId?: string) => {
    return store.deleteNewsComment(id, parentCommentId);
  };

  const handleTextAreaBackspace = (event: any) => {
    if (event.keyCode === 8 && Boolean(answeredAuthor) && !Boolean(event.target.selectionStart)) {
      setAnsweredAuthor('');
      setAnsweredCommentId('');
    }
  };

  return (
    <CommentsWrapper
      ref={refCommentsWrapper}
      style={{ minHeight: `${minCommentsHeight}px` }}
      data-test-id={'article-comments'}
    >
      <LeaveCommentGrid>
        <LeaveCommentWrapper ref={refLeaveCommentsWrapper}>
          {store.currentUserId ? (
            <LeaveCommentContent>
              <LeaveCommentAvatar>
                {user && user.avatar ? (
                  <img src={user.avatar.downloadUrl} alt="" />
                ) : (
                  <Icon size="large" icon="user" />
                )}
              </LeaveCommentAvatar>
              <LeaveCommentFieldWrapper>
                <LeaveCommentTextAreaWrapper>
                  {answeredAuthor && (
                    <LeaveCommentAuthorName>{answeredAuthor},</LeaveCommentAuthorName>
                  )}
                  <LeaveCommentTextArea
                    ref={refLeaveCommentsTextarea}
                    value={leaveCommentValue}
                    onChange={event => setLeaveCommentValue(event.target.value)}
                    rows={textareaIsMultiline ? undefined : 1}
                    placeholder="Оставить комментарий..."
                    onKeyDown={handleTextAreaBackspace}
                  />
                </LeaveCommentTextAreaWrapper>
              </LeaveCommentFieldWrapper>
              <LeaveCommentButton disabled={!leaveCommentValue} onClick={handleSubmit}>
                <Icon size={'small'} icon={'arrow_right'} />
              </LeaveCommentButton>
            </LeaveCommentContent>
          ) : (
            <LeaveCommentContentUnregistered>
              <LeaveCommentNotificationIconWrapper>
                <img src={iconWaring} />
              </LeaveCommentNotificationIconWrapper>
              <LeaveCommentNotificationText>
                Только зарегистрированные пользователи могут оставлять комментарии
              </LeaveCommentNotificationText>
              <Gutter />
              <ButtonAuth href={`${window._env_.AUTH_URL}${AuthorizationRoutes.signIn}`}>
                <span data-test-id={'article-comments-auth'}>Вход</span>
                <Icon size={'small'} icon={'arrow_right'} />
              </ButtonAuth>
              <ButtonRegister
                href={`${window._env_.AUTH_URL}${AuthorizationRoutes.signUp}`}
                data-test-id={'article-comments-reg'}
              >
                Регистрация
              </ButtonRegister>
            </LeaveCommentContentUnregistered>
          )}
        </LeaveCommentWrapper>
      </LeaveCommentGrid>
      {store.newsComments.length === 0 && (
        <LeaveCommentNoComments data-test-id={'article-comments-no-comment'}>
          Комментариев нет
        </LeaveCommentNoComments>
      )}
      {store.newsComments.map((comment, index) => (
        <CommentItemComponent
          dataTestId={`comment-${index}`}
          key={comment.commentId}
          comment={comment}
          currentUserId={store.currentUserId}
          handleSendLike={handleSendLike}
          handleDeleteComment={handleDeleteComment}
          setAnsweredAuthor={setAnsweredAuthor}
          setAnsweredCommentId={setAnsweredCommentId}
          fetchNewsCommentAnswers={store.fetchNewsCommentAnswers}
        />
      ))}
    </CommentsWrapper>
  );
});
