import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { CulturesWizard } from '../../modules/cultures/components/CulturesWizard';
import { useStore } from '../../../shared/utils/IoC';
import { CulturesStore } from '../../modules/cultures/stores/cultures.store';
import { CardProps } from '../../modules/cultures/components/Card';

export const CulturesPage: FC = observer(() => {
  const store = useStore(CulturesStore);
  const {
    fetchCultures,
    breadcrumbs,
    resetAll,
    // searchQuery,
    setSearchQuery,
    isLoading,
    cultures,
    changeLevel,
    isAdditionalLoadVisible,
    isAdditionalLoading,
    fetchAdditionalLoadCultures,
    entity,
    searchQuery,
    reset,
  } = store;

  useEffect(() => {
    fetchCultures();
    return () => reset();
  }, []);

  const onChangeLevel = (id: string) => changeLevel(id);

  const convertCulturesToCards: CardProps[] = cultures.map(item => ({
    name: item.name,
    onClick: () => onChangeLevel(item.id),
    isLastLevel: !item.hasChild,
    id: item.id,
    descriptionName: item.name,
    description: item.description,
    avatar: item.picLink,
    code: item.code,
  }));

  const onReset = () => {
    resetAll();
    fetchCultures();
  };

  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <CulturesWizard
        isPage={true}
        entity={entity}
        searchQuery={searchQuery}
        cards={convertCulturesToCards}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        onChangeNode={(code, id, name, chainIndex) => changeLevel(id, chainIndex)}
        onReset={onReset}
        onSearch={setSearchQuery}
        isPaginational={isAdditionalLoadVisible}
        isPaginationLoading={isAdditionalLoading}
        loadAdditionalCards={fetchAdditionalLoadCultures}
      />
    </div>
  );
});
