import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Input, Select, Button } from '@uralhim-innovative/farmik-ui';

import application_time from '../../../../../../../../../shared/assets/images/application_time.png';
import { TypeUserRole } from '../../../../../../../../../shared/types/users';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { OrganizationInviteUserFormStore } from '../../stores/organization.inviteUserForm.store';
import { UserRoleMap } from '../../OrganizationUsersList';

import {
  ModalTitle,
  FieldWrapper,
  ActionsWrapper,
  ErrorLabel,
  ImageWrapper,
  InfoTitle,
} from './style';

type Props = {
  hideForm: () => void;
  getOrganizationUsersList: (organizationId: string) => void;
  organizationId: string;
};

const rolesOptions = [
  {
    label: 'Владелец',
    value: 'OWNER',
  },
  {
    label: 'Сотрудник',
    value: 'EMPLOYEE',
  },
  {
    label: 'Менеджер',
    value: 'MANAGER',
  },
];

export const InviteUserForm: FC<Props> = observer(
  ({ hideForm, getOrganizationUsersList, organizationId }) => {
    const {
      inviteUserByEmail,
      comment,
      email,
      errors,
      fio,
      position,
      setFormErrors,
      validateForm,
      setComment,
      setEmail,
      setFio,
      setPosition,
      setErrors,
      isValid,
      resetForm,
    } = useStore(OrganizationInviteUserFormStore);
    const [sended, setSended] = useState(false);

    useEffect(() => resetForm, []);

    const handleInviteUser = () => {
      const { isValid: _isValid, formErrors } = validateForm();

      if (_isValid) {
        inviteUserByEmail({
          email,
          position,
          comment,
          fio,
          organizationId,
        })
          .then(() => {
            getOrganizationUsersList(organizationId);
            setSended(true);
          })
          .catch(() => {
            setFormErrors('Возникла ошибка');
          });
      } else {
        setErrors(formErrors);
      }
    };

    const handleOnBlur = (field: string) => () => {
      const { formErrors } = validateForm();
      console.log(formErrors);

      setErrors({
        ...errors,
        // @ts-ignore
        [field]: formErrors[field],
      });
    };

    if (sended) {
      return (
        <>
          <ImageWrapper>
            <img src={application_time} alt="" />
          </ImageWrapper>
          <InfoTitle>Вы отправили приглавшение присоедениться к вашей организации</InfoTitle>
          <ActionsWrapper>
            <Button type="button" color="primary" onClick={hideForm}>
              Хорошо
            </Button>
          </ActionsWrapper>
        </>
      );
    }

    return (
      <>
        <ModalTitle>Добавление сотрудника</ModalTitle>
        <FieldWrapper>
          <Input
            label="Email"
            placeholder="email@email.com"
            error={errors.email}
            value={email}
            onChange={value => setEmail(value)}
            onBlur={handleOnBlur('email')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            label="ФИО"
            placeholder="Иван Иванович Иванов"
            error={errors.fio}
            value={fio}
            onChange={value => setFio(value)}
            onBlur={handleOnBlur('fio')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Select
            label="Роль"
            options={rolesOptions}
            defaultValue={{
              label: UserRoleMap[position],
              value: position,
            }}
            onChange={value => setPosition(value as TypeUserRole)}
            error={errors.position}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            label="Ваше сообщение"
            placeholder="Здравствуйте! Ждем вас в наших рядах. "
            error=""
            value={comment}
            onChange={value => setComment(value)}
          />
        </FieldWrapper>
        {errors.formErrors && <ErrorLabel>{errors.formErrors}</ErrorLabel>}
        <ActionsWrapper>
          <Button type="button" color="default" onClick={hideForm}>
            Отменить
          </Button>
          <Button disabled={!isValid} type="button" color="primary" onClick={handleInviteUser}>
            Отправить
          </Button>
        </ActionsWrapper>
      </>
    );
  }
);
