import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  applicationId: string;
};

type TypeResponse = {};

export const updateInvitationCancel: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/organizations/user/inviteCancel/${request.applicationId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
