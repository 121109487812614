import { action, computed, makeAutoObservable, runInAction } from 'mobx';

import { lazyInject } from '../../shared/utils/IoC';
import { Axios2 } from '../../shared/utils/axios2';

import { NotificationType } from './../models/notification-type';

export class NotificationPageStore {
  @lazyInject(Axios2)
  axios: Axios2;

  constructor(group: string, type: string) {
    makeAutoObservable(this);
    runInAction(() => {
      this.group = group;
      this.type = type;
    });
  }

  group: string;
  type: string;

  idToNotifications: Map<string, NotificationType> = new Map<string, NotificationType>();

  currentPage = 0;
  pageSize = 10;
  totalPages = 0;

  @action
  initiate = () => {
    this.fetchNotificationList();
  };

  @action
  fetchAdditional = () => {
    if (!this.isAdditionalLoadAllow) {
      return;
    }
    this.currentPage += 1;
    this.fetchNotificationList();
  };

  @computed
  get isAdditionalLoadAllow() {
    return this.currentPage !== this.totalPages;
  }

  isAdditionalLoading: false;

  @action
  fetchNotificationList = async () => {
    let response = null;

    if (!this.group && !this.type) {
      try {
        response = await this.axios.api.getNotificationList({
          page: this.currentPage,
          size: this.pageSize,
        });
      } catch (e) {
        console.error(`fetchNotificationList ${this.group}`);
        this.isAdditionalLoading = false;
        return;
      }
    } else {
      try {
        response = await this.axios.api.getNotificationList({
          notificationGroup: this.group,
          page: this.currentPage,
          size: this.pageSize,
        });
      } catch (e) {
        console.error(`fetchNotificationList ${this.group}`);
        this.isAdditionalLoading = false;
        return;
      }
    }

    this.isAdditionalLoading = false;
    this.totalPages = response.totalPages;

    response.content.forEach(item => {
      this.idToNotifications.set(item.id, {
        contentHtml: item.contentHtml,
        id: item.id,
        notificationType: item.notificationType,
        senderAvatar: item.senderAvatar,
        senderName: item.senderName,
        statusRead: item.statusRead,
      });
    });
  };

  @computed
  get notifications() {
    return Array.from(this.idToNotifications.values());
  }

  @action
  clearStore = () => {
    this.idToNotifications.clear();
    this.pageSize = 10;
    this.currentPage = 0;
    this.totalPages = 0;
  };
}
