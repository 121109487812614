import { TypeApiRoute } from '../models/TypeApiRoute';

type TypeRequest = {
  systemId: string;
};

type TypeResponse = {
  id: string;
  domain: string;
  pathPrefix: string;
  name: string;
};

export const getExternalSystemById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/externalSystems/${request.systemId}`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
