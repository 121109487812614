import { action, computed, makeAutoObservable, toJS } from 'mobx';

import { lazyInject, provide } from '../../modules/shared/utils/IoC';
import { Axios2, TypeApiResponse } from '../../modules/shared/utils/axios2';
import { Zoom } from '../pages/FullEditQuotesPage/components/Chart/Chart';

@provide.singleton()
export class FullQuoteStore {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(Axios2)
  protected axios: Axios2;

  id: string;
  config: any = {};

  @action
  fetchWidget = (id: string) => {
    this.axios.api
      .getWidgetInfo({ id })
      .then(this.onFetchWidgetSuccess)
      .catch(this.onFetchWidgetError);
  };

  widgets: Array<any> = [];

  @action.bound
  protected onFetchWidgetSuccess = async (response: TypeApiResponse<'getWidgetInfo'>) => {
    if (!response) {
      return;
    }
    this.config = JSON.parse(response.widget_config);
    const ids = this.config?.positions.map((i: any) => i.linkId);

    const positions = this.config?.positions.map((item: any) => {
      return {
        basisId: item.basisId,
        providerId: item.providerId,
        cultureId: item.cultureId,
        portId: item.portId,
        currencyId: item.currencyId,
        unitsId: item.unitsId,
        economicRegionId: item.economicRegionId,
        cultureClassId: item.cultureClassId,
        vat: item.vat,
      };
    });

    this.widgets = (
      await this.axios.api.getQuotesByPositions(positions, {
        noValidate: true,
      })
    ).map((item, index) => {
      return {
        ...item,
        linkId: ids[index],
      };
    });
    this.fetchCultureInformation();
    this.fetchHistoryData('WEEK');
    console.log('widgets', toJS(this.widgets));
  };

  @action.bound
  protected onFetchWidgetError = (response: TypeApiResponse<'getWidgetInfo'>) => {
    console.error('onFetchWidgetError ', response);
  };

  selectedIndex = '';

  @action
  selectQuote = (index: string) => {
    this.selectedIndex = index;
    if (!this.quote) {
      return;
    }
    this.chartPoints = this.widgets
      .filter(i => i.linkId === index)[0]
      .priceHistory.values.map((item: any) => {
        return [new Date(item.date).getTime(), item.price];
      });
    this.fetchCultureInformation();
    this.fetchHistoryData('WEEK');
  };

  chartPoints: Array<any> = [];

  @computed
  get quote() {
    console.log('widgets ', this.widgets);
    return this.widgets.filter(i => i.linkId === this.selectedIndex)[0];
  }

  @action
  fetchHistoryData = (frequently: Zoom) => {
    this.axios.api
      .getPriceHistory({ id: this.quote.id, freq: frequently }, { noValidate: true })
      .then(this.onFetchHistoryDataSuccess)
      .catch(this.onFetchHistoryDataError);
  };

  @action.bound
  protected onFetchHistoryDataSuccess = (response: TypeApiResponse<'getPriceHistory'>) => {
    this.chartPoints = response.values.map((item: any) => {
      return [new Date(item.date).getTime(), item.price];
    });
  };

  @action.bound
  protected onFetchHistoryDataError = () => {
    console.log('onFetchHistoryDataError');
  };

  @action
  fetchCultureInformation = () => {
    if (!this.quote) {
      return;
    }
    this.axios.api
      .getCultureById({ id: this.quote.culture.id }, { noValidate: true })
      .then(this.onFetchCultureInformationSuccess)
      .catch(this.onFetchCultureInformationError);
  };

  @action.bound
  protected onFetchCultureInformationSuccess = (response: TypeApiResponse<'getCultureById'>) => {
    this.description = response.description;
  };

  @action.bound
  protected onFetchCultureInformationError = () => {
    console.log('onFetchCultureInformationError');
  };

  description = '';

  @computed
  get sliderCards() {
    return this.widgets.map(item => {
      return {
        isActive: item.linkId === this.selectedIndex,
        linkId: item.linkId,
        id: item.id,
        image: item.culture.icon && item.culture.icon.downloadUrl,
        name: item.culture.name,
        position: `${item.basis && item.basis.name} ${
          item.economicRegion ? item.economicRegion.name : ''
        }`,
        price: item.price,
        tendency: {
          amount: item.tendency.diff,
          percent: item.tendency.diffPercent,
          direction: item.tendency.direction,
        },
      };
    });
  }
}
