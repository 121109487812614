import { FC, useRef, useState, useContext } from 'react';
import { GeolocationControl, Map, Placemark, YMaps } from 'react-yandex-maps';

import { RootStoresContext } from '../../../../modules/App';
import SunRiseIcon from '../../../assets/icons/sunrise.svg';
import SunSetIcon from '../../../assets/icons/sunset.svg';
import CloudyIcon from '../../../assets/weather-conditions/cloudy.svg';
import MapIcon from '../../../assets/icons/map.svg';
import HumidityIcon from '../../../assets/icons/humidity.svg';
import RainIcon from '../../../assets/icons/rain.svg';
import DewIcon from '../../../assets/icons/dew.svg';
import PressureIcon from '../../../assets/icons/pressure.svg';
import WindIcon from '../../../assets/icons/wind.svg';
import { WeatherEngToRus } from '../../../utils/WeatherEngToRus';
import { BreakpointsNumbers } from '../../../../modules/shared/consts/sizes';

import {
  Content,
  GeoPointNameWrapper,
  LeftBar,
  LineWeatherDescription,
  MainInformationHeader,
  MainInformationHeaderTime,
  MainInformationHeaderTitle,
  MainInformationWrapper,
  MapWrapper,
  RightBar,
  TemperatureFeelsLike,
  TemperatureNow,
  WeatherConditionIcon,
  MainInformation as MainInfo,
} from './style';

type MainInformationProps = {
  condition: string;
  sunrise: string;
  sunset: string;
  temperature: string;
  feelsLikeTemperature?: string;
  geoPoint: string;
  humidity: string;
  rain: string;
  rainChance: number;
  dew: string;
  pressure: string;
  windSpeed: string;
  windGust: string;
  windDirection: string;
  feelsLike: number;
  coordinates: Array<number>;
};

export const MainInformation: FC<MainInformationProps> = ({
  condition,
  temperature,
  geoPoint,
  sunset,
  sunrise,
  feelsLike,
  rain,
  humidity,
  dew,
  feelsLikeTemperature,
  pressure,
  rainChance,
  windDirection,
  windGust,
  windSpeed,
  coordinates,
}) => {
  const { uiStateStore } = useContext(RootStoresContext);
  const { deviceWidth } = uiStateStore;

  const [coordView, setCoordView] = useState<number[]>(coordinates);
  const ymaps: any = useRef(null);
  let map: any;

  const onYmapsLoad = (ymapsData: any) => {
    ymaps.current = ymapsData;

    if (!coordinates.length) {
      ymaps.current.geolocation
        .get({
          // Выставляем опцию для определения положения по ip
          provider: 'yandex',
          // Карта автоматически отцентрируется по положению пользователя.
          mapStateAutoApply: true,
        })
        .then((result: any) => {
          map.geoObjects.add(result.geoObjects);
        });
    }
  };

  const handleInstance = (instance: any) => {
    if (!map) {
      map = instance;
      // instance.events.add('click', handleOnMap);
    }
  };
  const precType = (id: number | string) => {
    const precTypeMap: { [key: string]: string } = {
      '0': 'без осадков',
      '1': 'дождь',
      '2': 'дождь со снегом',
      '3': 'снег',
      '4': 'град',
    };

    return precTypeMap[String(id)];
  };

  return (
    <MainInformationWrapper>
      <MainInfo>
        <MainInformationHeader>
          <MainInformationHeaderTitle>
            Сегодня <span>{WeatherEngToRus(condition)}</span>
          </MainInformationHeaderTitle>
          <MainInformationHeaderTime>
            <img src={SunRiseIcon} alt="" />
            <p>{sunrise}</p>
          </MainInformationHeaderTime>
          <MainInformationHeaderTime>
            <img src={SunSetIcon} alt="" />
            <p>{sunset}</p>
          </MainInformationHeaderTime>
        </MainInformationHeader>
        <Content>
          <LeftBar>
            <WeatherConditionIcon src={CloudyIcon} />
            <TemperatureNow>{temperature}°C</TemperatureNow>
            <TemperatureFeelsLike>Ощущается как {feelsLike}°С</TemperatureFeelsLike>
          </LeftBar>
          <RightBar>
            <GeoPointNameWrapper>
              <img src={MapIcon} alt="" />
              <p>{geoPoint}</p>
            </GeoPointNameWrapper>

            <LineWeatherDescription>
              <img src={HumidityIcon} />
              <p>
                Влажность
                <span>{humidity} %</span>
              </p>
            </LineWeatherDescription>
            <LineWeatherDescription>
              <img src={RainIcon} />
              <p>
                Осадки
                <span>{precType(rainChance)}</span>
              </p>
            </LineWeatherDescription>
            {/* <LineWeatherDescription>*/}
            {/*  <img src={RainIcon} />*/}
            {/*  <p>*/}
            {/*    Вероятность дождя*/}
            {/*    <span> -</span>*/}
            {/*  </p>*/}
            {/* </LineWeatherDescription>*/}
            <LineWeatherDescription>
              <img src={DewIcon} />
              <p>
                Точка росы
                <span>{dew}° С</span>
              </p>
            </LineWeatherDescription>
            <LineWeatherDescription>
              <img src={PressureIcon} />
              <p>
                Давление
                <span>{pressure} мм рт.ст.</span>
              </p>
            </LineWeatherDescription>
            <LineWeatherDescription>
              <img src={WindIcon} />
              <p>
                Скорость ветра
                <span>{windSpeed} м/с</span>
              </p>
            </LineWeatherDescription>
            <LineWeatherDescription>
              <img src={WindIcon} />
              <p>
                Порывы ветра
                <span>{windGust} м/с</span>
              </p>
            </LineWeatherDescription>
            <LineWeatherDescription>
              <img src={WindIcon} />
              <p>
                Направление ветра
                <span>{windDirection}</span>
              </p>
            </LineWeatherDescription>
          </RightBar>
        </Content>
      </MainInfo>
      <MapWrapper>
        <YMaps
          query={{
            ns: 'use-load-option',
            load: 'package.full',
            apikey: 'fc3a7de7-4fd6-4699-a3cc-c1b3863ed0b0',
          }}
        >
          <div>
            <Map
              width="100%"
              height={deviceWidth < BreakpointsNumbers.small ? 146 : 344}
              defaultState={{
                center: coordView,
                zoom: 11,
                modules: ['geoObject.addon.hint', 'SuggestView'],
                controls: [],
                behaviors: undefined,
              }}
              state={{
                center: coordinates,
                zoom: 11,
                modules: ['geoObject.addon.hint', 'SuggestView'],
                controls: [],
                behaviors: undefined,
              }}
              onLoad={onYmapsLoad}
              // @ts-ignore
              instanceRef={handleInstance}
              onClick={() => 1}
            >
              <Placemark
                // @ts-ignore
                geometry={coordinates}
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                properties={{
                  hintContent: 'Поле',
                  balloonContent: 'Поле',
                }}
              />
            </Map>
          </div>
        </YMaps>
      </MapWrapper>
    </MainInformationWrapper>
  );
};
