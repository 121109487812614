import styled from 'styled-components';

import { Colors } from '../../../../modules/shared/consts/color';
import { Breakpoints } from '../../../../modules/shared/consts/sizes';

export const Wrapper = styled.div`
  width: 100%;
  height: 96px;
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
  transition: 300ms;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  } ;
`;

export const ContentWrapper = styled.div`
  width: 1032px;
  height: 96px;
  overflow-x: hidden;
  display: flex;
  align-items: center;

  @media (max-width: 1316px) {
    max-width: calc(100vw - 289px);
  }
`;

type ControlStyledProps = {
  isActive: boolean;
};

const renderColor = (isActive: boolean) =>
  isActive
    ? `
  svg * {
    fill: ${Colors.green} !important;
  }
`
    : `
  svg * {
    fill: ${Colors.grayBorder} !important;
  }
`;

export const LeftControl = styled.div<ControlStyledProps>`
  position: absolute;
  left: 0;
  top: 16px;
  width: 32px;
  height: 64px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  background: ${Colors.white};
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  ${({ isActive }) => renderColor(isActive)};
`;

export const RightControl = styled.div<ControlStyledProps>`
  position: absolute;
  right: 0;
  top: 16px;
  width: 32px;
  height: 64px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  background: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
  z-index: 2;
  ${({ isActive }) => renderColor(isActive)};
`;

type LineStyledProps = {
  shift: number;
};

type GeoPointType = {
  active: boolean;
};

const renderColors = (isActive: boolean) => {
  return isActive
    ? `
      background: ${Colors.green}; 
      }
      ${Temperature}, ${GeoPointTitle}, ${Condition} {
        color: ${Colors.white} !important; 
      }
      `
    : `background: ${Colors.white}; color: ${Colors.darkGray}; `;
};

export const GeoPoint = styled.div<GeoPointType>`
  cursor: pointer;
  user-select: none;
  width: 220px;
  height: 64px;
  margin-right: 8px;
  background: ${Colors.white};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  ${({ active }) => renderColors(active)}

  &:first-child {
    margin-left: 8px;
  }
`;

export const GeoPointTitle = styled.p`
  max-width: 107px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.darkGray};
`;

export const RightBar = styled.div`
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const ConditionIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const Temperature = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.darkGray};
`;

export const Condition = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
  text-align: right;
`;

export const Line = styled.div<LineStyledProps>`
  width: 9999px;
  height: 64px;
  display: flex;
  ${GeoPoint}:last-child {
    margin-right: 0;
  }
  transition: 300ms;
  ${({ shift }) => `transform: translate3d(${shift}px, 0, 0 );`};

  &:after {
    content: '';
    width: 220px;
    height: 1px;
    flex: 0 0 220px;
  }
`;

export const MobileLine = styled.div`
  height: 64px;
  display: flex;
  padding-left: 5px;

  & > div {
    flex: 0 0 220px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }
`;

export const MobileSliderWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding: 18px 0;
  display: none;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;
