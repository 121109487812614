import React, { FC } from 'react';
import { Icon as IconBlock } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';

import { ProfileRoutes } from '../../../../../modules/main/modules/dashboard/pages/profile/routes';
import { Arrow } from '../../../../components/Quotes/QuotesEditWidget/style';
import ARROW_UP from '../../../../assets/icons/arrow-top.svg';
import ARROW_DOWN from '../../../../assets/icons/arrow-bottom.svg';
import { Colors } from '../../../../../modules/shared/consts/color';
import { formatPrice } from '../../../../../modules/shared/utils/formatPrice';

import {
  Wrapper,
  LeftBar,
  RightBar,
  ButtonWrapper,
  Title,
  Description,
  Price,
  PriceLine,
  Stonks,
  Icon,
  LeftBackButton,
} from './style';

type HeaderProps = {
  price: string;
  title: string;
  tendency: {
    amount: number;
    percent: number;
    direction: 'DESC' | 'ASC' | 'NONE';
  };
  cultureClass?: {
    id: string;
    name: string;
  };
  qualityString: string;
  port: string;
  basis: string;
  image: string;
};

export const Header: FC<HeaderProps> = ({
  title,
  price,
  cultureClass,
  tendency,
  port,
  basis,
  image,
}) => {
  const history = useHistory();

  return (
    <Wrapper>
      <LeftBar>
        <ButtonWrapper>
          <LeftBackButton onClick={() => history.push(ProfileRoutes.Widgets)}>
            <IconBlock size={'small'} icon={'arrow_left'} fill={Colors.darkGray} />
            Назад ко всем виджетам
          </LeftBackButton>

          <Title data-test-id={'quote-full-title'}>{title}</Title>
          <PriceLine>
            <Price data-test-id={'quote-full-price'}>{formatPrice(price)} Р</Price>
            <Stonks direction={tendency.direction} data-test-id={'quote-full-stonks'}>
              {tendency.direction === 'ASC' && <Arrow style={{ height: '10px' }} src={ARROW_UP} />}
              {tendency.direction === 'DESC' && (
                <Arrow style={{ height: '10px' }} isDown={true} src={ARROW_DOWN} />
              )}
              {tendency.amount} ({tendency.percent}%)
            </Stonks>
            {/* <Description>{qualityString} клейковины</Description>*/}
            <Description>
              <span style={{ marginRight: 20 }} data-test-id={'quote-full-basis'}>
                {basis}
              </span>
              <span data-test-id={'quote-full-port'}>{port}</span>
            </Description>
            {cultureClass && (
              <Description data-test-id={'quote-full-description'}>{cultureClass.name}</Description>
            )}
          </PriceLine>
        </ButtonWrapper>
      </LeftBar>
      <RightBar>
        {/* <Link label={'Изменить виджет'} color={'primary'} onClick={() => 1} />*/}
        <Icon src={image} alt="" />
      </RightBar>
    </Wrapper>
  );
};
